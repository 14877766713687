import useQuery from 'hooks/useQuery'
import { api } from 'api'
import { useEntityList } from 'hooks/useEntityList/useEntityList'
import { useMemo } from 'react'

export const useCompanyData = () => {
  const defaultParameters = useMemo(() => ({ IsFavourite: true }), [])
  const { isLoading, request } = useQuery(data => api.accounts.fetchList(data))

  const searchParameters = useMemo(() => (
    { ...defaultParameters }
  ), [defaultParameters])

  const {
    getNextPage,
    hasMoreRows,
    totalCount,
    dataList
  } = useEntityList({
    request, searchParameters
  })

  return {
    getNextPage,
    isLoading,
    hasMoreRows,
    totalCount,
    dataList,
  }
}
