import { Button, Icon, Stack, Text } from '@chakra-ui/react'
import { FiLock } from 'react-icons/fi'
import React from 'react'

export const Block = () => {

  return (
    <Stack lineHeight="1.3rem" spacing="10px" alignItems="center">
      <Icon as={FiLock} fontSize="1.6rem"/>
      <Text>Авторизуйтесь, чтобы увидеть информацию</Text>
      <Button
        width="100%"
        onClick={() => window.open(`/account/0`)}
      >Авторизоваться</Button>
    </Stack>
  )
}
