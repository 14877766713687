import React, { useState } from 'react'
import { Box, Button, Collapse, Grid, Heading, Stack, Text, useColorMode, useColorModeValue } from '@chakra-ui/react'
import { UnauthorizedInfo } from 'components/UnauthorizedInfo'
import { useAuthentication } from 'hooks/useAuthentication'

export const ContactsCompany = ({ phone, email }) => {
  const { isAuthenticated } = useAuthentication()
  const bg = useColorModeValue('white', 'gray.700')
  const colorSecondaryText = useColorModeValue('gray.400', 'gray.500')
  const { colorMode } = useColorMode()
  const [isShow, setIsShow] = useState(false)
  return (
    <Box
      position="sticky"
      top="20px"
      borderRadius="12px"
      padding="28px"
      backgroundColor={bg}
      {...colorMode === 'light' && {boxShadow: 'rgba(15, 15, 15, 0.1) 0px 3px 6px, rgba(15, 15, 15, 0.1) 0px 9px 24px'}}
    >
      {isAuthenticated ? (
        <Stack spacing="28px">
          <Heading fontSize="1.1rem">Контакты компании</Heading>
          {!isShow && (
            <Button onClick={setIsShow} colorScheme="blue" width="100%" size="lg">Показать контакты</Button>
          )}
          <Collapse in={isShow}>
            <Grid templateColumns="0.5fr 1fr" gap="6px">
              <Text color={colorSecondaryText}>Телефон:</Text>
              {phone ? <Text>{phone}</Text> : <Text color={colorSecondaryText}>Нет</Text>}
              <Text color={colorSecondaryText}>Почта:</Text>
              {email ? <Text>{email}</Text> : <Text color={colorSecondaryText}>Нет</Text>}
            </Grid>
          </Collapse>
        </Stack>
        ) : (
        <Stack spacing="28px">
          <Heading fontSize="1.1rem">Контакты компании</Heading>
          <UnauthorizedInfo.Block/>
        </Stack>
      )}
    </Box>
  )
}
