import { MapBox } from '../MapBox'
import { MapPin } from '../MapPin'
import { Box, Center, HStack, Icon, Text, useColorModeValue } from '@chakra-ui/react'
import React, { useState } from 'react'
import { HiMapPin } from 'react-icons/hi2'

export const ContentMap = ({ latitude, longitude }) => {
  const [isShow, setIsShow] = useState(false)

  const borderColorHover = useColorModeValue('gray.300', 'gray.700')

  if (!isShow) {
    return (
      <Center
        height="56px"
        borderWidth={1}
        cursor="pointer"
        borderRadius="14px"
        transition="0.3s"
        _hover={{
          borderColor: borderColorHover
        }}
        onClick={() => setIsShow(true)}
      >
        <HStack>
          <Icon as={HiMapPin} color="#ED64A6" fontSize="1.4rem"/>
          <Text>Показать на карте</Text>
        </HStack>
      </Center>
    )
  }

  return (
    <Box height="300px">
      <MapBox
        initialViewState={{
          longitude: longitude,
          latitude: latitude,
          zoom: 12,
        }}
      >
        <MapPin
          longitude={longitude}
          latitude={latitude}
        />
      </MapBox>
    </Box>
  )
}
