import React from 'react'
import { Grid } from '@chakra-ui/react'
import { useWindowHeight } from '../../hooks/useWindowHeight'

export const ListContent = ({ children, ...props }) => {
  const windowHeight = useWindowHeight()
  return (
    <Grid
      //height={`${windowHeight}px`}
      templateColumns="minmax(500px, 1fr) minmax(360px, 400px)"
      //gap="40px"
      pb="80px"
      flex={1}
      {...props}
    >
      {children}
    </Grid>
  )
}
