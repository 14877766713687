import React from 'react'
import { CardWrapper } from 'components/CardWrapper'
import { Heading, Stack } from '@chakra-ui/react'
import { LayoutServicesUnauthorized } from '../../components/LayoutServicesUnauthorized'
import { RegistrationForm } from '../../components/RegistrationForm'

export const Registration = () => {
  return (
    <LayoutServicesUnauthorized>
      <CardWrapper
        maxW="500px"
        width="100%"
        overflow="initial"
      >
        <Stack pt="28px" pb="30px" px="40px" spacing="20px">
          <Heading
            fontSize="1.8rem"
            textAlign="center"
            borderBottomWidth={1}
            pb="20px"
          >
            Регистрация
          </Heading>
          <RegistrationForm />
        </Stack>
      </CardWrapper>
    </LayoutServicesUnauthorized>
  )
}
