import { SlideWrapper } from '../components/SlideWrapper'
import { Flex, Grid, Heading, Image, Stack, Text, useMediaQuery } from '@chakra-ui/react'
import { Container } from '../components/Container'
import Img from './images/image.png'
import Img2 from './images/image-2.png'
import { Scene } from 'react-scrollmagic'
import { useWindowHeight } from '../components/useWindowHeight'
import { transition } from '../constants/transition'
import Bg from './images/bg.svg'
import Bgone from './images/bg-1.svg'
import { Logo } from '../components/Logo'

export const Additional  = () => {
  const windowHeight = useWindowHeight()
  const [isLarge768] = useMediaQuery('(min-width: 768px)')

  return (
    <>
      <Scene duration={windowHeight * 3} triggerElement="#scene29">
        {scene29 => {
          const isScene1 = scene29 > 0.44
          return (
            <div id="scene29">
              <Scene duration={windowHeight} triggerElement="#scene30">
                {scene30 => {
                  const style = () => {
                    if (isLarge768) {
                      if (!isScene1) {
                        return { backgroundColor: '#F0F0F0', backgroundImage: Bg, backgroundSize: 'cover'}
                      } else {
                        return { backgroundColor: 'white', backgroundImage: Bgone, backgroundSize: 'cover'}
                      }
                    }
                    return { backgroundColor: '#F0F0F0'}
                  }
                  return (
                    <div>
                      <SlideWrapper
                        justifyContent="space-between"
                        top={0}
                        position={['static', 'static', 'sticky']}
                        transition={transition.cubic}
                        pt={['20px', '20px', '0px']}
                        pb={['30px', '30px', '0px']}
                        {...style()}
                      >
                        <Container flex={1}>
                          <Grid
                            templateColumns={['1fr', '1fr', '1fr', '.68fr 1fr']}
                            gap="50px"
                            flex={1}
                          >
                            <Flex
                              alignItems="center"
                              maxW="500px"
                              transition={transition.cubic}
                            >
                              <Stack spacing={4} color='black'>
                                <Logo/>
                                <Heading fontSize={['30px', '40px', '60px', '65px']}>
                                  Дополнительные возможности
                                </Heading>
                                <Text pl={2}>
                                  - История просмотров вашего оборудования и заявок
                                </Text>
                                <Text pl={2}>
                                  - Сортировка оборудования/заявок/компаний по местоположению
                                </Text>
                                <Text pl={2}>
                                  - Добавление оборудования/заявок в избранное
                                </Text>
                                <Text pl={2}>
                                  - Система помощи пользователям
                                </Text>
                              </Stack>
                            </Flex>
                            <Flex alignItems="center" display={['none', 'none', 'none', 'flex']}>
                              <Image
                                position="absolute"
                                width="1100px"
                                height="auto"
                                src={Img}
                                borderRadius="30px"
                                boxShadow="0px 20px 100px rgba(0, 0, 0, 0.1)"
                                transition={transition.cubic}
                                transform={isScene1 ? 'scale(.95)' : 'scale(0.9)'}
                              />
                              <Image
                                position="absolute"
                                width="1100px"
                                height="auto"
                                zIndex={2}
                                src={Img2}
                                opacity={isScene1 ? 1 : 0}
                                borderRadius="30px"
                                boxShadow="0px 20px 100px rgba(0, 0, 0, 0.1)"
                                transition={transition.cubic}
                                transform={isScene1 ? 'scale(.95)' : 'scale(0.9)'}
                              />
                            </Flex>
                          </Grid>
                        </Container>
                      </SlideWrapper>
                       <SlideWrapper id="scene30"/>
                    </div>
                  )
                }}
              </Scene>
            </div>
          )
        }}
      </Scene>
    </>
  )
}
