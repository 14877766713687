import React, { useEffect, useRef, useState } from 'react'
import { Box, Flex, useColorModeValue } from '@chakra-ui/react'

const Item = ({ onSetActiveLine, onChange, isActive, value, children }) => {
  const container = useRef()

  const color = useColorModeValue('blue.400', 'blue.300')
  const colorActive = useColorModeValue('gray.600', 'gray.500')

  const handleClick = () => {
    const param = {
      left: container.current?.offsetLeft,
      width: container.current?.offsetWidth,
    }
    onSetActiveLine && onSetActiveLine(param)
    onChange && onChange(value)
  }

  useEffect(() => {
    if (isActive) {
      const param = {
        left: container.current?.offsetLeft,
        width: container.current?.offsetWidth,
      }
      onSetActiveLine && onSetActiveLine(param)
    }
  }, [])

  return (
    <Flex
      onClick={handleClick}
      ref={container}
      fontSize="20px"
      alignItems="center"
      justifyContent="center"
      px="10px"
      mx="10px"
      cursor="pointer"
      fontWeight="semibold"
      color={isActive ? color : colorActive}
      transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
    >
      {children}
    </Flex>
  )
}

export const SegmentControl = ({ options, value, onChange }) => {
  const [activeLine, setActiveLine] = useState({ left: 0, width: 0})
  const color = useColorModeValue('blue.400', 'blue.300')

  return (
    <Flex height="54px" width="100%" justifyContent="center" borderBottomWidth={1}>
      <Flex position="relative">
        {options.map((item, index) => (
          <Item
            key={index}
            index={index}
            onSetActiveLine={setActiveLine}
            onChange={onChange}
            isActive={value === item.value}
            value={item.value}
          >
            {item.label}
          </Item>
        ))}
        <Box
          transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
          position="absolute"
          height="2px"
          backgroundColor={color}
          left={`${activeLine.left}px`}
          width={`${activeLine.width}px`}
          bottom={0}
        />
      </Flex>
    </Flex>
  )
}
