import axios from 'axios'
import { bindActionCreators } from '@reduxjs/toolkit'
import { store } from './store';
import { resetStore } from './store/reset'
import { apiConfig } from './api.config'

const TIMEOUT = 1 * 60 * 3000
axios.defaults.timeout = TIMEOUT
axios.defaults.baseURL = apiConfig

const actions = bindActionCreators(
  { resetStore },store.dispatch)

let token

const getSettings = () => {
  const _token = store.getState().profile.authentication.token
  if (_token !== token) {
    token = _token
  }
};

const unsubscribe = store.subscribe(getSettings);

const setupAxiosInterceptors = () => {
  const onRequestSuccess = config => {
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`
    }
    return config
  }
  const onResponseSuccess = response => response;
  const onResponseError = err => {
    const status = err.status || err.response?.status
    if (status === 401) {
      actions.resetStore()
    }
    return Promise.reject(err)
  };
  axios.interceptors.request.use(onRequestSuccess)
  axios.interceptors.response.use(onResponseSuccess, onResponseError)
}

setupAxiosInterceptors()
