import useQuery from 'hooks/useQuery'
import { api } from 'api'
import { useEffect } from 'react'
import { useToast } from '@chakra-ui/react'

export const useFetchMachine = () => {
  const toast = useToast()
  const {
    isLoading,
    isSuccess,
    response,
    isError,
    error,
    request
  } = useQuery((data) => api.classifier.fetchMachine(data))

  useEffect(() => {
    if (isError) {
      toast({
        title: 'Ошибка',
        description: error.response?.data?.ErrorMessage,
        status: 'error',
      })
    }
  }, [isError])

  return ({
    fetchMachine: request,
    dataMachine: response,
    isLoadingFetchMachine: isLoading,
    isSuccessFetchMachine: isSuccess
  })
}
