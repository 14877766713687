import React, { useEffect, useState } from 'react'
import { PageHeading } from 'components/PageHeading'
import { Button, Tab, TabList, TabPanels, Tabs } from '@chakra-ui/react'
import { VendorQuestionnaire } from 'entities/VendorQuestionnaire'
import { useHistory, useParams } from 'react-router'
import { Profile } from 'entities/Profile'
import { TabPanel } from 'components/TabPanel'
import { Notifications } from './components/Notifications'
import { Count } from 'components/Count'
import { useFetchNotificationCount } from 'services/useFetchNotificationCount'
import { useAccess } from 'hooks/useAccess'
import { rolesType } from 'constants/rolesType'
import { Container } from 'components/Container'
import { useFetchReplyCount } from 'services/useFetchReplyCount'
import { Reply } from './components/Reply'
import { Helmet } from 'react-helmet'
import { ScrollBox } from 'components/ScrollBox'

export const Account = () => {
  const { paramTabIndex } = useParams()
  const [isAdmin] = useAccess([rolesType.admin])
  const history = useHistory()
  const [tabIndex, setTabIndex] = useState(0)
  const [isEdit, setIsEdit] = useState(false)
  const { notificationCount } = useFetchNotificationCount()
  const { replyCountCount } = useFetchReplyCount()

  const handleTabsChange = (index) => {
    history.replace(`/account/${index}`)
  }

  const handleLogout = () => {
    history.push('/logout')
  }

  useEffect(() => {
    setTabIndex(Number(paramTabIndex))
  }, [paramTabIndex])

  return (
    <>
      <Helmet>
        <title>Профиль – Сделаем в России</title>
      </Helmet>
      <Container>
        <ScrollBox>
          <PageHeading
            renderAction={
              <Button
                size="sm"
                colorScheme="gray"
                variant="outline"
                onClick={handleLogout}
              >
                Выход
              </Button>
            }
          >
            Личный кабинет
          </PageHeading>
          <Tabs onChange={handleTabsChange} index={tabIndex} isLazy>
            <TabList>
              <Tab>Профиль</Tab>
              {!isAdmin && <Tab>Анкета исполнителя</Tab>}
              <Tab>
                {isAdmin ? 'Логирование' : 'Уведомления'}
                <Count ml={2} count={notificationCount}/>
              </Tab>
              {!isAdmin && (
                <Tab>
                  Отклики
                  <Count ml={2} count={replyCountCount}/>
                </Tab>
              )}
            </TabList>

            <TabPanels>
              <TabPanel>
                <Profile isEdit={isEdit} setIsEdit={setIsEdit}/>
              </TabPanel>
              {!isAdmin && (
                <TabPanel>
                  <VendorQuestionnaire isEdit={isEdit} setIsEdit={setIsEdit}/>
                </TabPanel>
              )}
              <TabPanel>
                <Notifications/>
              </TabPanel>
              <TabPanel>
                <Reply/>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ScrollBox>
      </Container>
    </>
  )
}
