import { useEffect, useState } from 'react'
import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { apiConfig } from 'api.config'
import { isEmpty } from 'lodash'

export const useFetchImage = (image) => {
  const { token } = useSelector(state => state.profile.authentication)
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState(null)

  const request = useCallback((id) => {
    setIsLoading(true)
    fetch(`${apiConfig}/image/download/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    }).then(res => res.blob().then(blob => {
      const reader = new FileReader()
      reader.readAsDataURL(blob)
      reader.onloadend = () => {
        setData(reader.result)
      }
    })).finally(() => setIsLoading(false))
  }, [])

  useEffect(() => {
    if (!isEmpty(image)) {
      request(image?.id)
    }
  }, [image])

  return ({
    getImage: request,
    isLoadingFetchImage: isLoading,
    dataImage: data
  })
}
