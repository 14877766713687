import { combineReducers } from '@reduxjs/toolkit'
import authenticationReducer from './authentication'
import settingsReducer from './settings'
import accountReducer from './account'

export default combineReducers({
  authentication: authenticationReducer,
  settings: settingsReducer,
  account: accountReducer
})
