import axios from 'axios'

const ENTITY = 'vendorsData'

export const vendorsData = Object.freeze({
  async fetchData(id) {
    const url = id ? `/${ENTITY}/?accountId=${id}` : `/${ENTITY}`
    const response = await axios.get(url)
    return response.data
  },
  async updateData(data, id) {
    const url = id ? `/${ENTITY}/?accountId=${id}` : `/${ENTITY}`
    const response = await axios.put(url, data)
    return response.data
  }
})
