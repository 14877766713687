import React from 'react'
import { Icon } from '@chakra-ui/react';

export const IconLamp = ({ ...props }) => {
  return (
    <Icon viewBox="0 0 20 20" fill="none" {...props}>
      <path d="M11.86 11.8301H8.14001" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M7.96499 11.6591C6.91999 10.9211 6.29399 9.63208 6.51399 8.21308C6.75299 6.67908 8.03099 5.45508 9.57299 5.27508C11.713 5.02408 13.529 6.69008 13.529 8.77908C13.529 9.97008 12.937 11.0211 12.033 11.6601C11.871 11.7751 11.764 11.9521 11.764 12.1511V13.7791C11.764 14.5911 11.106 15.2501 10.293 15.2501H9.70499C8.89299 15.2501 8.23399 14.5921 8.23399 13.7791V12.1541C8.23499 11.9531 8.12799 11.7751 7.96499 11.6591Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M16.364 3.63604C19.8787 7.15076 19.8787 12.8492 16.364 16.3639C12.8493 19.8787 7.1508 19.8787 3.6361 16.3639C0.121381 12.8492 0.121381 7.15074 3.6361 3.63604C7.15082 0.12132 12.8493 0.12132 16.364 3.63604" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>

    </Icon>
  )
}
