import useQuery from 'hooks/useQuery'
import { api } from 'api'
import { useEffect } from 'react'
import { useToast } from '@chakra-ui/react'
import { actions as authentication } from 'store/profile/authentication/store'
import { useDispatch, useSelector } from 'react-redux';

export const useAuthentication = () => {
  const toast = useToast()
  const dispatch = useDispatch()
  const { isAuthenticated } = useSelector(state => state.profile.authentication);

  const {
    isError,
    isLoading,
    isSuccess,
    request,
    response,
    error
  } = useQuery((data) => api.accounts.authenticate(data))

  useEffect(() => {
    if (isError) {
      toast({
        title: 'Ошибка',
        description: error.response?.data?.ErrorMessage,
        status: 'error',
      })
    }
  }, [isError])

  useEffect(() => {
    if (isSuccess) {
      dispatch(authentication.update(response))
    }
  }, [isSuccess])

  return {
    request,
    isLoading,
    isAuthenticated
  }
}
