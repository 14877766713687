import React from 'react'
import { useNotifications } from './services/useNotifications'
import { Box, Flex, Grid, Stack, Text, useColorModeValue } from '@chakra-ui/react'
import { isEmpty } from 'lodash'
import { BellIcon } from '@chakra-ui/icons'
import { getDateTimeReadFormat } from 'utils/dateFormatting'
import { Loading } from 'components/Loading'

const Item = ({ data }) => {
  const bgIcon = useColorModeValue('gray.100', 'gray.700')
  const bgIconActive = useColorModeValue('blue.500', 'blue.300')

  return (
    <Grid templateColumns="40px 1fr 200px" gap="14px" alignItems="center">
      <Flex
        flex="none"
        backgroundColor={data?.isNew ? bgIconActive : bgIcon}
        boxSize="40px"
        borderRadius="full"
        alignItems="center"
        justifyContent="center"
      >
        <BellIcon fontSize="1.1rem" color={data?.isNew ? 'white' : 'gray.400'}/>
      </Flex>
      <Stack spacing={0}>
        {data.message ? (
          <Text lineHeight={1.3} fontWeight={data?.isNew ? '700' : 'normal'}>{data.message}</Text>
        ) : (
          <Text color="gray.400">{'Нет сообщения'}</Text>
        )}
      </Stack>
      <Flex justifyContent="flex-end" alignItems="center" color="gray.400" fontSize="0.8rem">
        {getDateTimeReadFormat(data.createdDateTime)}
      </Flex>
    </Grid>
  )
}

export const Notifications = () => {
  const { data, isLoading } = useNotifications()
  if (isLoading) {
    return <Loading/>
  }
  return (
    <Stack spacing={5} pb="80px">
      {isEmpty(data) ? (
        <Flex height="110px" align="center" justify="center">
          <Text color="gray.400">Нет уведомлений</Text>
        </Flex>
      ) : data.map((item, index) => <Item key={index} data={item}/>)}
      {data?.length === 30 && (
        <Box pt="30px">
          <Text fontSize="0.8rem" color="gray.400">Показано последние 30 уведомлений</Text>
        </Box>
      )}
    </Stack>
  )
}
