import React from 'react'
import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'

export const PolicyModal = ({isOpen, onClose}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay/>
      <ModalContent>
        <ModalHeader lineHeight="1.2">Политика в отношении обработки персональных данных</ModalHeader>
        <ModalCloseButton mt={1}/>
        <ModalBody>
          <Box height="400px" overflowY="scroll" fontSize="0.86rem">
            <p>
              Политика конфиденциальности
            </p>
            <p>
              Настоящая Политика конфиденциальности персональной информации (далее —
              Политика) действует в отношении всей информации, которую юр.лицо АНО
              «Региональный центр координации проектов по искусственному интеллекту и
              информационным технологиям «Горький» (ОГРН: 1205200017440, ИНН: 5260470977,
              адрес: 603115, г. Н.Новгород, ул.Большая Печерская, д.44, пом.17) и/или его
              аффилированные лица, могут получить о пользователе во время использования
              им сайта https://сделаемвроссии.рф .
            </p>
            <p>
              Использование сайта https://сделаемвроссии.рф означает безоговорочное
              согласие пользователя с настоящей Политикой и указанными в ней условиями
              обработки его персональной информации; в случае несогласия с этими
              условиями пользователь должен воздержаться от использования данного
              ресурса.
            </p>
            <p>
              1. Персональная информация пользователей, которую получает и обрабатывает
              сайт https://сделаемвроссии.рф
            </p>
            <p>
              1.1. В рамках настоящей Политики под «персональной информацией
              пользователя» понимаются:
            </p>
            <p>
              1.1.1. Персональная информация, которую пользователь предоставляет о себе
              самостоятельно при оставлении заявки, совершении покупки, регистрации
              (создании учётной записи) или в ином процессе использования сайта.
            </p>
            <p>
              1.1.2 Данные, которые автоматически передаются сайтом
              https://сделаемвроссии.рф в процессе его использования с помощью
              установленного на устройстве пользователя программного обеспечения, в том
              числе IP-адрес, информация из cookie, информация о браузере пользователя
              (или иной программе, с помощью которой осуществляется доступ к сайту),
              время доступа, адрес запрашиваемой страницы.
            </p>
            <p>
              1.1.3. Данные, которые предоставляются сайту, в целях осуществления
              оказания услуг и/или продаже товара и/или предоставления иных ценностей для
              посетителей сайта, в соответствии с деятельностью настоящего ресурса:
            </p>
            <p>
              фамилия
            </p>
            <p>
              имя
            </p>
            <p>
              отчество
            </p>
            <p>
              адрес регистрации/отправки корреспонденции
            </p>
            <p>
              электронная почта
            </p>
            <p>
              номер телефона
            </p>
            <p>
              ссылка на персональный сайт или соцсети
            </p>
            <p>
              ИНН
            </p>
            <p>
              ОГРН
            </p>
            <p>
              1.2. Настоящая Политика применима только к сайту https://сделаемвроссии.рф
              и не контролирует и не несет ответственность за сайты третьих лиц, на
              которые пользователь может перейти по ссылкам, доступным на сайте
              https://сделаемвроссии.рф. На таких сайтах у пользователя может собираться
              или запрашиваться иная персональная информация, а также могут совершаться
              иные действия.
            </p>
            <p>
              1.3. Сайт в общем случае не проверяет достоверность персональной
              информации, предоставляемой пользователями, и не осуществляет контроль за
              их дееспособностью. Однако сайт https://сделаемвроссии.рф исходит из того,
              что пользователь предоставляет достоверную и достаточную персональную
              информацию по вопросам, предлагаемым в формах настоящего ресурса, и
              поддерживает эту информацию в актуальном состоянии.
            </p>
            <p>
              2. Цели сбора и обработки персональной информации пользователей
            </p>
            <p>
              2.1. Сайт собирает и хранит только те персональные данные, которые
              необходимы для оказания услуг и/или продаже товара и/или предоставления
              иных ценностей для посетителей сайта https://сделаемвроссии.рф.
            </p>
            <p>
              2.2. Персональную информацию пользователя можно использовать в следующих
              целях:
            </p>
            <p>
              2.2.1 Идентификация стороны в рамках соглашений и договоров с сайтом
            </p>
            <p>
              2.2.2 Предоставление пользователю персонализированных услуг и сервисов,
              товаров и иных ценностей
            </p>
            <p>
              2.2.3 Связь с пользователем, в том числе направление уведомлений, запросов
              и информации, касающихся использования сайта, оказания услуг, а также
              обработка запросов и заявок от пользователя
            </p>
            <p>
              2.2.4 Улучшение качества сайта, удобства его использования, разработка
              новых товаров и услуг
            </p>
            <p>
              2.2.5 Таргетирование рекламных материалов
            </p>
            <p>
              2.2.6 Проведение статистических и иных исследований на основе
              предоставленных данных
            </p>
            <p>
              2.2.7 Передача данных третьим лицам, в целях осуществления деятельности
              ресурса (например доставка товара курьером, транспортной компанией и иные)
            </p>
            <p>
              2.2.8 Заключения, исполнения и прекращения гражданско-правовых договоров с
              физическими, юридическими лицами, индивидуальными предпринимателями и иными
              лицами, в случаях, предусмотренных действующим законодательством и/или
              Уставом предприятия
            </p>
            <p>
              3. Условия обработки персональной информации пользователя и её передачи
              третьим лицам
            </p>
            <p>
              3.1. Сайт https://сделаемвроссии.рф хранит персональную информацию
              пользователей в соответствии с внутренними регламентами конкретных
              сервисов.
            </p>
            <p>
              3.2. В отношении персональной информации пользователя сохраняется ее
              конфиденциальность, кроме случаев добровольного предоставления
              пользователем информации о себе для общего доступа неограниченному кругу
              лиц.
            </p>
            <p>
              3.3. Сайт https://сделаемвроссии.рф вправе передать персональную информацию
              пользователя третьим лицам в следующих случаях:
            </p>
            <p>
              3.3.1. Пользователь выразил свое согласие на такие действия, путем согласия
              выразившегося в предоставлении таких данных;
            </p>
            <p>
              3.3.2. Передача необходима в рамках использования пользователем
              определенного сайта https://сделаемвроссии.рф, либо для предоставления
              товаров и/или оказания услуги пользователю;
            </p>
            <p>
              3.3.3. Передача предусмотрена российским или иным применимым
              законодательством в рамках установленной законодательством процедуры;
            </p>
            <p>
              3.3.4. В целях обеспечения возможности защиты прав и законных интересов
              сайта https://сделаемвроссии.рф или третьих лиц в случаях, когда
              пользователь нарушает Пользовательское соглашение сайта
              https://сделаемвроссии.рф.
            </p>
            <p>
              3.4. При обработке персональных данных пользователей сайт
              https://сделаемвроссии.рф руководствуется Федеральным законом РФ «О
              персональных данных».
            </p>
            <p>
              4. Изменение пользователем персональной информации
            </p>
            <p>
              4.1. Пользователь может в любой момент изменить (обновить, дополнить)
              предоставленную им персональную информацию или её часть, а также параметры
              её конфиденциальности, оставив заявление в адрес администрации сайта
              следующим способом:
            </p>
            <p>
              Телефон: +7 (831) 414-05-52
            </p>
            <p>
              Email: info@сделаемвроссии.рф
            </p>
            <p>
              Форма обратной связи: https://сделаемвроссии.рф
            </p>
            <p>
              4.2. Пользователь может в любой момент, отозвать свое согласие на обработку
              персональных данных, оставив заявление в адрес администрации сайта
              следующим способом:
            </p>
            <p>
              Телефон: +7 (831) 414-05-52
            </p>
            <p>
              Email: info@сделаемвроссии.рф
            </p>
            <p>
              Форма обратной связи: https://сделаемвроссии.рф
            </p>
            <p>
              5. Меры, применяемые для защиты персональной информации пользователей
            </p>
            <p>
              Сайт принимает необходимые и достаточные организационные и технические меры
              для защиты персональной информации пользователя от неправомерного или
              случайного доступа, уничтожения, изменения, блокирования, копирования,
              распространения, а также от иных неправомерных действий с ней третьих лиц.
            </p>
            <p>
              6. Изменение Политики конфиденциальности. Применимое законодательство
            </p>
            <p>
              6.1. Сайт имеет право вносить изменения в настоящую Политику
              конфиденциальности. При внесении изменений в актуальной редакции
              указывается дата последнего обновления. Новая редакция Политики вступает в
              силу с момента ее размещения, если иное не предусмотрено новой редакцией
              Политики. Действующая редакция всегда находится на странице по адресу
              <a
                href="https://%25D0%25B8%25D1%2582-%25D0%25BC%25D0%25B0%25D1%2580%25D0%25BA%25D0%25B5%25D1%2582%25D0%25BF%25D0%25BB%25D0%25B5%25D0%25B9%25D1%2581.%25D1%2580%25D1%2584"
              >
                https://сделаемвроссии.рф
              </a>
              /fz/privacy.html
            </p>
            <p>
              6.2. К настоящей Политике и отношениям между пользователем и Сайтом,
              возникающим в связи с применением Политики конфиденциальности, подлежит
              применению право Российской Федерации.
            </p>
            <p>
              7. Обратная связь. Вопросы и предложения
            </p>
            <p>
              7.1. Все предложения или вопросы по поводу настоящей Политики следует
              направлять следующим способом:
            </p>
            <p>
              Телефон: +7 (831) 414-05-52
            </p>
            <p>
              Email: info@сделаемвроссии.рф
            </p>
            <p>
              Форма обратной связи: https://сделаемвроссии.рф
            </p>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
