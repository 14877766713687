import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { actions } from 'store/filter/supportRequest/store'

export const useFilter = () => {
  const dispatch = useDispatch()
  const filter = useSelector(state => state.filter.supportRequest)

  const update = useCallback(async (field, value) => {
    dispatch(actions.update({field, value}))
  }, [dispatch])

  const reset = useCallback(async () => {
    dispatch(actions.reset())
  }, [dispatch])

  return {
    dataFilter: filter,
    updateFilter: update,
    resetFilter: reset,
    sort: filter.sort
  };
};
