import { Modal } from 'components/Modal'
import { Form } from 'components/Form'
import { Field, Formik } from 'formik'
import * as Yup from 'yup'
import { InputForm } from 'components/InputForm'
import React, { useState } from 'react'
import { BlockSection } from 'components/BlockSection'
import { Button, IconButton, Stack, useDisclosure } from '@chakra-ui/react'
import { HiPlusCircle } from 'react-icons/hi'

const formSchema = Yup.object().shape({
  name: Yup.string().required('')
})

export const Add = ({ onCreate, parentId, header, children, ...props }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [isLoading, setIsLoading] = useState(false)

  const handleForm = async (values) => {
    try {
      setIsLoading(true)
      const dataRequest = {
        ...values,
        ...parentId && {
          parentId
        }
      }
      await onCreate(dataRequest)
      onClose()
    } catch (e) {} finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      {children ? (
        <Button
          colorScheme="blue"
          onClick={onOpen}
          width="fit-content"
          flex="none"
          leftIcon={<HiPlusCircle fontSize="1.3rem" />}
          {...props}
        >
          {children}
        </Button>
      ) : (
        <IconButton
          aria-label="Add"
          colorScheme="blue"
          borderRadius="full"
          icon={<HiPlusCircle fontSize="1rem" />}
          onClick={onOpen}
          {...props}
        />
      )}
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        header={header ?? 'Вид оборудования'}
      >
        <Formik
          enableReinitialize
          onSubmit={handleForm}
          validationSchema={formSchema}
          initialValues={{
            name: ''
          }}
        >
          {(props) => (
            <Form onSubmit={props.handleSubmit}>
              <Stack spacing="24px">
                <BlockSection>
                  <Field
                    autoFocus
                    name="name"
                    label="Наименование"
                    component={InputForm}
                    isDisabled={isLoading}
                  />
                </BlockSection>
                <BlockSection>
                  <Button
                    width="100%"
                    type="submit"
                    isLoading={isLoading}
                    colorScheme="blue"
                  >
                    Добавить
                  </Button>
                </BlockSection>
              </Stack>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  )
}
