import { Icon, IconButton } from '@chakra-ui/react'
import React from 'react'
import { MdCheckCircle } from 'react-icons/md'

export const VerifyButton = ({ isLoading, onClick }) => {
  return (
    <IconButton
      isLoading={isLoading}
      variant="ghost"
      size="sm"
      aria-label="Favorite"
      icon={<Icon as={MdCheckCircle} color="gray.400" fontSize="1.1rem"/>}
      borderRadius="full"
      onClick={onClick}
    />
  )
}
