import React from 'react'
import { FileWidget } from '../FileWidget'
import { ErrorBoundary } from '../ErrorBoundary'

export const FileWidgetForm = ({field, form, onDelete, ...props}) => {
  return (
    <ErrorBoundary>
      <FileWidget
        data={field.value}
        {...field}
        onDelete={onDelete}
        {...props}
      />
    </ErrorBoundary>
  )
}
