import axios from 'axios'

const TOKEN = '729b21efbde6763624c5416de4690e2f4027d20f'
const ENTITY = 'https://suggestions.dadata.ru'

const headers = {
  'Content-Type': 'application/json',
  'Accept': 'application/json',
  'Authorization': `Token ${TOKEN}`
}

export const daData = Object.freeze({
  async fetchOKPD2(data) {
    const response = await fetch(`${ENTITY}/suggestions/api/4_1/rs/suggest/okpd2`, {
      method: 'POST',
      mode: 'cors',
      headers,
      body: data
    })
    return await response.json()
  },
  async fetchOkved2(data) {
    const response = await fetch(`${ENTITY}/suggestions/api/4_1/rs/suggest/okved2`, {
      method: 'POST',
      mode: 'cors',
      headers,
      body: data
    })
    return await response.json()
  },
  async fetchAddress(data) {
    const response = await axios.request({
      url: `${ENTITY}/suggestions/api/4_1/rs/suggest/address`,
      method: 'POST',
      headers,
      data,
    })
    return response.data
  },
})
