import { ResponsiveLine } from '@nivo/line'
import { Box } from '@chakra-ui/react'

export const Line = ({ data, ...props }) => {
  return (
    <Box height="300px">
      <ResponsiveLine
        data={data}
        animate
        margin={{ top: 20, right: 20, bottom: 40, left: 40 }}
        enableSlices="x"
        xScale={{
          type: 'point',
          min: 0,
          max: 'auto',
        }}
        axisLeft={{
          legend: 'количество',
          legendOffset: 10,
        }}
        axisBottom={{
          legend: 'время',
          legendOffset: 32,
        }}
        curve="monotoneX"
        pointSize={3}
        pointBorderWidth={1}
        pointBorderColor={{
          from: 'color',
          modifiers: [['darker', 0.3]],
        }}
        useMesh={true}
        enableGridX={false}
        theme={{
          crosshair: {
            line: {
              strokeWidth: 1,
              stroke: '#774dd7',
              strokeOpacity: 1,
            },
          },
        }}
        /*sliceTooltip={({ slice }) => {
          console.log(slice)
          return (
            <div
              style={{
                background: 'white',
                padding: '9px 12px',
                border: '1px solid #ccc',
              }}
            >
              <div>x: {slice.id}</div>
              {slice.points.map(point => (
                <div
                  key={point.id}
                  style={{
                    color: point.serieColor,
                    padding: '3px 0',
                  }}
                >
                  <strong>{point.serieId}</strong> [{point.data.yFormatted}]
                </div>
              ))}
            </div>
          )
        }}*/
        {...props}
      />
    </Box>
  )
}
