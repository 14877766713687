import {
  Box,
  Center,
  HStack,
  Icon,
  IconButton,
  Stack,
  Text,
  useBoolean,
  useColorModeValue
} from '@chakra-ui/react';
import { ChevronRightIcon, SettingsIcon } from '@chakra-ui/icons'
import { ImPlus } from 'react-icons/im'
import React from 'react'
import { isEmpty } from 'lodash'
import { levelLabel } from '../../constants/levelLabel'
import { MdDragIndicator } from 'react-icons/md'

export const Item = (
  { isSearch, isDragging, data, level, children, onAdd, onUpdate, dragProvided }) => {
  const bg = useColorModeValue('white', 'transparent')
  const bgHover = useColorModeValue('gray.50', 'whiteAlpha.200')
  const colorHover = useColorModeValue('blue.400', 'blue.300')
  const bgPoint = useColorModeValue('gray.300', 'gray.500')
  const borderColor = useColorModeValue('gray.100', 'transparent')

  const [isActive, setIsActive] = useBoolean()
  const isEmptyChildren = isEmpty(data?.childrens)
  const isFind = data?.isFind || data?.isFindChildren

  const handleClick = () => {
    if (!isEmptyChildren && !isSearch) {
      setIsActive.toggle()
    }
  }

  const Points = ({ num }) => {
    const result = []
    for (let i = 0; i < num - 1; i++) {
      result.push(
        <Center key={i} boxSize="12px">
          <Box boxSize="3px" bg={bgPoint} borderRadius="full" />
        </Center>
      )
    }
    return result
  }

  if (!isFind) {
    return null
  }

  return (
    <Box
      ref={dragProvided.innerRef}
      {...dragProvided.draggableProps}
      style={dragProvided.draggableProps.style}
    >
      <HStack
        cursor="pointer"
        opacity={isFind ? 1 : 0.2}
        role="group"
        spacing="10px"
        borderRadius="8px"
        minH="60px"
        backgroundColor={bg}
        px="var(--chakra-space-4)"
        py="var(--chakra-space-2)"
        alignItems="center"
        justifyContent="space-between"
        transition="0.3s"
        borderColor="transparent"
        borderWidth={1}
        onClick={handleClick}
        _hover={{
          borderColor: borderColor,
          backgroundColor: bgHover
        }}
        {...isDragging && {
          borderColor: 'blue.400'
        }}
      >
        <HStack
          spacing="12px"
        >
          <HStack spacing="12px">
            <Points num={level}/>
            {!isSearch && (
              <>
                <Icon
                  as={ChevronRightIcon}
                  transition="0.3s"
                  transform={!isActive ? 'rotate(0deg)' : 'rotate(90deg)'}
                  opacity={isEmptyChildren ? 0.2 : 1}
                />
                <Center
                  boxSize="20px"
                  {...dragProvided.dragHandleProps}
                >
                  <Icon as={MdDragIndicator} fontSize="0.9rem" />
                </Center>
              </>
            )}

          </HStack>
          <Stack spacing="1px">
            <HStack spacing="8px">
              <Text
                transition="0.3s"
                fontWeight={(isSearch && data?.isFind) ? '700' : 'normal'}
                {...!isEmptyChildren && {
                  '_groupHover': {
                    color: colorHover
                  }
                }}
              >
                {data?.name}
              </Text>
              <Box
                fontSize="0.76rem"
                fontWeight="600"
                bg={bgHover}
                borderRadius="4px"
                px="4px"
              >
                {data?.childrens?.length}
              </Box>
            </HStack>
            <HStack spacing="3px">
              <Text
                transition="0.3s"
                fontSize="0.74rem"
                opacity={0.3}
                {...!isEmptyChildren && {
                  '_groupHover': {
                    color: colorHover,
                    opacity: 1
                  }
                }}
              >
                {levelLabel[level]}
              </Text>
            </HStack>
          </Stack>
        </HStack>
        <HStack spacing="14px">
          <IconButton
            opacity={0}
            onClick={onUpdate}
            variant="ghost"
            size="sm"
            aria-label="Update"
            icon={<SettingsIcon fontSize="0.8rem"/>}
            borderRadius="full"
            _groupHover={{
              opacity: 1
            }}
          />
          {level < 4 && (
            <IconButton
              aria-label={'add'}
              onClick={onAdd}
              opacity={0}
              colorScheme="blue"
              borderRadius="full"
              icon={<ImPlus fontSize="0.6rem" />}
              size="sm"
              _groupHover={{
                opacity: 1
              }}
            />
          )}
        </HStack>
      </HStack>
      {(isActive || isSearch) && children(data?.childrens)}
    </Box>
  )
}
