import React from 'react'
import { Icon } from '@chakra-ui/react'

export const IconCheck = (props) => {
  return (
    <Icon viewBox="0 0 12 9" {...props}>
      <g fill="currentColor">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.66649 7.11499L10.7945 0.986328L11.7378 1.92899L4.66649 9.00033L0.423828 4.75766L1.36649 3.81499L4.66649 7.11499Z"
        />
      </g>
    </Icon>
  )
}
