import axios from 'axios'

const ENTITY = 'address'

export const address = Object.freeze({
  async fetch(params) {
    const response = await axios.get(`/${ENTITY}/search`, { params })
    return response.data
  }
})
