import React, { useMemo, useRef } from 'react'
import { YMaps, Map } from '@pbe/react-yandex-maps'
import { MapPin } from '../MapPin'

export const MapDraggable = (
  { viewState, marker, setMarker, onMarkerDragEnd, isDraggable = true }
) => {
  const mapRef = useRef()

  const handleDragEnd = (e) => {
    const longitude = e[1].toFixed(6)
    const latitude = e[0].toFixed(6)

    setMarker({
      longitude,
      latitude
    })
    onMarkerDragEnd({
      longitude,
      latitude
    })
  }

  const mapState = useMemo(() => ({
    center: [viewState?.latitude ?? 55.751574, viewState?.longitude ?? 37.573856],
    zoom: viewState?.zoom ?? 6,
    controls: []
  }))

  return (
    <YMaps>
      <Map
        instanceRef={mapRef}
        width="100%"
        height="100%"
        state={mapState}
      >
        {marker && (
          <MapPin
            longitude={marker.longitude}
            latitude={marker.latitude}
            onDragEnd={handleDragEnd}
            isDraggable={isDraggable}
          />
        )}
      </Map>
    </YMaps>
  )
}
