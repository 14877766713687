import { useState } from 'react'

const initialValues = {
  response: undefined,
  request: undefined,
  error: undefined,
  isIdle: false,
  isLoading: false,
  isSuccess: false,
  isError: false,
}

export const useQuery = (query, options = {}) => {
  const { onRequest, onSuccess, onError } = options

  const [state, setState] = useState(initialValues)

  const reset = () => {
    setState(initialValues)
  }

  const request = async (...params) => new Promise(async (resolve, reject) => {
    try {
      onRequest && onRequest()
      setState((state) => ({
        ...state,
        isIdle: true,
        isLoading: true,
        isSuccess: false,
        isError: false,
      }))

      const response = await query(...params)
      onSuccess && onSuccess(response)

      setState((state) => ({
        ...state,
        isIdle: true,
        isLoading: false,
        isSuccess: true,
        response,
      }))
      resolve(response)
      return response
    } catch (error) {
      if (error instanceof TypeError) throw error
      onError && onError(error)

      setState((state) => ({
        ...state,
        isIdle: true,
        isLoading: false,
        isError: true,
        error,
      }))
      reject(error)
      return error
    }
  })

  return { ...state, request, reset }
}

export default useQuery;
