import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { Logout } from 'entities/Logout'
import { Landing } from 'entities/Landing'
import { Wrapper } from './components/Wrapper'
import { Order } from 'entities/Order'
import { Company } from 'entities/Company'
import { SupportRequest } from 'entities/SupportRequest'
import { Account } from 'entities/Account'
import { Verify } from 'entities/Verify'
import { NewPassword } from 'entities/NewPassword'
import { Recover } from 'entities/Recover'
import { rolesType } from 'constants/rolesType'
import { PrivateRoute } from 'components/PrivateRoute'
import { YaMetric } from './components/YaMetric'
import { Registration } from './entities/Registration'
import { Enter } from './entities/Enter'
import { Configurator } from './entities/Configurator'
import { Equipment } from './entities/Equipment'
import { MyPublications } from './entities/MyPublications'
import { Article } from './entities/Article'
import { NotFound } from './entities/NotFound'
import { Analytics } from './entities/Analytic'
import { LandingStankofond } from './entities/LandingStankofond'
import { Favorite } from './entities/Favorite'
import { ViewPort } from './components/ViewPort'

function App() {
  return (
    <ViewPort>
      <YaMetric>
        <Wrapper>
          <Switch>
            <Route path="/" exact>
              <LandingStankofond/>
            </Route>
            <Route path="/stankofond" exact>
              <LandingStankofond/>
            </Route>
            <Route path="/logout">
              <Logout/>
            </Route>
            <Route path="/orders">
              <Order/>
            </Route>
            <Route path="/equipments">
              <Equipment/>
            </Route>
            <Route path="/companies">
              <Company/>
            </Route>
            <Route path="/articles">
              <Article/>
            </Route>
            <PrivateRoute
              path="/analytics/:paramTabIndex"
              hasAnyRoles={[rolesType.admin, rolesType.analytic]}
            >
              <Analytics/>
            </PrivateRoute>
            <PrivateRoute
              path="/support-requests"
              hasAnyRoles={[rolesType.admin]}
            >
              <SupportRequest/>
            </PrivateRoute>
            <Route path="/support-requests">
              <SupportRequest/>
            </Route>
            <PrivateRoute
              path="/account/:paramTabIndex"
              hasAnyRoles={[rolesType.user]}
            >
              <Account/>
            </PrivateRoute>
            <PrivateRoute
              path="/favorite/:paramTabIndex"
              hasAnyRoles={[rolesType.user, rolesType.admin]}
            >
              <Favorite/>
            </PrivateRoute>
            <PrivateRoute
              path="/my-publications/:paramTabIndex"
              hasAnyRoles={[rolesType.user]}
            >
              <MyPublications/>
            </PrivateRoute>
            <PrivateRoute
              path="/configurator/:paramTabIndex"
              hasAnyRoles={[rolesType.admin]}
            >
              <Configurator/>
            </PrivateRoute>
            <Route path={`/verify`}>
              <Verify/>
            </Route>
            <Route path={`/reset`}>
              <NewPassword/>
            </Route>
            <Route path={`/recover`}>
              <Recover/>
            </Route>
            <Route path={`/registration`}>
              <Registration/>
            </Route>
            <Route path={`/enter`}>
              <Enter/>
            </Route>
            <Route path="/404">
              <NotFound/>
            </Route>
            <Route path="*">
              <NotFound/>
            </Route>
          </Switch>
        </Wrapper>
      </YaMetric>
    </ViewPort>
  )
}

export default App
