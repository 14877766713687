import { useToast } from '@chakra-ui/react'
import useQuery from 'hooks/useQuery'
import { api } from 'api'
import { useEffect, useMemo } from 'react'
import { isEmpty } from 'lodash'

const mappingData = (data) => {
  if (isEmpty(data)) {
    return null
  }
  let result = []
  for (const item of data) {
    if (item?.dimensions[1]?.id === 'yes') {
      result.push({
        label: item?.dimensions[0]?.name,
        value: item?.metrics[0]
      })
    }
  }
  return result
}

export const useFetchVisitsBounce = () => {
  const toast = useToast()
  const {
    isError,
    isLoading,
    error,
    request,
    response
  } = useQuery((data) => api.yametrikaProxy.fetchVisitsBounce(data))

  const dataFetchVisitsBounce = useMemo(() => {
    return {
      avgTitle: null,
      avgValue: null,
      difference: null,
      footerTitle: 'Всего отказов',
      footerValue: null,
      series: null,
      title: 'Отказы',
      type: 'VisitsBounce',
      value: response?.totals[0] ?? null,
      chartData: mappingData(response?.data)
    }
  }, [response])

  useEffect(() => {
    if (isError) {
      toast({
        title: 'Ошибка',
        description: error.response?.data?.ErrorMessage,
        status: 'error',
      })
    }
  }, [isError])

  return {
    fetchFetchVisitsBounce: request,
    dataFetchVisitsBounce,
    isLoadingFetchVisitsBounce: isLoading
  }
}
