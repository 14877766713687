import { Button, Icon, Stack, Text, useColorModeValue } from '@chakra-ui/react'
import { FiLock } from 'react-icons/fi'
import React from 'react'

export const Document = ({ ...props }) => {
  const bg = useColorModeValue('white', 'gray.700')
  const bgContainerBorder = useColorModeValue('gray.100', 'transparent')

  return (
    <Stack
      borderRadius="10px"
      borderWidth={1}
      lineHeight="1.3rem"
      padding="18px"
      backgroundColor={bg}
      borderColor={bgContainerBorder}
      spacing="10px"
      alignItems="center"
      {...props}
    >
      <Icon as={FiLock} fontSize="1.6rem"/>
      <Text>Авторизуйтесь, чтобы увидеть информацию</Text>
      <Button
        size="sm"
        width="fit-content"
        onClick={() => window.open(`/account/0`)}
      >Авторизоваться</Button>
    </Stack>
  )
}
