import { PageHeading } from 'components/PageHeading'
import { Tab, TabList, TabPanels, Tabs } from '@chakra-ui/react'
import { TabPanel } from 'components/TabPanel'
import { Container } from '../../components/Container'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { Order } from './components/Order'
import { Equipment } from './components/Equipment'
import { Company } from './components/Company'
import { ScrollBox } from 'components/ScrollBox'
import { BlockCompletedModal } from '../../components/BlockCompletedModal'

export const Favorite = () => {
  const history = useHistory()
  const { paramTabIndex } = useParams()
  const [tabIndex, setTabIndex] = useState(0)

  const handleTabsChange = (index) => {
    history.replace(`/favorite/${index}`)
  }

  useEffect(() => {
    setTabIndex(Number(paramTabIndex))
  }, [paramTabIndex])

  return (
    <>
      <Container>
        <ScrollBox>
          <PageHeading>
            Избранное
          </PageHeading>
          <Tabs onChange={handleTabsChange} index={tabIndex} isLazy>
            <TabList>
              <Tab>Оборудование</Tab>
              <Tab>Заявки</Tab>
              <Tab>Компании</Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                <Equipment/>
              </TabPanel>
              <TabPanel>
                <Order/>
              </TabPanel>
              <TabPanel>
                <Company/>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ScrollBox>
      </Container>
      <BlockCompletedModal/>
    </>
  )
}
