import React from 'react'
import { Flex, Icon, Spinner, useColorModeValue } from '@chakra-ui/react'
import { isEmpty } from 'lodash'
import { BsCardImage } from 'react-icons/bs'
import { useFetchImage } from 'services/useFetchImage'

export const ImageEntity = ({ data, boxSize = '130px', ...props }) => {
  const { dataImage, isLoadingFetchImage } = useFetchImage(data)
  const borderWidth = useColorModeValue(1, 0)

  return (
    <Flex
      bg="white"
      boxSize={boxSize}
      backgroundImage={dataImage}
      backgroundRepeat="no-repeat"
      backgroundSize="contain"
      backgroundPosition="center"
      alignItems="center"
      justifyContent="center"
      transition="0.3s"
      borderColor="gray.100"
      borderRadius="8px"
      borderWidth={borderWidth}
      _groupHover={{
        backgroundColor: 'gray.100'
      }}
      {...props}
    >
      {isEmpty(data) ? (
        <Icon as={BsCardImage} boxSize="54px" color="gray.200"/>
      ) : (
        isLoadingFetchImage && <Spinner color="blue.500" size="md"/>
      )}
    </Flex>
  )
}
