import { HStack, Icon, Text, useColorModeValue } from '@chakra-ui/react'
import { FiLock } from 'react-icons/fi'
import React from 'react'

export const Link = () => {
  const color = useColorModeValue('gray.400', 'gray.600')
  const colorHover = useColorModeValue('black', 'white')

  return (
    <HStack
      color={color}
      cursor="pointer"
      transition="0.3s"
      onClick={() => window.open(`/account/0`)}
      _hover={{
        color: colorHover
      }}
    >
      <Icon as={FiLock} fontSize="1.2rem"/>
      <Text fontSize="0.94rem">Авторизуйтесь, чтобы увидеть информацию</Text>
    </HStack>
  )
}
