import React from 'react'
import { Image, Link as ChakraLink } from '@chakra-ui/react'
import ImgLogo from './images/logo.svg'
import { Link } from 'react-router-dom'

export const Logo = () => {
  return (
    <ChakraLink
      as={Link}
      position={['static', 'static', 'static', 'absolute']}
      left="20px"
      top="20px"
      to="/"
      mb="30px"
    >
      <Image
        src={ImgLogo}
        width="104px"
        height="40px"
        transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
        _active={{
          transform: 'scale(0.98)'
        }}
      />
    </ChakraLink>
  )
}
