import useQuery from 'hooks/useQuery'
import { api } from 'api'
import { useEffect } from 'react'
import { useToast } from '@chakra-ui/react'

export const useUpdateVendorsData = (id) => {
  const toast = useToast()
  const {
    isLoading,
    response,
    request,
    isError,
    isSuccess
  } = useQuery((data) => api.vendorsData.updateData(data, id))

  useEffect(() => {
    if (isError) {
      toast({
        title: 'Ошибка',
        description: 'Не удалось получить данные',
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  }, [isError])


  useEffect(() => {
    if (isSuccess) {
      toast({
        title: 'Готово',
        description: 'Анкета успешно обновлена',
        status: 'success',
        duration: 9000,
        isClosable: true,
      })
    }
  }, [isSuccess])

  return ({
    updateVendorsData: request,
    dataFiles: response,
    isLoadingUpdate: isLoading
  })
}
