import * as Chakra from '@chakra-ui/react'

export const Modal = ({
  isOpen,
  isHideClose,
  onClose,
  header,
  footer,
  contentProps,
  children,
}) => {
  return (
    <>
      <Chakra.Modal
        isOpen={isOpen}
        onClose={onClose}
        trapFocus={false}
        {...isHideClose && {
          closeOnEsc: false,
          closeOnOverlayClick: false
        }}
      >
        <Chakra.ModalOverlay />
        <Chakra.ModalContent {...contentProps}>
          <Chakra.ModalHeader>{header}</Chakra.ModalHeader>
          {!isHideClose && (
            <Chakra.ModalCloseButton />
          )}
          <Chakra.ModalBody
            {...(!footer && {
              pb: 'var(--chakra-space-6)',
            })}
          >
            {children}
          </Chakra.ModalBody>
          {footer && <Chakra.ModalFooter>{footer}</Chakra.ModalFooter>}
        </Chakra.ModalContent>
      </Chakra.Modal>
    </>
  )
}
