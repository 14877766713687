import { Add } from './components/Add'
import { useCreateManufacturer } from './services/useCreateManufacturer'
import React, { useEffect, useMemo, useState } from 'react'
import { Accordion, Box, Grid, HStack, Stack, useColorModeValue } from '@chakra-ui/react'
import { Item } from './components/Item'
import { InputSearch } from 'components/InputSearch'
import { filter, isEmpty } from 'lodash'
import { Entity } from 'components/Entity'
import { useFetchManufacturers } from 'services/useFetchManufacturers'
import { Update } from './components/Update'
import { useUpdateManufacturer } from './services/useUpdateManufacturer'
import { MachineClassifierList } from '../RequiredField/components/MachineClassifierList';

export const Manufacturer = () => {
  const [valueSearch, setValueSearch] = useState('')
  const {
    fetchManufacturers,
    isLoadingFetchManufacturers,
    dataManufacturers
  } = useFetchManufacturers()
  const { createManufacturer } = useCreateManufacturer()
  const { updateManufacturer } = useUpdateManufacturer()
  const bg = useColorModeValue('white', 'gray.700')

  const [updateValue, setUpdateValue] = useState(null)
  const [activeClassifierId, setActiveClassifierId] = useState(null)

  const rows = useMemo(() => {
    if (isEmpty(dataManufacturers?.rows)) {
      return []
    }
    return filter(dataManufacturers?.rows, e => {
      const name = e?.name?.toLowerCase() ?? ''
      const _valueSearch = valueSearch.toLowerCase() ?? ''
      return name.includes(_valueSearch)
    })
  }, [dataManufacturers?.rows, valueSearch])

  const handleCreate = (data) => new Promise(async (resolve, reject) => {
    try {
      await createManufacturer({
        machineClassifierId: activeClassifierId,
        ...data
      })
      await fetchManufacturers({ machineClassifierId: activeClassifierId })
      resolve()
    } catch (e) {
      reject(e)
    }
  })

  const handleUpdate = (data) => new Promise(async (resolve, reject) => {
    try {
      await updateManufacturer({
        machineClassifierId: activeClassifierId,
        ...data
      })
      await fetchManufacturers({ machineClassifierId: activeClassifierId })
      resolve()
    } catch (e) {
      reject(e)
    }
  })

  const handleOpenUpdate = (data) => {
    setUpdateValue(data)
  }

  const handleCloseUpdate = () => {
    setUpdateValue(null)
  }

  useEffect(() => {
    if (activeClassifierId) {
      fetchManufacturers({ machineClassifierId: activeClassifierId })
      return
    }
    fetchManufacturers()
  }, [activeClassifierId])

  return (
    <>
      <Grid templateColumns="minmax(300px, 400px) 1fr" gap="24px" pb="80px">
        <Box>
          <Box position="sticky" top={0}>
            <MachineClassifierList
              activeClassifierId={activeClassifierId}
              setActiveClassifierId={setActiveClassifierId}
            />
          </Box>
        </Box>
        <Stack spacing="16px" pb="80px">
          <HStack spacing="12px" alignItems="center" mb="12px">
            <InputSearch
              autoFocus
              delay={90}
              placeholder="Поиск по производителям"
              name="search"
              value={valueSearch}
              onChange={e => setValueSearch(e)}
              bg={bg}
            />
            <Add isDisabled={!activeClassifierId} onCreate={handleCreate}/>
          </HStack>
          <Entity isLoading={isLoadingFetchManufacturers} isEmpty={isEmpty(rows)}>
            <Accordion allowMultiple>
              {rows?.map(item => (
                <Item
                  data={item}
                  onUpdateClick={handleOpenUpdate}
                />
              ))}
            </Accordion>
          </Entity>
        </Stack>
      </Grid>
      <Update
        initialValues={updateValue}
        isOpen={!!updateValue}
        onClose={handleCloseUpdate}
        onUpdate={handleUpdate}
      />
    </>
  )
}
