import React from 'react'
import { BlockCollection } from 'components/BlockCollection'
import { Box, Button, Grid } from '@chakra-ui/react'
import { BlockSection } from 'components/BlockSection'
import { Select } from 'components/Select'
import { Okved2 } from 'components/Okved2'
import { RegionSelect } from 'components/RegionSelect'

export const Filter = ({ data, update, reset, isLoading }) => {
  return (
    <Box
      pointerEvents={isLoading ? 'none' : 'auto'}
      opacity={isLoading ? 0.4 : 1}
    >
      <BlockCollection>
        <BlockSection>
          <Grid templateColumns="1fr" gap="14px" pt="20px">
            <Button size="sm" onClick={reset}>Сбросить</Button>
          </Grid>
          <Select
            label="Роль компании"
            options={[
              {
                label: 'Заказчик',
                value: 'isCustomer'
              },
              {
                label: 'Исполнитель',
                value: 'isSupplier'
              },
            ]}
            placeholder="Выберите..."
            value={data.roles?.value}
            onChange={e => update('roles', e)}
          />
          <Okved2
            isMulti
            label="ОКВЭД 2"
            placeholder="Выберите..."
            value={data.mainOKVEDCode}
            onChange={e => update('mainOKVEDCode', e)}
          />
          <Select
            label="Тип организации"
            isMulti
            options={[
              {
                label: 'Юридические лица',
                value: 'Company'
              },
              {
                label: 'Индивидуальные предприниматели',
                value: 'Individual'
              }
            ]}
            placeholder="Выберите..."
            value={data.organizationsTypes}
            onChange={e => update('organizationsTypes', e)}
          />
          <RegionSelect
            isMulti
            value={data.regions}
            onChange={e => update('regions', e)}
            label="Регион"
          />
        </BlockSection>
      </BlockCollection>
    </Box>
  )
}
