import { Center, Heading, HStack, Stack } from '@chakra-ui/react'
import { IconArrow } from './components/IconArrow'

const Item = ({ children }) => {
  return (
    <HStack
      spacing={3}
      alignItems="flex-start"
      fontSize={['1rem', '1rem', '1.3rem', '1.3rem']}
    >
      <IconArrow mt="10px"/>
      {children}
    </HStack>
  )
}

export const AnnotationCard = ({ title, value, ...props }) => {
  return (
    <Center
      backgroundColor="#3571E0"
      color="white"
      px="24px"
      py="24px"
      maxW="480px"
      width="100%"
      minH={['260px', '260px', '470px', '480px']}
      borderRadius={['20px', '20px', '40px']}
      {...props}
    >
      <Stack spacing={[4, 4, 8]} maxW="370px">
        <Heading
          fontSize={['1.3rem', '1.4rem', '1.6rem', '1.9rem']}
        >
          {title}
        </Heading>
        <Stack spacing={4} fontSize="0.9rem">
          {value.map(item => (
            <Item key={item.key}>{item}</Item>
          ))}
        </Stack>
      </Stack>
    </Center>
  )
}
