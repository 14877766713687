import React, { useEffect, useState } from 'react'
import { ItemColumns } from 'components/ItemColumns'
import { Edit } from './Edit'
import { Show } from './Show'
import { Box, Button, Flex } from '@chakra-ui/react'
import { useHistory } from 'react-router'
import { useLocation } from 'react-router-dom'
import { getUrlParameter } from '../../utils/getUrlParameter'
import { useAccount } from '../../hooks/useAccount'
import { ShortCompletedProfileModal } from '../../components/ShortCompletedProfileModal'

export const Profile = ({ id, isEdit, setIsEdit }) => {
  const history = useHistory()
  const location = useLocation()
  const { dataAccount } = useAccount()
  const isVerify = location.search ? getUrlParameter('verify', location.search) === 'true' : false
  const [isShowModal, setIsShowModal] = useState(false)

  const handleCancel = () => {
    if (id) {
      history.goBack()
      return
    }
    window.scrollTo(0, 0)
    setIsEdit(false)
  }

  useEffect(() => {
    if (!dataAccount?.fullyCompletedProfile) {
      setIsEdit(true)
      setIsShowModal(true)
    }
  }, [dataAccount])

  return (
    <>
      <ItemColumns>
        <Box>
          {isEdit ? (
            <Edit
              isValidateOnMount={!dataAccount?.fullyCompletedProfile}
              id={id}
              onCancel={handleCancel}
            />
          ) : (
            <Show />
          )}
        </Box>
        <Box>
          {!isEdit && (
            <Flex justifyContent="flex-end">
              <Button onClick={() => setIsEdit((e) => !e)}>Редактировать</Button>
            </Flex>
          )}
        </Box>
      </ItemColumns>
      <ShortCompletedProfileModal isOpen={isShowModal} onClose={() => setIsShowModal(false)} />
    </>
  )
}
