import axios from 'axios'

const ENTITY = 'analytic'

export const analytic = Object.freeze({
  async fetchEquipment(data) {
    const response = await axios.post(`/${ENTITY}/equipment`, data)
    return response.data
  },
  async fetchOrder(data) {
    const response = await axios.post(`/${ENTITY}/order`, data)
    return response.data
  },
  async fetchContragent(data) {
    const response = await axios.post(`/${ENTITY}/contragent`, data)
    return response.data
  },
  async createFeedback(data) {
    const response = await axios.post(`/${ENTITY}/feedback`, data)
    return response.data
  },
  async fetchFeedbackStat(params) {
    const response = await axios.get(`/${ENTITY}/feedbackstat`, { params })
    return response.data
  },
})
