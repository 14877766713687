import { useWindowHeight } from '../../hooks/useWindowHeight'
import { Box } from '@chakra-ui/react'
import { useEffect } from 'react'

export const ScrollBox = ({ children, ...props }) => {
  const windowHeight = useWindowHeight()

  useEffect(() => {
    if (document) {
      const html = document.querySelector('html')
      document.body.style.overflow = 'hidden'

      if (html) {
        html.style.overflow = 'hidden'
      }
    }

    return () => {
      if (document) {
        const html = document.querySelector('html')
        document.body.style.overflow = ''
        html && (html.style.overflow = '')
      }
    }
  }, [])

  return (
    <Box
      id="scrollableDiv"
      //height={`${windowHeight - 134}px`}
      height="calc(100vh - 134px)"
      overflowY="scroll"
      overflowX="auto"
      px="30px"
      py="30px"
      css={styleScrollbar}
      {...props}
    >
      {children}
    </Box>
  )
}

const styleScrollbar = {
  '&::-webkit-scrollbar': {
    width: '12px',
  },
  '&::-webkit-scrollbar-button': {
    width: '0px',
    height: '0px',
  },
  '&::-webkit-scrollbar-thumb': {
    background: 'rgba(0,0,0,0.4)',
    border: '2px solid transparent',
    borderRadius: '100px',
    backgroundClip: 'content-box',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: 'rgba(0,0,0,0.8)',
    backgroundClip: 'content-box',
    border: '2px solid transparent',
  },
  '&::-webkit-scrollbar-thumb:active': {
    background: 'rgba(0,0,0,1)',
    backgroundClip: 'content-box',
    border: '2px solid transparent',
  },
  '&::-webkit-scrollbar-track': {
    background: 'transparent',
    border: '0px none #ffffff',
    borderRadius: '100px',
  },
  '&::-webkit-scrollbar-track:hover': {
    background: 'transparent',
  },
  '&::-webkit-scrollbar-track:active': {
    background: 'transparent',
  },
  '&::-webkit-scrollbar-corner': {
    background: 'transparent',
  },
}
