import React from 'react'
import { Box } from '@chakra-ui/react'
import { ErrorBoundary } from '../ErrorBoundary'

export const Form = ({ onSubmit, children, ...props}) => {
  return (
    <ErrorBoundary>
      <form onSubmit={onSubmit}>
        <Box {...props}>
          {children}
        </Box>
      </form>
    </ErrorBoundary>
  )
}
