import useQuery from 'hooks/useQuery'
import { api } from 'api'

export const useVerify = () => {
  const {
    isError,
    isLoading,
    isIdle,
    error,
    request
  } = useQuery((data) => api.accounts.verify(data))

  return {
    isLoading,
    isIdle,
    isError,
    error,
    request
  }
}
