import { useCallback, useState } from 'react'

const initialState = {
  hasMoreRows: false,
  totalCount: 0,
  pageNumber: 1,
  pageSize: 20
}

const usePagination = () => {
  const [state, setState] = useState(initialState)

  const paginationUpdate = useCallback(({totalCount, pageNumber}) => {
      const hasMoreRows = (pageNumber * initialState.pageSize) >= totalCount
      setState(e => ({
        ...e,
        hasMoreRows,
        totalCount,
        pageNumber,
      }))
    },
    [setState]
  )

  const paginationNextPage = useCallback(() => {
    return {
      pageNumber: state.pageNumber + 1,
      pageSize: initialState.pageSize,
    }
  }, [state])

  return {
    ...state,
    pageSize: initialState?.pageSize,
    paginationUpdate,
    paginationNextPage,
  }
}

export default usePagination

