import React, { useMemo } from 'react'
import { BlockCollection } from 'components/BlockCollection'
import { Box, Button, Grid } from '@chakra-ui/react'
import { BlockSection } from 'components/BlockSection'
import { Select } from 'components/Select'
import { RegionSelect } from 'components/RegionSelect'
import { Okpd2 } from 'components/Okpd2'
import { useAccess } from 'hooks/useAccess'
import { rolesType } from 'constants/rolesType'
import { CompanyLabel } from '../CompanyLabel/CompanyLabel'

const optionsState = [
  {
    label: 'Обновлено',
    value: 2
  },
  {
    label: 'На модерации',
    value: 3
  },
  {
    label: 'Опубликовано',
    value: 4
  },
  {
    label: 'Отменено',
    value: 5
  },
  {
    label: 'Отклонено',
    value: 6
  },
  {
    label: 'Завершено',
    value: 7
  },
]

const optionsAdmin = [
  {
    label: 'Новая заявка',
    value: 1
  }
]

const optionsUser = [
  {
    label: 'Опубликовано',
    value: 4
  },
  {
    label: 'Завершено',
    value: 7
  },
]

export const Filter = ({ data, update, reset, isMy, isLoading, accountId }) => {
  const [isAdmin] = useAccess([rolesType.admin])

  const optionsOrderStates = useMemo(() => {
    if (isAdmin) {
      return [
        ...optionsAdmin,
        ...optionsState
      ]
    }
    if (isMy) {
      return optionsState
    }
    return optionsUser
  }, [optionsState, optionsAdmin, optionsUser])

  return (
    <Box
      pointerEvents={isLoading ? 'none' : 'auto'}
      opacity={isLoading ? 0.4 : 1}
    >
      <BlockCollection>
        <BlockSection>
          <Grid templateColumns="1fr" gap="14px" pt="20px">
            <Button size="sm" onClick={reset}>Сбросить</Button>
          </Grid>
          <Select
            isMulti
            label="Статус заявки"
            options={optionsOrderStates}
            placeholder="Выберите..."
            value={data.orderStates}
            onChange={e => update('orderStates', e)}
          />
          <Okpd2
            isMulti
            label="ОКПД 2"
            placeholder="Выберите..."
            value={data.okpd2Codes}
            onChange={e => update('okpd2Codes', e)}
          />
          <CompanyLabel accountId={accountId}/>
          <RegionSelect
            isMulti
            value={data.deliveryRegions}
            onChange={e => update('deliveryRegions', e)}
            label="Регион поставки"
          />
          <Select
            label="Дата размещения"
            placeholder="Выберите..."
            value={data.publishedFor}
            onChange={e => update('publishedFor', e?.value ?? '')}
            isClearable
            options={[
              {
                label: 'За неделю',
                value: 'week'
              },
              {
                label: 'За месяц',
                value: 'month'
              },
              {
                label: 'За квартал',
                value: 'quarter'
              },
              {
                label: 'За год',
                value: 'year'
              }
            ]}
          />
        </BlockSection>
      </BlockCollection>
    </Box>
  )
}
