import React from 'react'
import { Select } from '../Select'
import _ from 'lodash'
import { ErrorBoundary } from '../ErrorBoundary'

export const SelectForm = React.forwardRef((
  { field, form, options, onChange, isMulti = false, ...props },
  ref
  ) => {
    const handleChange = (option) => {
      if (onChange) {
        onChange(option)
      }
      if (option) {
        form.setFieldValue(field.name, isMulti ? option.map((item) => item.value) : option.value)
      } else {
        form.setFieldValue(field.name, '')
      }
    }

    const getValue = () => {
      if (options) {
        let result
        if (isMulti) {
          result = options.filter((option) => field.value?.indexOf(option.value) >= 0) ?? []
        } else {
          result = options.find((option) => option.value === field.value) ?? ''
        }
        return result
      } else {
        return isMulti ? [] : ''
      }
    }

    return (
      <ErrorBoundary>
        <Select
          ref={ref}
          isForm
          name={field.name}
          value={getValue()}
          onChange={handleChange}
          options={options}
          isMulti={isMulti}
          isInvalid={_.get(form.errors, field.name) && _.get(form.touched, field.name)}
          error={_.get(form.errors, field.name)}
          {...props}
        />
      </ErrorBoundary>
    )
  }
)
