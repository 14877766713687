import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import { ErrorBoundary } from 'components/ErrorBoundary'
import { List } from './List'
import { Edit } from './Edit'
import { Show } from './Show'

export const Article = () => {
  const match = useRouteMatch()
  return (
    <Switch>
      <Route path={`${match.url}/create`}>
        <ErrorBoundary>
          <Edit/>
        </ErrorBoundary>
      </Route>
      <Route path={`${match.url}/:id/edit`}>
        <ErrorBoundary>
          <Edit/>
        </ErrorBoundary>
      </Route>
      <Route path={`${match.url}/:id`}>
        <ErrorBoundary>
          <Show/>
        </ErrorBoundary>
      </Route>
      <Route path={`${match.url}/`}>
        <ErrorBoundary>
          <List/>
        </ErrorBoundary>
      </Route>
    </Switch>
  )
}
