import React from 'react'
import { Switch } from '../Switch'

export const SwitchForm = React.forwardRef(({ field, form, label, labelProps, ...props }) => {
  const handleChange = (e) => form.setFieldValue(field.name, e.target.checked)
  return (
    <Switch
      onChange={handleChange}
      value={field.value}
      label={label}
      {...props}
    />
  )
})
