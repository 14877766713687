import React from 'react'
import { createIcon } from '@chakra-ui/icons'

export const IconMyOrders = createIcon({
  displayName: 'MyOrders',
  viewBox: '0 0 20 20',
  path: (
    <g fill="none">
      <path
        stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"
        d="M15.8358 9.16633V6.5219C15.8358 6.08005 15.6599 5.6557 15.3472 5.34307L12.9896 2.98542C12.677 2.67279 12.2526 2.49689 11.8108 2.49689H5.83161C4.91039 2.49689 4.16425 3.24303 4.16425 4.16425V15.8358C4.16425 16.757 4.91039 17.5031 5.83161 17.5031H10"
      />
      <path
        stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"
        d="M15.8358 6.66529H12.501C12.0408 6.66529 11.6674 6.2918 11.6674 5.83161V2.49689"/>
      <path
        stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"
        d="M15.288 16.6886L16.7511 17.4573C17.0363 17.6074 17.3689 17.3648 17.3147 17.048L17.0354 15.4189L18.2193 14.266C18.4502 14.0417 18.3226 13.6499 18.0042 13.604L16.3685 13.3664L15.6373 11.8833C15.4948 11.5948 15.0829 11.5948 14.9412 11.8833L14.2101 13.3664L12.5744 13.604C12.2559 13.6507 12.1284 14.0417 12.3593 14.266L13.5431 15.4189L13.263 17.0488C13.2088 17.3656 13.5415 17.6074 13.8266 17.4581L15.2897 16.6895"/>
    </g>
  ),
})
