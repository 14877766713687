import { ClassifierSelects } from '../ClassifierSelects'
import React from 'react'

export const ClassifierSelectsForm = ({ field, form, ...props }) => {
  const value = field.value

  const handleChange = (e) => {
    form.setFieldValue(field.name, e)
    form.setFieldValue('manufacturer', '')
    form.setFieldValue('brand', '')
  }

  return (
    <ClassifierSelects
      isClearable
      onChange={handleChange}
      value={value}
      {...props}
    />
  )
}
