export const searchAreaType = {
  orders: 'orders',
  companies: 'companies',
  equipments: 'equipments'
}

export const searchAreaLabel = {
  [searchAreaType.orders]: 'Заявки',
  [searchAreaType.companies]: 'Компании',
  [searchAreaType.equipments]: 'Оборудование'
}

export const searchAreaInputLabel = {
  [searchAreaType.orders]: 'Поиск по заявкам',
  [searchAreaType.companies]: 'Поиск по компаниям',
  [searchAreaType.equipments]: 'Поиск по оборудованию'
}
