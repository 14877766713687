import {
  Popover as ChakraPopover,
  Button,
  Icon, PopoverArrow, PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text,
} from '@chakra-ui/react'
import { FiLock } from 'react-icons/fi'
import { useLocation } from 'react-router-dom'
import React from 'react'

export const Popover = ({ children }) => {
  return (
    <ChakraPopover trigger="hover">
      <PopoverTrigger>
        {children}
      </PopoverTrigger>
      <PopoverContent maxW="240px">
        <PopoverArrow />
        <PopoverBody>
          <Stack
            lineHeight="1.3rem"
            padding="18px"
            spacing="10px"
            alignItems="center"
            textAlign="center"
          >
            <Icon as={FiLock} fontSize="1.6rem"/>
            <Text>Авторизуйтесь, чтобы увидеть информацию</Text>
            <Button
              size="sm"
              width="fit-content"
              onClick={() => window.open(`/account/0`)}
            >Авторизоваться</Button>
          </Stack>
        </PopoverBody>
      </PopoverContent>
    </ChakraPopover>
  )
}
