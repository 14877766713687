import { Box, Image, useColorModeValue } from '@chakra-ui/react'
import Par from './images/img-par.svg'
import React from 'react'

export const ImageParams = () => {
  const borderColor = useColorModeValue('gray.100', 'gray.600')

  return (
    <Box position="relative" borderRadius="14px" borderWidth={1} borderColor={borderColor}>
      <Image src={Par}/>
    </Box>
  )
}
