import useQuery from 'hooks/useQuery'
import { api } from 'api'
import { useEffect } from 'react'
import { useToast } from '@chakra-ui/react';

export const useUpdateStatus = (id) => {
  const toast = useToast()
  const {
    isError,
    isLoading,
    response,
    error,
    request,
  } = useQuery((data) => api.supportRequests.updateStatusItem(id, data))

  useEffect(() => {
    if (isError) {
      toast({
        title: 'Ошибка',
        description: error.response?.data?.ErrorMessage,
        status: 'error',
      })
    }
  }, [isError])

  return {
    data: response,
    updateStatus: request,
    isLoadingUpdateStatus: isLoading
  }
}
