import React from 'react'
import { Stack } from '@chakra-ui/react'
import { ErrorBoundary } from '../ErrorBoundary';

export const BlockSection = ({ children, ...props }) => {
  return (
    <ErrorBoundary>
      <Stack spacing={4} {...props}>
        {children}
      </Stack>
    </ErrorBoundary>
  )
}
