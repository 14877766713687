import useQuery from 'hooks/useQuery'
import { api } from 'api'
import { useEffect } from 'react'
import { useToast } from '@chakra-ui/react'

export const useSaveClassifierOrder = () => {
  const toast = useToast()
  const {
    isSuccess,
    request
  } = useQuery((data) => api.admin.saveMachineClassifierOrder(data))

  useEffect(() => {
    if (isSuccess) {
      toast({
        title: 'Готово',
        description: 'Порядок успешно обновлен',
        status: 'success',
      })
    }
  }, [isSuccess])

  return {
    saveClassifierOrder: request
  }
}
