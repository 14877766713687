import { Route, Switch, useRouteMatch } from 'react-router-dom'
import { Show } from './Show'
import React from 'react'
import { Edit } from './Edit'
import { rolesType } from 'constants/rolesType'
import { ErrorBoundary } from 'components/ErrorBoundary'
import { PrivateRoute } from 'components/PrivateRoute'

export const Item = () => {
  const match = useRouteMatch()

  return (
    <Switch>
      <PrivateRoute path={`${match.url}/create`} hasAnyRoles={[rolesType.user]}>
        <ErrorBoundary>
          <Edit/>
        </ErrorBoundary>
      </PrivateRoute>
      <PrivateRoute path={`${match.url}/:id/edit`} hasAnyRoles={[rolesType.user]}>
        <ErrorBoundary>
          <Edit/>
        </ErrorBoundary>
      </PrivateRoute>
      <Route path={`${match.url}/:id`}>
        <ErrorBoundary>
          <Show/>
        </ErrorBoundary>
      </Route>
      <Route path={`${match.url}/`}>
        <ErrorBoundary>
          <Show/>
        </ErrorBoundary>
      </Route>
    </Switch>
  )
}
