import React from 'react'
import { Box, Flex } from '@chakra-ui/react'
import _ from 'lodash'

const getStateTab = (fields = [], errors = {}) => {
  let result = false
  fields.forEach(item => {
    if (_.get(errors, item)) {
      result = true
    }
  })
  return result
}

export const TabCheck = ({ form = {}, fields, children}) => {
  const { submitCount, errors } = form;
  const isShow = getStateTab(fields, errors) && submitCount > 0;
  return (
    <Flex alignItems="center" fontWeight={isShow && 'semibold'}>
      {children}
      {isShow && (
        <Box ml={2} mt="2px" width="6px" height="6px" backgroundColor="red.500" borderRadius={100} />
      )}
    </Flex>
  )
}
