import { Informer } from 'components/Informer'
import { Box, Stack, Text } from '@chakra-ui/react'
import React from 'react'
import { projectType } from 'constants/projectType'
import { SupportRequestForm } from 'components/SupportRequestForm'

export const InformerAssistance = () => {
  return (
    <Informer title="Помощь при размещении оборудования">
      <Stack spacing={3}>
        <Text>Если у Вас не получается заполнить информацию об оборудовании — обратитесь за помощью к администратору.</Text>
        <Box>
          <SupportRequestForm project={projectType.equipments}/>
        </Box>
      </Stack>
    </Informer>
  )
}
