import React from 'react'
import { Box, Flex, useColorModeValue } from '@chakra-ui/react'
import Bg from './image/bg.png'
import { ScrollBox } from '../ScrollBox'

export const LayoutServices = ({ children }) => {
  const bg = useColorModeValue('gray.100', 'whiteAlpha.50')

  return (
    <Box
      backgroundImage={Bg}
      backgroundRepeat="no-repeat"
      backgroundPosition="left bottom"
      backgroundColor={bg}
      width="100%"
      //overflowY="scroll"
      flexDirection="column"
      position="relative"
      flex={1}
    >
      <ScrollBox>
        <Flex justifyContent="center" py="40px">
          {children}
        </Flex>
      </ScrollBox>
    </Box>
  )
}

