import { filter, find, isEmpty } from 'lodash'
import moment from 'moment/moment'
import { isInvalidDate } from 'utils/dateFormatting'
import { fieldTypeId } from 'constants/fieldTypeId'

export const getIndustrySetToSubmit = (data) => {
  const filtered = filter(data, e => !!e?.id)
  const result = filtered.map(e => ({ id: e?.id }))
  if (!isEmpty(result)) {
    return result
  }
  return null
}

export const getIndustrySetToForm = (data, level) => {
  if (isEmpty(data)) {
    return null
  }
  const result = find(data, e => e?.levelId === level)
  if (result) {
    return {
      id: result?.id,
      name: result?.name
    }
  }
  return null
}

export const getKeywordsToSubmit = (data = []) => {
  const result = []
  for (const item of data) {
    if (item !== '') {
      result.push(item)
    }
  }
  if (isEmpty(result)) {
    return null
  }
  return result
}

export const getStubDetails = (data = [], isClear) => {
  if (isEmpty(data)) {
    return []
  }
  let result = []
  for (const item of data) {

    if (item?.fieldTypeId === fieldTypeId.bool) {
      result.push({
        machineClassifierFieldId: item.id,
        fieldValue: item.fieldValue ?? 'false'
      })
    } else if (isClear) {
      if (item.fieldValue !== '' && !isEmpty(item.fieldValue)) {
        result.push({
          machineClassifierFieldId: item.id,
          fieldValue: item.fieldValue ? String(item.fieldValue) : ''
        })
      }
    } else {
      result.push({
        machineClassifierFieldId: item.id,
        fieldValue: item.fieldValue ? String(item.fieldValue) : ''
      })
    }
  }
  return result
}

export const getActiveContragentContactToSubmit = (list, id) => {
  const init = {
    contactFullName: '',
    contactEmail: '',
    jobTitle: '',
    contactPhoneNumber: '',
    id: 0
  }
  if (isEmpty(list) || id === '') {
    return { ...init }
  }
  const item = find(list, e => e.id === id)
  if (!isEmpty(item)) {
    return { ...item }
  }
  return { ...init }
}

export const getMappingFields = (fields, stubDetails) => {
  if (!isEmpty(fields) && isEmpty(stubDetails)) {
    return fields?.map(e => ({...e, fieldValue: ''}))
  }
  if (!isEmpty(fields) && !isEmpty(stubDetails)) {
    return fields.map(item => {
      const _item = find(stubDetails, i => i?.machineClassifierField?.id === item.id)
      return {
        ...item,
        fieldValue: _item?.fieldValue
      }
    })
  }
  return []
}

export const getDateToSubmit = (value) => {
  if (!isInvalidDate(value)) {
    return null
  }
  return moment(value, 'DD.MM.YYYY').utc(true).format()
}
