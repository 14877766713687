import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { isEmpty } from 'lodash'

export const useEntityMap = ({ request, searchParameters, isDisabled }) => {
  const location = useLocation()
  const [data, setData] = useState([])
  const [totalCount, setTotalCount] = useState(0)

  useEffect(async () => {
    if (isDisabled) {
      return
    }
    setData([])
    const result = await request(
      {
        ...!isEmpty(searchParameters) && {searchParameters},
        grouping: true
      })
    setData(result?.groups)
    setTotalCount(result?.totalCount)
  }, [searchParameters, location.pathname, isDisabled])

  return {
    dataMap: data,
    mapTotalCount: totalCount
  }
}
