import React from 'react'
import { Flex, Stack, Text } from '@chakra-ui/react'
import { IconCheck } from './IconCheck'

const Item = ({ isCheck, children }) => {
  return (
    <Flex
      alignItems="center"
      color={isCheck ? 'green.500' : 'red.400'}
      fontWeight={isCheck ? 'normal' : 'bold'}
    >
      <IconCheck
        fontSize="0.8rem"
        transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
      />
      <Text
        fontSize="0.84rem"
        ml={2}
      >{children}</Text>
    </Flex>
  )
}

export const PasswordCheck = ({ value = '' }) => {
  return (
    <Stack spacing={0}>
      <Item isCheck={value.length >= 8}>Минимум 8 символов</Item>
      <Item isCheck={/[A-Z]/g.test(value)}>Минимум одна заглавная буква</Item>
      <Item isCheck={/[a-z]/g.test(value)}>Минимум одна строчная буква</Item>
      <Item isCheck={/\d/.test(value)}>Минимум одна цифра</Item>
      <Item isCheck={/[*$%@?]/.test(value)}>Спецсимвол: *, $, %, @, ?</Item>
    </Stack>
  )
}
