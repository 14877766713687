import React from 'react'
import { createIcon } from '@chakra-ui/icons'

export const IconEquipment = createIcon({
  displayName: 'Equipment',
  viewBox: '0 0 18 18',
  path: (
    <g fill="none">
      <path fillRule="evenodd" clipRule="evenodd" d="M11.0843 5.12631L13.216 2.99465C13.2443 2.96631 13.2352 2.91631 13.1985 2.90048C11.9885 2.38298 10.5018 2.59965 9.50435 3.59715C8.53602 4.56548 8.28102 5.94215 8.74018 7.11381L5.54602 10.308C5.01852 10.8355 5.01852 11.6913 5.54602 12.2196C6.07352 12.7471 6.92935 12.7471 7.45768 12.2196L10.6777 8.99965C11.8502 9.45798 13.226 9.20381 14.1943 8.23548C15.1935 7.23631 15.4585 5.74715 14.8927 4.53715C14.876 4.50215 14.8277 4.49298 14.7993 4.52048L12.6135 6.65548L11.0843 5.12631Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M8.08333 1C4.10333 1.41667 1 4.78167 1 8.8725C1 13.245 4.54417 16.7892 8.91667 16.7892C13.0075 16.7892 16.3725 13.6858 16.7892 9.70583" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
  ),
})
