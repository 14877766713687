import useQuery from 'hooks/useQuery'
import { api } from 'api'
import { useEffect } from 'react'
import { useToast } from '@chakra-ui/react'

export const useFetch = (id) => {
  const toast = useToast()
  const {
    isError,
    isLoading,
    response,
    error,
    request
  } = useQuery(() => api.accounts.fetchItem(id))

  useEffect(() => {
    if (id) {
      request()
    }
  }, [])

  useEffect(() => {
    if (isError) {
      toast({
        title: 'Ошибка',
        description: error.response?.data?.ErrorMessage,
        status: 'error',
      })
    }
  }, [isError])

  return {
    isLoading,
    dataFetch: response
  }
}
