import {
  Box,
  Button,
  Divider,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react'
import React from 'react'
import { Form } from 'components/Form'
import { BlockSection } from 'components/BlockSection'
import { Field, Formik } from 'formik'
import { InputForm } from 'components/InputForm'
import { useCreateFeedback } from './services/useCreateFeedback'
import { CheckboxGroupForm } from './components/CheckboxGroupForm'
import { TextAreaForm } from '../TextAreaForm'
import { isEmpty } from 'lodash'
import { RadioGroupForm } from './components/RadioGroupForm'

const getValue = (value) => {
  return value === 'Да'
}

const getInitialValue = (value) => {
  return value === true ? 'Да' : 'Нет'
}

export const InterviewModal = ({ isOpen, onClose, data }) => {
  const { createFeedback, isLoadingCreateCreateFeedback } = useCreateFeedback()
  const isNew = !data
  const isLoading = isLoadingCreateCreateFeedback
  const isDisabled = isLoading || data

  const handleForm = async (values) => {
    try {
      const dataRequest = {
        ...values,
        haveYouFoundPartners: getValue(values?.haveYouFoundPartners),
        offersOfCooperation: getValue(values?.offersOfCooperation),
        isDealCompleted: getValue(values?.isDealCompleted),
        usability: Number(values.usability),
        dealSumResult: Number(values.dealSumResult),
      }
      await createFeedback(dataRequest)
      onClose()
    } catch (e) {}
  }

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent maxW="540px">
          <ModalHeader>Опрос</ModalHeader>
          <ModalCloseButton mt={1} />
          <ModalBody>
            <Stack spacing="24px">
              <Text>Участвуйте в улучшении сервиса, ответьте на несколько вопросов</Text>
              <Formik
                enableReinitialize
                initialValues={{
                  usability: !isNew ? String(data?.usability) : '',
                  servicesUseMoreOften: !isNew ? data?.servicesUseMoreOften : [],
                  haveYouFoundPartners: !isNew ? getInitialValue(data?.haveYouFoundPartners) : '',
                  offersOfCooperation: !isNew ? getInitialValue(data?.offersOfCooperation) : '',
                  isDealCompleted: !isNew ? getInitialValue(data?.isDealCompleted) : '',
                  dealSumResult: !isNew ? String(data?.dealSumResult) : '',
                  whatYouWouldLikeToImprove: !isNew ? data?.whatYouWouldLikeToImprove : '',
                }}
                onSubmit={handleForm}
              >
                {(props) => {
                  const {
                    usability,
                    servicesUseMoreOften,
                    haveYouFoundPartners,
                    offersOfCooperation,
                    isDealCompleted,
                  } = props.values

                  const isFormDone =
                    usability !== '' &&
                    !isEmpty(servicesUseMoreOften) &&
                    haveYouFoundPartners !== '' &&
                    offersOfCooperation !== '' &&
                    isDealCompleted !== ''

                  return (
                    <Form onSubmit={props.handleSubmit}>
                      <BlockSection divider={<Divider />}>
                        <Field
                          name="usability"
                          label="Удобно ли было использовать платформу? Оцените удобство по шкале от 1 (совсем нет) до 10 (максимально удобно)"
                          options={['1', '2', '3', '4', '5', '6', '7', '8', '9', '10']}
                          isReadonly={isDisabled}
                          component={RadioGroupForm}
                          containerProps={{
                            direction: 'row',
                            gap: '12px',
                          }}
                        />
                        <Field
                          name="servicesUseMoreOften"
                          label="Какими сервисами платформы вы чаще пользуетесь? Выберите 1 или несколько вариантов:"
                          options={[
                            'Размещение оборудования и мониторинг откликов',
                            'Размещение заявок и мониторинг откликов',
                            'Реестр оборудования и поиск исполнителей',
                            'Реестр заявок и поиск заказов',
                            'Реестр компаний и поиск партнеров',
                          ]}
                          isReadonly={isDisabled}
                          component={CheckboxGroupForm}
                        />
                        <Field
                          name="haveYouFoundPartners"
                          label="Удалось ли найти партнеров на платформе или получить полезные контакты?"
                          options={['Да', 'Нет']}
                          isReadonly={isDisabled}
                          component={RadioGroupForm}
                        />
                        <Field
                          name="offersOfCooperation"
                          label="Обратился ли кто-то к Вам с предложениями о сотрудничестве?"
                          options={['Да', 'Нет']}
                          isReadonly={isDisabled}
                          component={RadioGroupForm}
                        />
                        <Field
                          name="isDealCompleted"
                          label="Удалось ли заключить контракт с предприятием, которое вы нашли на платформе?"
                          options={['Да', 'Нет']}
                          isReadOnly={isDisabled}
                          component={RadioGroupForm}
                        />
                        {props?.values?.isDealCompleted === 'Да' && (
                          <Field
                            name="dealSumResult"
                            label="Если да, укажите сумму (порядок суммы)"
                            description="Поле заполняется по желанию"
                            isDisabled={isLoading}
                            isReadonly={isDisabled}
                            component={InputForm}
                          />
                        )}
                        <Field
                          name="whatYouWouldLikeToImprove"
                          label="Опишите, что бы Вы улучшили в работе платформы?"
                          isDisabled={isLoading}
                          isReadOnly={isDisabled}
                          component={TextAreaForm}
                        />
                      </BlockSection>
                      <Box pt="30px" pb="24px">
                        <Button
                          type="submit"
                          colorScheme="blue"
                          isLoading={isLoading}
                          isDisabled={isDisabled || !isFormDone}
                        >
                          Отправить
                        </Button>
                        {!isFormDone && (
                          <Box pt="16px" fontSize="0.9rem" color="orange.400">
                            Пожалуйста, заполните всю форму
                          </Box>
                        )}
                      </Box>
                    </Form>
                  )
                }}
              </Formik>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
