import React from 'react'
import { CardWrapper } from 'components/CardWrapper'
import { Button, Heading, Stack, Text } from '@chakra-ui/react'
import { InputForm } from 'components/InputForm'
import { Form } from 'components/Form'
import { Field, Formik } from 'formik'
import * as Yup from 'yup'
import { useHistory } from 'react-router'
import { useSendResetPasswordEmail } from './services'
import { LayoutServices } from 'components/LayoutServices'

const formSchema = Yup.object().shape({
  email: Yup.string().required('Заполните поле')
})

const initialValues = {
  email: ''
}

export const Recover = () => {
  const history = useHistory()
  const { request, isLoading } = useSendResetPasswordEmail()

  const handleForm = async (values, {setSubmitting, resetForm}) => {
    try {
      await request(values)
      resetForm(initialValues)
      setSubmitting(false)
    } catch (e) {}
  }

  return (
    <LayoutServices>
      <CardWrapper
        pt="40px"
        pb="50px"
        px="60px"
      >
        <Stack spacing={5}>
          <Heading fontSize="1.6rem">Востановление пароля</Heading>
          <Text lineHeight={1.3}>
            Введите электронную почту, указанную при регистрации, на нее будет отправлено письмо с ссылкой для восстановления пароля
          </Text>
          <Formik
            enableReinitialize={true}
            onSubmit={handleForm}
            validationSchema={formSchema}
            initialValues={initialValues}
          >
            {(props) => (
              <Form onSubmit={props.handleSubmit}>
                <Stack spacing={6}>
                  <Field
                    size="lg"
                    name="email"
                    label="Электронная почта"
                    component={InputForm}
                    isDisabled={isLoading}
                  />
                  <Stack spacing={3}>
                    <Button
                      type="submit"
                      size="lg"
                      isLoading={isLoading}
                      colorScheme="blue"
                    >
                      Востановить пароль
                    </Button>
                    <Button
                      size="lg"
                      colorScheme="gray"
                      variant="ghost"
                      onClick={() => history.goBack()}
                    >
                      Ввести пароль
                    </Button>
                  </Stack>
                </Stack>
              </Form>
            )}
          </Formik>
        </Stack>
      </CardWrapper>
    </LayoutServices>
  )
}
