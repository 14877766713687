import { SlideWrapper } from '../components/SlideWrapper'
import { Box, Flex, Grid, Heading, Image, Stack, Text, useMediaQuery } from '@chakra-ui/react'
import { Container } from '../components/Container'
import Img from './images/image.png'
import Img3 from './images/image-3.png'
import { Scene } from 'react-scrollmagic'
import { useWindowHeight } from '../components/useWindowHeight'
import { transition } from '../constants/transition'
import Bg from './images/bg.svg'
import { Logo } from '../components/Logo'

export const Lessors = () => {
  const windowHeight = useWindowHeight()
  const [isLarge768] = useMediaQuery('(min-width: 768px)')

  return (
    <>
      <Scene duration={windowHeight * 3} triggerElement="#scene21">
        {scene21 => {
          const isScene1 = scene21 > 0.44
          return (
            <div id="scene21">
              <Scene duration={windowHeight} triggerElement="#scene22">
                {scene22 => {
                  const style = () => {
                    if (isLarge768) {
                      if (!isScene1) {
                        return { backgroundColor: '#3571E0', backgroundImage: Bg, backgroundSize: 'cover'}
                      } else {
                        return { backgroundColor: 'white'}
                      }
                    }
                    return { backgroundColor: '#3571E0'}
                  }
                  return (
                    <div>
                      <SlideWrapper
                        justifyContent="space-between"
                        top={0}
                        position={['static', 'static', 'sticky']}
                        transition={transition.cubic}
                        pt={['20px', '20px', '0px']}
                        pb={['30px', '30px', '0px']}
                        {...style()}
                      >
                        <Container flex={1}>
                          <Grid
                            templateColumns={['1fr', '1fr', '1fr', '.68fr 1fr']}
                            gap="50px"
                            flex={1}
                          >
                            <Flex
                              alignItems="center"
                              maxW="500px"
                              transition={transition.cubic}
                              {...isLarge768 && {
                                opacity: isScene1 > 0.3 ? 0 : 1
                              }}
                            >
                              <Stack spacing={4} color="white">
                                <Logo/>
                                <Heading fontSize={['30px', '40px', '60px', '65px']}>
                                  Для заказчика
                                </Heading>
                                <Text>
                                  С помощью фильтров подберите оборудование, подходящее для исполнения Ваших заказов. Свяжитесь с владельцем и запросите технико-коммерческое предложение
                                </Text>
                              </Stack>
                            </Flex>
                            <Flex alignItems="center" display={['none', 'none', 'none', 'flex']}>
                              <Image
                                position="absolute"
                                width="1100px"
                                height="auto"
                                src={Img}
                                borderRadius="30px"
                                boxShadow="0px 20px 100px rgba(0, 0, 0, 0.1)"
                                transition={transition.cubic}
                                transform={isScene1 ? 'scale(.95)' : 'scale(0.9)'}
                              />
                              <Image
                                position="absolute"
                                width="1100px"
                                height="auto"
                                zIndex={2}
                                src={Img3}
                                opacity={isScene1 ? 1 : 0}
                                borderRadius="30px"
                                boxShadow="0px 20px 100px rgba(0, 0, 0, 0.1)"
                                transition={transition.cubic}
                                transform={isScene1 ? 'scale(.95)' : 'scale(0.9)'}
                              />
                            </Flex>
                          </Grid>
                        </Container>
                      </SlideWrapper>
                      <SlideWrapper 
                        position={['static', 'static', 'sticky']}
                        top="0px"
                        pt="30px"
                        id="scene22"
                      >
                        <Container>
                          <Flex
                            alignItems="center"
                            maxW="450px"
                            mb="40px"
                            {...isLarge768 ? {
                              color: isScene1 ? 'black' : 'white'
                            } : {
                              color: 'white'
                            }}
                          >
                            <Box>
                              <Stack spacing={4}>
                                <Heading fontSize={['1.2rem', '1.5rem', '1.7rem', '2rem']}>
                                  Для заказчика 
                                </Heading>
                                <Text>
                                  Размещайте заявки на изготовление или поставку продукции. Получайте предложения от заинтересованных предприятий
                                </Text>
                              </Stack>
                            </Box>
                          </Flex>
                        </Container>
                      </SlideWrapper>
                    </div>
                  )
                }}
              </Scene>
            </div>
          )
        }}
      </Scene>
    </>
  )
}
