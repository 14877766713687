import MarkdownIt from 'markdown-it'
import Video from 'markdown-it-video'
import Insert from 'markdown-it-ins'
import Mila from 'markdown-it-link-attributes'
import Center from 'markdown-it-center-text'

export const getMarkdown = (value) => {
  const md = new MarkdownIt({
    html: true,
    linkify: true,
    //typography: false,
    //xhtmlOut: false,
    breaks: true
  })
    .use(Video, {
      youtube: { width: '100%', height: 450 },
      vimeo: { width: 500, height: 281 },
      vine: { width: 600, height: 600, embed: 'simple' },
      prezi: { width: 550, height: 400 }
    })
    .use(Insert) // used for adding underlines to text
    .use(Mila, {
      attrs: {
        // For opening all the links in new tab
        target: '_blank',
        rel: 'noopener',
      },
    })
    .use(Center)
  return md.render(value)
}
