import { useWindowHeight } from '../useWindowHeight'
import { Flex } from '@chakra-ui/react'
import React from 'react'

// eslint-disable-next-line react/display-name
export const SlideWrapper = React.forwardRef(({ children, ...props }, ref) => {
  const windowHeight = useWindowHeight()
  return (
    <Flex
      ref={ref}
      flexDirection="column"
      minH={['auto', 'auto', `${windowHeight}px`, `${windowHeight}px`]}
      width="100%"
      overflow="hidden"
      {...props}
    >
      {children}
    </Flex>
  )
})
