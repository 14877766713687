import { Box } from '@chakra-ui/react'

export const FullContainer = ({ children, ...props }) => {
  return (
    <Box
      maxWidth="1500px"
      ml="auto"
      mr="auto"
      {...props}
    >
      {children}
    </Box>
  )
}
