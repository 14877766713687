import React, { useCallback, useState } from 'react'
import { Form } from 'components/Form'
import { Field, FieldArray, Formik } from 'formik'
import { BlockSection } from 'components/BlockSection'
import { BlockHeading } from 'components/BlockHeading'
import { InputForm } from 'components/InputForm'
import { SwitchForm } from 'components/SwitchForm'
import { BlockGrid } from 'components/BlockGrid'
import { Box, Button, Stack, Text, useBoolean } from '@chakra-ui/react'
import { FileUploader } from 'components/FileUploader'
import { BlockFooter } from 'components/BlockFooter'
import { FileWidget } from 'components/FileWidget'
import { Okved2Form } from 'components/Okved2Form'
import { TextAreaForm } from 'components/TextAreaForm'
import { BlockCollection } from 'components/BlockCollection'
import { useUploadFile, useUpdate } from './services'
import { useFiles } from '../services'
import { isEmpty } from 'lodash'
import { useAccount } from 'hooks/useAccount'
import * as Yup from 'yup'
import { useAccess } from 'hooks/useAccess'
import { rolesType } from 'constants/rolesType'
import { ImageUploaderForm } from 'components/ImageUploaderForm'
import { useFetch } from './services/useFetch'
import { residentOptions, residentType } from 'constants/residentType'
import { SelectForm } from 'components/SelectForm'
import { GeoAddressForm } from 'components/GeoAddressForm'
import { ShortCompletedProfileModal } from '../../../components/ShortCompletedProfileModal'
import { FullyCompletedProfileModal } from '../../../components/FullyCompletedProfileModal'

const formSchema = Yup.object().shape({
  contragentName: Yup.string().required('Заполните поле'),
  mainOKVED: Yup.object().test('mainOKVED', 'Заполните поле', (e) => !isEmpty(e)),
  equipmentTools: Yup.array().of(
    Yup.object({
      contactFullName: Yup.string().required('Заполните поле'),
      contactEmail: Yup.string().required('Заполните поле').default(''),
      contactPhoneNumber: Yup.string().required('Заполните поле').default(''),
      jobTitle: Yup.string().required('Заполните поле').default(''),
    })
  ),
  address: Yup.object({
    displayString: Yup.string().required('Заполните поле Адрес'),
  }),
  contragentContacts: Yup.array().of(
    Yup.object({
      contactFullName: Yup.string().required('Заполните поле'),
      contactEmail: Yup.string().required('Заполните поле'),
      contactPhoneNumber: Yup.string().required('Заполните поле'),
      jobTitle: Yup.string().required('Заполните поле'),
    })
  ),
})

const initialTouched = {
  contragentName: true,
  mainOKVED: true,
  address: true,
  contragentContacts: [
    {
      contactFullName: true,
      contactEmail: true,
      contactPhoneNumber: true,
      jobTitle: true,
    },
  ],
}

export const Edit = ({ id, isValidateOnMount, onCancel }) => {
  const { dataAccount, getAccount } = useAccount()
  const [isAdmin] = useAccess([rolesType.admin])
  const [isOpenUploadFile, setIsOpenUploadFile] = useBoolean()
  const { dataFetch } = useFetch(id)
  const { update, isLoadingUpdate } = useUpdate(id)
  const { uploadFile, isLoadingUploadFile } = useUploadFile()
  const { getFiles, dataFiles, isLoadingFiles } = useFiles()
  const [isShowModal, setIsShowModal] = useState(false)

  const getValues = useCallback(
    (data) => ({
      isCustomer: data?.isCustomer,
      isSupplier: data?.isSupplier,
      contragentName: data?.contragentName ?? '',
      logoImageId: data?.logo ?? {},
      mainOKVED: data?.mainOKVED ?? {},
      description: data?.description ?? '',
      resident: data?.resident ?? residentType.russianFederation,
      bic: data?.bic ?? '',
      checkingAccount: data?.checkingAccount ?? '',
      correspondentAccount: data?.correspondentAccount ?? '',
      nameOfTheBank: data?.nameOfTheBank ?? '',
      contragentContacts: !isEmpty(data?.contragentContacts)
        ? data?.contragentContacts
        : [
            {
              contactFullName: '',
              contactEmail: '',
              contactPhoneNumber: '',
              jobTitle: '',
            },
          ],
      address: {
        ...data?.address,
        displayString: data?.address?.displayString ?? '',
        longitude: data?.address?.longitude ?? 44.3297,
        latitude: data?.address?.latitude ?? 56.231708,
        region: data?.address?.region ?? '',
        city: data?.address?.city ?? '',
        area: data?.address?.area ?? '',
        street: data?.address?.street ?? '',
        house: data?.address?.house ?? '',
        flat: data?.address?.flat ?? '',
        postalCode: data?.address?.postalCode ?? '',
      },
    }),
    []
  )

  const handleUploadFile = async (e) => {
    try {
      await uploadFile({
        name: e.name,
        description: e.description,
        file: e.file.uri,
        fileName: e.file.name,
        fileSize: e.file.size,
        type: e.file.type,
      })
      setIsOpenUploadFile.off()
      await getFiles()
    } catch (e) {}
  }

  const handleDelete = async () => {
    await getFiles()
  }

  const handleForm = async (values) => {
    const dataRequest = {
      ...values,
      mainOKVED: {
        code: values.mainOKVED?.code,
      },
      logoImageId: values.logoImageId?.id,
    }
    await update(dataRequest)
    await getAccount()
    if (isValidateOnMount) {
      setIsShowModal(true)
      return
    }
    onCancel()
  }

  return (
    <>
      <Formik
        //validateOnChange={false}
        enableReinitialize
        initialValues={getValues(id ? dataFetch : dataAccount)}
        validationSchema={formSchema}
        onSubmit={handleForm}
        {...isValidateOnMount && {
          validateOnMount: true,
          initialTouched
        }}
      >
        {(props) => (
          <Form onSubmit={props.handleSubmit}>
            <BlockCollection>
              {(!isAdmin || id) && (
                <>
                  <BlockSection>
                    <BlockHeading>Роль в системе</BlockHeading>
                    <Field
                      name="isCustomer"
                      component={SwitchForm}
                      isDisabled={!props.values.isSupplier}
                      label="Заказчик"
                    />
                    <Field
                      name="isSupplier"
                      component={SwitchForm}
                      isDisabled={!props.values.isCustomer}
                      label="Исполнитель"
                    />
                  </BlockSection>
                  <BlockSection>
                    <BlockHeading>Общая информация</BlockHeading>
                    <Field
                      name="logoImageId"
                      label="Изображение"
                      component={ImageUploaderForm}
                      isDisabled={isLoadingUpdate}
                    />
                    <Field
                      name="contragentName"
                      label="Наименование"
                      component={InputForm}
                      isDisabled={isLoadingUpdate}
                    />
                    <Field
                      name="resident"
                      label="Резидент"
                      component={SelectForm}
                      placeholder={'Выберите из списка'}
                      isDisabled={isLoadingUpdate}
                      options={residentOptions}
                    />
                    <Field
                      name="mainOKVED"
                      label="Основной вид деятельности"
                      placeholder="Выберите..."
                      component={Okved2Form}
                      isDisabled={isLoadingUpdate}
                    />
                    <Field
                      name="description"
                      label="Описание"
                      placeholder="Введите описание"
                      component={TextAreaForm}
                      isDisabled={isLoadingUpdate}
                    />
                  </BlockSection>
                </>
              )}
              <BlockSection>
                <BlockHeading>Местонахождение</BlockHeading>
                <Field name="address" component={GeoAddressForm} />
              </BlockSection>
              <BlockSection>
                <BlockHeading>Реквизиты (необязательно)</BlockHeading>
                <BlockGrid>
                  <Field
                    name="checkingAccount"
                    label="Расчетный счет"
                    component={InputForm}
                    isDisabled={isLoadingUpdate}
                  />
                  <Field
                    name="correspondentAccount"
                    label="Корреспондентский счёт"
                    component={InputForm}
                    isDisabled={isLoadingUpdate}
                  />
                </BlockGrid>
                <BlockGrid>
                  <Field
                    name="bic"
                    label="БИК"
                    component={InputForm}
                    isDisabled={isLoadingUpdate}
                  />
                  <Field
                    name="nameOfTheBank"
                    label="Наименование банка"
                    component={InputForm}
                    isDisabled={isLoadingUpdate}
                  />
                </BlockGrid>
              </BlockSection>
              <BlockSection>
                <BlockHeading>Контактные лица</BlockHeading>
                <FieldArray
                  name="contragentContacts"
                  render={(arrayHelpers) => (
                    <>
                      {props.values.contragentContacts?.map((item, index) => (
                        <Stack spacing="20px" borderWidth={1} padding="24px" borderRadius="12px">
                          <Stack spacing={4}>
                            <BlockGrid>
                              <Field
                                name={`contragentContacts.${index}.contactFullName`}
                                label="ФИО"
                                component={InputForm}
                                isDisabled={isLoadingUpdate}
                              />
                              <Field
                                name={`contragentContacts.${index}.jobTitle`}
                                label="Должность"
                                component={InputForm}
                                isDisabled={isLoadingUpdate}
                              />
                            </BlockGrid>
                            <BlockGrid>
                              <Field
                                name={`contragentContacts.${index}.contactPhoneNumber`}
                                label="Телефон"
                                component={InputForm}
                                isDisabled={isLoadingUpdate}
                              />
                              <Field
                                name={`contragentContacts.${index}.contactEmail`}
                                label="Электронная почта для связи"
                                component={InputForm}
                                isDisabled={isLoadingUpdate}
                              />
                            </BlockGrid>
                          </Stack>
                          {props.values.contragentContacts?.length > 1 && (
                            <Button
                              width="fit-content"
                              size="sm"
                              colorScheme="gray"
                              onClick={() => arrayHelpers.remove(index)}
                            >
                              Удалить
                            </Button>
                          )}
                        </Stack>
                      ))}
                      <Box>
                        <Button
                          onClick={() =>
                            arrayHelpers.push({
                              contactFullName: '',
                              contactEmail: '',
                              contactPhoneNumber: '',
                              jobTitle: '',
                            })
                          }
                          size="sm"
                        >
                          Добавить
                        </Button>
                      </Box>
                    </>
                  )}
                />
              </BlockSection>
              {(!isAdmin || id) && (
                <>
                  <BlockSection>
                    <BlockHeading isLoading={isLoadingFiles}>Документы</BlockHeading>
                    <BlockGrid>
                      {isEmpty(dataFiles) ? (
                        <>
                          <Text color="gray.400">Нет документов</Text>
                        </>
                      ) : (
                        dataFiles?.map((file, index) => (
                          <FileWidget key={index} data={file} onDelete={handleDelete} />
                        ))
                      )}
                    </BlockGrid>
                    <Box>
                      <FileUploader
                        isOpen={isOpenUploadFile}
                        onOpen={() => setIsOpenUploadFile.on()}
                        onClose={() => setIsOpenUploadFile.off()}
                        isLoading={isLoadingUploadFile}
                        onChange={handleUploadFile}
                      />
                    </Box>
                  </BlockSection>
                </>
              )}
            </BlockCollection>
            <BlockFooter>
              <Button
                type="submit"
                colorScheme="blue"
                isDisabled={!props.dirty}
                isLoading={isLoadingUpdate}
              >
                Обновить
              </Button>
              <Button onClick={onCancel}>Отменить</Button>
            </BlockFooter>
          </Form>
        )}
      </Formik>
      <FullyCompletedProfileModal
        isOpen={isShowModal}
        onClose={() => setIsShowModal(false)}
      />
    </>
  )
}
