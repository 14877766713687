import useQuery from 'hooks/useQuery'
import { api } from 'api'
import { useEffect } from 'react'
import { actions as settings } from 'store/profile/settings/store'
import { useDispatch, useSelector } from 'react-redux'

export const useFetchNotificationCount = () => {
  const dispatch = useDispatch()
  const { notificationCount } = useSelector(state => state.profile.settings);

  const {
    isSuccess,
    request,
    response,
  } = useQuery(() => api.notifications.fetchCount())

  useEffect(() => {
    if (isSuccess) {
      dispatch(settings.updateNotificationCount(response?.count))
    }
  }, [isSuccess])

  return {
    notificationCount,
    getCount: request
  }
}
