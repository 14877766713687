import { Center, Flex, Heading, HStack, Spinner, Stack, Text } from '@chakra-ui/react'
import { Charts } from 'components/Charts/Charts'
import { isEmpty } from 'lodash'
import { DifferenceText } from '../DifferenceText'
import { useMemo } from 'react'

const mappingDatePoints = (data = []) => {
  if (isEmpty(data)) {
    return []
  }
  return data?.map(item => ({
    x: item?.label,
    y: item?.value
  }))
}

const mappingLinePoints = (data = []) => {
  if (isEmpty(data)) {
    return []
  }
  return data?.map(item => ({
    x: item?.label,
    y: item?.value
  }))
}

const mappingPie = (data = []) => {
  if (isEmpty(data)) {
    return []
  }
  return data?.map(item => ({
    ...item,
    id: item?.label
  }))
}

const Chart = ({ type, data, ...props }) => {
  if (type === 'date') {
    return <Charts.Date data={data} {...props}/>
  }
  if (type === 'line') {
    return <Charts.Line data={data} {...props}/>
  }
  if (type === 'pie') {
    return <Charts.Pie data={data} {...props}/>
  }
  return null
}

export const AnalyticChart = ({ data, isLoading, chartProps, ...props }) => {
  const _dataChart = useMemo(() => {
    if (!isEmpty(data?.series)) {
      const result = []
      data?.series.forEach((item, index) => {
        result.push({
          id: item?.footerTitle,
          data: mappingLinePoints(item?.chartData)
        })
      })
      return {
        type: 'line',
        data: result
      }
    }

    if (!isEmpty(data?.chartData)) {
      return {
        type: 'date',
        data: [
          {
            id: 'people',
            data: mappingDatePoints(data?.chartData)
          }
        ]
      }
    }

    if (!isEmpty(data?.pieData)) {
      return {
        type: 'pie',
        data: mappingPie(data?.pieData)
      }
    }

    return null
  }, [data])

  return (
    <Flex position="relative" flex={1} width="100%" {...props}>
      <Center
        position="absolute"
        zIndex={10}
        left={0}
        right={0}
        bottom={0}
        top={0}
        width="100%"
        height="100%"
        transition="0.3s"
        backgroundColor="rgba(255, 255, 255, 0.7)"
        opacity={isLoading ? 1 : 0}
        pointerEvents="none"
      >
        <Spinner color="blue.400" size="md"/>
      </Center>
      <Stack
        flex={1}
        boxShadow="rgba(15, 15, 15, 0.09) 0px 6px 24px"
        padding="28px"
        borderRadius="14px"
      >
        <Heading
          fontSize="1.2rem"
        >
          {data?.title}
        </Heading>
        <Flex alignItems="center" justifyContent="space-between">
          <HStack spacing="8px" py="12px" alignItems="center">
            <Text fontSize="1.6rem" fontWeight="bold">{data?.value ? Number(data?.value?.toFixed(2)) : 0}</Text>
            <DifferenceText value={data?.difference ?? 0}/>
          </HStack>
          {data?.avgValue && (
            <Text fontSize="0.9rem">{`${data?.avgValue ? Number(data?.avgValue?.toFixed(2)) : 0} ${data?.avgTitle}`}</Text>
          )}
        </Flex>
        {_dataChart ? (
          <Chart
            type={_dataChart?.type}
            data={_dataChart?.data}
          />
        ) : (
          <Center backgroundColor="gray.100" borderRadius="12px" flex={1} minH="300px">
            Нет данных
          </Center>
        )}
        {data?.footerValue && (
          <Flex alignItems="center" justifyContent="space-between" pt="14px">
            <Text fontSize="0.9rem">{`${data?.footerTitle}: `}<Text as="span" fontWeight="bold">{data?.footerValue ?? 0}</Text></Text>
          </Flex>
        )}
      </Stack>
    </Flex>
  )
}
