import { Button, Flex, Grid, Textarea, useBoolean } from '@chakra-ui/react'
import { MarkdownRender } from '../MarkdownRender'
import { initialValue } from './constants/initialValue'

export const MarkdownEditor = ({ value, onChange }) => {
  const [isHints, setIsHints] = useBoolean()

  return (
    <Grid templateColumns="1fr 1fr" flex={1} pb="20px" gap="20px">
      <Flex>
        <Textarea
          fontSize="0.84rem"
          fontFamily="Menlo, monospace"
          height="100%"
          minHeight="500px"
          value={value}
          placeholder="Пишите статью тут"
          onChange={e => onChange(e.target.value)}
        />
      </Flex>
      <Flex
        position="relative"
        borderWidth={1}
        borderRadius="var(--chakra-radii-md)"
        px="var(--chakra-space-6)"
        py="var(--chakra-space-4)"
        minHeight="400px"
      >
        <Button
          zIndex={10}
          position="absolute"
          right="14px"
          top="14px"
          size="sm"
          onClick={() => setIsHints.toggle()}
        >
          {isHints ? 'Предпросмотр' : 'Подсказки'}
        </Button>
        {isHints ? (
          <Textarea
            variant="unstyled"
            fontSize="0.84rem"
            fontFamily="Menlo, monospace"
            height="100%"
            minHeight="400px"
            value={initialValue}
            isReadOnly
          />
        ) : (
          <MarkdownRender content={value ?? ''}/>
        )}
      </Flex>
    </Grid>
  )
}
