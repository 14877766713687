import React from 'react'
import { InputDate } from '../InputDate'
import _ from 'lodash'

export const InputDateForm = React.forwardRef(
  ({ field, form, sizeInput, label, ...props }, ref) => {
    const handleChange = (e) => {
      form.setFieldValue(field.name, e)
    }
    return (
      <InputDate
        name={field.name}
        label={label}
        isInvalid={_.get(form.errors, field.name) && _.get(form.touched, field.name)}
        error={_.get(form.errors, field.name)}
        value={field.value}
        onChange={handleChange}
        {...props}
      />
    )
  }
)
