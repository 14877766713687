import React, { useState } from 'react'
import {
  Badge,
  Box,
  Button,
  Flex,
  Grid,
  HStack,
  Spinner,
  Stack,
  Text, useBoolean,
  useColorModeValue
} from '@chakra-ui/react'
import { orderStateType, statusColorScheme, statusDescription, statusLabel } from 'constants/orderStateType'
import { isEmpty } from 'lodash'
import { useHistory } from 'react-router'
import { useRouteMatch } from 'react-router-dom'
import { useAccess } from 'hooks/useAccess'
import { rolesType } from 'constants/rolesType'
import { ReasonForm } from './ReasonForm'
import { DoneForm } from './DoneForm'

export const State = ({data, rejectReason, onChange}) => {
  const [isOpenReasonForm, setIsOpenReasonForm] = useBoolean(false)
  const [isOpenDoneForm, setIsOpenDoneForm] = useBoolean(false)
  const [isAdmin] = useAccess([rolesType.admin])
  const history = useHistory()
  const match = useRouteMatch()
  const [isLoading, setIsLoading] = useState(false)

  const bg = useColorModeValue('gray.50', 'gray.700')
  const borderColor = useColorModeValue('gray.100', 'transparent')

  const handleEdit = () => {
    history.push(`${match.url}/edit`)
  }

  const handleUpdate = async (stateId) => {
    try {
      setIsLoading(true)
      if (stateId === 6) {
        setIsOpenReasonForm.on()
        return
      }
      if (stateId === 7) {
        setIsOpenDoneForm.on()
        return
      }
      await onChange(stateId, {})
    } catch (e) {} finally {
      setIsLoading(false)
    }
  }

  const handleReasonChange = async (e) => {
    try {
      setIsLoading(true)
      await onChange(6, {
        rejectReason: e.message
      })
      setIsOpenReasonForm.off()
    } catch (e) {} finally {
      setIsLoading(false)
    }
  }

  const handleDoneChange = async (e) => {
   try {
     setIsLoading(true)
     await onChange(7, {
       supplier: e.supplier,
       contractAmount: e.contractAmount
     })
     setIsOpenDoneForm.off()
   } catch (e) {} finally {
     setIsLoading(false)
   }
  }

  const handleCloseAllForm = () => {
    setIsOpenReasonForm.off()
    setIsOpenDoneForm.off()
  }

  if (isEmpty(data)) {
    return null
  }

  return (
    <>
      <Grid
        borderColor={borderColor}
        borderWidth={1}
        templateColumns="1fr 1fr"
        minH="100px"
        backgroundColor={bg}
        borderRadius="12px"
        mb="38px"
        gap="40px"
        padding="24px"
        position="relative"
      >
        {isLoading && (
          <Flex
            position="absolute"
            zIndex={10}
            left={0}
            right={0}
            bottom={0}
            top={0}
            width="100%"
            height="100%"
            backgroundColor="whiteAlpha.200"
            alignItems="center"
            justifyContent="center"
            borderRadius="12px"
            mb="38px"
          >
            <Spinner color="blue.500"/>
          </Flex>
        )}
        <Stack spacing={2}>
          <Box>
            <Badge
              fontSize="0.9rem"
              colorScheme={`${statusColorScheme[data.name]}`}
              px="8px"
              py="1px"
              borderRadius="4px"
            >
              {statusLabel[data.name]}
            </Badge>
          </Box>
          <Box fontSize="0.96rem" lineHeight="1.4">
            <Text>
              {statusDescription[data.name]}
            </Text>
            {rejectReason && (
              <Text>{rejectReason}</Text>
            )}
          </Box>
        </Stack>
        {isAdmin ? (
          <HStack justifyContent="flex-end" spacing={3}>
            {(data.name === orderStateType.new || data.name === orderStateType.updated) && (
              <>
                <Button
                  isLoading={isLoading}
                  variant="outline"
                  colorScheme="purple"
                  onClick={() => handleUpdate(3)}
                  size="sm"
                >
                  На модерацию
                </Button>
                <Button
                  isLoading={isLoading}
                  variant="outline"
                  colorScheme="green"
                  onClick={() => handleUpdate(4)}
                  size="sm"
                >
                  Опубликовать
                </Button>
              </>
            )}
            {data.name === orderStateType.onModeration && (
              <>
                <Button
                  colorScheme="gray"
                  variant="outline"
                  onClick={handleEdit}
                  size="sm"
                >
                  Редактировать
                </Button>
                <Button
                  isLoading={isLoading}
                  variant="outline"
                  colorScheme="green"
                  onClick={() => handleUpdate(4)}
                  size="sm"
                >
                  Принять
                </Button>
                <Button
                  isLoading={isLoading}
                  variant="outline"
                  colorScheme="red"
                  onClick={() => handleUpdate(6)}
                  size="sm"
                >
                  Отклонить
                </Button>
                <Button
                  isLoading={isLoading}
                  variant="outline"
                  colorScheme="orange"
                  onClick={() => handleUpdate(5)}
                  size="sm"
                >
                  Отменить
                </Button>
              </>
            )}
            {(data.name === orderStateType.rejected || data.name === orderStateType.cancelled) && (
              <>
                <Button
                  isLoading={isLoading}
                  variant="outline"
                  colorScheme="purple"
                  onClick={() => handleUpdate(3)}
                  size="sm"
                >
                  На модерацию
                </Button>
                <Button
                  isLoading={isLoading}
                  variant="outline"
                  colorScheme="green"
                  onClick={() => handleUpdate(4)}
                  size="sm"
                >
                  Опубликовать
                </Button>
              </>
            )}
            {data.name === orderStateType.published && (
              <>
                <Button
                  isLoading={isLoading}
                  variant="outline"
                  colorScheme="purple"
                  onClick={() => handleUpdate(3)}
                  size="sm"
                >
                  На модерацию
                </Button>
                <Button
                  isLoading={isLoading}
                  variant="outline"
                  colorScheme="red"
                  onClick={() => handleUpdate(5)}
                  size="sm"
                >
                  Снять с публикации
                </Button>
              </>
            )}
            {data.name === orderStateType.completed && (
              <>
                <Button
                  isLoading={isLoading}
                  variant="outline"
                  colorScheme="green"
                  onClick={() => handleUpdate(4)}
                  size="sm"
                >
                  Опубликовать
                </Button>
              </>
            )}
          </HStack>
        ) : (
          <HStack justifyContent="flex-end" spacing={3}>
            {(data.name === orderStateType.new || data.name === orderStateType.updated) && (
              <>
                <Button
                  colorScheme="gray"
                  variant="outline"
                  size="sm"
                  onClick={handleEdit}
                >
                  Редактировать
                </Button>
                <Button
                  isLoading={isLoading}
                  variant="outline"
                  colorScheme="purple"
                  onClick={() => handleUpdate(3)}
                  size="sm"
                >
                  Отправить на модерацию
                </Button>
              </>
            )}
            {data.name === orderStateType.rejected && (
              <>
                <Button
                  colorScheme="gray"
                  variant="outline"
                  size="sm"
                  onClick={handleEdit}
                >
                  Редактировать
                </Button>
              </>
            )}
            {data.name === orderStateType.cancelled && (
              <>
                <Button
                  colorScheme="gray"
                  variant="outline"
                  size="sm"
                  onClick={handleEdit}
                >
                  Редактировать
                </Button>
                <Button
                  isLoading={isLoading}
                  variant="outline"
                  colorScheme="purple"
                  onClick={() => handleUpdate(3)}
                  size="sm"
                >
                  Отправить на модерацию
                </Button>
              </>
            )}
            {data.name === orderStateType.published && (
              <>
                <Button
                  isLoading={isLoading}
                  variant="outline"
                  colorScheme="purple"
                  onClick={() => handleUpdate(7)}
                  size="sm"
                >
                  Завершить
                </Button>
                <Button
                  isLoading={isLoading}
                  variant="outline"
                  colorScheme="red"
                  onClick={() => handleUpdate(5)}
                  size="sm"
                >
                  Снять с публикации
                </Button>
              </>
            )}
            {data.name === orderStateType.completed && (
              <>
                <Button
                  isLoading={isLoading}
                  variant="outline"
                  colorScheme="green"
                  onClick={() => handleUpdate(4)}
                  size="sm"
                >
                  Вернуть в реестр
                </Button>
                <Button
                  isLoading={isLoading}
                  variant="outline"
                  colorScheme="blue"
                  onClick={() => window.open(`${match.url}/edit?create=true`)}
                  size="sm"
                >
                  Опубликовать повторно
                </Button>
              </>
            )}
          </HStack>
        )}
      </Grid>
      <ReasonForm
        isLoading={isLoading}
        isOpen={isOpenReasonForm}
        onClose={handleCloseAllForm}
        onChange={handleReasonChange}
      />
      <DoneForm
        isLoading={isLoading}
        isOpen={isOpenDoneForm}
        onClose={handleCloseAllForm}
        onChange={handleDoneChange}
      />
    </>
  )
}
