import { Icon, IconButton } from '@chakra-ui/react'
import React from 'react'
import { AiOutlineStar, AiFillStar } from 'react-icons/ai'

export const FavoriteButton = ({ isLoading, isActive, onClick }) => {
  return (
    <IconButton
      isLoading={isLoading}
      variant="ghost"
      size="sm"
      aria-label="Favorite"
      icon={isActive ? (
        <Icon as={AiFillStar} color="orange.400" fontSize="1.1rem"/>
      ) : <Icon as={AiOutlineStar} color="gray.400" fontSize="1.1rem"/>}
      borderRadius="full"
      onClick={onClick}
    />
  )
}
