import { Flex, SlideFade } from '@chakra-ui/react'

export const Tag = ({ isShow, children, ...props}) => {
  return (
    <SlideFade offsetY="30px" in={isShow}>
      <Flex
        color="white"
        padding="8px 20px"
        borderRadius="18px"
        borderColor="blue.400"
        borderWidth={1}
        width="fit-content"
        fontWeight="600"
        fontSize="1.2rem"
        {...props}
      >{children}</Flex>
    </SlideFade>
  )
}
