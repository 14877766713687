import React, { useMemo } from 'react'
import { ListContent } from 'components/ListContent'
import { Button, HStack, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react'
import { PageHeading } from 'components/PageHeading'
import { Filter } from './components/Filter'
import { EntityList } from 'components/EntityList'
import { useEquipmentList } from './services'
import { useParams } from 'react-router-dom'
import { SortMenu } from 'components/SortMenu/SortMenu'
import { sortFieldType } from 'constants/sortType'
import { useHistory } from 'react-router'
import { Container } from 'components/Container'
import { Snippet } from 'components/Snippet'
import { filter, truncate } from 'lodash'
import { ViewSwitch } from 'components/ViewSwitch'
import { useUpdateFavoriteState } from '../services/useUpdateFavoriteState'
import { ScrollBox } from 'components/ScrollBox'
import { EntityMap } from 'components/EntityMap'
import { useAccess } from '../../../hooks/useAccess'
import { rolesType } from '../../../constants/rolesType'
import { ChevronDownIcon } from '@chakra-ui/icons'

export const List = () => {
  const { accountId } = useParams()
  const { updateFavorite } = useUpdateFavoriteState()
  const [isAdmin] = useAccess([rolesType.admin])

  const {
    totalCount,
    dataList,
    dataMap,
    isLoading,
    getNextPage,
    hasMoreRows,
    dataFilter,
    updateFilter,
    resetFilter,
    exportFile,
    isLoadingExportFile,
    exportRepliesFile,
    isLoadingExportRepliesFile
  } = useEquipmentList(accountId)

  const history = useHistory()

  const handleReset = () => {
    resetFilter()
    history.push('/equipments')
  }

  const _dataMap = useMemo(() => {
    return (
      dataMap?.map(item => ({
        id: item?.id,
        latitude: item?.latitude,
        longitude: item?.longitude,
        list: item?.list?.map(i => ({
          id: i?.id,
          title: truncate(i?.name, { length: 100 }),
          description: truncate(i?.description, { length: 100 }),
          contragentName: i?.creator?.contragent?.contragentName,
          to: `/equipments/card/${i?.id}`,
          imageData: i?.images?.[0],
        }))
      }))
    )
  }, [dataMap])

  return (
    <Container>
      <ListContent>
        <ScrollBox>
          <PageHeading
            count={totalCount}
            renderAction={
              <HStack>
                {isAdmin && (
                  <Menu>
                    <MenuButton
                      as={Button}
                      rightIcon={<ChevronDownIcon />}
                      size="sm"
                      _focus={{
                        boxShadow: 'none'
                      }}
                    >
                      Скачать
                    </MenuButton>
                    <MenuList
                      type="radio"
                      boxShadow={'rgba(15, 15, 15, 0.05) 0px 0px 0px 1px, rgba(15, 15, 15, 0.1) 0px 3px 6px, rgba(15, 15, 15, 0.1) 0px 9px 24px'}
                    >
                      <MenuItem isLoading={isLoadingExportFile} onClick={exportFile}>
                        Список
                      </MenuItem>
                      <MenuItem isLoading={isLoadingExportRepliesFile} onClick={exportRepliesFile}>
                        Данные по откликам
                      </MenuItem>
                    </MenuList>
                  </Menu>
                )}
                <SortMenu
                  value={dataFilter.sort}
                  onChange={(e) => updateFilter('sort', e)}
                  fields={[sortFieldType.numberOfViews, sortFieldType.numberOfReplies]}
                />
                <ViewSwitch value={dataFilter.view} onChange={(e) => updateFilter('view', e)} />
              </HStack>
            }
          >
            Оборудование
          </PageHeading>
          {dataFilter.view === 'list' ? (
            <EntityList
              isLoading={isLoading}
              hasMore={hasMoreRows}
              data={dataList}
              onNext={getNextPage}
              keyField={'id'}
            >
              {(item, index) => (
                <Snippet.Equipment data={item} onFavorite={updateFavorite} isOpenNewTab />
              )}
            </EntityList>
          ) : (
            <EntityMap isActivePopover isLoading={isLoading} data={_dataMap} />
          )}
        </ScrollBox>
        <ScrollBox borderLeftWidth={1}>
          <Filter
            isLoading={isLoading}
            data={dataFilter}
            update={updateFilter}
            reset={handleReset}
            accountId={accountId}
          />
        </ScrollBox>
      </ListContent>
    </Container>
  )
}
