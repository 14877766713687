import React from 'react'
import { Okved2 } from 'components/Okved2'
import _ from 'lodash'
import { useMemo } from 'react'
import { ErrorBoundary } from '../ErrorBoundary';

export const Okved2Form = React.forwardRef(({field, form, sizeInput, label, ...props}) => {
  const handleChange = (e) => {
    form.setFieldValue(field.name, e)
  }

  const _value = useMemo(() => {
    if (field.value) {
      return field.value
    }
    return {}
  }, [field.value])

  return (
    <ErrorBoundary>
      <Okved2
        label={label}
        isInvalid={_.get(form.errors, field.name) && _.get(form.touched, field.name)}
        error={_.get(form.errors, field.name)}
        value={_value}
        onChange={handleChange}
        {...props}
      />
    </ErrorBoundary>
  )
})
