import useQuery from 'hooks/useQuery'
import { api } from 'api'

export const useFetchAccount = () => {
  const {
    isLoading,
    response,
    request
  } = useQuery((id) => api.accounts.fetchItem(id))

  return {
    isLoadingAccount: isLoading,
    dataAccount: response,
    getAccount: request
  }
}
