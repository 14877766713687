import { Placemark } from '@pbe/react-yandex-maps'
import { useMemo, useRef, useState } from 'react'

export const MapPin = ({ latitude, longitude, list, isDraggable, to, onDragEnd, isActivePopover }) => {
  const ref = useRef()

  const [isOpen, setIsOpen] = useState(false)

  const handleDragEnd = () => {
    onDragEnd(ref.current.geometry._coordinates)
  }

  const balloonContent = useMemo(() => {
    const _list = list
      ?.map((item) => {
        return `
          <div style='line-height: 1.6; display: flex'>
            ${item?.imageUrl
              ? `<img src='${item.imageUrl}' style='border-radius: 4px; width: 50px; height: 50px; object-fit: contain; margin-top: 6px; margin-right: 10px; />`
              : `<div style='display: flex; align-items: center; justify-content: center; background-color: #EDF2F7; border-radius: 4px; width: 50px; height: 50px; margin-top: 6px; margin-right: 10px'>
                    <svg stroke="currentColor" fill="#CBD5E0" stroke-width="0" viewBox="0 0 16 16" focusable="false" height="16px" width="16px" xmlns="http://www.w3.org/2000/svg"><path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"></path><path d="M1.5 2A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13zm13 1a.5.5 0 0 1 .5.5v6l-3.775-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12v.54A.505.505 0 0 1 1 12.5v-9a.5.5 0 0 1 .5-.5h13z"></path></svg>
                  </div>`}
            <div>
              <div style='font-weight: bold; font-size: 1rem'>${item.title}</div>
              ${item.contragentName ? `<div style='opacity: 0.5'>${item.contragentName}</div>` : ''}
              <a href='${item.to}' style='color: #3182ce' target='_blank'>Подробнее</a>
            </div>
          </div>
        `
      })
      .join('')
    return `
    <div style='display: flex; flex-direction: column; gap: 10px'>
      ${_list}
    </div>
    `
  }, [list])

  if (isActivePopover) {
    return (
      <Placemark
        geometry={[latitude, longitude]}
        properties={{
          balloonContent,
        }}
        options={{
          draggable: isDraggable,
          balloonPanelMaxMapArea: 0,
        }}
        onClick={() => setIsOpen(!isOpen)}
        modules={['geoObject.addon.balloon']}
        instanceRef={(ref) => {
          if (ref && isOpen) {
            ref.balloon.open()
          } else if (ref) {
            ref.balloon.close()
          }
        }}
        onDragEnd={handleDragEnd}
      />
    )
  }

  return (
    <Placemark
      instanceRef={ref}
      geometry={[latitude, longitude]}
      onDragEnd={handleDragEnd}
      options={{
        draggable: isDraggable,
      }}
      {...(to && {
        onClick: () => window.open(String(to)),
      })}
    />
  )
}
