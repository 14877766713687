import { Modal } from '../Modal'
import React from 'react'
import { Box, Button, Stack } from '@chakra-ui/react'

export const FullyCompletedProfileModal = ({ isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} header="Профиль почти заполнен">
      <Stack spacing="20px">
        <Box>
          Опишите деятельность компании, укажите реквизиты и приложите документы, чтобы другие
          предприятия смогли узнать о Вашей компании больше информации. Заполните вкладку «Анкета
          исполнителя», если выбрали соответствующую роль. Публикуйте своё свободное оборудование и
          размещайте заявки, оперативно находите партнеров.
        </Box>
        <Button width="fit-content" colorScheme="blue" onClick={onClose}>
          Хорошо
        </Button>
      </Stack>
    </Modal>
  )
}
