import { SlideWrapper } from '../components/SlideWrapper'
import { Flex, Grid, Heading, Stack, Text } from '@chakra-ui/react'
import { Logo } from '../components/Logo'
import { AnnotationCard } from '../components/AnnotationCard'
import { Container } from '../components/Container'
import { Scene } from 'react-scrollmagic'
import { useWindowHeight } from '../components/useWindowHeight'
import { Tag } from './components/Tag'
import { useRef, useEffect, useState } from 'react'

export const Segment = () => {
  const windowHeight = useWindowHeight()
  const headingRef = useRef()

  const [heightHeading, setHeightHeading] = useState(0)
  useEffect(() => {
    const handleResize = () => setHeightHeading(headingRef.current?.clientHeight)
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <>
      <Scene
        duration={windowHeight}
        triggerElement="#scene11"
      >
        {scene1 => {
          return (
            <div data-aos="fade-up">
              <Scene
                duration={windowHeight}
                triggerElement="#scene12"
              >
                {scene2 => {
                  return (
                    <div>
                      <SlideWrapper
                        top={0}
                        position={['static', 'static', 'sticky']}
                        justifyContent="center"
                        pt={['30px', '30px', '0px']}
                      >
                        <Container>
                          <Grid
                            templateColumns={['1fr', '1fr', '1fr', '1fr 480px']}
                            templateRows={['auto auto', 'auto auto', 'auto auto', 'auto']}
                            gap="60px"
                          >
                            <Flex alignItems="center">
                              <Stack ref={headingRef} spacing={6} maxW="700px">
                                <Logo/>
                                <Heading
                                  fontSize={['1.2rem', '1.5rem', '1.7rem', '2rem']}
                                  color="white"
                                >
                                  Платформа позволяет организовать коммуникацию по вопросам импортозамещения и кооперационного взаимодействия для:
                                </Heading>
                                <Stack spacing={3} display={['none', 'none', 'none', 'block']}>
                                  <Tag isShow>Заказчиков</Tag>
                                  <Tag isShow={scene1 > 0.7}>Исполнителей </Tag>
                                  <Tag isShow={scene2 > 0.7}>Государственного сектора</Tag>
                                </Stack>
                              </Stack>
                            </Flex>
                            <Flex alignItems="center">
                              <AnnotationCard
                                title="Заказчики"
                                value={[
                                  <Text key={1}>Унифицированный каталог оборудования других предприятий</Text>,
                                  <Text key={2}>Оперативный поиск исполнителей по технологическому признаку</Text>,
                                  <Text key={3}>Снижение пиковых нагрузок на собственное производство</Text>,
                                ]}
                              />
                            </Flex>
                          </Grid>
                        </Container>
                      </SlideWrapper>
                      <SlideWrapper
                        position={['static', 'static', 'sticky']}
                        top={0}
                        justifyContent="center"
                        id="scene11"
                        pt={['20px', '20px', '0px']}
                      >
                        <Container alignItems={['flex-start', 'flex-start', 'flex-start', 'flex-end']}>
                          <AnnotationCard
                            mt={[`0px`, `0px`, `${heightHeading + 100}px`, '40px']}
                            mr={['0px', '0px', '0px', '20px']}
                            ml={['0px', '0px', '20px', '0px']}
                            title="Исполнители"
                            backgroundColor="#08090D"
                            value={[
                              <Text key={1}>Реклама технологических и производственных возможностей предприятия</Text>,
                              <Text key={2}>Привлечение дополнительных заказов</Text>,
                              <Text key={3}>Дозагрузка производственных мощностей</Text>,
                            ]}
                          />
                        </Container>
                      </SlideWrapper>
                      <SlideWrapper
                        position={['static', 'static', 'sticky']}
                        top={0}
                        justifyContent="center"
                        id="scene12"
                        pt={['20px', '20px', '0px']}
                        pb={['30px', '30px', '0px']}
                      >
                        <Container alignItems={['flex-start', 'flex-start', 'flex-start', 'flex-end']}>
                          <AnnotationCard
                            mt={[`0px`, `0px`, `${heightHeading + 140}px`, '80px']}
                            mr={['0px', '0px', '0px', '40px']}
                            ml={['0px', '0px', '40px', '0px']}
                            title="Государственный сектор"
                            backgroundColor="white"
                            color="black"
                            value={[
                              <Text key={1}>Полнота информации о производственных возможностях и потребностях предприятий.</Text>,
                              <Text key={2}>Отслеживание динамики отраслей. Аналитика и возможность точечной поддержки предприятий.</Text>,
                            ]}
                          />
                        </Container>
                      </SlideWrapper>
                    </div>
                  )
                }}
              </Scene>
            </div>
          )
        }}
      </Scene>
    </>
  )
}
