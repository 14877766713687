import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  data: {}
}

const {reducer, actions} = createSlice({
  initialState,
  name: 'account',
  reducers: {
    update: (state, { payload }) => {
      state.data = payload
    },
    reset: () => initialState,
  }
})

export { actions, reducer }
export default reducer
