import React, { useEffect } from 'react'
import { useFetchSupportRequest, useUpdateStatus } from './services'
import { useParams } from 'react-router-dom'
import { PageHeading } from 'components/PageHeading'
import { BackButton } from 'components/BackButton'
import { ItemColumns } from 'components/ItemColumns'
import { BlockCollection } from 'components/BlockCollection'
import { BlockSection } from 'components/BlockSection'
import { BlockHeading } from 'components/BlockHeading'
import { Box, Text } from '@chakra-ui/react'
import { GridFieldItem } from 'components/GridField'
import { isEmpty } from 'lodash'
import { BlockGrid } from 'components/BlockGrid'
import { FileWidget } from 'components/FileWidget'
import { State } from './components/State'
import { useFetchAccount } from './services/useFetchAccount'
import { Container } from 'components/Container'
import { ScrollBox } from 'components/ScrollBox'

export const Item = () => {
  const { id } = useParams()
  const { data, getSupportRequest, isLoadingFetch } = useFetchSupportRequest(id)
  const { updateStatus, isLoadingUpdateStatus} = useUpdateStatus(id)
  const { getAccount, dataAccount } = useFetchAccount()
  const isLoading = isLoadingFetch

  const handleStatus = async (e) => {
    await updateStatus({ statusId: e})
    await getSupportRequest()
  }

  useEffect(() => {
    if (!isEmpty(data?.contragent)) {
      getAccount(data?.contragent?.id)
    }
  }, [data])

  return (
    <Container>
      <ScrollBox>
        <PageHeading
          isLoading={isLoadingFetch}
          renderBack={<BackButton/>}
        >
          {`Запрос №${data?.id ?? ''}`}
        </PageHeading>
        <State
          isLoading={isLoadingUpdateStatus}
          data={data}
          dataAccount={dataAccount}
          onChange={handleStatus}
        />
        <ItemColumns>
          <BlockCollection>
            <BlockSection>
              <BlockHeading>Информация о заказчике</BlockHeading>
              <Box>
                <GridFieldItem
                  isReady
                  isLoading={isLoading}
                  label="Наименование"
                >
                  {data?.contragent?.contragentName}
                </GridFieldItem>
                <GridFieldItem
                  isReady
                  isLoading={isLoading}
                  label="ИНН"
                >
                  {data?.contragent?.inn}
                </GridFieldItem>
                <GridFieldItem
                  isReady
                  isLoading={isLoading}
                  label="Регион"
                >
                  {data?.contragent?.region}
                </GridFieldItem>
                <GridFieldItem
                  isReady
                  isLoading={isLoading}
                  label="Основной вид деятельности"
                >
                  {`(${data?.contragent?.mainOKVEDCode}) ${data?.contragent?.mainOKVED}`}
                </GridFieldItem>
              </Box>
            </BlockSection>
            {data?.description && (
              <BlockSection>
                <BlockHeading>Описание</BlockHeading>
                <Box>
                  {data?.description}
                </Box>
              </BlockSection>
            )}
            <BlockSection>
              <BlockHeading>Документы</BlockHeading>
              <BlockGrid>
                {isEmpty(data?.files) ? (
                  <>
                    <Text color="gray.400">Нет документов</Text>
                  </>
                ) : data?.files?.map((file, index) => (
                  <FileWidget
                    key={index}
                    data={file}
                    isHideDelete
                  />
                ))}
              </BlockGrid>
            </BlockSection>
          </BlockCollection>
        </ItemColumns>
      </ScrollBox>
    </Container>
  )
}
