import { daData } from 'api/daData'
import { accounts } from './accounts'
import { file } from './file'
import { order } from './order'
import { supportRequests } from './supportRequests'
import { notifications } from './notifications'
import { classifier } from './classifier'
import { vendorsData } from './vendorsData'
import { image } from './image'
import { admin } from './admin'
import { equipment } from './equipment'
import { address } from './address'
import { reply } from './reply'
import { knowledgeBase } from './knowledgeBase'
import { analytic } from './analytic'
import { yametrikaProxy } from './yametrikaProxy'

export const api = {
  daData,
  accounts,
  file,
  order,
  equipment,
  supportRequests,
  notifications,
  classifier,
  vendorsData,
  image,
  admin,
  address,
  reply,
  knowledgeBase,
  analytic,
  yametrikaProxy
}
