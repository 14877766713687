import { Field, Formik } from 'formik'
import { Box, Button, Fade, Flex } from '@chakra-ui/react'
import { InputForm } from 'components/InputForm'
import { Link, useLocation } from 'react-router-dom'
import { Form } from 'components/Form'
import * as Yup from 'yup'
import { BlockSection } from 'components/BlockSection'
import { useAuthentication } from 'hooks/useAuthentication'
import React from 'react'
import { getUrlParameter } from 'utils/getUrlParameter'
import { useHistory } from 'react-router'
import { InputPasswordForm } from '../InputPasswordForm'

const formSchema = Yup.object().shape({
  email: Yup.string().required('Заполните поле'),
  password: Yup.string().required('Заполните поле')
})

export const LoginForm = () => {
  const location = useLocation()
  const history = useHistory()
  const { request, isLoading } = useAuthentication()
  const redirectUrl = location.search ? getUrlParameter('redirectUrl', location.search) : null

  const handleForm = async (values) => {
    await request(values)
    redirectUrl && history.push(redirectUrl)
  }

  return (
    <Fade in>
      <Formik
        enableReinitialize
        onSubmit={handleForm}
        validationSchema={formSchema}
        initialValues={{
          email: '',
          password: ''
        }}
      >
        {(props) => (
          <Form onSubmit={props.handleSubmit}>
            <BlockSection>
              <Field
                size="lg"
                name="email"
                label="Электронная почта"
                component={InputForm}
                isDisabled={isLoading}
              />
              <Field
                name="password"
                size="lg"
                label="Пароль"
                component={InputPasswordForm}
                isDisabled={isLoading}
              />
              <Box pt="10px">
                <Button
                  width="100%"
                  type="submit"
                  size="lg"
                  isLoading={isLoading}
                  colorScheme="blue"
                >
                  Войти
                </Button>
              </Box>
              <Flex justifyContent="center" alignItems="center">
                <Box>
                  <Link to={'/recover'}>Забыли пароль?</Link>
                </Box>
              </Flex>
            </BlockSection>
          </Form>
        )}
      </Formik>
    </Fade>
  )
}
