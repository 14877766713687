import { isEmpty } from 'lodash'
import {
  Box,
  Center,
  Spinner,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { Add } from './components/Add'
import { Item } from './components/Item/Item'
import { levelLabel } from './constants/levelLabel'
import { Update } from './components/Update'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { useSaveClassifierOrder } from '../../services/useSaveClassifierOrder'

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
};

const Row = ({ data, parentId, level = 0, isSearch, setParentId, setHeader, setUpdateValue }) => {
  const [rows, setRows] = useState(null)
  const { saveClassifierOrder } = useSaveClassifierOrder()

  const _level = level + 1

  const handleDragEnd = (result) => {
    if (!result.destination) {
      return
    }

    const items = reorder(
      rows,
      result.source.index,
      result.destination.index
    )

    setRows(items)

    const ids = items?.map(i => i.id)
    saveClassifierOrder({
      ids,
      parentId
    })
  }

  useEffect(() => {
    data && setRows(data)
  }, [data])

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {rows?.map((item, index) => {

              const handleAdd = (e) => {
                e.preventDefault()
                e.stopPropagation()
                setParentId(item.id)
                setHeader(levelLabel[_level + 1])
              }

              const handleUpdate = (e) => {
                e.preventDefault()
                e.stopPropagation()
                setUpdateValue && setUpdateValue(item)
              }

              return (
                <Draggable
                  key={item.id}
                  //disableInteractiveElementBlocking
                  draggableId={String(item.id)}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <Item
                      isSearch={isSearch}
                      data={item}
                      onAdd={handleAdd}
                      onUpdate={handleUpdate}
                      level={_level}
                      dragProvided={provided}
                      isDragging={snapshot.isDragging}
                    >
                      {children => (
                        <Row
                          data={children}
                          parentId={item?.id}
                          level={_level}
                          isSearch={isSearch}
                          setParentId={setParentId}
                          setHeader={setHeader}
                          setUpdateValue={setUpdateValue}
                        />
                      )}
                    </Item>
                  )}
                </Draggable>
              )
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )

}

export const Tree = ({ data, isSearch, isLoading, onCreate, onUpdate }) => {
  const [parentId, setParentId] = useState(null)
  const [header, setHeader] = useState('')
  const [updateValue, setUpdateValue] = useState(null)

  const handleCloseUpdate = () => {
    setUpdateValue(null)
  }


  return (
    <>
      <Box>
        {!isEmpty(data) ? (
          <Row
            data={data}
            isSearch={isSearch}
            setParentId={setParentId}
            setHeader={setHeader}
            setUpdateValue={setUpdateValue}
          />
        ) : (
          <Center height="500px">
            {isLoading ? <Spinner color="blue.500"/> : 'Нет данных'}
          </Center>
        )}
      </Box>
      <Add
        header={header}
        setHeader={setHeader}
        parentId={parentId}
        setParentId={setParentId}
        onCreate={onCreate}
      />
      <Update
        initialValues={updateValue}
        isOpen={!!updateValue}
        onClose={handleCloseUpdate}
        onUpdate={onUpdate}
      />
    </>
  )
}
