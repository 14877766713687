import { useToast } from '@chakra-ui/react'
import useQuery from 'hooks/useQuery'
import { api } from 'api'
import download from 'downloadjs'

export const useExportList = (data) => {
  const toast = useToast()
  const {
    isLoading,
    request
  } = useQuery(() => api.equipment.exportList(data))

  const exportFile = async () => {
    try {
      const result = await request(data)
      download(result, `exported-file.xlsx`)
    } catch (e) {
      toast({
        title: 'Ошибка',
        description: 'Не удалось получить данные',
        status: 'error'
      })
    }
  }

  return {
    isLoading,
    exportFile
  }
}
