import React from 'react'
import { HiOutlineTrash } from 'react-icons/hi';
import { Flex } from '@chakra-ui/react';

export const InputAddonTrash = ({ onClick, isDisabled, ...props }) => {
  if (isDisabled) {
    return null
  }
  return (
    <Flex
      role="group"
      height="100%"
      width="22px"
      alignItems="center"
      transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
      cursor="pointer"
      _hover={{
        color: 'blue.500'
      }}
      onClick={onClick}
      {...props}
    >
      <HiOutlineTrash />
    </Flex>
  )
}
