import React from 'react'
import { Box, Stack, Text, useColorMode } from '@chakra-ui/react'
import { IconLamp } from './components/IconLamp'

export const Informer = ({ title, children, colorScheme = 'green' }) => {
  const { colorMode } = useColorMode()
  const isLight = colorMode === 'light'

  return (
    <Box backgroundColor={`${colorScheme}.${isLight ? '50' : '900'}`} padding="24px" borderRadius="12px">
      <IconLamp color={`${colorScheme}.500`} fontSize="1.5rem" mb="10px"/>
      <Stack spacing={2} fontSize="0.9rem" lineHeight="1.4">
        {title && <Text fontWeight="semibold">{title}</Text>}
        <Box>
          {children}
        </Box>
      </Stack>
    </Box>
  )
}
