import useQuery from 'hooks/useQuery'
import { api } from 'api'
import { useEntityList } from 'hooks/useEntityList/useEntityList'
import { useFilter } from './useFilter'
import { projectType } from 'constants/projectType'

const parameters = {
  project: projectType.orders
}

export const useSupportRequestList = () => {
  const { request, isLoading } = useQuery((data) => api.supportRequests.fetchList(data))
  const { dataFilter, updateFilter, resetFilter, sort } = useFilter()
  const { getNextPage, hasMoreRows, dataList } = useEntityList(
    { request, sort, parameters })

  return {
    getNextPage,
    hasMoreRows,
    isLoading,
    dataList,
    dataFilter,
    updateFilter,
    resetFilter
  }
}
