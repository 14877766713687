import { FormLabel } from 'components/FormLabel'
import { Box, Collapse, Flex, Icon, Stack, Text, useStyleConfig } from '@chakra-ui/react'
import { SmallCloseIcon } from '@chakra-ui/icons'
import React, { useState } from 'react'
import { useAccount } from './services/useAccount'
import { useEffect } from 'react'
import { isEmpty } from 'lodash'
import { useHistory } from 'react-router';

export const CompanyLabel = ({ accountId }) => {
  const history = useHistory()
  const styles = useStyleConfig('Input', { size: 'md'})
  const [activeCompany, setActiveCompany] = useState(null)
  const { request, data, isError } = useAccount()

  const handleClear = (e) => {
    e.preventDefault()
    e.stopPropagation()
    history.push('/orders')
    setActiveCompany(null)
  }

  useEffect(() => {
    if (!isEmpty(data)) {
      setActiveCompany(data)
    }
  }, [data])

  useEffect(() => {
    if (isError) {
      setActiveCompany({ contragentName: `Компания: ${accountId}`})
    }
  }, [isError])

  useEffect(() => {
    if (accountId) {
      request(accountId)
    }
  }, [accountId])

  return (
   <Collapse in={!isEmpty(activeCompany) && !!accountId} unmountOnExit>
     <Box>
       <FormLabel>
         Компания
       </FormLabel>
       <Flex
         {...styles.field}
         alignItems="center"
         minH="40px"
         height="auto"
         flex={1}
       >
         <Flex justifyContent="space-between" alignItems="center" flex={1}>
           <Stack spacing={1} pt="1px" justifyContent="center">
             <Text fontSize="0.93rem" lineHeight="1.2">{activeCompany?.contragentName}</Text>
           </Stack>
           <Icon as={SmallCloseIcon} color="gray.600" onClick={handleClear} />
         </Flex>
       </Flex>
     </Box>
   </Collapse>
  )
}
