import { Button, Menu, MenuButton, MenuItemOption, MenuList, MenuOptionGroup } from '@chakra-ui/react'
import { ChevronDownIcon } from '@chakra-ui/icons'
import React from 'react';
import { sortDirectionType, sortFieldType } from 'constants/sortType'

export const SortMenu = (
  {
    value,
    onChange,
    fields = [],
  }) => {
  return (
    <Menu>
      <MenuButton
        as={Button}
        rightIcon={<ChevronDownIcon />}
        size="sm"
        _focus={{
          boxShadow: 'none'
        }}
      >
        Сортировка
      </MenuButton>
      <MenuList
        type="radio"
        boxShadow={'rgba(15, 15, 15, 0.05) 0px 0px 0px 1px, rgba(15, 15, 15, 0.1) 0px 3px 6px, rgba(15, 15, 15, 0.1) 0px 9px 24px'}
      >
        <MenuOptionGroup title="По времени" value={value} onChange={onChange}>
          <MenuItemOption value={`${sortFieldType.default}:${sortDirectionType.ascending}`}>
            Сначала старые
          </MenuItemOption>
          <MenuItemOption value={`${sortFieldType.default}:${sortDirectionType.descending}`}>
            Сначала новые
          </MenuItemOption>
        </MenuOptionGroup>
        {fields.includes(sortFieldType.numberOfViews) && (
          <MenuOptionGroup title="По просмотрам" value={value} onChange={onChange}>
            <MenuItemOption value={`${sortFieldType.numberOfViews}:${sortDirectionType.ascending}`}>
              Меньше просмотров
            </MenuItemOption>
            <MenuItemOption value={`${sortFieldType.numberOfViews}:${sortDirectionType.descending}`}>
              Больше просмотров
            </MenuItemOption>
          </MenuOptionGroup>
        )}
        {fields.includes(sortFieldType.numberOfReplies) && (
          <MenuOptionGroup title="По откликам" value={value} onChange={onChange}>
            <MenuItemOption value={`${sortFieldType.numberOfReplies}:${sortDirectionType.ascending}`}>
              Меньше откликов
            </MenuItemOption>
            <MenuItemOption value={`${sortFieldType.numberOfReplies}:${sortDirectionType.descending}`}>
              Больше откликов
            </MenuItemOption>
          </MenuOptionGroup>
        )}
      </MenuList>
    </Menu>
  )
}
