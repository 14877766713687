import React from 'react'
import { Flex, useColorModeValue } from '@chakra-ui/react';

export const Count = ({ count = 0, ...props}) => {
  const bg = useColorModeValue('gray.200', 'gray.600')
  const color = useColorModeValue('black', 'white')
  return (
    <Flex
      fontSize="0.73rem"
      fontWeight="semibold"
      backgroundColor={count === 0 ? bg : 'red.500'}
      borderRadius="full"
      px="7px"
      color={count === 0 ? color : 'white'}
      {...props}
    >
      {count}
    </Flex>
  )
}
