import React from 'react'
import { useSupportRequestList } from './services'
import { EntityList } from 'components/EntityList'
import { SortMenu } from 'components/SortMenu/SortMenu'
import { Box, Flex } from '@chakra-ui/react'
import { SnippetItem } from '../SnippetItem'

export const Equipment = () => {
  const { dataList, getNextPage, hasMoreRows, dataFilter, updateFilter, isLoading } = useSupportRequestList()
  return (
    <Box pb="80px">
      <Flex mb="24px">
        <SortMenu
          value={dataFilter.sort}
          onChange={e => updateFilter('sort', e)}
        />
      </Flex>
      <EntityList
        hasMore={hasMoreRows}
        isLoading={isLoading}
        data={dataList}
        onNext={getNextPage}
      >
        {(item, index) => (
          <SnippetItem data={item}/>
        )}
      </EntityList>
    </Box>
  )
}
