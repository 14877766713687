import axios from 'axios'

const ENTITY = 'yametrikaProxy'

export const yametrikaProxy = Object.freeze({
  async fetchUsers(params) {
    const response = await axios.get(`/${ENTITY}/users`, { params })
    return response.data
  },
  async fetchVisits(params) {
    const response = await axios.get(`/${ENTITY}/visits`, { params })
    return response.data
  },
  async fetchVisitsBounce(params) {
    const response = await axios.get(`/${ENTITY}/visitsbounce`, { params })
    return response.data
  },
  async fetchAvgPageViews(params) {
    const response = await axios.get(`/${ENTITY}/avgPageViews`, { params })
    return response.data
  },
  async fetchVisitsDuration(params) {
    const response = await axios.get(`/${ENTITY}/visitsDuration`, { params })
    return response.data
  },
  async fetchTrafficSources(params) {
    const response = await axios.get(`/${ENTITY}/trafficSources`, { params })
    return response.data
  },
  async fetchVisitsDeviceCategory(params) {
    const response = await axios.get(`/${ENTITY}/visitsDeviceCategory`, { params })
    return response.data
  }
})
