export const markdownCss = {
  '*': {
    lineHeight: '1.8',
  },
  'h1': {
    fontSize: '1.5rem',
    fontWeight: 'bold'
  },
  'h2': {
    fontSize: '1.4rem',
    fontWeight: 'bold'
  },
  'h3': {
    fontSize: '1.3rem',
    fontWeight: 'bold'
  },
  'h4': {
    fontSize: '1.2rem',
    fontWeight: 'bold'
  },
  'h5': {
    fontSize: '1.13rem',
    fontWeight: 'bold'
  },
  'h6': {
    fontSize: '1rem',
    fontWeight: 'bold'
  },
  'a': {
    color: '#4299E1',
    transition: '0.3s'
  },
  'a:hover': {
    opacity: '0.6',
  },
  'ul': {
    paddingLeft: '14px'
  },
  'ol': {
    paddingLeft: '28px',
  },
  'blockquote': {
    borderLeftWidth: '4px',
    borderColor: '#BEE3F8',
    paddingLeft: '14px'
  },
  '.text-align-center': {
    textAlign: 'center'
  }
}
