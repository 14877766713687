import { useWindowHeight } from '../useWindowHeight'
import { FullContainer } from '../FullContainer'
import { Box } from '@chakra-ui/react'
import { useWindowWidth } from '../useWindowWidth'
import { useEffect, useRef, useState } from 'react';

export const BackgroundVideo = ({ ...props }) => {
  const videoRef = useRef()
  const [isLoading, setIsLoading] = useState(true)
  const windowHeight = useWindowHeight()
  const windowWidth = useWindowWidth()

  useEffect(() => {
    videoRef.current?.addEventListener('loadeddata', () => setIsLoading(false))
    return () => {
      videoRef.current?.removeEventListener('loadeddata', () => setIsLoading(true))
    }
  }, [])

  return (
    <Box
      position="fixed"
      left={0}
      right={0}
      top={0}
      bottom={0}
      width="100%"
      height="100%"
      zIndex={1}
      backgroundColor="black"
    >
      <FullContainer
        height={`${windowHeight}px`}
        width="100%"
      >
        <video
          ref={videoRef}
          preload="auto"
          autoPlay
          muted
          loop
          playsInline
          width={`${windowWidth}px`}
          height={`${windowHeight}px`}
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
            objectFit: 'cover',
            objectPosition: 'center center',
            outline: 'none',
            border: 'none',
            opacity: isLoading ? 0 : 0.3,
            transition: "opacity, 2s ease-in-out"
          }}
          src={require('./bg-2.mp4')}
        />
      </FullContainer>
    </Box>
  )
}
