import React, { useEffect, useMemo } from 'react'
import { Select } from 'components/Select'
import { isEmpty } from 'lodash';
import { useFetchRegionOrders } from 'services/useFetchRegionOrders'

export const RegionOrdersSelect = ({ value, onChange, ...props }) => {
  const { fetchRegionOrders, isLoadingFetchRegionOrders, dataRegionOrders } = useFetchRegionOrders()

  const options = useMemo(() => (
    [
      {
        label: 'Все регионы',
        value: ''
      },
      ...!isEmpty(dataRegionOrders) ? dataRegionOrders?.map(i => ({ label: i, value: i })) : []
    ]
  ), [dataRegionOrders])

  const handleChange = (e) => {
    onChange(e)
  }

  useEffect(() => {
    fetchRegionOrders()
  }, [])

  return (
    <Select
      options={options}
      isLoading={isLoadingFetchRegionOrders}
      isDisabled={isLoadingFetchRegionOrders}
      placeholder="Регион"
      onChange={handleChange}
      value={value}
      {...props}
    />
  )
}
