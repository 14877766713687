import { useMemo } from 'react';
import { analyticPeriodEnum } from '../constants/analyticPeriodEnum';

export const useChartProps = (filter) => {
  const chartProps = useMemo(() => {
    if (filter?.period === analyticPeriodEnum.week) {
      return {
        axisBottom: {
          format: '%d',
          tickValues: 'every 7 days',
          legend: 'время',
          legendOffset: -12,
        }
      }
    }
    if (filter?.period === analyticPeriodEnum.month) {
      return {
        axisBottom: {
          format: '%d',
          tickValues: 'every 2 days',
          legend: 'время',
          legendOffset: -12,
        }
      }
    }
    if (filter?.period === analyticPeriodEnum.year) {
      return {
        axisBottom: {
          format: '%m',
          tickValues: 'every 31 days',
          legend: 'время',
          legendOffset: -12,
        }
      }
    }
    return {
      axisBottom: {
        format: '%d',
        tickValues: 'every 2 days',
        legend: 'время',
        legendOffset: -12,
      }
    }
  }, [filter])

  return { chartProps }
}
