import useQuery from 'hooks/useQuery'
import { api } from 'api'
import { useEntityList } from 'hooks/useEntityList/useEntityList'
import { useMemo } from 'react'

export const useOrderList = () => {
  const defaultParameters = useMemo(() => ({ IsFavourite: true }), [])
  const { request, isLoading } = useQuery((data) => api.equipment.fetchList(data))

  const searchParameters = useMemo(() => (
    { ...defaultParameters }
  ), [defaultParameters])

  const {
    getNextPage,
    hasMoreRows,
    dataList,
    totalCount
  } = useEntityList({ request, searchParameters })

  return {
    getNextPage,
    isLoading,
    hasMoreRows,
    totalCount,
    dataList
  }
}
