import { createSlice } from '@reduxjs/toolkit'
import { sortDirectionType, sortFieldType } from 'constants/sortType'

const initialState = {
  sort: `${sortFieldType.default}:${sortDirectionType.ascending}`
}

const {reducer, actions} = createSlice({
  initialState,
  name: 'supportRequest',
  reducers: {
    update: (state, action) => {
      state[action.payload.field] = action.payload.value
    },
    reset: () => initialState,
  }
});

export { actions, reducer }
export default reducer
