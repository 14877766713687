import React from 'react'
import { Box, Button, HStack } from '@chakra-ui/react'
import { Select } from 'components/Select'
import { PeriodSelect } from '../../../../components/PeriodSelect'
import { RegionOrdersSelect } from '../../../../components/RegionOrdersSelect'
import { Okpd2 } from 'components/Okpd2'

const optionsState = [
  {
    label: 'Все статусы',
    value: ''
  },
  {
    label: 'Новая заявка',
    value: 1
  },
  {
    label: 'Обновлено',
    value: 2
  },
  {
    label: 'На модерации',
    value: 3
  },
  {
    label: 'Опубликовано',
    value: 4
  },
  {
    label: 'Отменено',
    value: 5
  },
  {
    label: 'Отклонено',
    value: 6
  }
]

export const Filter = ({ data, update, reset }) => {
  return (
    <HStack spacing="14px">
      <Box minW="240px" flex={1}>
        <Select
          options={optionsState}
          placeholder="Статус"
          value={data.state}
          onChange={e => update('state', e.value)}
        />
      </Box>
      <Box minW="240px" flex={1}>
        <Okpd2
          isHideName
          placeholder="ОКПД 2"
          value={data.okpd2Code}
          onChange={e => update('okpd2Code', e)}
        />
      </Box>
      <Box width="280px">
        <RegionOrdersSelect
          value={data.region}
          onChange={e => update('region', e.value)}
        />
      </Box>
      <Box width="140px">
        <PeriodSelect
          value={data.period}
          onChange={e => update('period', e.value)}
        />
      </Box>
      <Button size="md" onClick={reset}>Сбросить</Button>
    </HStack>
  )
}
