import useQuery from 'hooks/useQuery'
import { api } from 'api'
import { useEntityList } from 'hooks/useEntityList/useEntityList'
import { useFilter } from './useFilter'
import { useEntityMap } from 'hooks/useEntityMap/useEntityMap'
import { useExportList } from './useExportList'

export const useCompanyData = () => {
  const { dataFilter, updateFilter, searchParameters, resetFilter, sort } = useFilter()
  const { isLoading, request } = useQuery(data => api.accounts.fetchList(data))

  const isListDisabled = dataFilter?.view === 'map'
  const isMapDisabled = dataFilter?.view === 'list'

  const {
    getNextPage,
    hasMoreRows,
    totalCount,
    dataList,
    dataExportRequest
  } = useEntityList({
    request, searchParameters, sort, isDisabled: isListDisabled
  })

  const { dataMap, mapTotalCount } = useEntityMap({
    request, searchParameters, isDisabled: isMapDisabled
  })

  const { exportFile, isLoading: isLoadingExportFile } = useExportList(dataExportRequest)

  return {
    getNextPage,
    isLoading,
    hasMoreRows,
    totalCount: isLoading ? null : isListDisabled ? mapTotalCount : totalCount,
    dataList,
    dataMap,
    dataFilter,
    updateFilter,
    resetFilter,
    exportFile,
    isLoadingExportFile
  }
}
