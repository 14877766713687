import { useDispatch, useSelector } from 'react-redux'
import { useCallback } from 'react'
import { actions } from 'store/map/companies'

export const useMapViewState = () => {
  const dispatch = useDispatch()
  const mapViewState = useSelector(state => state.map.companies.data)

  const update = useCallback(async (value) => {
    dispatch(actions.update(value))
  }, [dispatch])

  return {
    mapViewState,
    updateViewState: update
  }
}
