import useQuery from 'hooks/useQuery'
import { api } from 'api'
import { useEffect } from 'react'
import { actions as favorite } from 'store/favorite/orders'
import { useDispatch, useSelector } from 'react-redux'

export const useFetchFavoriteOrders = () => {
  const dispatch = useDispatch()
  const favoriteOrdersIds = useSelector(state => state.favorite.orders.data)

  const dataRequest = {
    searchParameters: {
      IsFavourite: true
    }
  }

  const {
    isSuccess,
    request,
    response,
  } = useQuery(() => api.order.fetchList(dataRequest))

  useEffect(() => {
    if (isSuccess) {
      const result = response?.rows?.map(i => i.id)
      dispatch(favorite.update(result))
    }
  }, [isSuccess])

  return {
    favoriteOrdersIds,
    fetchFavoriteOrders: request
  }
}
