import { useDispatch, useSelector } from 'react-redux'
import { actions } from 'store/profile/settings/store'
import { useCallback } from 'react';

export const useSettings = () => {
  const dispatch = useDispatch()
  const { settings } = useSelector(state => state.profile)

  const updateSearchArea = useCallback((e) => {
    dispatch(actions.updateSearchArea(e))
  }, [actions])

  return [
    {
      settings,
      updateSearchArea
    }
  ]
}
