import React from 'react'
import { Flex, Text } from '@chakra-ui/react'
import { ArrowBackIcon } from '@chakra-ui/icons'
import { useHistory } from 'react-router'

export const BackButton = ({ ...props }) => {
  const history = useHistory()
  return (
    <Flex
      alignItems="center"
      onClick={() => history.goBack()}
      cursor="pointer"
      flex={1}
      transition="0.3s"
      borderRadius="4px"
      _hover={{
        color: 'blue.400'
      }}
      {...props}
    >
      <ArrowBackIcon boxSize="16px" mr={1} />
      <Text>Назад</Text>
    </Flex>
  )
}
