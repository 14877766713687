import { ResponsiveLine } from '@nivo/line'
import { Badge, Box, Flex, HStack, Text } from '@chakra-ui/react'

export const Date = ({ data, ...props }) => {
  return (
    <Box height="300px">
      <ResponsiveLine
        data={data}
        animate
        margin={{ top: 20, right: 20, bottom: 80, left: 40 }}
        enableSlices="x"
        xScale={{
          type: 'point',
          min: 0,
          max: 'auto',
        }}
        axisLeft={{
          legend: 'количество',
          legendOffset: 10,
        }}
        axisBottom={{
          legend: 'время',
          legendOffset: 50,
          tickRotation: 90,
        }}
        curve="monotoneX"
        pointSize={3}
        pointBorderWidth={1}
        pointBorderColor={{
          from: 'color',
          modifiers: [['darker', 0.3]],
        }}
        useMesh={true}
        enableGridX={false}
        theme={{
          crosshair: {
            line: {
              strokeWidth: 1,
              stroke: '#774dd7',
              strokeOpacity: 1,
            },
          },
        }}
        sliceTooltip={({ slice }) => {
          return (
            <Flex
              backgroundColor="white"
              padding="8px 10px"
              borderWidth={1}
            >
              {slice.points.map(point => (
                <HStack
                  key={point.id}
                  padding="3px"
                  spacing="8px"
                >
                  <Text fontSize="0.83rem">{point.data.xFormatted}</Text>
                  <Badge colorScheme="blue">{point.data.yFormatted}</Badge>
                </HStack>
              ))}
            </Flex>
          )
        }}
        {...props}
      />
    </Box>
  )
}
