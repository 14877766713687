import React, { useEffect, useRef } from 'react'
import { Box, useColorModeValue } from '@chakra-ui/react'

export const ItemSearch = ({
  children,
  tabIndex,
  focusIndex,
  focused,
  onEnterItem = null,
  item,
  ...props
}) => {
  const itemRef = useRef(null)
  const themeBgOptionHoverSelected = useColorModeValue('#3182ce', '#90cdf4')
  const themeColorOption = useColorModeValue('#ffffff', '#000000')
  const handleKeyDown = (e) => {
    switch (e.which) {
      case 13: { // Down Arrow
        onEnterItem && onEnterItem(item)
        break
      }
      default:
        return
    }
    e.preventDefault()
  }
  useEffect(() => {
    if (focused && focusIndex !== -1 && itemRef) {
      itemRef.current.focus()
    }
    // eslint-disable-next-line
  }, [focused])
  return (
    <Box
      ref={itemRef}
      role="option"
      ariaSelected="true"
      padding="6px 14px"
      lineHeight="1.4"
      cursor="pointer"
      tabIndex={tabIndex}
      onKeyDown={handleKeyDown}
      _focus={{
        outline: 'none',
        border: 0,
        backgroundColor: '#EDF2F6',
        boxShadow: 'none',
      }}
      _hover={{
        backgroundColor: themeBgOptionHoverSelected,
        color: themeColorOption,
      }}
      _active={{
        color: '#3181CE',
      }}
      {...props}
    >
      {children}
    </Box>
  )
}
