import { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { getUrlParameter } from 'utils/getUrlParameter'
import { isEmpty } from 'lodash'
import { useUrlState } from 'hooks/useUrlState'
import { sortDirectionType, sortFieldType } from 'constants/sortType'

const initialState = {
  productParameters: [],
  deliveryRegions: [],
  okpd2Codes: [],
  publishedFor: '',
  accountId: null,
  orderStates: [],
  sort: `${sortFieldType.default}:${sortDirectionType.descending}`,
  view: 'list'
}

export const useFilter = (defaultParameters) => {
  const location = useLocation()
  const searchString = location.search ? getUrlParameter('search', location.search) : null
  const dispatch = useDispatch()
  const [filter, setFilter, reset] = useUrlState(initialState)

  const mappingProductParameters = (data) => {
    return data.map(i => {
      return {
        name: i.name,
        ...i.value.from !== '' && { valueFrom: Number(i.value.from)},
        ...i.value.to !== '' && { valueTo: Number(i.value.to)},
        uoMCode: i.code
      }
    })
  }

  const searchParameters = useMemo(() => {
    return  {
      ...searchString && { searchString },
      ...filter.accountId && { accountId: filter.accountId},
      ...!isEmpty(filter.productParameters) && {productParameters: mappingProductParameters(filter.productParameters)},
      ...!isEmpty(filter.deliveryRegions) && { deliveryRegions: filter.deliveryRegions},
      ...!isEmpty(filter.okpd2Codes) && { okpd2Codes: filter.okpd2Codes?.map(i => ({ code: i.code }))},
      ...filter.publishedFor && { publishedFor: filter.publishedFor},
      ...!isEmpty(filter.orderStates) && { orderStates: filter.orderStates?.map(i => ({ id: i.value }))},
      ...defaultParameters && { ...defaultParameters },
    }
  }, [filter, searchString, defaultParameters])

  const update = useCallback(
    async (field, value) => {
      setFilter((prevState) => ({
        ...prevState,
        [field]: value,
      }))
    },
    [setFilter]
  )

  return {
    dataFilter: filter,
    updateFilter: update,
    resetFilter: reset,
    searchParameters,
    sort: filter.sort
  };
};
