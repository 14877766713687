import React, { useEffect, useMemo, useState } from 'react'
import { Select } from '../Select'
import { api } from 'api'
import { isEmpty } from 'lodash'

export const RegionSelect = (
  {
    value,
    onChange,
    error,
    isInvalid,
    isHideError,
    isMulti = false,
    ...props
  }
) => {
  const [data, setData] = useState(null)

  const fetchRegions = async () => {
    try {
      const result = await api.classifier.fetchRegions()
      setData(result?.rows)
    } catch (e) {}
  }

  const options = useMemo(() => (
    data?.map(e => ({ label: `${e?.regionCode} ${e.name}`, value: e?.id }))
  ), [data])

  const _value = useMemo(() => {
    if (isMulti) {
      if (!isEmpty(value)) {
        return value.map(i => ({ value: i.id, label: i.name }))
      }
    }
    if (!isEmpty(value)) {
      return {
        value: value?.id,
        label: value?.name
      }
    }
    return null
  }, [value])

  const handleChange = (e) => {
    if (isMulti) {
      onChange && onChange(e.map(i => ({ id: i.value, name: i.label })))
      return
    }
    onChange && onChange({
      id: e.value,
      name: e.label
    })
  }

  const customFilterOption = (option, inputValue) => {
    return option.label.toLowerCase().includes(inputValue.toLowerCase());
  }

  useEffect(() => {
    fetchRegions()
  }, [])

  return (
    <Select
      isMulti={isMulti}
      label="Регион"
      placeholder="Выберите регион"
      value={_value}
      onChange={handleChange}
      isInvalid={isInvalid}
      error={error}
      optionsMessage={'Начните вводить для поиска'}
      options={options}
      filterOption={customFilterOption}
      {...props}
    />
  )
}
