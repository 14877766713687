import { Icon, IconButton } from '@chakra-ui/react'
import React from 'react'
import { AiOutlineAreaChart } from 'react-icons/ai'

export const AnalyticAccessButton = React.forwardRef(({ isLoading, isActive, onClick }, ref ) => {
  return (
    <IconButton
      ref={ref}
      isLoading={isLoading}
      variant="ghost"
      size="sm"
      aria-label="Favorite"
      icon={isActive ? (
        <Icon as={AiOutlineAreaChart} color="orange.400" fontSize="1.1rem"/>
      ) : <Icon as={AiOutlineAreaChart} color="gray.400" fontSize="1.1rem"/>}
      borderRadius="full"
      onClick={onClick}
    />
  )
})
