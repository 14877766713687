export const supportRequestStatusType = {
  inWork: 'InWork',
  completed: 'Completed',
  rejected: 'Rejected'
}

export const supportRequestStatusLabel = {
  [supportRequestStatusType.inWork]: 'В работе',
  [supportRequestStatusType.completed]: 'Завершено',
  [supportRequestStatusType.rejected]: 'Отклонено',
}

export const supportRequestStatusColorScheme = {
  [supportRequestStatusType.inWork]: 'blue',
  [supportRequestStatusType.completed]: 'green',
  [supportRequestStatusType.rejected]: 'red',
}
