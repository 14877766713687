import { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { getUrlParameter } from 'utils/getUrlParameter'
import { isEmpty, filter } from 'lodash'
import { fieldTypeId } from 'constants/fieldTypeId'
import { useUrlState } from 'hooks/useUrlState'
import { sortDirectionType, sortFieldType } from 'constants/sortType'

const initialState = {
  regions: [],
  accountId: null,
  states: [],
  industrySet: [
    {
      levelId: 1,
    },
    {
      levelId: 2,
    },
    {
      levelId: 3,
    },
    {
      levelId: 4,
    },
  ],
  fields: [],
  fieldsRequired: [],
  sort: `${sortFieldType.default}:${sortDirectionType.descending}`,
  view: 'list',
}

const getIndustrySet = (data) => {
  const result = filter(data, (e) => !!e?.id)
  return result.map((e) => ({ id: e?.id }))
}

const getStubDetails = (data = []) => {
  if (isEmpty(data)) {
    return []
  }
  let result = []
  for (const item of data) {
    if (item?.typeId === fieldTypeId.text || item?.typeId === fieldTypeId.list) {
      if (item.value !== '' && !isEmpty(item.value)) {
        result.push({
          machineClassifierFieldId: item.id,
          fieldValue: item.value,
        })
      }
    }

    if (item?.typeId === fieldTypeId.bool) {
      if (item.value === 'true') {
        result.push({
          machineClassifierFieldId: item.id,
          fieldValue: item.value,
        })
      }
    }

    if (item?.typeId === fieldTypeId.number) {
      const parsed = item?.value?.split(',')
      if (!isEmpty(parsed)) {
        result.push({
          machineClassifierFieldId: item.id,
          ...(parsed[0] !== '' && { fieldValueFrom: Number(parsed[0]) }),
          ...(parsed[1] !== '' && { fieldValueTo: Number(parsed[1]) }),
        })
      }
    }
  }
  return result
}

export const useFilter = (defaultParameters) => {
  const location = useLocation()
  const searchString = location.search ? getUrlParameter('search', location.search) : null
  const dispatch = useDispatch()
  const [filter, setFilter, reset] = useUrlState(initialState)

  const searchParameters = useMemo(() => {
    const industrySet = getIndustrySet(filter.industrySet)
    const stubDetails = [
      ...getStubDetails(filter?.fields),
      ...getStubDetails(filter?.fieldsRequired),
    ]

    return {
      ...(searchString && { searchString }),
      ...(filter.accountId && { accountId: filter.accountId }),
      ...(!isEmpty(filter.states) && { states: filter.states?.map((i) => ({ id: i.value })) }),
      ...(!isEmpty(industrySet) && { industrySet }),
      ...(!isEmpty(stubDetails) && { stubDetails }),
      ...(!isEmpty(filter.regions) && { regions: filter.regions }),
      ...(defaultParameters && { ...defaultParameters }),
    }
  }, [filter, searchString, defaultParameters])

  const update = useCallback(
    async (field, value) => {
      setFilter((prevState) => ({
        ...prevState,
        [field]: value,
      }))
    },
    [setFilter]
  )

  return {
    dataFilter: filter,
    updateFilter: update,
    resetFilter: reset,
    searchParameters,
    sort: filter.sort,
  }
}
