import { Loading } from 'components/Loading'
import React from 'react'
import { useFetchReply } from './services/useFetchReply'
import { Box, Flex, Text } from '@chakra-ui/react'
import { isEmpty } from 'lodash'
import { SnippetItem } from './SnippetItem'

export const Reply = () => {
  const { data, isLoading } = useFetchReply()
  if (isLoading) {
    return <Loading/>
  }

  return (
    <Box pb="80px">
      {isEmpty(data) ? (
        <Flex height="110px" align="center" justify="center">
          <Text color="gray.400">Нет откликов</Text>
        </Flex>
      ) : data.map((item, index) => (
        <SnippetItem key={item?.id} data={item}/>
      ))}
    </Box>
  )
}
