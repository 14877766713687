import { useToast } from '@chakra-ui/react'
import useQuery from 'hooks/useQuery'
import { api } from 'api'
import { useEffect } from 'react'

export const useFetchAnalyticContragent = () => {
  const toast = useToast()
  const {
    isError,
    isLoading,
    error,
    request,
    response
  } = useQuery((data) => api.analytic.fetchContragent(data))

  useEffect(() => {
    if (isError) {
      toast({
        title: 'Ошибка',
        description: error.response?.data?.ErrorMessage,
        status: 'error',
      })
    }
  }, [isError])

  return {
    fetchAnalyticContragent: request,
    data: response,
    isLoadingFetchAnalyticContragent: isLoading,
  }
}
