import React, { useEffect, useState } from 'react'
import { PageHeading } from 'components/PageHeading'
import { Container } from 'components/Container'
import { Tab, TabList, TabPanels, Tabs } from '@chakra-ui/react'
import { useHistory, useParams } from 'react-router'
import { TabPanel } from 'components/TabPanel'
import { Order } from './components/Order'
import { Equipment } from './components/Equipment'
import { ScrollBox } from 'components/ScrollBox'

export const List = () => {
  const history = useHistory()
  const { paramTabIndex } = useParams()
  const [tabIndex, setTabIndex] = useState(0)

  const handleTabsChange = (index) => {
    history.replace(`/support-requests/${index}`)
  }

  useEffect(() => {
    setTabIndex(Number(paramTabIndex))
  }, [paramTabIndex])

  return (
    <Container>
      <ScrollBox>
        <PageHeading>
          Запросы на администрирование
        </PageHeading>
        <Tabs onChange={handleTabsChange} index={tabIndex} isLazy>
          <TabList>
            <Tab>Сделаем в России</Tab>
            <Tab>Станкофонд</Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              <Order/>
            </TabPanel>
            <TabPanel>
              <Equipment/>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </ScrollBox>
    </Container>
  )
}
