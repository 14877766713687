import React from 'react'
import { Box, useColorModeValue } from '@chakra-ui/react'

export const CardWrapper = ({ children, ...props }) => {
  const bg = useColorModeValue('white', 'gray.700')

  return (
    <Box
      width="500px"
      borderRadius="14px"
      backgroundColor={bg}
      pt="12px"
      borderWidth={1}
      //boxShadow="0px 0px 24px rgba(0, 0, 0, 0.05)"
      transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
      overflow="hidden"
      {...props}
    >
      {children}
    </Box>
  )
}
