import { Block } from './Block'
import { Document } from './Document'
import { Link } from './Link'
import { Popover } from './Popover'

export const UnauthorizedInfo = {
  Block,
  Document,
  Link,
  Popover
}
