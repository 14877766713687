import { isEmpty } from 'lodash'
import { Box, Text } from '@chakra-ui/react'
import { BlockGrid } from '../BlockGrid'
import { Field } from 'formik'
import { FileWidgetForm } from '../FileWidgetForm'
import { FileUploaderForm } from '../FileUploaderForm'
import React from 'react'

export const FilesForm = ({ name, form, push, remove }) => {
  const rows = form?.values[name]

  return (
    <>
      {isEmpty(rows) ? (
        <>
          <Text color="gray.400">Нет документов</Text>
        </>
      ) : (
        <BlockGrid>
          {rows?.map((item, index) => (
            <Field
              key={index}
              name={`files.${index}`}
              component={FileWidgetForm}
              onDelete={() => remove(index)}
            />
          ))}
        </BlockGrid>
      )}
      <Box>
        <FileUploaderForm
          onChange={e => push(e)}
        />
      </Box>
    </>
  )
}
