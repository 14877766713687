import React from 'react'
import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay, Stack,
} from '@chakra-ui/react'
import { Field, Formik } from 'formik'
import { Form } from 'components/Form'
import { InputForm } from 'components/InputForm'

export const DoneForm = ({ isOpen, onClose, isLoading, onChange }) => {
  const handleForm = (values) => {
    onChange && onChange(values)
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Завершение заявки</ModalHeader>
        <ModalCloseButton mt={1} />
        <ModalBody>
          <Formik
            initialValues={{
              supplier: '',
              contractAmount: ''
            }}
            onSubmit={handleForm}
          >
            {(props) => (
              <Form onSubmit={props.handleSubmit}>
                <Stack>
                  <Field
                    autoFocus
                    name="supplier"
                    label="Исполнитель"
                    placeholder="Введите наименование"
                    component={InputForm}
                  />
                  <Field
                    autoFocus
                    name="contractAmount"
                    label="Сумма контракта"
                    placeholder="Введите сумму в рублях"
                    component={InputForm}
                  />
                </Stack>
                <Box mt={6} mb={6}>
                  <Button isLoading={isLoading} type="submit" colorScheme="blue" mr={3}>
                    Сохранить
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
