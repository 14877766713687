import React from 'react'
import { Form } from 'components/Form'
import { Field, FieldArray, Formik } from 'formik'
import { BlockSection } from 'components/BlockSection'
import { BlockHeading } from 'components/BlockHeading'
import { InputForm } from 'components/InputForm'
import { SwitchForm } from 'components/SwitchForm'
import { BlockGrid } from 'components/BlockGrid'
import { Box, Button, Text } from '@chakra-ui/react'
import { InputAddonTrash } from 'components/InputAddonTrash'
import { BlockFooter } from 'components/BlockFooter'
import { BlockCollection } from 'components/BlockCollection'
import { useFetchVendorsData } from '../services/useFetchVendorsData'
import { useUpdateVendorsData } from './services'
import { FileUploaderForm } from 'components/FileUploaderForm/FileUploaderForm'
import { FileWidgetForm } from 'components/FileWidgetForm'
import { isEmpty } from 'lodash'
import * as Yup from 'yup'
import { WorkScheduleForm } from 'components/WorkScheduleForm'
import { GeoAddressForm } from '../../../components/GeoAddressForm'

const formSchema = Yup.object({
  //manufacturingAddress: Yup.string().required('Заполните поле'),
  wareHouses: Yup.array().of(
    Yup.object({
      address: Yup.object({
        displayString: Yup.string().required('Заполните поле').default('')
      })
    })
  ),
  manufacturingRange: Yup.array().of(
    Yup.object({
      name: Yup.string().required('Заполните поле').default('')
    })
  ),
  equipmentTools: Yup.array().of(
    Yup.object({
      name: Yup.string().required('Заполните поле').default('')
    })
  ),
})

export const Edit = ({ id, onCancel }) => {
  const { data, isLoadingFetch } = useFetchVendorsData(id)
  const { updateVendorsData, isLoadingUpdate } = useUpdateVendorsData(id)
  const isLoading = isLoadingUpdate || isLoadingFetch

  const handleForm = async (values) => {
    const dataRequest = {
      ...values,
      equipmentTools: values.equipmentTools,
      manufacturingAddress: {
        displayString: values.manufacturingAddress.displayString,
        city: values.manufacturingAddress.city,
        area: values.manufacturingAddress.area,
        street: values.manufacturingAddress.street,
        longitude: values.manufacturingAddress.longitude,
        latitude: values.manufacturingAddress.latitude,
        region: values.manufacturingAddress.region,
        house: values.manufacturingAddress.house,
        flat: values.manufacturingAddress.flat,
        postalCode: values.manufacturingAddress.postalCode,
      },
      files: values.files.map(i => ({ fileId: i.id })),
      wareHouses: values?.wareHouses?.map(i => ({
        ...i,
        address: {
          ...i?.address,
          city: '',
          area: '',
          street: '',
          longitude: 0,
          latitude: 0,
          region: '',
          house: '',
          flat: '',
          postalCode: '',
        }
      }))
    }
    await updateVendorsData(dataRequest)
    onCancel()
  }

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          hasDesignBureau: data?.hasDesignBureau ?? false,
          isManufacturer: data?.isManufacturer ?? false,
          manufacturingRange: !isEmpty(data?.manufacturingRange) ? data?.manufacturingRange : [],
          equipmentTools: !isEmpty(data?.equipmentTools) ? data?.equipmentTools : [],
          hasDelivery: data?.hasDelivery ?? false,
          wareHouses: !isEmpty(data?.wareHouses) ? data?.wareHouses : [],
          files: !isEmpty(data?.files) ? data?.files : [],
          workingHours: !isEmpty(data?.workingHours) ? data?.workingHours : [],
          manufacturingAddress: {
            ...data?.manufacturingAddress,
            displayString: data?.manufacturingAddress?.displayString ?? '',
            longitude: data?.manufacturingAddress?.longitude ?? 44.329700,
            latitude: data?.manufacturingAddress?.latitude ?? 56.231708,
            region: data?.manufacturingAddress?.region ?? '',
            city: data?.manufacturingAddress?.city ?? '',
            area: data?.manufacturingAddress?.area ?? '',
            street: data?.manufacturingAddress?.street ?? '',
            house: data?.manufacturingAddress?.house ?? '',
            flat: data?.manufacturingAddress?.flat ?? '',
            postalCode: data?.manufacturingAddress?.postalCode ?? '',
          },
        }}
        validationSchema={formSchema}
        onSubmit={handleForm}
      >
        {(props) => (
          <Form onSubmit={props.handleSubmit}>
            <BlockCollection>
              <BlockSection>
                <BlockHeading>Роль в системе</BlockHeading>
                <BlockGrid>
                  <Field
                    name="hasDesignBureau"
                    component={SwitchForm}
                    label="Есть конструкторское бюро"
                  />
                </BlockGrid>
                <BlockGrid>
                  <Field
                    name="isManufacturer"
                    component={SwitchForm}
                    label="Являюсь производителем"
                  />
                </BlockGrid>
                <Field
                  name="manufacturingAddress"
                  label="Адрес производства"
                  component={GeoAddressForm}
                  isDisabled={isLoading}
                  isHideMap
                />
              </BlockSection>
              <BlockSection>
                <BlockHeading>Выпускаемая продукция</BlockHeading>
                <FieldArray
                  name="manufacturingRange"
                  render={(arrayHelpers) => (
                    <>
                      {props.values.manufacturingRange.map((item, index) => (
                        <Field
                          key={index}
                          name={`manufacturingRange.${index}.name`}
                          component={InputForm}
                          isDisabled={isLoading}
                          rightElement={
                            <InputAddonTrash
                              color="gray.300"
                              onClick={() => arrayHelpers.remove(index)}
                            />
                          }
                        />
                      ))}
                      <Box>
                        <Button onClick={() => arrayHelpers.push({ name: '' })} size="sm">
                          Добавить
                        </Button>
                      </Box>
                    </>
                  )}
                />
              </BlockSection>
              <BlockSection>
                <BlockHeading>Дополнительная информация</BlockHeading>
                <Field
                  name="workingHours"
                  label="Часы работы"
                  component={WorkScheduleForm}
                  isDisabled={isLoading}
                />
                <BlockGrid>
                  <Field
                    name="hasDelivery"
                    component={SwitchForm}
                    label="Есть доставка"
                  />
                </BlockGrid>
              </BlockSection>
              <BlockSection>
                <BlockHeading>Складские помещения</BlockHeading>
                <Text>Если у вас есть склады, добавьте их виды по одному</Text>
                <FieldArray
                  name="wareHouses"
                  render={(arrayHelpers) => (
                    <>
                      {props.values.wareHouses.map((item, index) => (
                        <Field
                          key={index}
                          name={`wareHouses.${index}.address.displayString`}
                          component={InputForm}
                          isDisabled={isLoading}
                          placeholder="Введите адрес склада"
                          rightElement={
                            <InputAddonTrash
                              color="gray.300"
                              onClick={() => arrayHelpers.remove(index)}
                            />
                          }
                        />
                      ))}
                      <Box>
                        <Button onClick={() => arrayHelpers.push('')} size="sm">
                          Добавить
                        </Button>
                      </Box>
                    </>
                  )}
                />
              </BlockSection>
              <BlockSection>
                <BlockHeading>Документы</BlockHeading>
                <FieldArray
                  name="files"
                  render={(arrayHelpers) => (
                    <>
                    {isEmpty(props.values.files) ? (
                        <>
                          <Text color="gray.400">Нет документов</Text>
                        </>
                      ) : (
                        <BlockGrid>
                          {props.values.files.map((item, index) => (
                            <Field
                              key={index}
                              name={`files.${index}`}
                              component={FileWidgetForm}
                              onDelete={() => arrayHelpers.remove(index)}
                            />
                          ))}
                        </BlockGrid>
                      )}
                      <Box>
                        <FileUploaderForm
                          onChange={e => arrayHelpers.push(e)}
                        />
                      </Box>
                    </>
                  )}
                />
              </BlockSection>
              <BlockFooter>
                <Button
                  type="submit"
                  colorScheme="blue"
                  isDisabled={!props.dirty}
                  isLoading={isLoading}
                >
                  Обновить
                </Button>
                <Button
                  onClick={onCancel}
                >
                  Отменить
                </Button>
              </BlockFooter>
            </BlockCollection>
          </Form>
        )}
      </Formik>
    </>
  )
}
