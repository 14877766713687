import React from 'react'
import ReactDOM from "react-dom"
import { BrowserRouter as Router } from 'react-router-dom'
import reportWebVitals from './reportWebVitals'
import { Theme } from 'components/Theme'
import App from './App'
import { StoreProvider } from 'components/StoreProvider'
import { ScrollToTop } from 'components/ScrollToTop'
import './axios.config'
import 'index.css'

ReactDOM.render(
  //<React.StrictMode>
    <Theme>
      <StoreProvider>
        <Router>
          <ScrollToTop />
          <App />
        </Router>
      </StoreProvider>
    </Theme>,
  //</React.StrictMode>,
  document.getElementById("root")
)

reportWebVitals();
