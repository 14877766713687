import React from 'react'
import { Box, Button, HStack } from '@chakra-ui/react'
import { Select } from 'components/Select'
import { PeriodSelect } from '../../../../components/PeriodSelect'
import { RegionOrdersSelect } from '../../../../components/RegionOrdersSelect'
import { Okved2 } from 'components/Okved2'

const optionsState = [
  {
    label: 'Заказчик',
    value: 'isCustomer'
  },
  {
    label: 'Исполнитель',
    value: 'isSupplier'
  },
]

export const Filter = ({ data, update, reset }) => {
  return (
    <HStack spacing="14px">
      <Box minW="240px" flex={1}>
        <Select
          options={optionsState}
          placeholder="Роль"
          value={data.role}
          onChange={e => update('role', e.value)}
        />
      </Box>
      <Box minW="240px" flex={1}>
        <Okved2
          isHideName
          placeholder="ОКВЭД 2"
          value={data.okved}
          onChange={e => update('okved', e)}
        />
      </Box>
      <Box width="280px">
        <RegionOrdersSelect
          value={data.region}
          onChange={e => update('region', e.value)}
        />
      </Box>
      <Box width="140px">
        <PeriodSelect
          value={data.period}
          onChange={e => update('period', e.value)}
        />
      </Box>
      <Button size="md" onClick={reset}>Сбросить</Button>
    </HStack>
  )
}
