import React from 'react'
import { GridFieldItem } from 'components/GridField'
import { Box, Heading, HStack, Stack, Text } from '@chakra-ui/react'
import { FileWidget } from 'components/FileWidget'
import { BlockSection } from 'components/BlockSection'
import { BlockHeading } from 'components/BlockHeading'
import { BlockCollection } from 'components/BlockCollection'
import { BlockGrid } from 'components/BlockGrid'
import { useFiles } from '../services'
import { isEmpty } from 'lodash'
import { useAccount } from 'hooks/useAccount'
import { useAccess } from 'hooks/useAccess'
import { rolesType } from 'constants/rolesType'
import { ImageEntity } from '../../../components/ImageEntity'
import { residentLabel, residentType } from '../../../constants/residentType';
import { ContentMap } from '../../../components/ContentMap';

export const Show = () => {
  const { dataAccount } = useAccount()
  const { dataFiles, isLoadingFiles } = useFiles()
  const [isAdmin] = useAccess([rolesType.admin])

  return (
    <BlockCollection>
      {!isAdmin && (
        <>
          <BlockSection>
            <HStack spacing="30px">
              <ImageEntity
                width="100px"
                height="100px"
                data={dataAccount?.logo}
              />
              <Heading fontSize="1.3rem" lineHeight="1.8rem">
                {dataAccount?.contragentName ?? 'Компания'}
              </Heading>
            </HStack>
          </BlockSection>
          <BlockSection>
            <BlockHeading>Роль в системе</BlockHeading>
            <Box>
              <GridFieldItem
                isReady
                isLoading={false}
                label="Заказчик"
              >
                {dataAccount?.isCustomer ? 'Да' : 'Нет'}
              </GridFieldItem>
              <GridFieldItem
                isReady
                isLoading={false}
                label="Исполнитель"
              >
                {dataAccount?.isSupplier ? 'Да' : 'Нет'}
              </GridFieldItem>
            </Box>
          </BlockSection>
          <BlockSection>
            <BlockHeading>Общая информация</BlockHeading>
            <Box>
              <GridFieldItem
                isReady
                isLoading={false}
                label="Наименование"
              >
                {dataAccount?.contragentName}
              </GridFieldItem>
              <GridFieldItem
                isReady
                isLoading={false}
                label="Резидент"
              >
                {residentLabel[dataAccount?.resident]}
              </GridFieldItem>
              <GridFieldItem
                isReady
                isLoading={false}
                label={dataAccount?.resident === residentType.belarus ? 'УНП' : 'ИНН'}
              >
                {dataAccount?.inn}
              </GridFieldItem>
              <GridFieldItem
                isReady
                isLoading={false}
                label="Основной вид деятельности"
              >
                {!isEmpty(dataAccount?.mainOKVED) && `(${dataAccount?.mainOKVED?.code}) ${dataAccount?.mainOKVED?.name}`}
              </GridFieldItem>
              <GridFieldItem
                isReady
                isLoading={false}
                label="Адрес"
              >
                {dataAccount?.address?.displayString}
              </GridFieldItem>
            </Box>
          </BlockSection>
          {dataAccount?.address?.latitude && dataAccount?.address?.longitude && (
            <ContentMap
              longitude={dataAccount?.address?.longitude}
              latitude={dataAccount?.address?.latitude}
            />
          )}
          {dataAccount?.description && (
            <BlockSection>
              <BlockHeading>Описание</BlockHeading>
              <Box>
                {dataAccount?.description}
              </Box>
            </BlockSection>
          )}
        </>
      )}
      <BlockSection>
        <BlockHeading>Реквизиты</BlockHeading>
        <Box>
          <GridFieldItem
            isReady
            isLoading={false}
            label="Расчетный счет"
          >
            {dataAccount?.checkingAccount}
          </GridFieldItem>
          <GridFieldItem
            isReady
            isLoading={false}
            label="Корреспондентский счёт"
          >
            {dataAccount?.correspondentAccount}
          </GridFieldItem>
          <GridFieldItem
            isReady
            isLoading={false}
            label="БИК"
          >
            {dataAccount?.bic}
          </GridFieldItem>
          <GridFieldItem
            isReady
            isLoading={false}
            label="Наименование банка"
          >
            {dataAccount?.nameOfTheBank}
          </GridFieldItem>
        </Box>
      </BlockSection>
      <BlockSection>
        <BlockHeading>Контактные лица</BlockHeading>
        {dataAccount?.contragentContacts?.map(contact => (
          <Stack spacing="30px">
            <Box>
              <GridFieldItem
                isReady
                isLoading={false}
                fontWeight="bold"
              >
                {contact?.contactFullName}
              </GridFieldItem>
              <GridFieldItem
                isReady
                isLoading={false}
                label="Должность"
              >
                {contact?.jobTitle}
              </GridFieldItem>
              <GridFieldItem
                isReady
                isLoading={false}
                label="Телефон"
              >
                {contact?.contactPhoneNumber}
              </GridFieldItem>
              <GridFieldItem
                isReady
                isLoading={false}
                label="Электронная почта для связи"
              >
                {contact?.contactEmail}
              </GridFieldItem>
            </Box>
          </Stack>
        ))}
      </BlockSection>
      {!isAdmin && (
        <BlockSection>
          <BlockHeading isLoading={isLoadingFiles}>Документы</BlockHeading>
          <BlockGrid>
            {isEmpty(dataFiles) ? (
              <>
                <Text color="gray.400">Нет документов</Text>
              </>
            ) : dataFiles?.map((file, index) => (
              <FileWidget
                key={index}
                data={file}
                isHideDelete
              />
            ))}
          </BlockGrid>
        </BlockSection>
      )}
    </BlockCollection>
  )
}
