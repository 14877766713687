import React from 'react'
import { Box, Flex, Grid, Icon, Stack, Text, useColorModeValue } from '@chakra-ui/react'
import { getDateTimeReadFormat } from 'utils/dateFormatting'
import { MdReplyAll } from 'react-icons/md'
import { truncate } from 'lodash'

export const SnippetItem = ({ data }) => {
  const bgIcon = useColorModeValue('gray.100', 'gray.700')
  const bgContainerBorder = useColorModeValue('gray.100', 'gray.700')

  return (
    <Box
      borderBottomWidth={1}
      borderColor={bgContainerBorder}
      transition="0.3s"
    >
      <Grid templateColumns="40px 1fr 1fr 1fr" gap={5} padding="18px" alignItems="center">
        <Flex
          flex="none"
          backgroundColor={bgIcon}
          boxSize="40px"
          borderRadius="full"
          alignItems="center"
          justifyContent="center"
        >
          <Icon as={MdReplyAll} fontSize="1.1rem" color="gray.400"/>
        </Flex>
        <Flex flexDirection="column" justifyContent="center">
          <Stack spacing={2}>
            <Text
              fontWeight="semibold"
              fontSize="18px"
              lineHeight={1.2}
            >
              {truncate(data?.name !== '' ? data?.name : 'Нет заголовка', { length: 80 })}
            </Text>
            <Text
              fontSize="0.8rem"
              lineHeight="18px"
              color="gray.400"
            >
              {data?.organization}
            </Text>
          </Stack>
        </Flex>
        <Stack spacing={2}>
          <Text lineHeight={1.3}>
            {data.replySource}
          </Text>
          <Text fontSize="0.8rem" color="gray.400">
            {getDateTimeReadFormat(data.repliedAt)}
          </Text>
        </Stack>
        <Stack spacing={0}>
          <Text lineHeight={1.3}>
            {data.contactFullName}
          </Text>
          <Text fontSize="0.8rem">
            {data?.contactPhoneNumber}
          </Text>
        </Stack>
      </Grid>
    </Box>
  )
}
