import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  data: []
}

const {reducer, actions} = createSlice({
  initialState,
  name: 'favoriteCompany',
  reducers: {
    update: (state, action) => {
      state.data = action.payload
    },
    reset: () => initialState,
  }
});

export { actions, reducer }
export default reducer
