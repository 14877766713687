import { BackButton } from 'components/BackButton'
import { Box, Button, Flex, HStack, useBoolean } from '@chakra-ui/react'
import { PageHeading } from 'components/PageHeading'
import React, { useEffect } from 'react'
import { Container } from 'components/Container'
import { useAccess } from 'hooks/useAccess'
import { rolesType } from 'constants/rolesType'
import { Link, useParams } from 'react-router-dom'
import { MarkdownRender } from 'components/MarkdownRender'
import { useFetchArticle } from '../services/useFetchArticle'
import { isEmpty } from 'lodash'
import { Entity } from 'components/Entity'
import { ItemColumns } from 'components/ItemColumns'
import { Delete } from './components/Delete'
import { useDeleteArticle } from '../services/useDeleteArticle'
import { useHistory } from 'react-router'
import { ScrollBox } from 'components/ScrollBox'

export const Show = () => {
  const history = useHistory()
  const { id } = useParams()
  const [isOpenDelete, setIsOpenDelete] = useBoolean()
  const { data, isLoadingFetchArticle, fetchArticle } = useFetchArticle()
  const [isAdmin] = useAccess([rolesType.admin])

  const { deleteArticle, isLoadingDeleteArticle } = useDeleteArticle()

  const handleDelete = async () => {
    await deleteArticle(id)
    history.goBack()
  }

  useEffect(() => {
    if (id) {
      fetchArticle(id)
    }
  }, [])

  return (
    <Container>
      <ScrollBox>
        <PageHeading
          renderBack={<BackButton/>}
          {...isAdmin && {
            renderAction: (
              <HStack spacing="14px">
                <Delete
                  isLoading={isLoadingDeleteArticle}
                  isOpen={isOpenDelete}
                  onOpen={() => setIsOpenDelete.on()}
                  onClose={() => setIsOpenDelete.off()}
                  onDelete={handleDelete}
                />
                <Button
                  as={Link}
                  to={`/articles/${id}/edit`}
                  colorScheme="gray"
                >
                  Редактировать
                </Button>
              </HStack>
            )
          }}
        >
          <Flex minH="40px" alignItems="center">
            {data?.title !== '' ? data?.title : 'Нет заголовка'}
          </Flex>
        </PageHeading>
        <ItemColumns>
          <Entity isLoading={isLoadingFetchArticle} isEmpty={isEmpty(data)}>
            <Box>
              <MarkdownRender content={data?.content ?? ''}/>
            </Box>
          </Entity>
        </ItemColumns>
      </ScrollBox>
    </Container>
  )
}
