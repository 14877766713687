import React, { useEffect, useState } from 'react'
import { PageHeading } from 'components/PageHeading'
import { ItemColumns } from 'components/ItemColumns'
import {
  Box,
  Flex,
  Grid, Heading,
  HStack,
  Icon, Link,
  Spinner, Stack,
  Text,
  Tooltip,
  useBoolean,
  useColorModeValue
} from '@chakra-ui/react'
import { GridFieldItem } from 'components/GridField'
import { MdDoneAll, MdOutlineCalendarToday, MdOutlineEditCalendar, MdOutlineRemoveRedEye } from 'react-icons/md'
import { ItemIconOptions } from 'components/ItemIconOptions'
import { BlockCollection } from 'components/BlockCollection'
import { BlockSection } from 'components/BlockSection'
import { BlockHeading } from 'components/BlockHeading'
import { BlockGrid } from 'components/BlockGrid'
import { useParams } from 'react-router-dom'
import { useDeleteOrder, useFetchOrder } from '../services'
import { State } from './components/State'
import { getDateReadFormat } from 'utils/dateFormatting'
import { isEmpty } from 'lodash'
import { FileWidget } from 'components/FileWidget'
import { ContactsCompany } from './components/ContactsCompany'
import { useFetchAccount, useReply, useUpdateState } from '../services'
import { BsCardImage } from 'react-icons/bs'
import { useAccess } from 'hooks/useAccess'
import { rolesType } from 'constants/rolesType'
import { useAccount } from 'hooks/useAccount'
import { deliveryLabel } from 'constants/deliveryType'
import { Entity } from 'components/Entity'
import { Delete } from './components/Delete'
import { useHistory } from 'react-router'
import { Container } from 'components/Container'
import { useAuthentication } from 'hooks/useAuthentication'
import { UnauthorizedInfo } from 'components/UnauthorizedInfo'
import { useFetchImage } from 'services/useFetchImage'
import { ContentMap } from 'components/ContentMap'
import { Helmet } from 'react-helmet'
import { useUpdateFavoriteState } from '../../services/useUpdateFavoriteState'
import { FavoriteButton } from 'components/FavoriteButton'
import { useFetchFavoriteOrders } from '../../../../services/useFetchFavoriteOrders'
import { ScrollBox } from 'components/ScrollBox'

const ParameterItem = ({ label, children }) => {
  return (
    <Box borderBottomWidth={0} borderColor="gray.100" py="10px">
      <Text fontSize="0.9rem" color="gray.400" mb="3px">{label}</Text>
      <Text lineHeight="1.2">{children}</Text>
    </Box>
  )
}

export const Show = () => {
  const history = useHistory()
  const { isAuthenticated } = useAuthentication()
  const { id } = useParams()
  const { dataAccount: dataMyAccount } = useAccount()
  const { data, isLoadingFetch, isSuccessFetch, getOrder } = useFetchOrder(id)
  const { setReply, isLoadingReply } = useReply(id)
  const [isAdmin] = useAccess([rolesType.admin])
  const { getAccount, dataAccount } = useFetchAccount()
  const { updateState, isLoadingUpdateState } = useUpdateState(id)
  const { dataImage, isLoadingFetchImage } = useFetchImage(data?.image)
  const [isOpenDelete, setIsOpenDelete] = useBoolean()
  const { deleteOrder } = useDeleteOrder(id)
  const isMy = isAuthenticated && data?.creator?.id === dataMyAccount.accountId

  const { updateFavorite } = useUpdateFavoriteState()
  const { favoriteOrdersIds } = useFetchFavoriteOrders()
  const isFavorite = !isEmpty(favoriteOrdersIds) && favoriteOrdersIds?.includes(data?.id)
  const [isLoadingFavorite, setIsLoadingFavorite] = useState(false)

  const handleFavoriteClick = async (e) => {
    try {
      e.stopPropagation()
      e.preventDefault()
      setIsLoadingFavorite(true)
      await updateFavorite({ id: data?.id, stateId: isFavorite ? 2 : 1})
    } catch (e) {} finally {
      setIsLoadingFavorite(false)
    }
  }

  const borderColor = useColorModeValue('gray.100', 'gray.700')
  const borderWidth = useColorModeValue(1, 0)

  const handleShowContacts = async () => {
    await setReply()
    await getOrder()
  }

  const handleUpdateState = (stateId, message) => new Promise(async (resolve, reject) => {
    try {
      await updateState({ stateId, message })
      await getOrder()
      resolve()
    } catch (e) {
      reject(e)
    }
  })

  const handleDelete = async () => {
    await deleteOrder(id)
    setIsOpenDelete.off()
    history.goBack()
  }

  useEffect(async () => {
    if (isSuccessFetch) {
      await getAccount(data?.creator?.id)
    }
  }, [isSuccessFetch])

  return (
    <>
      <Helmet>
        <title>{data?.productName ?? `Заявка ${id}`} – Сделаем в России</title>
      </Helmet>
      <Container>
        <ScrollBox>
          <PageHeading>
            {data?.productName ?? `Заявка ${id}`}
          </PageHeading>
          {(isMy || isAdmin) && (
            <State
              data={data?.state}
              rejectReason={data?.rejectReason}
              isLoading={isLoadingUpdateState || isLoadingFetch}
              onChange={handleUpdateState}
            />
          )}
          <ItemColumns>
            <BlockCollection>
              <Entity isLoading={isLoadingFetch} isEmpty={isEmpty(data)}>
                <Grid templateColumns="1fr 1fr" gap="16px">
                  <Box pr="30px">
                    <Flex
                      borderRadius="12px"
                      bg="white"
                      height="360px"
                      backgroundImage={dataImage}
                      backgroundRepeat="no-repeat"
                      backgroundSize="contain"
                      backgroundPosition="center"
                      borderColor={borderColor}
                      alignItems="center"
                      justifyContent="center"
                      borderWidth={borderWidth}
                    >
                      {isEmpty(data?.image) ? (
                        <Icon as={BsCardImage} boxSize="80px" color="gray.300" mb={3}/>
                      ) : (
                        isLoadingFetchImage && <Spinner color="blue.500" size="lg"/>
                      )}
                    </Flex>
                  </Box>
                  <Box>
                    <HStack height="20px" alignItems="center" spacing={4}>
                      <Tooltip label="Создано">
                        <ItemIconOptions
                          size="lg"
                          icon={MdOutlineCalendarToday}
                        >
                          {getDateReadFormat(data?.createdAt)}
                        </ItemIconOptions>
                      </Tooltip>
                      <Tooltip label="Изменено">
                        <ItemIconOptions
                          size="lg"
                          icon={MdOutlineEditCalendar}
                        >
                          {getDateReadFormat(data?.updatedAt)}
                        </ItemIconOptions>
                      </Tooltip>
                      <Tooltip label="Всего откликов">
                        <ItemIconOptions
                          size="lg"
                          icon={MdDoneAll}
                        >
                          {data?.numberOfReplies}
                        </ItemIconOptions>
                      </Tooltip>
                      <Tooltip label="Просмотров">
                        <ItemIconOptions
                          size="lg"
                          icon={MdOutlineRemoveRedEye}
                        >
                          {data?.numberOfViews}
                        </ItemIconOptions>
                      </Tooltip>
                      <Box>
                        {isAuthenticated && data?.creator?.id !== dataMyAccount.accountId && (
                          <FavoriteButton
                            isLoading={isLoadingFavorite}
                            isActive={isFavorite}
                            onClick={handleFavoriteClick}
                          />
                        )}
                      </Box>
                    </HStack>
                    <Box borderTopWidth={1} borderColor={borderColor} mt="14px">
                      <ParameterItem label="Потребность в продукции">
                        {data?.deliveryQuantityValue}
                      </ParameterItem>
                      <ParameterItem label="Периодичность">
                        {data?.deliveryPeriodicity?.name}
                      </ParameterItem>
                      {!isEmpty(data?.deliveryType) && (
                        <ParameterItem label="Способ доставки">
                          {deliveryLabel[data?.deliveryType?.type]}
                        </ParameterItem>
                      )}
                      {isAuthenticated ? (
                        <>
                          {data?.location?.displayString && (
                            <ParameterItem label="Регион">
                              {data?.deliveryAddress?.regionDict?.name}
                            </ParameterItem>
                          )}
                        </>
                      ) : (
                        <ParameterItem label="Регион">
                          <UnauthorizedInfo.Link/>
                        </ParameterItem>
                      )}
                      <ParameterItem label="Классификация продукции по ОКПД 2">
                        {`${data?.okpD2?.code} ${data?.okpD2?.name ?? ''}`}
                      </ParameterItem>
                    </Box>
                  </Box>
                </Grid>
                {!isEmpty(data?.productParameters) && (
                  <BlockSection>
                    <BlockHeading>Параметры</BlockHeading>
                    <Box>
                      {data?.productParameters?.map((i, index) => (
                        <GridFieldItem
                          key={index}
                          isReady
                          isLoading={false}
                          label={i.name}
                        >
                          {`${i?.value} ${i?.uoM?.nationalNotationKeys}`}
                        </GridFieldItem>
                      ))}
                    </Box>
                  </BlockSection>
                )}
                {!isEmpty(data?.description) && (
                  <BlockSection>
                    <BlockHeading>Описание</BlockHeading>
                    <Box style={{ whiteSpace: 'pre-line' }}>
                      {data?.description}
                    </Box>
                  </BlockSection>
                )}
                <BlockSection>
                  <BlockHeading>Адрес доставки</BlockHeading>
                  {isAuthenticated ? (
                    <Box>
                      <GridFieldItem
                        isReady
                        isLoading={false}
                        label="Адрес"
                      >
                        {data?.deliveryAddress?.displayString}
                      </GridFieldItem>
                    </Box>
                  ) : (
                    <UnauthorizedInfo.Document/>
                  )}
                </BlockSection>
                {data?.deliveryAddress?.latitude && data?.deliveryAddress?.longitude && (
                  <ContentMap
                    longitude={data?.deliveryAddress?.longitude}
                    latitude={data?.deliveryAddress?.latitude}
                  />
                )}
                <BlockSection>
                  <BlockHeading>Документы</BlockHeading>
                  <BlockGrid>
                    {isEmpty(data?.files) ? (
                      <>
                        <Text color="gray.400">Нет документов</Text>
                      </>
                    ) : data?.files?.map((file, index) => (
                      <FileWidget
                        key={index}
                        data={file}
                        isHideDelete
                      />
                    ))}
                  </BlockGrid>
                </BlockSection>

                <BlockSection>
                  <BlockHeading>Информация о заказчике</BlockHeading>
                  {isAuthenticated ? (
                    <Box>
                      <GridFieldItem
                        isReady
                        isLoading={false}
                        label="Наименование"
                      >
                        {isAuthenticated ? (
                          <Link
                            isExternal href={`/companies/${dataAccount?.accountId}`}
                            color="blue.400"
                            transition="0.3s"
                            _hover={{
                              color: 'blue.200'
                            }}
                          >
                            {dataAccount?.contragentName}
                          </Link>
                        ) : <UnauthorizedInfo.Link/>}
                      </GridFieldItem>
                      <GridFieldItem
                        isReady
                        isLoading={false}
                        label="ИНН"
                      >
                        {isAuthenticated ? dataAccount?.inn : <UnauthorizedInfo.Link/>}
                      </GridFieldItem>
                      <GridFieldItem
                        isReady
                        isLoading={false}
                        label="Основной вид деятельности"
                      >
                        {`${dataAccount?.mainOKVED?.code} ${dataAccount?.mainOKVED?.name ?? ''}`}
                      </GridFieldItem>
                    </Box>
                  ) : (
                    <UnauthorizedInfo.Document/>
                  )}
                </BlockSection>
                {(isMy || isAdmin) && (
                  <Delete
                    isOpen={isOpenDelete}
                    onOpen={() => setIsOpenDelete.on()}
                    onClose={() => setIsOpenDelete.off()}
                    onDelete={handleDelete}
                  />
                )}
              </Entity>
            </BlockCollection>
            <Box position="relative">
              <Stack spacing="30px" position="sticky" top="20px">
                {(isMy || isAdmin) && (
                  <Box
                    position="sticky"
                    top="20px"
                    borderRadius="12px"
                    padding="28px"
                    backgroundColor="white"
                    boxShadow="rgba(15, 15, 15, 0.1) 0px 3px 6px, rgba(15, 15, 15, 0.1) 0px 9px 24px"
                  >
                    <Stack spacing="14px">
                      <Heading fontSize="1.1rem">Заявка завершена</Heading>
                      <Stack spacing="4px">
                        <Text color="gray.400">Исполнитель:</Text>
                        {data?.supplier ? <Text>{data?.supplier}</Text> : <Text color="gray.400">Нет</Text>}
                      </Stack>
                      <Stack spacing="4px">
                        <Text color="gray.400">Сумма контракта:</Text>
                        {data?.contractAmount ? <Text>{data?.contractAmount}</Text> : <Text color="gray.400">Нет</Text>}
                      </Stack>
                    </Stack>
                  </Box>
                )}
                <ContactsCompany
                  data={data?.activeContragentContact}
                  onShow={handleShowContacts}
                  isShow={data?.isOrderRepliedByCurrentUser || data?.creator?.id === dataMyAccount?.accountId}
                  isLoading={isLoadingFetch || isLoadingReply}
                />
              </Stack>
            </Box>
          </ItemColumns>
        </ScrollBox>
      </Container>
    </>
  )
}
