import React from 'react'
import { TabPanel as TabPanelChakra } from '@chakra-ui/react'
import { ErrorBoundary } from '../ErrorBoundary'

export const TabPanel = React.forwardRef(({ children, ...props }, ref) => {
  return (
    <ErrorBoundary>
      <TabPanelChakra
        ref={ref}
        pt="30px"
        pb={0}
        px={0}
        {...props}
      >{children}</TabPanelChakra>
    </ErrorBoundary>
  )
})
