import useQuery from 'hooks/useQuery'
import { api } from 'api'
import { useEffect } from 'react'

export const useFetchReplyCount = () => {
  const {
    request,
    response
  } = useQuery(() => api.reply.fetchCount())

  useEffect(() => {
    request()
  }, [])

  return {
    replyCountCount: response?.count ?? 0
  }
}
