import React, { useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { Box, Flex, Spinner, Text } from '@chakra-ui/react'
import { FileIcon } from 'components/FileIcon';
import { FileSizeBadge } from 'components/FileSizeBadge';
import { BsCloudUpload } from 'react-icons/bs';

export const DropZone = ({
  uploadedFile,
  onUpload,
  onError,
  maxSize = 1048576,
  maxFiles,
  multiple,
  height = '160px',
  width = '100%',
}) => {
  const [fullSize, setFullSize] = useState()
  const loadingUploadImage = false
  const { getRootProps, getInputProps } = useDropzone({
    //accept: 'image/jpeg, image/png',
    maxSize: maxSize,
    maxFiles: maxFiles,
    multiple: multiple,
    onDropAccepted: (e) => {
      setFullSize(false)
      onUpload(e)
    },
    onDropRejected: (e) => {
      setFullSize(false)
      onError && onError(e)
    },
    //onDrop: onUpload
  })
  return (
    <section className="dropzone-input">
      <Box
        width={width}
        height={height}
        borderWidth={1}
        borderRadius={5}
        display="flex"
        alignItems="center"
        justifyContent="center"
        transition="0.2s"
        outline="none"
        cursor="pointer"
        borderColor={fullSize ? 'blue.300' : 'gray.200'}
        overflow="hidden"
        {...getRootProps({ className: 'dropzone' })}
        _hover={{
          borderColor: 'blue.300',
        }}
      >
        <input {...getInputProps()} />
        {uploadedFile && (
          <Flex
            height={height}
            width="100%"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <FileIcon name={uploadedFile?.name} mb={2}/>
            <Text>{uploadedFile?.name}</Text>
            <FileSizeBadge size={uploadedFile?.size}/>
          </Flex>
        )}
        {!uploadedFile && (
          <Flex flexDirection="column" maxW="400px" alignItems="center" textAlign="center">
            <BsCloudUpload color="gray.400" fontSize="1.6rem"/>
            <Text fontSize="0.83rem" mt="10px">
              Перетащите сюда файл или щелкните, чтобы выбрать
            </Text>
            <Text fontSize="0.8rem" color="gray.400">Не более 100 МБ: doc, docx, xls, xlsx, pdf, jpg, png, mp4</Text>
          </Flex>
        )}
        {loadingUploadImage && <Spinner color="blue.500" size="sm" marginRight="10px" />}
      </Box>
    </section>
  )
}
