import React from 'react'
import _, { isEmpty } from 'lodash'
import { useMemo } from 'react'
import { WorkSchedule } from '../WorkSchedule'
import { ErrorBoundary } from '../ErrorBoundary'

export const WorkScheduleForm = React.forwardRef(
  ({field, form, options, ...props}, ref) => {
    const handleChange = (data) => {
      form.setFieldValue(field.name, data.weekdays)
    }
    const _value = useMemo(() => {
      if (!isEmpty(field.value)) {
        return {
          weekdays: field.value
        }
      }
      return {}
    }, [field.value])

    return (
      <ErrorBoundary>
        <WorkSchedule
          name={field.name}
          placeholder="Введите расписание"
          isInvalid={_.get(form.errors, field.name) && _.get(form.touched, field.name)}
          error={_.get(form.errors, field.name)}
          {...field}
          value={_value}
          onChange={handleChange}
          {...props}
        />
      </ErrorBoundary>
    )
  }
)
