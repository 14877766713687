import React from 'react'
import { Flex, Spinner } from '@chakra-ui/react'

export const Entity = ({ isLoading, isEmpty, children }) => {
  if (isLoading) {
    return (
      <Flex alignItems="center" justifyContent="center" height="200px">
        <Spinner color="blue.500"/>
      </Flex>
    )
  }
  if (isEmpty) {
    return (
      <Flex alignItems="center" justifyContent="center" height="200px">
        Ничего нет
      </Flex>
    )
  }
  return children
}
