export const sortDirectionType = {
  ascending: 'Ascending',
  descending: 'Descending'
}

export const sortFieldType = {
  default: 'default',
  numberOfViews: 'NumberOfViews',
  numberOfReplies: 'NumberOfReplies',
  conditionalDate: 'ConditionalDate'
}

export const sortLabel = {
  [sortDirectionType.ascending]: 'Сначала старые',
  [sortDirectionType.descending] : 'Сначала новые'
}
