
export const fieldTypeId = {
  text: 'Text',
  number: 'Number',
  bool: 'Bool',
  list: 'List'
}

export const fieldTypeIdLabel = {
  [fieldTypeId.list]: 'Список',
  [fieldTypeId.bool]: 'Чекбокс',
  [fieldTypeId.text]: 'Текстовое поле',
  [fieldTypeId.number]: 'Числовое поле'
}

export const fieldTypeIdBadge = {
  [fieldTypeId.list]: 'green',
  [fieldTypeId.bool]: 'orange',
  [fieldTypeId.text]: 'purple',
  [fieldTypeId.number]: 'blue'
}
