import useQuery from 'hooks/useQuery'
import { api } from 'api'
import { useEffect } from 'react'
import { useToast } from '@chakra-ui/react'

export const useUpdateState = (id) => {
  const toast = useToast()
  const {
    isError,
    isLoading,
    isSuccess,
    error,
    request
  } = useQuery(({ stateId, message }) => api.equipment.updateStateItem({ id, stateId, message }))

  useEffect(() => {
    if (isError) {
      toast({
        title: 'Ошибка',
        description: error.response?.data?.ErrorMessage,
        status: 'error',
      })
    }
  }, [isError])

  useEffect(() => {
    if (isSuccess) {
      toast({
        title: 'Готово',
        description: 'Статус обновлен',
        status: 'success',
      })
    }
  }, [isSuccess])

  return ({
    updateState: request,
    isLoadingUpdateState: isLoading,
  })
}
