import { Flex } from '@chakra-ui/react'

export const Container = ({ children, ...props }) => {
  return (
    <Flex
      flexDirection="column"
      maxW="1400px"
      width="100%"
      //minWidth="1200px"
      ml="auto"
      mr="auto"
      pl="20px"
      pr="20px"
      {...props}
    >
      {children}
    </Flex>
  )
}
