import React, { useEffect, useMemo } from 'react'
import { PageHeading } from 'components/PageHeading'
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Spinner,
  Stack,
  Tab,
  TabList,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react'
import { Form } from 'components/Form'
import { Field, FieldArray, Formik } from 'formik'
import { InputForm } from 'components/InputForm'
import { BlockSection } from 'components/BlockSection'
import { ItemColumns } from 'components/ItemColumns'
import { TextAreaForm } from 'components/TextAreaForm'
import { BlockHeading } from 'components/BlockHeading'
import { BlockGrid } from 'components/BlockGrid'
import { BlockFooter } from 'components/BlockFooter'
import { TabCheck } from 'components/TabCheck'
import * as Yup from 'yup'
import { TabPanel } from 'components/TabPanel'
import { BlockCollection } from 'components/BlockCollection'
import { useCreateEquipment } from './services'
import { SelectForm } from 'components/SelectForm'
import { InputDateForm } from 'components/InputDateForm'
import { useFetchEquipment } from '../services'
import { useHistory, useParams } from 'react-router-dom'
import { useUpdateEquipment } from './services'
import { find, isEmpty } from 'lodash'
import { useAccess } from 'hooks/useAccess'
import { rolesType } from 'constants/rolesType'
import { orderStateType } from 'constants/orderStateType'
import { Informer } from 'components/Informer'
import { Container } from 'components/Container'
import { ImageUploaderCollectionForm } from 'components/ImageUploaderCollectionForm'
import { useFetchManufacturers } from 'services/useFetchManufacturers'
import { useFetchBrands } from 'services/useFetchBrands'
import { ManufacturerForm } from './components/ManufacturerForm'
import {
  getActiveContragentContactToSubmit,
  getDateToSubmit,
  getIndustrySetToForm,
  getIndustrySetToSubmit,
  getMappingFields,
  getStubDetails,
} from './utils/mapping'
import { InformerAssistance } from './components/InformerAssistance'
import { useFetchRequiredFields } from 'services/useFetchRequiredFields'
import { useFetchFields } from 'services/useFetchFields'
import { FieldsForm } from './components/FieldsForm/FieldsForm'
import { FilesForm } from 'components/FilesForm'
import { GeoAddressForm } from 'components/GeoAddressForm'
import {
  equipmentAvailabilityLabel,
  equipmentAvailabilityType,
} from 'constants/equipmentAvailabilityType'
import { ImageParams } from './components/ImageParams'
import { getDateReadFormat } from 'utils/dateFormatting'
import { ClassifierSelectsForm } from 'components/ClassifierSelectsForm'
import { useAccount } from './services/useAccount'
import { useCreateManufacturer } from './services/useCreateManufacturer'
import { useCreateBrand } from './services/useCreateBrand'
import { AddManufacturer } from './components/AddManufacturer/AddManufacturer'
import { AddBrand } from './components/AddBrand'
import { ScrollBox } from 'components/ScrollBox'
import { Helmet } from 'react-helmet'

const formSchema = Yup.object({
  name: Yup.string().required('Заполните поле'),
  description: Yup.string().required('Заполните поле'),
  keywords: Yup.array().of(Yup.string().required('Заполните поле').default('')),
  manufacturer: Yup.string().required('Заполните поле'),
  brand: Yup.string().required('Заполните поле'),
  model: Yup.string().required('Заполните поле'),
  activeContragentContactId: Yup.string().required('Заполните поле'),
  location: Yup.object({
    displayString: Yup.string().required('Заполните поле Адрес'),
  }),
})

export const Edit = () => {
  const { id } = useParams()
  const [isAdmin] = useAccess([rolesType.admin])
  const [tabIndex, setTabIndex] = React.useState(0)
  const history = useHistory()
  const isNew = !id

  const { dataEquipment: data } = useFetchEquipment(id)
  const { fetchAccount, dataAccount } = useAccount()

  const { fetchManufacturers, isLoadingFetchManufacturers, dataManufacturers } =
    useFetchManufacturers()
  const { getBrands, isLoadingFetchBrands, dataBrands } = useFetchBrands()

  const { fetchRequiredFields, dataRequiredFields, isLoadingFetchRequiredFields } =
    useFetchRequiredFields()
  const { fetchFields, dataFields, isLoadingFetchFields } = useFetchFields()

  const { createEquipment, isLoadingCreateEquipment } = useCreateEquipment()
  const { updateEquipment, isLoadingUpdateEquipment } = useUpdateEquipment()

  const { createManufacturer } = useCreateManufacturer()
  const { createBrand } = useCreateBrand()

  const isLoading = isLoadingCreateEquipment || isLoadingUpdateEquipment
  const isLoadingFields = isLoadingFetchRequiredFields || isLoadingFetchFields

  const isFullyCompletedProfile = useMemo(() => {
    if (isAdmin) return true
    return dataAccount?.accountId ? dataAccount.fullyCompletedProfile : true
  }, [dataAccount, isAdmin])

  const fields = useMemo(
    () => getMappingFields(dataFields?.rows, data?.stubDetails),
    [dataFields, data]
  )

  const fieldsRequired = useMemo(
    () => getMappingFields(dataRequiredFields?.rows, data?.stubDetails),
    [dataRequiredFields, data]
  )

  const contragentContactsOptions = useMemo(() => {
    return dataAccount?.contragentContacts?.map((i) => ({
      label: i?.contactFullName,
      value: i?.id,
    }))
  }, [dataAccount?.contragentContacts])

  const brandOptions = useMemo(() => {
    let result = {}
    if (isEmpty(dataBrands)) {
      return {
        '': [],
      }
    }
    for (const item of dataBrands.rows) {
      const field = item?.manufacturer?.id
      const options = result[field]
      if (options) {
        result[field] = [
          ...options,
          {
            label: item.name,
            value: item.id,
          },
        ]
      } else {
        result[field] = [
          {
            label: item.name,
            value: item.id,
          },
        ]
      }
    }
    return result
  }, [dataBrands])

  const manufacturerOptions = useMemo(() => {
    let result = {}
    if (isEmpty(dataManufacturers)) {
      return {
        '': [],
      }
    }
    for (const item of dataManufacturers.rows) {
      const field = item?.machineClassifierId
      const options = result[field]
      if (options) {
        result[field] = [
          ...options,
          {
            label: item.name,
            value: item.id,
          },
        ]
      } else {
        result[field] = [
          {
            label: item.name,
            value: item.id,
          },
        ]
      }
    }
    return result
  }, [dataManufacturers])

  const handleForm = async (values) => {
    const dataRequest = {
      name: values?.name,
      images: values.images?.map((i) => i.id),
      manufacturer: { id: values?.manufacturer },
      brand: { id: values?.brand },
      model: values?.model,
      scheduledStartDate: getDateToSubmit(values?.scheduledStartDate),
      scheduledEndDate: getDateToSubmit(values?.scheduledEndDate),
      description: values?.description,
      equipmentAvailabilityType: values?.equipmentAvailabilityType,
      industrySet: getIndustrySetToSubmit(values?.industrySet),
      stubDetails: [
        ...getStubDetails(values?.fields, true),
        ...getStubDetails(values?.fieldsRequired),
      ],
      location: values?.location,
      activeContragentContact: getActiveContragentContactToSubmit(
        dataAccount?.contragentContacts,
        values?.activeContragentContactId
      ),
      creator: {
        id: dataAccount?.accountId,
      },
    }

    if (isNew) {
      await createEquipment(dataRequest)
    } else {
      await updateEquipment({
        ...dataRequest,
        id: Number(id),
      })
    }

    history.goBack()
  }

  useEffect(() => {
    if (isNew) {
      fetchAccount()
      return
    }
    if (!isEmpty(data)) {
      fetchAccount(data?.creator?.id)
    }
  }, [data])

  useEffect(() => {
    fetchManufacturers()
    getBrands()
    fetchRequiredFields()
    fetchFields()
  }, [])

  if (!isAdmin && data?.state?.name === orderStateType.onModeration) {
    return (
      <Alert status="error" borderRadius="6px" mt="30px">
        <AlertIcon />
        Заявку нельзя редактировать, пока она находится на модерации
      </Alert>
    )
  }

  return (
    <>
      <Container>
        <ScrollBox>
          <PageHeading>{isNew ? 'Новое оборудование' : 'Редактирование оборудования'}</PageHeading>
          {!isFullyCompletedProfile && (
            <Alert
              flexDirection="column"
              gap="10px"
              alignItems="flex-start"
              status="error"
              borderRadius="6px"
              mb="30px"
              padding="18px 22px"
            >
              <Flex>
                <AlertIcon />
                <Box>Для дальнейшей работы на платформе требуется заполнить данные о компании</Box>
              </Flex>
              <Button
                ml="30px"
                variant="outline"
                colorScheme="red"
                size="sm"
                onClick={() => history.push('/account/0')}
              >
                Заполнить профиль
              </Button>
            </Alert>
          )}
          <Formik
            enableReinitialize
            validateOnChange={false}
            initialValues={{
              name: data?.name ?? '',
              images: data?.images ?? [],
              manufacturer: data?.manufacturer?.id ?? '',
              brand: data?.brand?.id ?? '',
              model: data?.model ?? '',
              scheduledStartDate: data?.scheduledStartDate
                ? getDateReadFormat(data?.scheduledStartDate)
                : '',
              scheduledEndDate: data?.scheduledEndDate
                ? getDateReadFormat(data?.scheduledEndDate)
                : '',
              description: data?.description ?? '',
              activeContragentContactId: data?.activeContragentContact?.id ?? '',
              equipmentAvailabilityType:
                data?.equipmentAvailabilityType ?? equipmentAvailabilityType.alwaysFree,
              industrySet: [
                {
                  levelId: 1,
                  ...getIndustrySetToForm(data?.industrySet, 1),
                },
                {
                  levelId: 2,
                  ...getIndustrySetToForm(data?.industrySet, 2),
                },
                {
                  levelId: 3,
                  ...getIndustrySetToForm(data?.industrySet, 3),
                },
                {
                  levelId: 4,
                  ...getIndustrySetToForm(data?.industrySet, 4),
                },
              ],
              fields,
              fieldsRequired,
              location: {
                ...data?.location,
                displayString: data?.location?.displayString ?? '',
                longitude: data?.location?.longitude ?? 44.3297,
                latitude: data?.location?.latitude ?? 56.231708,
                region: data?.location?.region ?? '',
                city: data?.location?.city ?? '',
                area: data?.location?.area ?? '',
                street: data?.location?.street ?? '',
                house: data?.location?.house ?? '',
                flat: data?.location?.flat ?? '',
                postalCode: data?.location?.postalCode ?? '',
              },
            }}
            validationSchema={formSchema}
            onSubmit={handleForm}
          >
            {(props) => {
              const manufacturerId = props?.values.manufacturer

              const industrySet = props.values.industrySet
              const valueLevelId1 = find(industrySet, (e) => e.levelId === 1)
              const machineClassifierId = valueLevelId1?.id

              const handleAddManufacturer = (values) =>
                new Promise(async (resolve, reject) => {
                  try {
                    const result = await createManufacturer({
                      machineClassifierId,
                      ...values,
                    })
                    await fetchManufacturers()
                    props.setFieldValue('manufacturer', result?.id)
                    resolve(result)
                    return result
                  } catch (e) {
                    reject(e)
                  }
                })

              const handleAddBrand = (values) =>
                new Promise(async (resolve, reject) => {
                  try {
                    const result = await createBrand({
                      manufacturerId,
                      ...values,
                    })
                    await getBrands()
                    props.setFieldValue('brand', result?.id)
                    resolve(result)
                    return result
                  } catch (e) {
                    reject(e)
                  }
                })

              return (
                <Form onSubmit={props.handleSubmit}>
                  <Tabs onChange={(e) => setTabIndex(e)} index={tabIndex}>
                    <TabList>
                      <Tab>
                        <TabCheck form={props} fields={['name', 'location', 'manufacturer']}>
                          Информация
                        </TabCheck>
                      </Tab>
                      <Tab>
                        <TabCheck
                          form={props}
                          fields={['productParameters', 'fieldsRequired', 'description']}
                        >
                          Параметры
                        </TabCheck>
                      </Tab>
                    </TabList>
                    <TabPanels maxW="1200px">
                      <TabPanel>
                        <ItemColumns pb="0">
                          <BlockCollection>
                            <BlockSection>
                              <FieldArray
                                name="images"
                                component={ImageUploaderCollectionForm}
                              />
                              <Field
                                name="name"
                                label="Наименование"
                                component={InputForm}
                                isDisabled={isLoading}
                              />
                              <Field
                                name="industrySet"
                                component={ClassifierSelectsForm}
                                isDisabled={isLoading}
                              />
                              <BlockGrid>
                                <Stack>
                                  <Field
                                    isHideError
                                    name="manufacturer"
                                    label="Производитель"
                                    component={ManufacturerForm}
                                    isDisabled={isLoading || isLoadingFetchManufacturers}
                                    isLoading={isLoadingFetchManufacturers}
                                    placeholder="Выберите из списка"
                                    options={manufacturerOptions[machineClassifierId]}
                                    isClearable
                                  />
                                  <AddManufacturer
                                    isDisabled={!machineClassifierId}
                                    onChange={handleAddManufacturer}
                                  />
                                </Stack>
                                <Stack>
                                  <Field
                                    isHideError
                                    name="brand"
                                    label="Марка"
                                    component={SelectForm}
                                    placeholder={
                                      manufacturerId ? 'Выберите из списка' : 'Выберите производителя'
                                    }
                                    isDisabled={
                                      isLoading || isLoadingFetchManufacturers || isLoadingFetchBrands
                                    }
                                    isLoading={isLoadingFetchManufacturers || isLoadingFetchBrands}
                                    options={brandOptions[manufacturerId]}
                                    isClearable
                                  />
                                  <AddBrand isDisabled={!manufacturerId} onChange={handleAddBrand} />
                                </Stack>
                              </BlockGrid>
                              <Field
                                name="model"
                                label="Модель"
                                component={InputForm}
                                isDisabled={isLoading}
                              />
                            </BlockSection>
                            <BlockSection>
                              <BlockHeading>Местонахождение</BlockHeading>
                              <Field name="location" component={GeoAddressForm} isDraggable={false} />
                            </BlockSection>
                            <BlockSection>
                              <BlockHeading>Контакт для связи</BlockHeading>
                              <Field
                                name="activeContragentContactId"
                                label="Сотрудник"
                                component={SelectForm}
                                isDisabled={isLoading}
                                options={contragentContactsOptions}
                                placeholder="Выберите"
                                isClearable
                              />
                            </BlockSection>
                          </BlockCollection>
                          <Box>
                            <Stack spacing={4} position="sticky" top="20px">
                              <InformerAssistance />
                            </Stack>
                          </Box>
                        </ItemColumns>
                      </TabPanel>
                      <TabPanel minH="500px">
                        <ItemColumns pb={0}>
                          <BlockCollection>
                            {isLoadingFields ? (
                              <Center height="180px">
                                <Spinner ml={3} size="sm" color="blue.500" />
                              </Center>
                            ) : (
                              <>
                                <BlockSection>
                                  <BlockHeading>Основные параметры</BlockHeading>
                                  <FieldArray name="fieldsRequired" component={FieldsForm} />
                                </BlockSection>
                                <BlockSection>
                                  <BlockHeading>Дополнительные признаки</BlockHeading>
                                  <FieldArray name="fields" component={FieldsForm} />
                                </BlockSection>
                              </>
                            )}
                            <BlockSection>
                              <BlockHeading>Загрузка оборудования</BlockHeading>
                              <Field
                                isHideError
                                name="equipmentAvailabilityType"
                                component={SelectForm}
                                placeholder="Выберите из списка"
                                isDisabled={
                                  isLoading || isLoadingFetchManufacturers || isLoadingFetchBrands
                                }
                                isLoading={isLoadingFetchManufacturers || isLoadingFetchBrands}
                                options={[
                                  {
                                    label:
                                      equipmentAvailabilityLabel[
                                        equipmentAvailabilityType.alwaysFree
                                        ],
                                    value: equipmentAvailabilityType.alwaysFree,
                                  },
                                  {
                                    label:
                                      equipmentAvailabilityLabel[
                                        equipmentAvailabilityType.freeByDeal
                                        ],
                                    value: equipmentAvailabilityType.freeByDeal,
                                  },
                                  {
                                    label:
                                      equipmentAvailabilityLabel[equipmentAvailabilityType.scheduled],
                                    value: equipmentAvailabilityType.scheduled,
                                  },
                                ]}
                              />
                              {props.values.equipmentAvailabilityType ===
                                equipmentAvailabilityType.scheduled && (
                                  <BlockGrid>
                                    <Field
                                      name="scheduledStartDate"
                                      hideClearButton
                                      label="Дата начала"
                                      placeholder="01.01.1990"
                                      component={InputDateForm}
                                    />
                                    <Field
                                      name="scheduledEndDate"
                                      hideClearButton
                                      label="Дата окончания"
                                      placeholder="01.01.1990"
                                      component={InputDateForm}
                                    />
                                  </BlockGrid>
                                )}
                            </BlockSection>
                            <BlockSection>
                              <Field
                                name="description"
                                label="Описание"
                                placeholder={`Опишите особенности вашего оборудования, информация о которых не указана в разделах "Основные параметры" и "Дополнительные признаки"`}
                                component={TextAreaForm}
                                isDisabled={isLoading}
                                minH="280px"
                              />
                            </BlockSection>
                          </BlockCollection>
                          <Box>
                            <Stack spacing={4} position="sticky" top="20px">
                              <InformerAssistance />
                              <Informer colorScheme="orange">
                                <Text>
                                  Добавьте параметры, чтобы оборудование было легче найти в поиске.
                                </Text>
                              </Informer>
                              <Informer colorScheme="orange">
                                <Text>
                                  Для упрощения поиска вы можете добавить до 15 ключевых слов к
                                  заявке.
                                </Text>
                              </Informer>
                              <ImageParams />
                            </Stack>
                          </Box>
                        </ItemColumns>
                      </TabPanel>
                    </TabPanels>
                  </Tabs>
                  <BlockFooter>
                    <Button
                      type="submit"
                      colorScheme="blue"
                      isDisabled={!props.dirty || !isFullyCompletedProfile}
                      isLoading={isLoading}
                    >
                      {isNew ? 'Создать и сохранить в публикациях' : 'Обновить'}
                    </Button>
                    {!isNew && (
                      <Button colorScheme="gray" onClick={() => history.goBack()}>
                        Отменить
                      </Button>
                    )}
                  </BlockFooter>
                </Form>
              )
            }}
          </Formik>
        </ScrollBox>
      </Container>
    </>
  )
}
