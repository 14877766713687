import React from 'react'
import { Grid } from '@chakra-ui/react';
import { ErrorBoundary } from '../ErrorBoundary'

export const ItemColumns = ({ children, ...props }) => {
  return (
    <ErrorBoundary>
      <Grid
        templateColumns="minmax(500px, 1fr) minmax(380px, 400px)"
        gap="60px"
        pb="80px"
        {...props}
      >
        {children}
      </Grid>
    </ErrorBoundary>
  )
}
