import { Input } from '../Input'
import { Select } from '../Select'
import { Checkbox, Text } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { fieldTypeId } from 'constants/fieldTypeId'
import { find } from 'lodash'
import { InputRange } from './conponents/InputRange'

const Item = (
  { id, typeId, label, placeholder, value, options, onChange }
) => {
  const item = find(value, e => e?.id === id)
  const [valueState, setValueState] = useState('')

  const handleChange = (e) => {
    onChange({ id, value: e, typeId })
  }

  useEffect(() => {
    setValueState(item?.value ?? '')
  }, [value])

  if (typeId === fieldTypeId.text) {
    return (
      <Input
        label={label}
        value={valueState}
        placeholder={placeholder}
        onChange={e => setValueState(e.target.value)}
        onBlur={e => handleChange(e.target.value)}
      />
    )
  }
  if (typeId === fieldTypeId.number) {
    return (
      <InputRange
        label={label}
        value={valueState}
        placeholder={placeholder}
        onChange={e => setValueState(e)}
        onBlur={e => handleChange(e)}
      />
    )
  }
  if (typeId === fieldTypeId.list) {
    return (
      <Select
        label={label}
        value={item?.value}
        placeholder={placeholder}
        onChange={e => handleChange(e.value)}
        options={options?.map(i => ({ label: i, value: i }))}
      />
    )
  }
  if (typeId === fieldTypeId.bool) {
    return (
      <Checkbox
        isChecked={item?.value === 'true'}
        onChange={e => handleChange(e.target.checked ? 'true' : 'false')}
      >
        <Text fontSize="0.93rem">{label || placeholder}</Text>
      </Checkbox>
    )
  }
  return null
}

export const FieldsConstructor = ({ data, value, onChange }) => {
  return (
    <>
      {data?.map((item, index) => (
        <Item
          key={item?.id}
          id={item?.id}
          typeId={item?.typeId}
          label={item?.label}
          placeholder={item?.placeholder}
          options={item?.options}
          value={value}
          onChange={onChange}
        />
      ))}
    </>
  )
}
