import useQuery from 'hooks/useQuery'
import { api } from 'api'
import { useEntityList } from 'hooks/useEntityList'

export const useArticleList = () => {
  const { request, isLoading } = useQuery((data) => api.knowledgeBase.fetchList(data))
  const { getNextPage, hasMoreRows, dataList, totalCount } = useEntityList(
    { request })

  return {
    getNextPage,
    hasMoreRows,
    isLoading,
    dataList,
    totalCount
  }
}
