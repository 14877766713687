import { useFetchAnalyticFeedback } from './services/useFetchAnalyticFeedback'
import { isEmpty } from 'lodash'
import { Entity } from 'components/Entity'
import { Item } from './components/Item'
import { Box, Grid, HStack, Stack, Stat, StatLabel, StatNumber, Text } from '@chakra-ui/react'

export const InterviewEquipments = () => {
  const { data, isLoadingFetchFeedback } = useFetchAnalyticFeedback()

  return (
    <Entity isLoading={isLoadingFetchFeedback} isEmpty={isEmpty(data)}>
      <Stack spacing="24px">
        <HStack spacing="20px" padding="0 18px">
          <Stat>
            <StatLabel>Оценка удобства использования</StatLabel>
            <StatNumber>{data?.avgUsability}</StatNumber>
          </Stat>
          <Stat>
            <StatLabel>Сделка завершилась в</StatLabel>
            <StatNumber>{data?.percentageOfCompletedDeals * 100}%</StatNumber>
          </Stat>
          <Stat>
            <StatLabel>Сумма завершенных сделок</StatLabel>
            <StatNumber>{data?.dealTotalSumResult}</StatNumber>
          </Stat>
        </HStack>
        <Box pb="80px">
          <Grid
            templateColumns="1fr 1fr 200px 200px"
            gap={5} padding="6px 18px"
            alignItems="center"
            backgroundColor="gray.50"
            borderBottomWidth={1}
            fontSize="0.88rem"
            fontWeight="semibold"
          >
            <Text>Контрагент</Text>
            <Text>Оценка</Text>
            <Text>Сумма сделки</Text>
            <Box></Box>
          </Grid>
          {data?.feedbackItems?.map((item, index) => (
            <Item key={index} data={item}/>
          ))}
        </Box>
      </Stack>
    </Entity>
  )
}
