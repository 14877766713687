import { Radio, RadioGroup, Stack } from '@chakra-ui/react'
import { FormLabel } from '../../../FormLabel'
import React from 'react'

export const RadioGroupForm = ({ label, field, form, options, containerProps, isReadonly, ...props }) => {
  const name = field.name

  const handleChange = (option) => {
    if (isReadonly) return
    form.setFieldValue(name, option)
  }

  return (
    <Stack spacing="10px">
      {label && (
        <FormLabel fontWeight="bold" fontSize="0.9rem" name={name}>
          {label}
        </FormLabel>
      )}
      <RadioGroup
        id={name}
        onChange={handleChange}
        value={field.value}
        {...props}
      >
        <Stack spacing="4px" {...containerProps}>
          {options?.map((item, index) => (
            <Radio key={index} value={item} fontSize="0.86rem">
              {item}
            </Radio>
          ))}
        </Stack>
      </RadioGroup>
    </Stack>
  )
}
