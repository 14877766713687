import React from 'react'
import { useBoolean } from '@chakra-ui/react'
import { FileUploader } from '../FileUploader'
import { useUploadFile } from './services'
import { ErrorBoundary } from '../ErrorBoundary';

export const FileUploaderForm = ({ onChange, ...props }) => {
  const [isOpenUploadFile, setIsOpenUploadFile] = useBoolean()
  const { uploadFile, isLoadingUploadFile } = useUploadFile()

  const handleUploadFile = async (e) => {
    try {
      const dataRequest = {
        name: e.name,
        description: e.description,
        file: e.file.uri,
        fileName: e.file.name,
        fileSize: e.file.size,
        type: e.file.type
      }
      const result = await uploadFile(dataRequest)
      onChange && onChange(result)
      setIsOpenUploadFile.off()
    } catch (e) {}
  }

  return (
    <ErrorBoundary>
      <FileUploader
        isOpen={isOpenUploadFile}
        onOpen={() => setIsOpenUploadFile.on()}
        onClose={() => setIsOpenUploadFile.off()}
        isLoading={isLoadingUploadFile}
        onChange={handleUploadFile}
        {...props}
      />
    </ErrorBoundary>
  )
}
