import { useCallback, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { getUrlParameter } from 'utils/getUrlParameter'
import { isEmpty } from 'lodash'
import { sortDirectionType, sortFieldType } from 'constants/sortType'
import { useUrlState } from 'hooks/useUrlState'

const initialState = {
  roles: '',
  organizationsTypes: [],
  regions: [],
  mainOKVEDCode: [],
  sort: `${sortFieldType.default}:${sortDirectionType.descending}`,
  view: 'list'
}

export const useFilter = () => {
  const location = useLocation()
  const searchString = location.search ? getUrlParameter('search', location.search) : null
  const [filter, setFilter, reset] = useUrlState(initialState)

  const searchParameters = useMemo(() => {
    return  {
      ...searchString && { searchString },
      ...filter.roles?.value === 'isCustomer' && { isCustomer: true },
      ...filter.roles?.value === 'isSupplier' && { isSupplier: true },
      ...!isEmpty(filter.manufacturingRange) && { manufacturingRange: filter.manufacturingRange?.map(i => i.name) },
      ...!isEmpty(filter.equipmentTools) && { equipmentTools: filter.equipmentTools?.map(i => i.name) },
      ...!isEmpty(filter.organizationsTypes) && { organizationsTypes: filter.organizationsTypes?.map(i => i.value) },
      ...!isEmpty(filter.mainOKVEDCode) && { mainOKVEDCodes: filter.mainOKVEDCode?.map(i => i.code)},
      ...!isEmpty(filter.regions) && { regions: filter.regions?.map(i => Number(i.id)) },
    }
  }, [filter, searchString])

  const update = useCallback(
    async (field, value) => {
      setFilter((prevState) => ({
        ...prevState,
        [field]: value,
      }))
    },
    [setFilter]
  )

  return {
    dataFilter: filter,
    updateFilter: update,
    resetFilter: reset,
    searchParameters,
    sort: filter.sort
  };
};
