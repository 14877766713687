import useQuery from 'hooks/useQuery'
import { api } from 'api'
import { useEffect } from 'react'
import { useToast } from '@chakra-ui/react'

export const useFetchSupportRequest = (id) => {
  const toast = useToast()
  const {
    isError,
    isLoading,
    response,
    error,
    request,
  } = useQuery(() => api.supportRequests.fetchItem(id))

  useEffect(() => {
    request()
  }, [])

  useEffect(() => {
    if (isError) {
      toast({
        title: 'Ошибка',
        description: error.response?.data?.ErrorMessage,
        status: 'error',
      })
    }
  }, [isError])

  return {
    data: response,
    getSupportRequest: request,
    isLoadingFetch: isLoading
  }
}
