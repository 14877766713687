import useQuery from 'hooks/useQuery'
import { api } from 'api'
import { useEffect, useMemo } from 'react';

export const useNotifications = () => {
  const {
    isLoading,
    response,
    request
  } = useQuery(() => api.notifications.fetchList())

  const _data = useMemo(() => {
    return response?.rows?.slice(0, 30)
  }, [response])

  useEffect(() => {
    request()
  }, [])

  return {
    data: _data,
    isLoading
  }
}
