import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  data: {
    longitude: 44.329700,
    latitude: 56.231708,
    zoom: 6,
    bearing: 0,
    pitch: 0
  }
}

const {reducer, actions} = createSlice({
  initialState,
  name: 'equipmentsMap',
  reducers: {
    update: (state, action) => {
      state.data = action.payload
    },
    reset: () => initialState,
  }
});

export { actions, reducer }
export default reducer
