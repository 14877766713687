import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import { List } from './List'
import { Item } from './Item'
import { ErrorBoundary } from 'components/ErrorBoundary'
import { BlockCompletedModal } from '../../components/BlockCompletedModal'

export const Equipment = () => {
  const match = useRouteMatch()

  return (
    <>
      <BlockCompletedModal/>
      <Switch>
        <Route path={`${match.url}/card`}>
          <ErrorBoundary>
            <Item/>
          </ErrorBoundary>
        </Route>
        <Route path={`${match.url}/:accountId?`}>
          <ErrorBoundary>
            <List/>
          </ErrorBoundary>
        </Route>
      </Switch>
    </>
  )
}
