import axios from 'axios'

const ENTITY = 'admin'

export const admin = Object.freeze({
  async createMachineManufacturer(data) {
    const response = await axios.post(`/${ENTITY}/machine/manufacturer`, data)
    return response.data
  },
  async updateMachineManufacturer(data) {
    const response = await axios.put(`/${ENTITY}/machine/manufacturer`, data)
    return response.data
  },
  async createMachineBrand(data) {
    const response = await axios.post(`/${ENTITY}/machine/brand`, data)
    return response.data
  },
  async updateMachineBrand(data) {
    const response = await axios.put(`/${ENTITY}/machine/brand`, data)
    return response.data
  },
  async createMachineField(data) {
    const response = await axios.post(`/${ENTITY}/machine/field`, data)
    return response.data
  },
  async updateMachineField(data) {
    const response = await axios.put(`/${ENTITY}/machine/field`, data)
    return response.data
  },
  async createMachineRequiredField(data) {
    const response = await axios.post(`/${ENTITY}/machine/requiredfield`, data)
    return response.data
  },
  async createMachineClassifier(data) {
    const response = await axios.post(`/${ENTITY}/machine/classifier`, data)
    return response.data
  },
  async updateMachineClassifier(data) {
    const response = await axios.put(`/${ENTITY}/machine/classifier`, data)
    return response.data
  },
  async fetchMachineManufacturerBrand(data) {
    const response = await axios.post(`/${ENTITY}/machine/manufacturerbrand`, data)
    return response.data
  },
  async fetchMachineClassifierAll() {
    const response = await axios.get(`/${ENTITY}/machine/classifier/all`)
    return response.data
  },
  async saveMachineClassifierOrder(data) {
    const response = await axios.put(`/${ENTITY}/machine/classifier/order`, data)
    return response.data
  },
})
