import React from 'react'
import { HStack } from '@chakra-ui/react'

export const BlockFooter = ({ children, ...props }) => {
  return (
    <HStack
      borderTopWidth={1}
      paddingTop="40px"
      my="40px"
      spacing={5}
      {...props}
    >
      {children}
    </HStack>
  )
}
