import { Modal } from '../Modal'
import React from 'react'
import { Box, Button, Stack } from '@chakra-ui/react'

export const ShortCompletedProfileModal = ({ isOpen, onClose }) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      header="Профиль не заполнен"
    >
      <Stack spacing="20px">
        <Box>
          Для дальнейшей работы на платформе требуется заполнить данные о компании
        </Box>
        <Button width="fit-content" colorScheme="blue" onClick={onClose}>
          Хорошо
        </Button>
      </Stack>
    </Modal>
  )
}
