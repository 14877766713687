import { combineReducers } from '@reduxjs/toolkit'
import profileReducer from './profile/profile.store'
import filterReducer from './filter/filter.store'
import mapReducer from './map/map.store'
import favoriteReducer from './favorite/favorite.store'

export const rootReducer = combineReducers({
  profile: profileReducer,
  filter: filterReducer,
  map: mapReducer,
  favorite: favoriteReducer
})
