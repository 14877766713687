import React from 'react'
import { FormLabel } from '../FormLabel'
import { Box, FormControl, Stack, Textarea } from '@chakra-ui/react'

export const TextArea = React.forwardRef(
  (
    {
      name,
      isInvalid,
      label,
      placeholder,
      tooltipLabel,
      error,
      isHideError,
      onChange,
      value,
      textAlign = 'left',
      ...props
    },
    ref
  ) => {
    return (
      <Stack spacing={4}>
        <Box>
          <FormControl isInvalid={isInvalid}>
            {label && <FormLabel tooltipLabel={tooltipLabel}>{label}</FormLabel>}
            <Textarea
              ref={ref}
              id={name}
              placeholder={placeholder}
              textAlign={textAlign}
              value={value || ''}
              onChange={onChange}
              {...props}
            />
          </FormControl>
          {error && isInvalid && !isHideError && (
            <Box fontSize="0.7rem" color="red.500" mt="4px">
              {error}
            </Box>
          )}
        </Box>
      </Stack>
    )
  }
)
