import { useToast } from '@chakra-ui/react'
import useQuery from 'hooks/useQuery'
import { api } from 'api'
import { useEffect } from 'react'

export const useFetchAnalyticFeedback = () => {
  const toast = useToast()
  const {
    isError,
    isLoading,
    error,
    request,
    response
  } = useQuery(() => api.analytic.fetchFeedbackStat({ projectType: 'Equipments' }))

  useEffect(() => {
    if (isError) {
      toast({
        title: 'Ошибка',
        description: error.response?.data?.ErrorMessage,
        status: 'error',
      })
    }
  }, [isError])

  useEffect(() => {
    request()
  }, [])

  return {
    fetchAnalyticFeedback: request,
    data: response,
    isLoadingFetchFeedback: isLoading,
  }
}
