import React, { useEffect } from 'react'
import { CardWrapper } from 'components/CardWrapper'
import { Box, Button, Heading, Spinner, Stack, Text } from '@chakra-ui/react'
import { useVerify } from './services'
import { useHistory } from 'react-router'
import { useLocation } from 'react-router-dom'
import { getUrlParameter } from 'utils/getUrlParameter'
import { LayoutServices } from 'components/LayoutServices'

export const Verify = () => {
  const location = useLocation()
  const email = location.search ? getUrlParameter('email', location.search) : ''
  const token = location.search ? getUrlParameter('token', location.search) : ''
  const { request, isLoading, isIdle, isError, error } = useVerify()
  const history = useHistory()

  const handleClick = () => {
    history.push('/account/0?verify=true')
  }

  useEffect(() => {
    request({ email, token })
  }, [])

  return (
    <LayoutServices>
      <CardWrapper
        pt="40px"
        pb="50px"
        px="60px"
      >
        <Stack spacing={5}>
          {(!isIdle || isLoading) ? (
            <>
              <Spinner color="blue.500"/>
              <Heading fontSize="1.6rem">Подтверждение</Heading>
              <Text lineHeight={1.3}>
                Проверяем вашу почту, подождите немного...
              </Text>
            </>
          ) : (
            <>
              {isError ? (
                <>
                  <Heading fontSize="1.6rem">Ошибка</Heading>
                  <Text lineHeight={1.3}>
                    {error?.response?.data?.ErrorMessage}
                  </Text>
                </>
              ) : (
                <>
                  <Heading fontSize="1.6rem">Все готово</Heading>
                  <Text lineHeight={1.3}>
                    Почта успешна подтверждена. Вы можете перейти в профиль и приступить к работе. Заполните информацию о компании. Публикуйте своё свободное оборудование и размещайте заявки, оперативно находите партнеров.
                  </Text>
                  <Box>
                    <Button colorScheme="blue" onClick={handleClick}>Перейти в мой профиль</Button>
                  </Box>
                </>
              )}
            </>
          )}
        </Stack>
      </CardWrapper>
    </LayoutServices>
  )
}
