export const segmentControlType = {
  enter: 'enter',
  registration: 'registration'
}

export const segmentControlOptions = [
  {
    label: 'Вход',
    value: segmentControlType.enter
  },
  {
    label: 'Регистрация',
    value: segmentControlType.registration
  }
]
