import React from 'react'
import { GridFieldItem } from 'components/GridField'
import { Box, Text } from '@chakra-ui/react'
import { BlockCollection } from 'components/BlockCollection'
import { BlockSection } from 'components/BlockSection'
import { BlockHeading } from 'components/BlockHeading'
import { useFetchVendorsData } from '../services/useFetchVendorsData'
import { isEmpty } from 'lodash';
import { FileWidget } from 'components/FileWidget'
import { BlockGrid } from 'components/BlockGrid'
import { getWorkScheduleString } from 'utils/getWorkScheduleString'

export const Show = () => {
  const { data, isLoadingFetch } = useFetchVendorsData()
  return (
    <BlockCollection>
      <BlockSection>
        <BlockHeading>Условия производства</BlockHeading>
        <Box>
          <GridFieldItem
            isReady
            isLoading={isLoadingFetch}
            label="Есть конструкторское бюро"
          >
            {data?.hasDesignBureau ? 'Да' : 'Нет'}
          </GridFieldItem>
          <GridFieldItem
            isReady
            isLoading={isLoadingFetch}
            label="Являюсь производителем"
          >
            {data?.isManufacturer ? 'Да' : 'Нет'}
          </GridFieldItem>
          <GridFieldItem
            isReady
            isLoading={isLoadingFetch}
            label="Адрес производства"
          >
            {data?.manufacturingAddress?.displayString}
          </GridFieldItem>
        </Box>
      </BlockSection>
      <BlockSection>
        <BlockHeading>Выпускаемая продукция</BlockHeading>
        <Box>
          {data?.manufacturingRange?.map(i => (
            <GridFieldItem
              isReady
              isLoading={isLoadingFetch}
            >
              {i.name}
            </GridFieldItem>
          ))}
        </Box>
      </BlockSection>
      <BlockSection>
        <BlockHeading>Дополнительная информация</BlockHeading>
        <Box>
          <GridFieldItem
            isReady
            isLoading={isLoadingFetch}
            label="Часы работы"
          >
            {getWorkScheduleString(data?.workingHours)}
          </GridFieldItem>
          <GridFieldItem
            isReady
            isLoading={isLoadingFetch}
            label="Доставка"
          >
            {data?.hasDelivery ? 'Есть' : 'Нет'}
          </GridFieldItem>
        </Box>
      </BlockSection>
      {!isEmpty(data?.wareHouses) && (
        <BlockSection>
          <BlockHeading>Складские помещения</BlockHeading>
          <Box>
            {data?.wareHouses?.map(i => (
              <GridFieldItem
                isReady
                isLoading={isLoadingFetch}
              >
                {i.address?.displayString}
              </GridFieldItem>
            ))}
          </Box>
        </BlockSection>
      )}
      <BlockSection>
        <BlockHeading>Документы</BlockHeading>
        <BlockGrid>
          {isEmpty(data?.files) ? (
            <>
              <Text color="gray.400">Нет документов</Text>
            </>
          ) : data?.files?.map((file, index) => (
            <FileWidget
              key={index}
              data={file}
              isHideDelete
            />
          ))}
        </BlockGrid>
      </BlockSection>
    </BlockCollection>
  )
}
