import React from 'react'
import { Radio, Stack, RadioGroup as ChakraRadioGroup, Box } from '@chakra-ui/react'
import { FormLabel } from '../FormLabel';

export const RadioGroup = ({ options, onChange, value, label }) => {
  return (
    <Box>
      {label && (
        <FormLabel>
          {label}
        </FormLabel>
      )}
      <ChakraRadioGroup onChange={onChange} value={value}>
        <Stack spacing={1}>
          {options && options.map((item, index) => (
            <Radio
              key={index}
              value={item.value}
            >
              {item.label}
            </Radio>
          ))}
        </Stack>
      </ChakraRadioGroup>
    </Box>
  )
}
