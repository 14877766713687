import React from 'react'
import { PageHeading } from 'components/PageHeading'
import { Flex, Tab, TabList, TabPanels, Tabs, Text } from '@chakra-ui/react'
import { VendorQuestionnaire } from 'entities/VendorQuestionnaire'
import { useParams } from 'react-router'
import { Profile } from 'entities/Profile'
import { TabPanel } from 'components/TabPanel'
import { useAccess } from 'hooks/useAccess'
import { rolesType } from 'constants/rolesType'
import { Container } from 'components/Container'
import { ScrollBox } from 'components/ScrollBox'

export const Edit = () => {
  const {id} = useParams()
  const [isAdmin] = useAccess([rolesType.admin])

  if (!isAdmin) {
    return (
      <Flex alignItems="center" justifyContent="center" height="200px">
        <Text>Редактирование доступно только администратору</Text>
      </Flex>
    )
  }

  return (
    <Container>
      <ScrollBox>
        <PageHeading>Редактирование</PageHeading>
        <Tabs isLazy>
          <TabList>
            <Tab>Профиль</Tab>
            <Tab>Анкета исполнителя</Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              <Profile isEdit id={id}/>
            </TabPanel>
            <TabPanel>
              <VendorQuestionnaire isEdit id={id}/>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </ScrollBox>
    </Container>
  )
}
