import React from 'react'
import { Flex, Spinner } from '@chakra-ui/react';

export const FileIcon = ({ name, isLoading, ...props }) => {
  const fileExt = name?.split('.').pop()
  return (
    <Flex
      boxSize="50px"
      backgroundColor="purple.500"
      borderRadius="10px"
      textTransform="uppercase"
      alignItems="center"
      justifyContent="center"
      color="white"
      fontSize="0.9rem"
      {...props}
    >
      {isLoading ? <Spinner color="white"/> : fileExt}
    </Flex>
  )
}
