import React from 'react'
import { Box, Flex, Grid, Stack, Text, useColorModeValue } from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { getDateReadFormat } from 'utils/dateFormatting'
import { truncate } from 'lodash';

export const SnippetItem = ({ data }) => {
  const bgContainerBorder = useColorModeValue('gray.100', 'gray.700')
  const bgContainerHover = useColorModeValue('gray.50', 'whiteAlpha.100')
  const bgContainerActive = useColorModeValue('gray.100', 'whiteAlpha.50')

  return (
    <Link to={`/articles/${data.id}`}>
      <Box
        cursor="pointer"
        borderBottomWidth={1}
        borderColor={bgContainerBorder}
        transition="0.3s"
        _hover={{
          zIndex: 3,
          backgroundColor: bgContainerHover
        }}
        _active={{
          backgroundColor: bgContainerActive
        }}
      >
        <Grid templateColumns="1fr 320px" gap={5} padding="18px">
          <Flex flexDirection="column" justifyContent="center">
            <Stack spacing={2}>
              <Text
                fontWeight="semibold"
                fontSize="18px"
                lineHeight={1.2}
              >
                {data?.title !== '' ? data?.title : 'Нет заголовка'}
              </Text>
              <Text
                fontSize="14px"
                lineHeight="18px"
                color="gray.400"
              >
                {truncate(data?.description, { length: 300 })}
              </Text>
            </Stack>
          </Flex>
          <Stack spacing={1} justifyContent="center">
            <Text color="gray.400" fontSize="0.84rem">Создано: {getDateReadFormat(data?.createdAt)}</Text>
            {data?.updatedAt && (
              <Text color="gray.400" fontSize="0.84rem">Обновлено: {getDateReadFormat(data?.updatedAt)}</Text>
            )}
          </Stack>
        </Grid>
      </Box>
    </Link>
  )
}
