import { Box, Button, Flex, Heading, HStack, Stack, Text } from '@chakra-ui/react'
import { SlideWrapper } from '../components/SlideWrapper'
import { Container } from '../components/Container'
import { SpecialHeading } from '../components/SpecialHeading'
import { ArrowForwardIcon } from '@chakra-ui/icons'
import { Logo } from '../components/Logo'
import { Link } from 'react-router-dom'
import { LandingVideoModal } from '../../../components/LandingVideoModal'

export const Intro = () => {
  return (
    <>
      <SlideWrapper justifyContent="space-between">
        <Flex
          borderBottomWidth={1}
          borderColor="whiteAlpha.400"
          height="70px"
          alignItems="center"
        >
          <Container>
            <Box width="100%">
              <Logo/>
            </Box>
          </Container>
        </Flex>
        <Flex position="relative" flex={1} alignItems="center">
          <Container pt={['30px', '30px', '0px', '0px']} pb={['30px', '30px', '0px', '0px']}>
            <Stack
              spacing="20px"
              data-aos="fade-up"
            >
              <SpecialHeading color="white">агрегатор производственных мощностей</SpecialHeading>
              <Heading
                textTransform="uppercase"
                fontWeight="black"
                letterSpacing="1px"
                fontSize={['30px', '40px', '60px', '70px']}
                color="white"
              >
                Станкофонд
                <Text fontSize={['20px', '22px']}>
                  сделаем в россии
                </Text>
              </Heading>
              <Box maxW="800px" color="white">
                <Text fontSize={['1fr', '22px']}>
                  Размещайте информацию о свободных производственных мощностях и отслеживайте потребности бизнеса. Находите исполнителей в сжатые сроки без посредников и переплат
                </Text>
              </Box>
              <Stack spacing={3} pt="20px" pb="40px" direction={['column', 'row']}>
                <Button
                  as={Link}
                  colorScheme="blueRus"
                  to="/registration"
                >Зарегистрироваться</Button>
                <Button
                  as={Link}
                  colorScheme="whiteAlpha"
                  to="/equipments"
                  rightIcon={<ArrowForwardIcon/>}
                >Войти</Button>
              </Stack>
              <LandingVideoModal/>
            </Stack>
          </Container>
        </Flex>
        <HStack/>
      </SlideWrapper>
    </>
  )
}
