import useQuery from 'hooks/useQuery'
import { api } from 'api'
import { useEffect } from 'react'
import { useToast } from '@chakra-ui/react'

export const useReply = (id) => {
  const toast = useToast()
  const {
    isError,
    isLoading,
    error,
    request
  } = useQuery(() => api.order.replyItem(id))

  useEffect(() => {
    if (isError) {
      toast({
        title: 'Ошибка',
        description: error.response?.data?.ErrorMessage,
        status: 'error',
      })
    }
  }, [isError])

  return ({
    setReply: request,
    isLoadingReply: isLoading,
  })
}
