import { useState, useEffect } from 'react'
import { api } from '../../api'
import { residentType } from '../../constants/residentType'

const useDaData = (query) => {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    if (!query.trim()) {
      setData(null)
      setLoading(false)
      setError(null)
      return
    }

    const fetchData = async () => {
      try {
        setLoading(true)

        const response = await api.accounts.hints({
          query,
          resident: residentType.russianFederation
        })
        setData(response)
      } catch (error) {
        setError(error)
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [query])

  return { data, loading, error }
}

export default useDaData
