import React from 'react'
import { YMaps, Map, ZoomControl } from '@pbe/react-yandex-maps'

const MapBoxMemo = (
  {initialViewState, children},
) => {

  const defaultState = {
    center: [initialViewState?.latitude ?? 55.751574, initialViewState?.longitude ?? 37.573856],
    zoom: initialViewState?.zoom ?? 6,
    controls: []
  }

  return (
    <>
      <YMaps>
        <Map
          width="100%"
          height="100%"
          defaultState={defaultState}
        >
          <ZoomControl options={{ float: 'right' }} />
          {children}
        </Map>
      </YMaps>
    </>
  );
};

export const MapBox = React.memo(MapBoxMemo);
