import React from 'react'
import { Flex, HStack, Icon, Text, useColorModeValue } from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { useAccess } from '../../../hooks/useAccess'
import { rolesType } from 'constants/rolesType'
import { useFetchNotificationCount } from '../../../services/useFetchNotificationCount'
import { Count } from '../../Count'
import { IconCompanies } from './icons/IconCompanies'
import { IconMyOrders } from './icons/IconMyOrders'
import { IconOrders } from './icons/IconOrders'
import { IconProfile } from './icons/IconProfile'
import { useAuthentication } from '../../../hooks/useAuthentication'
import { IconClassifier } from './icons/IconClassifier'
import { IconEquipment } from './icons/IconEquipment'
import { IconFavorite } from './icons/IconFavorite'

const Item = ({ icon, label, count = null, to, isTransparent }) => {
  const colorLabel = useColorModeValue('black', 'white')
  const { isAuthenticated } = useAuthentication()
  return (
    <Link to={to}>
      <Flex
        role="group"
        flexDirection="column"
        alignItems="center"
        _active={{
          transform: 'scale(0.98)'
        }}
        position="relative"
        transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
        opacity={isTransparent ? 0.4 : 1}
        _hover={{
          opacity: 1
        }}
      >
        {isAuthenticated && count !== null && (
          <Count
            position="absolute"
            right={0}
            top={-2}
            count={count}
          />
        )}
        <Icon
          width="20px"
          height="20px"
          mb={1}
          as={icon}
        />
        <Text
          color={colorLabel}
          lineHeight={1}
          fontSize="0.78rem"
          fontWeight="medium"
          transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
          _groupHover={{
            color: 'blue.400'
          }}
        >
          {label}
        </Text>
      </Flex>
    </Link>
  )
}

export const NavigationMenu = ({ ...props }) => {
  const [isAdmin] = useAccess([rolesType.admin])
  const { notificationCount } = useFetchNotificationCount()
  const { isAuthenticated } = useAuthentication()
  return (
    <HStack spacing={6} {...props}>
      {isAdmin ? (
        <>
          <Item icon={IconEquipment} to={'/equipments'} label="Оборудование"/>
          <Item icon={IconOrders} to={'/orders'} label="Заявки"/>
          <Item icon={IconMyOrders} to={'/support-requests/0'} label="Запросы"/>
          <Item icon={IconCompanies} to={'/companies'} label="Компании"/>
          <Item icon={IconClassifier} to={'/configurator/0'} label="Конфигуратор"/>
        </>
      ) : (
        <>
          <Item icon={IconEquipment} to={'/equipments'} label="Оборудование"/>
          <Item icon={IconOrders} to={'/orders'} label="Заявки"/>
          <Item icon={IconCompanies} to={'/companies'} label="Компании"/>
          <Item icon={IconMyOrders} to={'/my-publications/0'} label="Мои публикации" isTransparent={!isAuthenticated}/>
        </>
      )}
      {isAuthenticated && (
        <>
          <Item icon={IconFavorite} to={'/favorite/0'} label="Избранное"/>
        </>
      )}
      <Item count={notificationCount} icon={IconProfile} to={'/account/0'} label="Профиль" isTransparent={!isAuthenticated}/>
    </HStack>
  )
}
