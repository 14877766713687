import React from 'react'
import { createIcon } from '@chakra-ui/icons'

export const IconMap = createIcon({
  displayName: 'Map',
  viewBox: '0 0 12 15',
  path: (
    <path
      fill='currentColor'
      d="M6 14.8188L1.75734 10.5761C0.918228 9.73699 0.346791 8.66789 0.115286 7.50401C-0.11622 6.34013 0.00260456 5.13373 0.456732 4.03738C0.91086 2.94103 1.6799 2.00396 2.66659 1.34467C3.65328 0.685388 4.81332 0.333496 6 0.333496C7.18669 0.333496 8.34672 0.685388 9.33342 1.34467C10.3201 2.00396 11.0891 2.94103 11.5433 4.03738C11.9974 5.13373 12.1162 6.34013 11.8847 7.50401C11.6532 8.66789 11.0818 9.73699 10.2427 10.5761L6 14.8188ZM9.3 9.63344C9.9526 8.98078 10.397 8.14926 10.577 7.24403C10.7571 6.33879 10.6646 5.40051 10.3114 4.54781C9.95817 3.69512 9.36003 2.96631 8.59261 2.45356C7.82519 1.9408 6.92296 1.66711 6 1.66711C5.07704 1.66711 4.17481 1.9408 3.40739 2.45356C2.63997 2.96631 2.04183 3.69512 1.68861 4.54781C1.33539 5.40051 1.24294 6.33879 1.42297 7.24403C1.603 8.14926 2.04741 8.98078 2.7 9.63344L6 12.9334L9.3 9.63344V9.63344ZM6 7.66678C5.64638 7.66678 5.30724 7.5263 5.05719 7.27625C4.80714 7.0262 4.66667 6.68707 4.66667 6.33344C4.66667 5.97982 4.80714 5.64068 5.05719 5.39063C5.30724 5.14059 5.64638 5.00011 6 5.00011C6.35362 5.00011 6.69276 5.14059 6.94281 5.39063C7.19286 5.64068 7.33334 5.97982 7.33334 6.33344C7.33334 6.68707 7.19286 7.0262 6.94281 7.27625C6.69276 7.5263 6.35362 7.66678 6 7.66678Z"
    />
  ),
})
