import React, { useState, memo } from 'react'
import { FixedSizeList as List, areEqual } from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'
import memoizeOne from 'memoize-one'
import { Box, Flex, Grid, Icon, Stack, Text } from '@chakra-ui/react'
import { Symbol } from '../../../Symbol'
import { isEmpty, truncate } from 'lodash'
import { ChevronRightIcon } from '@chakra-ui/icons'

const Row = memo(({ data, index, style }) => {
  const { flattenedData, onOpen, onSelect } = data
  const node = flattenedData[index]
  const left = node.depth * 20

  const handleOpenClick = () => {
    if (node.hasParent) {
      onOpen(node)
    }
  }

  return (
    <div className="item-background" style={style}>
      <div
        className={`${node.hasParent ? 'tree-branch' : ''} ${node.collapsed ? 'tree-item-closed' : 'tree-item-open'}`}
        style={{
          position: 'absolute',
          left: `${left}px`,
          height: '100%',
          width: `calc(100% - ${left}px)`,
        }}
      >
        <Grid
          role="group"
          pt="13px"
          pb="14px"
          px="10px"
          height="100%"
          templateColumns="26px 40px 1fr"
          cursor="pointer"
        >
          <Flex
            onClick={handleOpenClick}
            alignItems="center"
            transition="0.2s"
            _hover={{
              color: 'blue.300'
            }}
          >
            {node.hasParent ? (
              <Icon
                as={ChevronRightIcon}
                transform={node.collapsed ? 'rotate(0deg)' : 'rotate(90deg)'}
              />
            ) : (
              <Box backgroundColor="gray.100" boxSize="8px" borderRadius="full"/>
            )}
          </Flex>
          <Flex alignItems="center">
            <Symbol>
              {node?.section}
            </Symbol>
          </Flex>
          <Stack
            spacing="2px"
            justifyContent="center"
            onClick={e => onSelect(e, node)}
            mt="-3px"
            transition="0.2s"
            _hover={{
              color: 'blue.300'
            }}
          >
            {node?.code !== node?.name && (
              <Text fontWeight="semibold" fontSize="0.8rem">
                {truncate(node?.code, { length: 120 })}
              </Text>
            )}
            <Text fontSize="0.9rem" lineHeight="1.2">
              {truncate(node?.name, { length: 120 })}
            </Text>
          </Stack>
        </Grid>
      </div>
    </div>
  );
}, areEqual)

const getItemData = memoizeOne((onOpen, onSelect, flattenedData) => ({
  onOpen,
  onSelect,
  flattenedData,
}))

export const SpeedTree = ({ data, onChange }) => {
  const [openedNodeIds, setOpenedNodeIds] = useState([]);

  const flattenOpened = treeData => {
    const result = []
    for (let node of data) {
      flattenNode(node, 0, result)
    }
    return result
  }

  const flattenNode = (node, depth, result) => {
    const { id, name, nested, section, code } = node
    let collapsed = !openedNodeIds.includes(code)
    result.push({
      id,
      name,
      section,
      code,
      hasParent: !isEmpty(nested),
      depth,
      collapsed,
    })

    /*if (!collapsed && parent) {
      flattenNode(parent, depth + 1, result);
    }*/

    if (!collapsed && !isEmpty(nested)) {
      for (let item of nested) {
        flattenNode(item, depth + 1, result);
      }
    }
  };

  const onOpen = node => {
    if (node.collapsed) {
      setOpenedNodeIds([...openedNodeIds, node.code])
      return
    }
    setOpenedNodeIds(openedNodeIds.filter(code => code !== node.code))
  }

  const onSelect = (e, node) => {
    e.stopPropagation()
    onChange(node)
  };

  const flattenedData = flattenOpened(data);

  const itemData = getItemData(onOpen, onSelect, flattenedData);

  return (
    <AutoSizer>
      {({ height, width }) => (
        <List
          className="List"
          height={height}
          itemCount={flattenedData.length}
          itemSize={80}
          width={width}
          itemKey={index => flattenedData[index].code}
          itemData={itemData}
        >
          {Row}
        </List>
      )}
    </AutoSizer>
  )
}
