import React, { useMemo } from 'react'
import {
  Box,
  Heading,
  Stack,
  Text,
  useColorMode,
  useColorModeValue
} from '@chakra-ui/react'
import { find } from 'lodash'

const getItem = (data, levelId) => find(data, e => e.levelId === levelId)

const Item = ({ label, children }) => {
  const colorSecondaryText = useColorModeValue('gray.400', 'gray.500')
  return (
    <Stack spacing={0}>
      <Text color={colorSecondaryText} fontSize="0.9rem">{label}</Text>
      {children ? <Text>{children}</Text> : <Text color={colorSecondaryText}>Нет</Text>}
    </Stack>
  )
}

export const ClassifierBlock = ({ data }) => {
  const bg = useColorModeValue('white', 'gray.700')
  const { colorMode } = useColorMode()

  const valueLevelId1 = useMemo(() => getItem(data, 1), [data])
  const valueLevelId2 = useMemo(() => getItem(data, 2), [data])
  const valueLevelId3 = useMemo(() => getItem(data, 3), [data])
  const valueLevelId4 = useMemo(() => getItem(data, 4), [data])

  return (
    <Box
      position="sticky"
      top="20px"
      borderRadius="12px"
      padding="28px"
      backgroundColor={bg}
      {...colorMode === 'light' && {boxShadow: 'rgba(15, 15, 15, 0.1) 0px 3px 6px, rgba(15, 15, 15, 0.1) 0px 9px 24px'}}
    >
      <Stack spacing="24px">
        <Heading fontSize="1.1rem">Каталогизация</Heading>
        <Stack spacing="14px">
          <Item label="Вид оборудования:">{valueLevelId1?.name}</Item>
          <Item label="Тип оборудования:">{valueLevelId2?.name}</Item>
          <Item label="Технологическое назначение:">{valueLevelId3?.name}</Item>
          <Item label="Детализация:">{valueLevelId4?.name}</Item>
        </Stack>
      </Stack>
    </Box>
  )
}
