import { SlideWrapper } from '../components/SlideWrapper'
import { Container } from '../components/Container'
import { Box, Flex, Grid, Heading, Image, Stack, Text } from '@chakra-ui/react'
import Bg from './images/bg.svg'
import Logo1 from './images/logo-1.svg'
import Logo2 from './images/logo-2.svg'
import Logo3 from './images/logo-3.png'
import { SpecialHeading } from '../components/SpecialHeading'

const Item = ({ title, children }) => {
  return (
    <Stack spacing="4px">
      <Heading
        fontSize={['1.1rem', '1.2rem', '1.3rem', '1.4rem']}
        color="#3B38AB"
      >{title}</Heading>
      <Text>{children}</Text>
    </Stack>
  )
}

export const About = () => {
  return (
    <SlideWrapper
      justifyContent="center"
      bg="white"
      py="70px"
      backgroundImage={Bg}
      backgroundSize="cover"
    >
      <Container>
        <Grid
          templateColumns={['1fr', '1fr', '1fr 400px']}
          templateRows={['auto auto', 'auto auto', 'auto']}
          gap="60px"
        >
          <Box>
            <Box data-aos="fade-right">
              <Flex height="50px">
                <SpecialHeading>О платформе</SpecialHeading>
              </Flex>
              <Stack spacing="40px" maxW="700px">
                <Heading
                  fontSize={['1.2rem', '1.5rem', '1.7rem', '2rem']}
                >
                  Станкофонд
                  <Text fontSize={['0.9rem', '1.1rem', '1.2rem']}>
                    сделаем в России
                  </Text>
                </Heading>
                <Stack spacing={6}>
                  <Item>
                    Безбарьерная среда для коммуникации заказчиков и исполнителей и развития кооперационных связей между предприятиями
                  </Item>
                  <Item>
                    Платформа помогает предприятиям обмениваться информацией о технологических возможностях и свободных производственных мощностях, отслеживать потребности бизнеса и находить партнеров для исполнения заказов.
                  </Item>
                </Stack>
              </Stack>
            </Box>
          </Box>
          <Flex flexDirection="column" justifyContent="center">
            <Box data-aos="fade-left">
              <Flex height="50px">
                <SpecialHeading>При поддержке</SpecialHeading>
              </Flex>
              <Grid
                templateColumns={['1fr', '1fr', '1fr']}
                templateRows={['1fr 1fr', '1fr 1fr', '1fr']}
                gap={['20px', '30px', '60px']}
              >
                <Image src={Logo1} />
                <Image src={Logo2} />
                <Image src={Logo3} />
              </Grid>
            </Box>
          </Flex>
        </Grid>
      </Container>
    </SlideWrapper>
  )
}
