import React from 'react'
import { createIcon } from '@chakra-ui/icons'

export const IconOrders = createIcon({
  displayName: 'Orders',
  viewBox: '0 0 21 20',
  path: (
    <g fill="none">
      <path fillRule="evenodd" clipRule="evenodd" d="M15.5 17.5H5.49998C4.06498 17.5 2.92415 16.2942 3.00415 14.8617L3.51581 5.64833C3.61415 3.88167 5.07498 2.5 6.84415 2.5H14.1566C15.9258 2.5 17.3866 3.88167 17.485 5.64833L17.9966 14.8608C18.0758 16.2942 16.935 17.5 15.5 17.5Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M13.8333 6.45834C13.8333 8.29918 12.3408 9.79168 10.5 9.79168C8.65913 9.79168 7.16663 8.29918 7.16663 6.45834" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
  ),
})
