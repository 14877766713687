import useQuery from 'hooks/useQuery'
import { api } from 'api'
import { useEffect } from 'react'
import { useToast } from '@chakra-ui/react'

export const useFetchVendorsData = (id) => {
  const toast = useToast()
  const {
    isError,
    isLoading,
    response,
    error,
    request
  } = useQuery(() => api.vendorsData.fetchData(id))

  useEffect(() => {
    request()
  }, [])

  useEffect(() => {
    if (isError) {
      toast({
        title: 'Ошибка',
        description: error.response?.data?.ErrorMessage,
        status: 'error',
      })
    }
  }, [isError])

  return ({
    getVendorsData: request,
    data: response,
    isLoadingFetch: isLoading
  })
}
