import { createSlice } from '@reduxjs/toolkit'
import { searchAreaType } from 'constants/searchAreaType'

const initialState = {
  searchArea: searchAreaType.equipments,
  notificationCount: 0
}

const {reducer, actions} = createSlice({
  initialState,
  name: 'settings',
  reducers: {
    reset: () => initialState,
    updateSearchArea: (state, action) => {
      state.searchArea = action.payload
    },
    updateNotificationCount: (state, action) => {
      state.notificationCount = action.payload
    }
  }
});

export { actions, reducer }
export default reducer
