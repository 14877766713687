import { useToast } from '@chakra-ui/react'
import useQuery from 'hooks/useQuery'
import { api } from 'api'
import { useEffect } from 'react'

export const useVerify = () => {
  const toast = useToast()
  const {
    isError,
    isLoading,
    request
  } = useQuery((data) => api.accounts.verifyId(data))

  useEffect(() => {
    if (isError) {
      toast({
        title: 'Ошибка',
        description: 'Не удалось подтвердить аккаунт',
        status: 'error'
      })
    }
  }, [isError])

  return {
    verifyId: request,
    isLoading
  }
}
