import { useToast } from '@chakra-ui/react'
import useQuery from 'hooks/useQuery'
import { api } from 'api'
import { useEffect } from 'react'
import { useFetchFavoriteCompanies } from 'services/useFetchFavoriteCompanies'

export const useUpdateFavoriteState = () => {
  const toast = useToast()
  const {
    isError,
    isLoading,
    response,
    request
  } = useQuery((data) => api.accounts.updateFavoriteStateItem(data))

  const { fetchFavoriteCompanies } = useFetchFavoriteCompanies()

  const updateFavorite = (data) => new Promise(async (resolve, reject) => {
    try {
      await request(data)
      await fetchFavoriteCompanies()
      resolve()
    } catch (e) {
      reject(e)
    }
  })

  useEffect(() => {
    if (isError) {
      toast({
        title: 'Ошибка',
        description: 'Не удалось получить данные',
        status: 'error'
      })
    }
  }, [isError])

  return {
    updateFavorite,
    dataFavorite: response,
    isLoading
  }
}
