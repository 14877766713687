import { Modal } from '../Modal'
import React, { useState } from 'react'
import { Box, Center, Flex, Stack } from '@chakra-ui/react'
import PlayIcon from './play.svg'
import Bg from './bg.jpg'
import ReactPlayer from 'react-player'

export const LandingVideoModal = () => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <Flex
        role="group"
        height="160px"
        borderWidth={3}
        borderColor="white"
        position="relative"
        backgroundColor="black"
        cursor="pointer"
        borderRadius="24px"
        overflow="hidden"
        onClick={() => setIsOpen(true)}
      >
        <Center
          position="absolute"
          left={0}
          bottom={0}
          right={0}
          top={0}
          width="100%"
          height="100%"
          zIndex={1}
          padding={['20px', '40px']}
        >
          <Stack alignItems="center" spacing={['10px', '20px']} direction={['column', 'row']}>
            <Box
              textTransform="uppercase"
              color="white"
              fontWeight="bold"
              fontSize={['1.1rem', '1.3rem']}
              textAlign="center"
            >
              Посмотреть как работает платформа
            </Box>
            <Box
              boxSize="70px"
              backgroundImage={PlayIcon}
              backgroundRepeat="no-repeat"
              backgroundSize="cover"
            />
          </Stack>
        </Center>
        <Flex
          flex={1}
          backgroundImage={Bg}
          backgroundSize="cover"
          backgroundPosition="center"
          transition="0.3s"
          opacity={0.5}
          _groupHover={{
            opacity: 0.8
          }}
        />
      </Flex>
      <Modal
        isOpen={isOpen}
        contentProps={{
          maxW: '700px'
        }}
        onClose={() => setIsOpen(false)}
      >
        <Center pt="20px">
          <ReactPlayer playing controls url={`/video.mp4`} />
        </Center>
      </Modal>
    </>
  )
}
