import { combineReducers } from '@reduxjs/toolkit'
import companiesReducer from './companies'
import ordersReducer from './orders'
import equipmentsReducer from './equipments'

export default combineReducers({
  companies: companiesReducer,
  orders: ordersReducer,
  equipments: equipmentsReducer,
})
