import React, { useMemo } from 'react'
import { LevelSelect } from './components/LevelSelect'
import { find, filter } from 'lodash'

const getItem = (data, levelId) => find(data, e => e.levelId === levelId)

export const ClassifierSelects = ({ value = [], onChange, ...props }) => {

  const valueLevelId1 = useMemo(() => getItem(value, 1), [value])
  const valueLevelId2 = useMemo(() => getItem(value, 2), [value])
  const valueLevelId3 = useMemo(() => getItem(value, 3), [value])
  const valueLevelId4 = useMemo(() => getItem(value, 4), [value])

  const fullCount = useMemo(() => {
    return value.reduce((previousValue, item) => (
      item?.id ? previousValue+1 : previousValue
    ), 0)
  }, [value])

  const handleChange = (e) => {
    const _value = filter(value, i => i.levelId < e.levelId)
    const result = [
      ..._value,
      {
        levelId: e?.levelId,
        name: e.option?.label,
        id: e.option?.value
      }
    ]
    onChange && onChange(result)
  }

  return (
    <>
      <LevelSelect
        {...props}
        levelId={1}
        value={valueLevelId1}
        onChange={handleChange}
        label="Вид оборудования"
      />
      <LevelSelect
        {...props}
        levelId={2}
        parentId={valueLevelId1?.id}
        value={valueLevelId2}
        onChange={handleChange}
        isDisabled={fullCount < 1}
        label="Тип оборудования"
      />
      <LevelSelect
        {...props}
        levelId={3}
        parentId={valueLevelId2?.id}
        value={valueLevelId3}
        onChange={handleChange}
        isDisabled={fullCount < 2}
        label="Технологическое назначение"
      />
      <LevelSelect
        {...props}
        levelId={4}
        parentId={valueLevelId3?.id}
        value={valueLevelId4}
        onChange={handleChange}
        isDisabled={fullCount < 3}
        label="Детализация"
      />
    </>
  )
}
