import { Flex, Grid, Stack, Text } from '@chakra-ui/react'
import { Symbol } from '../Symbol'
import React from 'react'

export const ShortGuide = ({ section, code, name }) => {
  const isShowCode = code !== name
  return (
    <Stack spacing={1} justifyContent="center">
      <Grid py="0.46rem" pr="10px" templateColumns="40px 1fr" cursor="pointer">
        <Flex alignItems="center">
          <Symbol>{section}</Symbol>
        </Flex>
        <Stack spacing="6px" pt="1px" justifyContent="center">
          {isShowCode && (
            <Text fontWeight="semibold" fontSize="0.8rem" lineHeight="1.2">
              {code}
            </Text>
          )}
          <Text fontSize="0.8rem" lineHeight="1.2">
            {name}
          </Text>
        </Stack>
      </Grid>
    </Stack>
  )
}
