import { createSlice } from '@reduxjs/toolkit'
import { sortDirectionType, sortFieldType } from 'constants/sortType'

const initialState = {
  regions: [],
  accountId: null,
  states: [],
  industrySet: [
    {
      levelId: 1
    },
    {
      levelId: 2
    },
    {
      levelId: 3
    },
    {
      levelId: 4
    }
  ],
  fields: [],
  fieldsRequired: [],
  sort: `${sortFieldType.default}:${sortDirectionType.descending}`,
  view: 'list'
}

const {reducer, actions} = createSlice({
  initialState,
  name: 'equipmentsFilter',
  reducers: {
    update: (state, action) => {
      state[action.payload.field] = action.payload.value
    },
    reset: () => initialState,
  }
});

export { actions, reducer };
export default reducer;
