import React from 'react'
import { EntityList } from 'components/EntityList'
import { Snippet } from 'components/Snippet'
import { useOrderList } from './services/useOrderList'
import { Box } from '@chakra-ui/react'
import { useUpdateFavoriteState } from './services/useUpdateFavoriteState'

export const Equipment = () => {
  const { updateFavorite } = useUpdateFavoriteState()
  const {
    dataList,
    isLoading,
    getNextPage,
    hasMoreRows
  } = useOrderList()

  return (
    <Box pb="80px">
      <EntityList
        isLoading={isLoading}
        hasMore={hasMoreRows}
        data={dataList}
        onNext={getNextPage}
        keyField={'id'}
      >
        {(item, index) => (
          <Snippet.Equipment data={item} onFavorite={updateFavorite}/>
        )}
      </EntityList>
    </Box>
  )
}
