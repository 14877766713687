import axios from 'axios'

const ENTITY = 'supportRequests'

export const supportRequests = Object.freeze({
  async fetchList(data) {
    const response = await axios.post(`/${ENTITY}/list`, data)
    return response.data
  },
  async createItem(data) {
    const response = await axios.post(`/${ENTITY}`, data)
    return response.data
  },
  async fetchItem(id) {
    const response = await axios.get(`/${ENTITY}/${id}`)
    return response.data
  },
  async updateStatusItem(id, data) {
    const response = await axios.put(`/${ENTITY}/${id}`, data)
    return response.data
  },
})
