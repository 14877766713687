import { InputSearchDropdown } from '../InputSearchDropdown'
import { useEffect, useState } from 'react'
import { DaData } from '../DaData'
import { MapDraggable } from '../MapDraggable'
import { Box } from '@chakra-ui/react'

export const GeoAddress = ({ onChange, value, label, isHideMap, isInvalid, isDraggable = true }) => {
  const [searchValue, setSearchValue] = useState(value?.displayString)

  const [viewState, setViewState] = useState({
    longitude: 44.329700,
    latitude: 56.231708,
    zoom: 6
  })

  const [marker, setMarker] = useState({
    longitude: 44.329700,
    latitude: 56.231708,
  })

  const [eventsMarker, setEventsMarker] = useState({})

  const handleInputBlur = (e) => {
    if (e?.data) {
      onChange({
        displayString: e.value ?? '',
        longitude: Number(e.data.geo_lon) ?? 44.329700,
        latitude: Number(e.data.geo_lat) ?? 56.231708,
        region: e.data.region_with_type ?? '',
        city: e.data.city ?? '',
        area: e.data.area ?? '',
        street: e.data.street ?? '',
        house: e.data.house ?? '',
        flat: e.data.flat ?? '',
        postalCode: e.data.postal_code ?? '',
        regionKLADR: e.data['region_kladr_id'],
        countryCode: e.data['country_iso_code']
      })
      return
    }
    onChange({ ...value })
  }

  const handleMarkerDragEnd = (e) => {
    onChange({
      ...value,
      longitude: e.longitude,
      latitude: e.latitude,
    })
  }

  const isShowMarker = value?.displayString !== ''

  useEffect(() => {
    setViewState(e => ({
      ...e,
      longitude: value.longitude,
      latitude: value.latitude,
    }))
    setMarker({
      longitude: value.longitude,
      latitude: value.latitude,
    })
    setSearchValue(value?.displayString)
  }, [value])

  return (
    <>
      <DaData>
        {(data, isLoading, onUpdateQuery) => (
          <InputSearchDropdown
            name="address"
            label={label ?? 'Адрес'}
            isInvalid={isInvalid}
            placeholder="Введите адрес"
            onUpdateQuery={onUpdateQuery}
            resultData={data}
            isLoading={isLoading}
            inputValue={searchValue}
            onChangeInput={(e) => setSearchValue(e)}
            onBlurFormControl={handleInputBlur}
          />
        )}
      </DaData>
      {!isHideMap && (
        <>
          <Box height="280px" width="100%">
            <MapDraggable
              viewState={viewState}
              setViewState={setViewState}
              eventsMarker={eventsMarker}
              setEventsMarker={setEventsMarker}
              onMarkerDragEnd={handleMarkerDragEnd}
              setMarker={setMarker}
              isDraggable={isDraggable}
              {...isShowMarker && {
                marker
              }}
            />
          </Box>
          <Box fontSize="0.8rem" opacity="0.8">
            {`Longitude: ${value?.longitude}, Latitude: ${value?.latitude}`}
          </Box>
        </>
      )}
    </>
  )
}
