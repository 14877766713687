import React, { useMemo, useState } from 'react'
import {
  Box,
  Button,
  Modal, ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay, Text,
  useDisclosure, useToast
} from '@chakra-ui/react'
import { DropZone } from 'components/DropZone'
import { Field, Formik } from 'formik'
import { TextAreaForm } from 'components/TextAreaForm'
import { Form } from 'components/Form'
import { RadioGroupForm } from 'components/RadioGroupForm'
import * as Yup from 'yup';
import { BlockSection } from 'components/BlockSection'
import { useUploadFile, useSupportRequests } from './services'
import { InputForm } from 'components/InputForm'
import { projectType } from 'constants/projectType'

const formSchema = Yup.object().shape({
  name: Yup.string().required('Заполните поле'),
  description: Yup.string().required('Заполните поле')
})

export const SupportRequestForm = ({ project = projectType.orders }) => {
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [fileState, setFileState] = useState(null)
  const { uploadFile, isLoadingUploadFile } = useUploadFile()
  const { createSupportRequest, isLoadingSupportRequest } = useSupportRequests()
  const isLoading = isLoadingUploadFile || isLoadingSupportRequest;

  const handleClose = () => {
    setFileState(null)
    onClose()
  }

  const options = useMemo(() => {
    if (project === projectType.orders) {
      return [
        {
          label: 'Конструкторская документация',
          value: '1'
        },
        {
          label: 'Формирование заявки',
          value: '2'
        }
      ]
    }
    return [
      {
        label: 'Формирование лота',
        value: '3'
      },
      {
        label: 'Помощь с документацией',
        value: '4'
      }
    ]
  }, [project])

  const handleForm = async (values) => {
    try {
      let uploadResult
      const fileDataRequest = {
        name: 'Файл для заявки',
        description: values.description,
        file: fileState.uri,
        fileName: fileState.name,
        fileSize: fileState.size,
        type: fileState.type
      }
      uploadResult = await uploadFile(fileDataRequest)
      const dataRequest = {
        ...values,
        typeId: Number(values.typeId),
        files: [
          { fileId: uploadResult.id }
        ]
      }
      await createSupportRequest(dataRequest)
      handleClose()
    } catch (e) {
      console.log(e)
    }

  }

  const handleUpload = (acceptedFiles) => {
    const uploadFile = acceptedFiles[0]
    const reader = new FileReader()
    reader.readAsDataURL(uploadFile)
    reader.onloadend = () => {
      setFileState({
        name: uploadFile.name,
        type: uploadFile.type,
        size: uploadFile.size,
        uri: reader.result,
      })
    }
  }

  return (
    <>
      <Button
        variant="outline"
        colorScheme="green"
        size="sm"
        onClick={onOpen}
      >
        Запросить
      </Button>
      <Modal isOpen={isOpen} onClose={handleClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Помощь в формировании заявки</ModalHeader>
          <ModalCloseButton mt={1} />
          <ModalBody>
            <Box mb="30px">
              <Text>Кратко опишите суть вашего вопроса. В ближайщее время с вами свяжется специалист платформы</Text>
            </Box>
            <Formik
              enableReinitialize
              validationSchema={formSchema}
              initialValues={{
                name: '',
                description: '',
                typeId: '1'
              }}
              onSubmit={handleForm}
            >
              {(props) => (
                <Form onSubmit={props.handleSubmit}>
                  <BlockSection>
                    <DropZone
                      maxFiles={1}
                      maxSize={4194304}
                      height="200px"
                      multiple={false}
                      uploadedFile={fileState}
                      onUpload={handleUpload}
                      onError={() => {
                        toast({
                          description: `Файл слишком большой`,
                          variant: 'solid',
                          status: 'error',
                        })
                      }}
                    />
                    <Field
                      name="typeId"
                      label="Тип обращения"
                      component={RadioGroupForm}
                      isDisabled={isLoading}
                      options={options}
                    />
                    <Field
                      name="name"
                      label="Наименование"
                      component={InputForm}
                      isDisabled={isLoading}
                    />
                    <Field
                      name="description"
                      label="Описание"
                      component={TextAreaForm}
                      isDisabled={isLoading}
                    />
                  </BlockSection>
                  <Box pt="30px" pb="24px">
                    <Button
                      isDisabled={!fileState}
                      type="submit"
                      colorScheme="blue"
                      isLoading={isLoading}
                    >
                      Отправить запрос
                    </Button>
                  </Box>
                </Form>
                )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
