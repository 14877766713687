import axios from 'axios'
import { dataURIToBlob } from '../utils/dataURIToBlob';
import { formDataHeaderConfig } from './accounts';

const ENTITY = 'file'

export const file = Object.freeze({
  async download(id) {
    const response = await axios.get(`/${ENTITY}/download/${id}`)
    return response.data
  },
  async upload({ name, description, file, fileName, type, fileSize}) {
    const data = new FormData()
    const dataBlob = await dataURIToBlob(file)
    data.append('File', dataBlob, fileName)
    data.append('Description', description)
    data.append('Name', name)
    data.append('FileName', fileName)
    data.append('FileSize', fileSize)
    data.append('ContentType', type)
    const response = await axios.post(`/${ENTITY}/upload`, data, formDataHeaderConfig)
    return response.data
  },
  async deleteItem(id) {
    const response = await axios.delete(`/${ENTITY}/${id}`)
    return response.data
  },
})
