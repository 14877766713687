import React from 'react'
import { ItemColumns } from 'components/ItemColumns'
import { Edit } from './Edit'
import { Show } from './Show'
import { Box, Button, Flex, Heading, Stack, Text } from '@chakra-ui/react'
import { useAccount } from 'hooks/useAccount'
import { useUpdateProfile } from './services'
import { useHistory } from 'react-router';

export const VendorQuestionnaire = ({ isEdit, setIsEdit, id }) => {
  const history = useHistory()
  const { dataAccount, getAccount, isLoading } = useAccount()
  const { update, isLoadingUpdate } = useUpdateProfile()

  const handleCancel = () => {
    if (id) {
      history.goBack()
      return
    }
    window.scrollTo(0, 0)
    setIsEdit(false)
  }

  const handleSwitchVendorsData = async () => {
    await update({ ...dataAccount, isSupplier: true })
    await getAccount()
  }

  if (!dataAccount?.isSupplier) {
    return (
      <Flex alignItems="center" justifyContent="center" height="200px">
        <Stack maxW="400px" spacing={3} textAlign="center" lineHeight="20px">
          <Heading fontSize="1.5rem">Анкета отключена</Heading>
          <Text>В профиле отключена анкета исполнителя, для редактирования необходимо ее включить</Text>
          <Box pt="16px">
            <Button
              colorScheme="blue"
              onClick={handleSwitchVendorsData}
              isLoading={isLoadingUpdate || isLoading}
            >Включить</Button>
          </Box>
        </Stack>
      </Flex>
    )
  }

  return (
    <ItemColumns>
      <Box>
        {isEdit ? <Edit onCancel={handleCancel} id={id}/> : <Show/>}
      </Box>
      <Box>
        {!isEdit && (
          <Flex justifyContent="flex-end">
            <Button onClick={() => setIsEdit(e => !e)}>Редактировать</Button>
          </Flex>
        )}
      </Box>
    </ItemColumns>
  )
}
