export const supportRequestType = {
  applicationFormation: 'ApplicationFormation',
  designDocumentation: 'DesignDocumentation',
  lotFormation: 'LotFormation',
  documentationHelp: 'DocumentationHelp'
}

export const supportRequestLabel = {
  [supportRequestType.applicationFormation]: 'Формирование заявки',
  [supportRequestType.designDocumentation]: 'Конструкторская документация',
  [supportRequestType.lotFormation]: 'Формирование лота',
  [supportRequestType.documentationHelp]: 'Помощь с документацией'
}
