import { PageHeading } from '../../../components/PageHeading'
import React, { useEffect, useState } from 'react'
import { Container } from 'components/Container'
import { MarkdownEditor } from 'components/MarkdownEditor'
import { useCreateArticle } from '../services/useCreateArticle'
import { Button, Input, Stack, Textarea, useToast } from '@chakra-ui/react'
import { BackButton } from 'components/BackButton'
import { useParams } from 'react-router-dom'
import { useUpdateArticle } from '../services/useUpdateArticle'
import { useFetchArticle } from '../services/useFetchArticle'
import { isEmpty } from 'lodash'
import { useHistory } from 'react-router'
import { Helmet } from 'react-helmet'
import { ScrollBox } from 'components/ScrollBox'

export const Edit = () => {
  const toast = useToast()
  const { id } = useParams()
  const history = useHistory()
  const isNew = !id
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [content, setContent] = useState('')

  const { data, fetchArticle, isLoadingFetchArticle } = useFetchArticle(id)
  const { createArticle, isLoadingCreateArticle } = useCreateArticle()
  const { updateArticle, isLoadingUpdateArticle } = useUpdateArticle()
  const isLoading = isLoadingCreateArticle || isLoadingUpdateArticle || isLoadingFetchArticle

  const handleChange = async () => {
   try {
     const dataRequest = {
       ...id && { id },
       title,
       description,
       content
     }
     if (isEmpty(title) || title === '') {
       toast({
         description: 'Введите наименование статьи',
         status: 'warning',
       })
       return
     }
     if (isEmpty(description) || description === '') {
       toast({
         description: 'Введите описание статьи',
         status: 'warning',
       })
       return
     }
     isNew ? await createArticle(dataRequest) : await updateArticle(dataRequest)
     history.goBack()
   } catch (e) {}
  }

  useEffect(() => {
    if (id) {
      fetchArticle(id)
    }
  }, [])

  useEffect(() => {
    if (!isEmpty(data)) {
      setContent(data?.content)
      setTitle(data?.title)
      setDescription(data?.description)
    }
  }, [data])

  return (
    <>
      <Helmet>
        <title>{isNew ? 'Создание статьи' : data?.title ?? ''} – Сделаем в России</title>
      </Helmet>
      <Container>
        <ScrollBox>
          <PageHeading
            renderBack={<BackButton/>}
            renderAction={
              <Button
                isLoading={isLoading}
                colorScheme="blue"
                onClick={handleChange}
              >
                {isNew ? `Создать` : 'Сохранить'}
              </Button>
            }
          >
            {isNew ? 'Создание' : 'Редактирование'}
          </PageHeading>
          <Stack spacing="20px">
            <Input
              value={title}
              onChange={e => setTitle(e.target.value)}
              placeholder="Введите наименование статьи"
            />
            <Textarea
              value={description}
              onChange={e => setDescription(e.target.value)}
              placeholder="Введите описание статьи"
            />
            <MarkdownEditor
              value={content}
              onChange={e => setContent(e)}
            />
          </Stack>
        </ScrollBox>
      </Container>
    </>
  )
}
