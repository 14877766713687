import React, { useMemo } from 'react'
import { FormControl, Grid } from '@chakra-ui/react'
import { Input } from 'components/Input'
import { FormLabel } from 'components/FormLabel'
import { isEmpty } from 'lodash'
import isNumber from 'is-number'

export const InputRange = ({ name, label, value, placeholder, onChange, onBlur }) => {

  const _value = useMemo(() => {
    const parsed = value?.split(',')
    if (!isEmpty(parsed)) {
      return { from: parsed[0] ?? '', to: parsed[1] ?? '' }
    }
    return { from: '', to: '' }
  }, [value])

  const handleInput = (field, e) => {
    const data = {
      ..._value,
      [field]: e
    }
    onChange && onChange(`${data?.from},${data.to}`)
  }

  const handleBlur = () => {
    if (isNumber(Number(_value?.from || _value.to))) {
      onBlur && onBlur(`${_value?.from},${_value.to}`)
      return
    }
    onChange && onChange(``)
  }

  return (
    <FormControl>
      {(label || placeholder) && (
        <FormLabel name={name}>
          {label || placeholder}
        </FormLabel>
      )}
      <Grid id={name} templateColumns="0.5fr 0.5fr" gap="10px">
        <Input
          placeholder="От"
          value={_value?.from}
          onChange={e => handleInput('from', e.target.value)}
          onBlur={handleBlur}
        />
        <Input
          placeholder="До"
          value={_value?.to}
          onChange={e => handleInput('to', e.target.value)}
          onBlur={handleBlur}
        />
      </Grid>
    </FormControl>
  )
}
