import useQuery from 'hooks/useQuery'
import { api } from 'api'
import { useEffect } from 'react';
import { useToast } from '@chakra-ui/react'

export const useDeleteFile = () => {
  const toast = useToast()
  const {
    isError,
    isLoading,
    isSuccess,
    error,
    request
  } = useQuery((data) => api.file.deleteItem(data))

  useEffect(() => {
    if (isError) {
      toast({
        title: 'Ошибка',
        description: error.response?.data?.ErrorMessage,
        status: 'error',
      })
    }
  }, [isError])

  return ({
    isLoadingDeleteFile: isLoading,
    isSuccessDeleteFile: isSuccess,
    deleteFile: request
  })
}
