import React from 'react'
import { Alert, AlertDescription, AlertIcon } from '@chakra-ui/react'

export const ErrorFallback = ({error}) => {
  return (
    <Alert status="error" my="20px" borderRadius="10px">
      <AlertIcon/>
      <AlertDescription mr={2}>{error.message}</AlertDescription>
    </Alert>
  )
}
