import { useToast } from '@chakra-ui/react';
import useQuery from '../../../hooks/useQuery';
import { api } from '../../../api';
import { useEffect } from 'react';

export const useResetPassword = () => {
  const toast = useToast()
  const {
    isError,
    isLoading,
    isSuccess,
    error,
    request
  } = useQuery((data) => api.accounts.resetPassword(data))

  useEffect(() => {
    if (isError) {
      toast({
        title: 'Ошибка',
        description: error.response?.data?.ErrorMessage,
        status: 'error',
      })
    }
  }, [isError])

  useEffect(() => {
    if (isSuccess) {
      toast({
        title: 'Готово',
        description: 'Новый пароль успешно установлен',
        status: 'success'
      })
    }
  }, [isSuccess])

  return {
    request,
    isLoading
  }
}
