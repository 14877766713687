import React, { useEffect, useState } from 'react'
import { Box, Center, Flex, Icon, Spinner, Stack, useColorModeValue } from '@chakra-ui/react'
import { isEmpty } from 'lodash'
import { BsCardImage } from 'react-icons/bs'
import { useFetchImage } from 'services/useFetchImage'

const Item = ({ data, isActive, onClick }) => {
  const borderColor = useColorModeValue('gray.100', 'gray.700')
  const borderWidth = useColorModeValue(1, 0)
  const boxShadowActive = useColorModeValue('0 0 0 2px #4299E1', '0 0 0 2px #63B3ED')

  const { dataImage } = useFetchImage(data)
  return (
    <Box
      cursor="pointer"
      onClick={() => onClick(data)}
      mr="12px"
      mb="12px"
      boxSize="60px"
      borderRadius="10px"
      backgroundImage={dataImage}
      backgroundRepeat="no-repeat"
      backgroundSize="contain"
      backgroundPosition="center"
      borderWidth={borderWidth}
      borderColor={isActive ? 'transparent' : borderColor}
      boxShadow={isActive ? boxShadowActive : 'none'}
      backgroundColor="white"
      transition="0.3s"
      _hover={{
        opacity: 0.8
      }}
    />
  )
}

export const ImageCarouselEntity = ({ data }) => {
  const borderColor = useColorModeValue('gray.100', 'gray.700')
  const borderWidth = useColorModeValue(1, 0)

  const { dataImage, getImage, isLoadingFetchImage } = useFetchImage()
  const [active, setActive] = useState(null)

  useEffect(() => {
    if (!isEmpty(data)) {
      setActive(data[0])
    }
  }, [data])

  useEffect(() => {
    if (!isEmpty(active)) {
      getImage(active?.id)
    }
  }, [active])

  return (
    <Stack spacing="20px">
      <Flex
        borderRadius="12px"
        bg="white"
        height="360px"
        backgroundImage={dataImage}
        backgroundRepeat="no-repeat"
        backgroundSize="contain"
        backgroundPosition="center"
        borderColor={borderColor}
        alignItems="center"
        justifyContent="center"
        borderWidth={borderWidth}
      >
        {isEmpty(data) ? (
          <Icon as={BsCardImage} boxSize="80px" color="gray.300" mb={3} />
        ) : (
          isLoadingFetchImage && (
            <Center boxSize="30px" backgroundColor="blackAlpha.500" borderRadius="full">
              <Spinner color="white" size="sm"/>
            </Center>
          )
        )}
      </Flex>
      {!isEmpty(data) && (
        <Flex flexWrap="wrap">
          {data?.map(item => (
            <Item
              data={item}
              isActive={item?.id === active?.id}
              onClick={e => setActive(e)}
            />
          ))}
        </Flex>
      )}
    </Stack>
  )
}
