import React from 'react'
import {
  Box,
  Button,
  Flex,
  HStack,
  Link as ChakraLink,
  Menu, MenuButton, MenuItem, MenuList,
  Text, useBoolean,
  useColorMode,
  useColorModeValue
} from '@chakra-ui/react'
import BgLeft from './images/header-bg-left.png'
import BgRight from './images/header-bg-right.png'
import { Container } from 'components/Container'
import { useHistory } from 'react-router'
import { ChevronDownIcon } from '@chakra-ui/icons'
import { useAuthentication } from 'hooks/useAuthentication'
import { Link } from 'react-router-dom'
import { useAccess } from 'hooks/useAccess'
import { rolesType } from 'constants/rolesType'
import { InterviewModal } from '../../InterviewModal'
import { ContactsModal } from './components/ContactsModal'

const Item = React.forwardRef(({ label, ...props }, ref)  => {
  return (
    <Text
      ref={ref}
      transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
      fontSize="14px"
      cursor="pointer"
      fontWeight="medium"
      color="#ffffff"
      _hover={{
        opacity: '0.8',
        border: 'none'
      }}
      {...props}
    >
      {label}
    </Text>
  )
})

export const NavigationBar = () => {
  const history = useHistory()
  const [isAdmin] = useAccess([rolesType.admin])
  const [isAnalytic] = useAccess([rolesType.analytic])
  const { isAuthenticated } = useAuthentication()
  const [isContactsModal, setIsContactsModal] = useBoolean(false)
  const [isInterviewOpen, setIsInterviewOpen] = useBoolean(false)

  const { colorMode } = useColorMode()
  const isLight = colorMode === 'light'
  const bg = useColorModeValue('gray.100', 'whiteAlpha.50')

  const interviewClose = () => {
    setIsInterviewOpen.off()
  }

  const interviewOpen = () => {
    setIsInterviewOpen.on()
  }

  return (
    <>
      <Flex
        alignItems="center"
        position="relative"
        height="46px"
        px="30px"
        backgroundColor={bg}
        {...isLight && {bgGradient: 'linear(261.58deg, #0069CA 0%, rgba(255, 0, 0, 0.57) 48.42%, #0085FF 98.9%), linear-gradient(0deg, #3570E0, #3570E0)'}}
      >
        <Container
          flexDirection="row"
          position="relative"
          zIndex={1}
          display="flex"
          justifyContent="space-between"
        >
          <HStack spacing={6}>
            <Item as={Link} to="/" label="Главная"/>
            <Menu>
              <MenuButton
                as={Item}
                label="Обратная связь"
              />
              <MenuList
                boxShadow="rgba(15, 15, 15, 0.05) 0px 0px 0px 1px, rgba(15, 15, 15, 0.1) 0px 3px 6px, rgba(15, 15, 15, 0.1) 0px 9px 24px"
              >
                <MenuItem onClick={() => setIsContactsModal.on()}>
                  Техническая поддержка
                </MenuItem>
                <MenuItem onClick={interviewOpen}>
                  Пройти опрос
                </MenuItem>
              </MenuList>
            </Menu>
            {(isAdmin || isAnalytic) && (
              <Item as={Link} to="/analytics/0" label="Аналитика"/>
            )}
            <Item as={ChakraLink} href="https://ит-маркетплейс.рф" label="IT сектор"/>
            <Item as={ChakraLink} isExternal href="/manual.pdf" label="Инструкция"/>
          </HStack>
          <HStack spacing={2}>
            <Menu>
              <MenuButton
                as={Button}
                size="sm"
                rightIcon={<ChevronDownIcon />}
                colorScheme={isAuthenticated ? 'yellowRus' : 'whiteAlpha'}
              >
                Разместить
              </MenuButton>
              <MenuList
                boxShadow="rgba(15, 15, 15, 0.05) 0px 0px 0px 1px, rgba(15, 15, 15, 0.1) 0px 3px 6px, rgba(15, 15, 15, 0.1) 0px 9px 24px"
              >
                <MenuItem
                  onClick={() => history.push('/equipments/card/create')}
                >
                  Оборудование
                </MenuItem>
                <MenuItem
                  onClick={() => history.push('/orders/card/create')}
                >
                  Заявку
                </MenuItem>
              </MenuList>
            </Menu>
          </HStack>
        </Container>
        <Box
          zIndex={0}
          position="absolute"
          left={0}
          top={0}
          backgroundSize="contain"
          width="505px"
          height="46px"
          backgroundImage={BgLeft}
          opacity={isLight ? 1 : 0.6}
        />
        <Box
          zIndex={0}
          position="absolute"
          right={0}
          top={0}
          backgroundSize="contain"
          width="629px"
          height="46px"
          backgroundImage={BgRight}
          opacity={isLight ? 1 : 0.6}
        />
      </Flex>
      <ContactsModal onClose={() => setIsContactsModal.off()} isOpen={isContactsModal}/>
      <InterviewModal onClose={interviewClose} isOpen={isInterviewOpen} />
    </>
  )
}
