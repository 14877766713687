import { Box, Grid, Stack } from '@chakra-ui/react'
import { useFetchUsers } from './services/useFetchUsers'
import { useEffect, useMemo } from 'react'
import { Filter } from './components/Filter'
import { useFilter } from './hooks/useFilter'
import { isEmpty } from 'lodash';
import { AnalyticChart } from '../../components/AnalyticChart'
import { useFetchVisits } from './services/useFetchVisits'
import { useFetchVisitsBounce } from './services/useFetchVisitsBounce'
import { useFetchAvgPageViews } from './services/useFetchAvgPageViews'
import { useFetchVisitsDuration } from './services/useFetchVisitsDuration'
import { useFetchTrafficSources } from './services/useFetchTrafficSources'
import { useFetchDeviceCategory } from './services/useFetchDeviceCategory'

export const YaMetrika = () => {
  const { fetchFetchUsers, dataFetchUsers, isLoadingFetchUsers } = useFetchUsers()
  const { fetchFetchVisits, dataFetchVisits, isLoadingFetchVisits } = useFetchVisits()
  const { fetchFetchVisitsBounce, dataFetchVisitsBounce, isLoadingFetchVisitsBounce } = useFetchVisitsBounce()
  const { fetchFetchAvgPageViews, dataFetchAvgPageViews, isLoadingFetchAvgPageViews } = useFetchAvgPageViews()
  const { fetchFetchVisitsDuration, dataFetchVisitsDuration, isLoadingFetchVisitsDuration } = useFetchVisitsDuration()
  const { fetchTrafficSources, dataTrafficSources, isLoadingTrafficSources } = useFetchTrafficSources()
  const { fetchDeviceCategory, dataDeviceCategory, isLoadingDeviceCategory } = useFetchDeviceCategory()

  const { dataFilter, updateFilter, resetFilter, filterParameters } = useFilter()

  const isLoading = isLoadingFetchUsers || isLoadingFetchVisits || isLoadingFetchVisitsBounce
  || isLoadingFetchAvgPageViews || isLoadingFetchVisitsDuration || isLoadingTrafficSources || isLoadingDeviceCategory

  const data = useMemo(() => {
    return {
      charts: [
        dataFetchUsers,
        dataFetchVisits,
        dataFetchVisitsBounce,
        dataFetchAvgPageViews,
        dataFetchVisitsDuration,
        dataTrafficSources,
        dataDeviceCategory
      ]
    }
  }, [
    dataFetchUsers,
    dataFetchVisits,
    dataFetchVisitsBounce,
    dataFetchAvgPageViews,
    dataFetchVisitsDuration,
    dataTrafficSources,
    dataDeviceCategory
  ])

  useEffect(() => {
    fetchFetchUsers(filterParameters)
    fetchFetchVisits(filterParameters)
    fetchFetchVisitsBounce(filterParameters)
    fetchFetchAvgPageViews(filterParameters)
    fetchFetchVisitsDuration(filterParameters)
    fetchTrafficSources(filterParameters)
    fetchDeviceCategory(filterParameters)
  }, [filterParameters])

  return (
    <Stack spacing="30px">
      <Box>
        <Filter data={dataFilter} update={updateFilter} reset={resetFilter}/>
      </Box>
      <Stack spacing="50px" pb="80px">
        <Stack spacing="20px">
          <Grid
            templateColumns="1fr 1fr"
            templateRows="1fr 1fr"
            gap="34px"
          >
            {!isEmpty(data) && data?.charts?.map((item, index) => (
              <AnalyticChart
                key={index}
                isLoading={isLoading}
                data={item}
                {...item?.type === 'VisitsDuration' && {
                  gridColumn: 'span 2 / auto'
                }}
              />
            ))}
          </Grid>
        </Stack>
      </Stack>
    </Stack>
  )
}
