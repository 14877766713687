import React from 'react'
import { Badge, Box } from '@chakra-ui/react'
import { humanFileSize } from 'utils/humanFileSize'

export const FileSizeBadge = ({ size }) => {
  const fileSize = humanFileSize(size, true)
  return (
    <Box>
      <Badge
        borderRadius="3px"
        px="4px"
        fontSize="0.64rem"
        lineHeight={1.4}
      >{fileSize}</Badge>
    </Box>
  )
}
