import React, { useCallback, useEffect, useState } from 'react'
import {
  Box,
  Button,
  Flex,
  Input,
  InputGroup,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList, MenuOptionGroup, useColorModeValue
} from '@chakra-ui/react'
import { ChevronDownIcon } from '@chakra-ui/icons'
import { useSettings } from 'hooks/useSettings/useSettings'
import { searchAreaInputLabel, searchAreaLabel, searchAreaType } from 'constants/searchAreaType';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom'
import { getUrlParameter } from 'utils/getUrlParameter'
import { isEmpty } from 'lodash'

const urlType = {
  companies: '/companies/',
  orders: '/orders/',
  equipments: '/equipments/'
}

export const InputSearch = ({ ...props }) => {
  const location = useLocation()
  const searchString = location.search ? getUrlParameter('search', location.search) : null
  const [data] = useSettings()
  const searchArea = data.settings.searchArea
  const history = useHistory()
  const [value, setValue] = useState(searchString ?? '')

  const bg = useColorModeValue('#3570E0', 'gray.700')
  const bgInput = useColorModeValue('white', 'gray.700')
  const bgButton = useColorModeValue('#3570E0', 'whiteAlpha.100')
  const bgButtonHover = useColorModeValue('#2256b8', 'gray.600')
  const bgButtonActive = useColorModeValue('#1b489d', 'gray.800')

  const goUrl = (url = '') => {
    if (value !== '') {
      history.push(`${url}?search=${value}`)
      return
    }
    if (searchString) {
      history.push(`${url}?`)
    }
  }

  const handleSearch = () => {
    if (location.pathname === `/${searchArea}`) {
      goUrl()
      return
    }
    goUrl(urlType[searchArea])
  }

  const handleKeyDown = (e) => {
    if (e.which === 13) {
      handleSearch()
      e.preventDefault()
    }
  }

  const handleMenu = useCallback((e) => {
    data.updateSearchArea(e)
  }, [data])

  useEffect(() => {
    if (isEmpty(searchString)) {
      setValue('')
    }
  }, [searchString])

  return (
    <Flex
      padding="2px"
      backgroundColor={bg}
      borderRadius="6px"
      {...props}
    >
      <Flex
        backgroundColor={bg}
        borderRadius="5px"
        flex={1}
      >
        <Menu>
          <MenuButton
            as={Button}
            rightIcon={<ChevronDownIcon />}
            height="auto"
            borderTopLeftRadius="4px"
            borderBottomLeftRadius="4px"
            borderTopRightRadius={0}
            borderBottomRightRadius={0}
            _focus={{
              boxShadow: 'none'
            }}
          >
            {searchAreaLabel[searchArea]}
          </MenuButton>
          <MenuList
            type="radio"
            boxShadow={'rgba(15, 15, 15, 0.05) 0px 0px 0px 1px, rgba(15, 15, 15, 0.1) 0px 3px 6px, rgba(15, 15, 15, 0.1) 0px 9px 24px'}
          >
            <MenuOptionGroup value={searchArea} onChange={handleMenu}>
              <MenuItemOption value={searchAreaType.equipments}>{searchAreaLabel[searchAreaType.equipments]}</MenuItemOption>
              <MenuItemOption value={searchAreaType.orders}>{searchAreaLabel[searchAreaType.orders]}</MenuItemOption>
              <MenuItemOption value={searchAreaType.companies}>{searchAreaLabel[searchAreaType.companies]}</MenuItemOption>
            </MenuOptionGroup>
          </MenuList>
        </Menu>
        <InputGroup flex={1}  bg={bgInput}>
          <Input
            onKeyDown={handleKeyDown}
            value={value}
            onChange={e => setValue(e.target.value)}
            paddingLeft="15px"
            variant="unstyled"
            height="36px"
            placeholder={searchAreaInputLabel[searchArea] ?? 'Поиск'}
          />
        </InputGroup>
      </Flex>
      <Box
        onClick={handleSearch}
        as="button"
        ml="3px"
        height="36px"
        lineHeight="1.2"
        transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
        border={0}
        px="14px"
        borderRadius="4px"
        fontSize="16px"
        fontWeight="semibold"
        bg={bgButton}
        color="#ffffff"
        _hover={{ bg: bgButtonHover }}
        _active={{
          bg: bgButtonActive,
        }}
        _focus={{
          boxShadow:
            '0 0 1px 2px rgba(88, 144, 255, .75), 0 1px 1px rgba(0, 0, 0, .15)',
        }}
      >
        Найти
      </Box>
    </Flex>
  )
}
