import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import { List } from './List'
import { Item } from './Item'
import { ErrorBoundary } from 'components/ErrorBoundary'

export const SupportRequest = () => {
  const match = useRouteMatch()
  return (
    <Switch>
      <Route path={`${match.url}/card/:id`}>
        <ErrorBoundary>
          <Item/>
        </ErrorBoundary>
      </Route>
      <Route path={`${match.url}/:paramTabIndex`}>
        <ErrorBoundary>
          <List/>
        </ErrorBoundary>
      </Route>
    </Switch>
  )
}
