import React from 'react'
import { Badge, Box, Flex, Grid, Stack, Text, useColorModeValue } from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { getDateReadFormat } from 'utils/dateFormatting'
import { supportRequestLabel } from 'constants/supportRequestType'
import {
  supportRequestStatusColorScheme,
  supportRequestStatusLabel
} from 'constants/supportRequestStatusType'

export const SnippetItem = ({ data }) => {
  const bgContainerBorder = useColorModeValue('gray.100', 'gray.700')
  const bgContainerHover = useColorModeValue('gray.50', 'whiteAlpha.100')
  const bgContainerActive = useColorModeValue('gray.100', 'whiteAlpha.50')

  return (
    <Link to={`/support-requests/card/${data.id}`}>
      <Box
        cursor="pointer"
        borderBottomWidth={1}
        borderColor={bgContainerBorder}
        transition="0.3s"
        _hover={{
          zIndex: 3,
          backgroundColor: bgContainerHover
        }}
        _active={{
          backgroundColor: bgContainerActive
        }}
      >
        <Grid templateColumns="1fr 320px 180px" gap={5} padding="18px">
          <Flex flexDirection="column" justifyContent="center">
            <Stack spacing={2}>
              <Text
                fontWeight="semibold"
                fontSize="18px"
                lineHeight={1.2}
              >
                {data.customer}
              </Text>
              <Text
                fontSize="14px"
                lineHeight="18px"
              >
                {data.responsible}
              </Text>
            </Stack>
          </Flex>
          <Stack spacing={1} justifyContent="center">
            <Text>{supportRequestLabel[data?.type]}</Text>
            <Text color="gray.400" fontSize="0.9rem">{getDateReadFormat(data?.createdDateTime)}</Text>
          </Stack>
          <Stack spacing={1} justifyContent="center" alignItems="flex-end">
            <Box pb="4px">
              <Badge
                fontSize="0.8rem"
                colorScheme={supportRequestStatusColorScheme[data?.status]}
                borderRadius={4}
                pt="2px"
                px="6px"
              >
                {supportRequestStatusLabel[data?.status]}
              </Badge>
            </Box>
          </Stack>
        </Grid>
      </Box>
    </Link>
  )
}
