import useQuery from 'hooks/useQuery'
import { api } from 'api'
import { useEntityList } from 'hooks/useEntityList/useEntityList'
import { useFilter } from './useFilter'
import { useMemo } from 'react'
import { useEntityMap } from 'hooks/useEntityMap/useEntityMap'
import { useExportList } from './useExportList'
import { useExportReplies } from './useExportReplies'

export const useEquipmentList = (accountId) => {
  const defaultParameters = useMemo(() => ({ accountId }), [ accountId])
  const { dataFilter, updateFilter, searchParameters, resetFilter, sort } = useFilter(defaultParameters)
  const { request, isLoading } = useQuery((data) => api.equipment.fetchList(data))

  const isListDisabled = dataFilter?.view === 'map'
  const isMapDisabled = dataFilter?.view === 'list'

  const {
    getNextPage,
    hasMoreRows,
    dataList,
    totalCount,
    dataExportRequest
  } = useEntityList({ request, searchParameters, sort, isDisabled: isListDisabled })

  const { dataMap, mapTotalCount } = useEntityMap({
    request, searchParameters, isDisabled: isMapDisabled
  })

  const { exportFile, isLoading: isLoadingExportFile } = useExportList(dataExportRequest)
  const { exportRepliesFile, isLoading: isLoadingExportRepliesFile} = useExportReplies(dataExportRequest)

  return {
    getNextPage,
    isLoading,
    hasMoreRows,
    totalCount: isLoading ? null : isListDisabled ? mapTotalCount : totalCount,
    dataList,
    dataMap,
    dataFilter,
    updateFilter,
    resetFilter,
    exportFile,
    exportRepliesFile,
    isLoadingExportFile,
    isLoadingExportRepliesFile
  }
}
