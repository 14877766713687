export const equipmentAvailabilityType = {
  alwaysFree: 'AlwaysFree',
  freeByDeal: 'FreeByDeal',
  scheduled: 'Scheduled'
}

export const equipmentAvailabilityLabel = {
  [equipmentAvailabilityType.alwaysFree]: 'Свободно всегда',
  [equipmentAvailabilityType.freeByDeal]: 'Свободно по договоренности',
  [equipmentAvailabilityType.scheduled]: 'Выбрать график'
}
