import { InputForm } from 'components/InputForm'
import { Field } from 'formik'
import React, { useMemo } from 'react'
import { fieldTypeId } from 'constants/fieldTypeId'
import { SelectForm } from 'components/SelectForm'
import { Box, Checkbox, Text } from '@chakra-ui/react'
import { find, filter, isEmpty } from 'lodash'
import { InputNumberForm } from 'components/InputNumberForm'

const ItemField = React.memo(({ item, name, index, machineClassifierId }) => {
  if (item?.machineClassifierId !== machineClassifierId) {
    return <></>
  }
  if (item?.fieldTypeId === fieldTypeId.text) {
    return (
      <Field
        name={`${name}.${index}.fieldValue`}
        label={item?.name}
        component={InputForm}
      />
    )
  }
  if (item?.fieldTypeId === fieldTypeId.number) {
    return (
      <Field
        name={`${name}.${index}.fieldValue`}
        label={item?.name}
        component={InputNumberForm}
      />
    )
  }
  if (item?.fieldTypeId === fieldTypeId.list) {
    return (
      <Field
        placeholder="Выберите из списка"
        name={`${name}.${index}.fieldValue`}
        label={item?.name}
        component={SelectForm}
        options={item?.values?.map(i => ({ label: i, value: i }))}
        isClearable
      />
    )
  }
  if (item?.fieldTypeId === fieldTypeId.bool) {
    return (
      <Field
        name={`${name}.${index}.fieldValue`}
      >
        {({ field, form }) => {
          const handleCheckbox = (e) => {
            form.setFieldValue(field.name, e.target.checked ? 'true' : 'false')
          }
          return (
            <Checkbox
              isChecked={field.value === 'true'}
              onChange={handleCheckbox}
            >
              <Box>{item?.name}</Box>
            </Checkbox>
          )
        }}
      </Field>
    )
  }
  return null
})

export const FieldsForm = ({ name, form }) => {
  const industrySet = form.values.industrySet
  const valueLevelId1 = useMemo(() => (
    find(industrySet, e => e.levelId === 1)
  ), [industrySet])
  const machineClassifierId = valueLevelId1?.id
  const rows = form?.values[name]

  const _rows = useMemo(() => (
    filter(rows, e => machineClassifierId === e?.machineClassifierId)
  ), [rows, machineClassifierId])

  if (isEmpty(_rows)) {
    return (
      <Text color="gray.300">Нет полей для заполнения</Text>
    )
  }

  return (
   <>
     {rows?.map((item, index) => {
       return (
         <ItemField
           key={index}
           item={item}
           name={name}
           index={index}
           machineClassifierId={machineClassifierId}
         />
       )
     })}
   </>
  )
}
