import useQuery from 'hooks/useQuery'
import { api } from 'api'

export const useAccount = () => {
  const {
    isLoading,
    response,
    request
  } = useQuery((id) => api.accounts.fetchItem(id))


  return {
    isLoading,
    fetchAccount: request,
    dataAccount: response
  }
}
