import React, { useState } from 'react'
import { Flex } from '@chakra-ui/react'
import { SegmentControl } from './components/SegmentControl'
import { segmentControlOptions, segmentControlType } from './constants/segmentControl'
import { LoginForm } from '../../../LoginForm'
import { RegistrationForm } from '../../../RegistrationForm'
import { CardWrapper } from 'components/CardWrapper'
import { getUrlParameter } from '../../../../utils/getUrlParameter'
import { useLocation } from 'react-router-dom'

export const Login = () => {
  const location = useLocation()
  const segment = location.search ? getUrlParameter('segment', location.search) : null

  const init = segment === segmentControlType.registration ? segmentControlType.registration : segmentControlType.enter

  const [activeSegment, setActiveSegment] = useState(init)
  return (
    <CardWrapper
      maxW="500px"
      width="100%"
      overflow="initial"
    >
      <SegmentControl
        options={segmentControlOptions}
        value={activeSegment}
        onChange={setActiveSegment}
      />
      <Flex pt="28px" pb="30px" px="40px" justifyContent="center" flexDirection="column">
        {activeSegment === segmentControlType.enter && (
          <LoginForm />
        )}
        {activeSegment === segmentControlType.registration && (
          <RegistrationForm />
        )}
      </Flex>
    </CardWrapper>
  )
}
