import useQuery from 'hooks/useQuery'
import { api } from 'api'

export const useAccount = () => {
  const {
    isSuccess,
    isLoading,
    response,
    isError,
    request
  } = useQuery((id) => api.accounts.fetchItem(id))

  return {
    request,
    isLoading,
    isSuccess,
    isError,
    data: response
  }
}
