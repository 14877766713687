import React, { useEffect } from 'react'
import { Box, Button, Flex, useDisclosure } from '@chakra-ui/react'
import { FormLabel } from '../FormLabel'
import { ImageCropper } from '../ImageCropper'
import empty from './empty-image.svg'
import { useUploadImage } from 'services/useUploadImage'
import { useFetchImage } from 'services/useFetchImage'
import { isEmpty } from 'lodash'

export const ImageUploader = (
  {
    label,
    value,
    isCropRequired,
    colorScheme = 'blue',
    onChange,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { uploadFile, isLoadingUploadFile } = useUploadImage()
  const { getImage, dataImage, isLoadingFetchImage } = useFetchImage()

  const isLoading = isLoadingUploadFile || isLoadingFetchImage

  const handleChange = async (e) => {
    try {
      const dataRequest = {
        name: e.name,
        description: 'Image',
        file: e.uri,
        fileName: e.name,
        fileSize: e.size,
        type: e.type
      }
      const result = await uploadFile(dataRequest)
      onClose()
      onChange(result.id)
    } catch (e) {}
  }

  useEffect(() => {
    if (value) {
      getImage(value.id)
    }
  }, [value])

  return (
    <>
      <Box>
        {label && <FormLabel>{label}</FormLabel>}
        <Flex alignItems="center" borderRadius={6} borderWidth={1} padding="16px 20px">
          <Flex
            width="100px"
            height="100px"
            name="F"
            marginRight={6}
            borderRadius="8px"
            overflow="hidden"
            backgroundImage={empty}
          >
            {!isEmpty(dataImage) && (
              <Flex
                flex={1}
                backgroundImage={dataImage}
                backgroundSize="contain"
                backgroundRepeat="no-repeat"
                backgroundPosition="center"
              />
            )}
          </Flex>
          <Button variant="outline" onClick={onOpen} isLoading={isLoading}>
            Загрузить
          </Button>
        </Flex>
      </Box>
      <ImageCropper
        colorScheme={colorScheme}
        isOpen={isOpen}
        onClose={onClose}
        isCropRequired={isCropRequired}
        onChange={handleChange}
        optionsCropper={{
          aspectRatio: 1,
          maxCropBoxHeight: 1000,
          maxCropBoxWidth: 1000,
          minCropBoxHeight: 260,
          minCropBoxWidth: 260,
        }}
        optionsCompress={{
          maxWidth: 1000,
          maxHeight: 1000,
        }}
        optionsCroppedCanvas={{
          maxWidth: 1000,
          maxHeight: 1000,
        }}
      />
    </>
  )
}
