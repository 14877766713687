import React from 'react'
import { Flex } from '@chakra-ui/react'

export const Container = ({ children, ...props }) => {
  return (
    <Flex
      flexDirection="column"
      ml="auto"
      mr="auto"
      maxW="2200px"
      minW="1400px"
      width="100%"
      flex={1}
      {...props}
    >
      {children}
    </Flex>
  )
}
