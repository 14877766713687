import React from 'react'
import { Flex, Icon, Text, useColorModeValue } from '@chakra-ui/react'

const sizeType = {
  md: {
    icon: '0.94rem',
    label: '0.84rem'
  },
  lg: {
    icon: '1rem',
    label: '0.9rem'
  },
}

export const ItemIconOptions = React.forwardRef(({ icon, size = 'md', children, ...rest }, ref) => {
  const color = useColorModeValue('gray.400', 'gray.500')
  return (
    <Flex alignItems="center" ref={ref} {...rest}>
      <Icon as={icon} color={color} fontSize={sizeType[size].icon} />
      <Text fontSize={sizeType[size].label} ml={1} color={color}>{children}</Text>
    </Flex>
  )
})
