import React, { useState } from 'react'
import {
  Badge,
  Box,
  Flex,
  Grid,
  HStack,
  Stack,
  Text,
  Tooltip,
  useColorModeValue,
} from '@chakra-ui/react'
import { isEmpty, truncate } from 'lodash'
import {
  MdDoneAll,
  MdOutlineCalendarToday,
  MdOutlineEditCalendar,
  MdOutlineRemoveRedEye,
} from 'react-icons/md'
import { ItemIconOptions } from 'components/ItemIconOptions'
import { Link } from 'react-router-dom'
import { getDateReadFormat } from 'utils/dateFormatting'
import { statusColorScheme, statusLabel } from 'constants/orderStateType'
import { useAccount } from 'hooks/useAccount'
import { ImageEntity } from 'components/ImageEntity'
import { FavoriteButton } from '../../FavoriteButton'
import { useFetchFavoriteOrders } from 'services/useFetchFavoriteOrders'
import { useAuthentication } from 'hooks/useAuthentication'
import { useAccess } from '../../../hooks/useAccess'
import { rolesType } from '../../../constants/rolesType'
import { ShortGuide } from '../../ShortGuide'

const ItemOptions = ({ label, children }) => {
  const color = useColorModeValue('gray.400', 'gray.500')
  const colorValue = useColorModeValue('black', 'white')
  return (
    <Flex fontSize="14px">
      <Text color={color}>
        {label}:{' '}
        {children ? (
          <Text as="span" color={colorValue}>
            {children}
          </Text>
        ) : (
          <Text color={color} as="span">
            Нет данных
          </Text>
        )}
      </Text>
    </Flex>
  )
}

export const Order = ({ data, onFavorite, isMyOwn, isOpenNewTab }) => {
  const { dataAccount: dataMyAccount } = useAccount()
  const { isAuthenticated } = useAuthentication()

  const bgContainerBorder = useColorModeValue('gray.100', 'gray.700')
  const bgContainerHover = useColorModeValue('gray.50', 'whiteAlpha.100')
  const bgContainerActive = useColorModeValue('gray.100', 'whiteAlpha.50')
  const colorSecondaryText = useColorModeValue('gray.400', 'gray.500')
  const [isAdmin] = useAccess([rolesType.admin])

  const { favoriteOrdersIds } = useFetchFavoriteOrders()
  const isFavorite = !isEmpty(favoriteOrdersIds) && favoriteOrdersIds?.includes(data?.id)
  const [isLoadingFavorite, setIsLoadingFavorite] = useState(false)

  const handleFavoriteClick = async (e) => {
    try {
      e.stopPropagation()
      e.preventDefault()
      setIsLoadingFavorite(true)
      await onFavorite({ id: data?.id, stateId: isFavorite ? 2 : 1 })
    } catch (e) {
    } finally {
      setIsLoadingFavorite(false)
    }
  }

  return (
    <Link to={`/orders/card/${data.id}`} {...(isOpenNewTab && { target: '_blank' })}>
      <Box
        role="group"
        cursor="pointer"
        borderBottomWidth={1}
        borderColor={bgContainerBorder}
        transition="0.3s"
        _hover={{
          zIndex: 3,
          backgroundColor: bgContainerHover,
        }}
        _active={{
          backgroundColor: bgContainerActive,
        }}
      >
        <Grid templateColumns="150px 1fr" gap={5} padding="18px">
          <Flex flexDirection="column" alignItems="center">
            <ImageEntity width="150px" height="150px" data={data?.image} />
            <Flex height="20px" alignItems="center" mt={2}>
              <ItemOptions label="ID">{data?.id}</ItemOptions>
            </Flex>
          </Flex>
          <Flex flexDirection="column" flex={1}>
            <Grid templateColumns="1fr 280px" gap={5} flex={1}>
              <Stack spacing={2}>
                <Text fontWeight="semibold" fontSize="18px" lineHeight={1.2}>
                  {truncate(data.productName, { length: 80 })}
                </Text>
                {data.description !== '' ? (
                  <Text fontSize="14px" lineHeight="18px">
                    {truncate(data.description, { length: 150 })}
                  </Text>
                ) : (
                  <Text fontSize="14px" lineHeight="18px" color={colorSecondaryText}>
                    Нет описания
                  </Text>
                )}
              </Stack>
              <Stack spacing={1}>
                <Box pb="4px">
                  <Badge
                    fontSize="0.72rem"
                    colorScheme={statusColorScheme[data?.state?.name]}
                    borderRadius={4}
                    pt="2px"
                    px="6px"
                  >
                    {statusLabel[data?.state?.name]}
                  </Badge>
                </Box>
                {data?.okpD2 && (
                  <ShortGuide
                    section={data?.okpD2?.section}
                    code={data?.okpD2?.code}
                    name={data?.okpD2?.name}
                  />
                )}
                <ItemOptions label="Документы">{String(data?.files?.length)}</ItemOptions>
                {isAuthenticated && <ItemOptions label="Регион поставки">{data?.region?.name}</ItemOptions>}
              </Stack>
            </Grid>
            <Flex justifyContent="space-between" alignItems="flex-end">
              <HStack height="20px" alignItems="center" spacing={4}>
                <Tooltip label="Создано">
                  <ItemIconOptions icon={MdOutlineCalendarToday}>
                    {getDateReadFormat(data?.createdAt)}
                  </ItemIconOptions>
                </Tooltip>
                {(isAdmin || isMyOwn) ? (
                  <Tooltip label="Изменено">
                    <ItemIconOptions icon={MdOutlineEditCalendar}>
                      {getDateReadFormat(data?.updatedAt)}
                    </ItemIconOptions>
                  </Tooltip>
                ) : (
                  <Tooltip label="Опубликовано">
                    <ItemIconOptions icon={MdOutlineEditCalendar}>
                      {getDateReadFormat(data?.publishedAt)}
                    </ItemIconOptions>
                  </Tooltip>
                )}
                <Tooltip label="Всего откликов">
                  <ItemIconOptions icon={MdDoneAll}>{data?.numberOfReplies}</ItemIconOptions>
                </Tooltip>
                <Tooltip label="Просмотров">
                  <ItemIconOptions icon={MdOutlineRemoveRedEye}>
                    {data?.numberOfViews}
                  </ItemIconOptions>
                </Tooltip>
              </HStack>
              <Box>
                {isAuthenticated && data?.creator?.id !== dataMyAccount.accountId && (
                  <FavoriteButton
                    isLoading={isLoadingFavorite}
                    isActive={isFavorite}
                    onClick={handleFavoriteClick}
                  />
                )}
              </Box>
            </Flex>
          </Flex>
        </Grid>
      </Box>
    </Link>
  )
}
