import React, { useEffect, useState } from 'react'
import { PageHeading } from 'components/PageHeading'
import { ItemColumns } from 'components/ItemColumns'
import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Spinner,
  Stack,
  Text,
  Tooltip,
  useBoolean,
} from '@chakra-ui/react'
import { GridFieldItem } from 'components/GridField'
import { BlockCollection } from 'components/BlockCollection'
import { BlockSection } from 'components/BlockSection'
import { BlockHeading } from 'components/BlockHeading'
import { BlockGrid } from 'components/BlockGrid'
import { useFetchAccount, useFetchVendorsData } from './services'
import { useHistory, useParams } from 'react-router'
import { isEmpty } from 'lodash'
import { FileWidget } from 'components/FileWidget'
import { useFiles } from './services/useFiles'
import { ContactsCompany } from './components/ContactsCompany'
import { getWorkScheduleString } from 'utils/getWorkScheduleString'
import { Entity } from 'components/Entity'
import { useDeleteAccount } from './services/useDeleteAccount'
import { useAccess } from 'hooks/useAccess'
import { rolesType } from 'constants/rolesType'
import { Delete } from './components/Delete'
import { ImageEntity } from 'components/ImageEntity'
import { Link } from 'react-router-dom'
import { getDeclinationString } from 'utils/getDeclination'
import { Container } from 'components/Container'
import { UnauthorizedInfo } from 'components/UnauthorizedInfo'
import { useAuthentication } from 'hooks/useAuthentication'
import { ContentMap } from 'components/ContentMap'
import { useFetchFavoriteCompanies } from '../../../services/useFetchFavoriteCompanies'
import { useUpdateFavoriteState } from '../services/useUpdateFavoriteState'
import { FavoriteButton } from 'components/FavoriteButton'
import { useAccount } from 'hooks/useAccount'
import { ScrollBox } from 'components/ScrollBox'
import { ItemIconOptions } from '../../../components/ItemIconOptions'
import { MdOutlineCalendarToday, MdOutlineRemoveRedEye } from 'react-icons/md'
import { getDateReadFormat } from '../../../utils/dateFormatting'

export const Item = () => {
  const history = useHistory()
  const { id } = useParams()
  const { dataAccount: dataMyAccount } = useAccount()
  const { isAuthenticated } = useAuthentication()
  const { data, isLoading } = useFetchAccount(id)
  const [isAdmin] = useAccess([rolesType.admin])
  const { getVendorsData, vendorData, isLoadingVendorData } = useFetchVendorsData(id)
  const [isOpenDelete, setIsOpenDelete] = useBoolean()
  const { deleteAccount } = useDeleteAccount(id)
  const { dataFiles, isLoadingFiles } = useFiles(id)
  const isSupplier = data?.isSupplier

  const { updateFavorite } = useUpdateFavoriteState()
  const { favoriteCompaniesIds } = useFetchFavoriteCompanies()
  const isFavorite =
    !isEmpty(favoriteCompaniesIds) && favoriteCompaniesIds?.includes(data?.accountId)
  const [isLoadingFavorite, setIsLoadingFavorite] = useState(false)

  const handleFavoriteClick = async (e) => {
    try {
      e.stopPropagation()
      e.preventDefault()
      setIsLoadingFavorite(true)
      await updateFavorite({ id: data?.accountId, stateId: isFavorite ? 2 : 1 })
    } catch (e) {
    } finally {
      setIsLoadingFavorite(false)
    }
  }

  const handleDelete = async () => {
    await deleteAccount()
    setIsOpenDelete.off()
    history.push('/companies')
  }

  useEffect(() => {
    if (!isEmpty(data) && isSupplier) {
      getVendorsData()
    }
  }, [data])

  if (isLoading) {
    return (
      <Flex alignItems="center" justifyContent="center" height="200px">
        <Spinner color="blue.500" />
      </Flex>
    )
  }

  return (
    <Container>
      <ScrollBox>
        <PageHeading isLoading={isLoading} />
        <ItemColumns>
          <Entity isLoading={isLoading} isEmpty={isEmpty(data)}>
            <BlockCollection>
              <HStack spacing="30px">
                <ImageEntity data={data?.logo} />
                <Stack spacing={2}>
                  <Heading fontSize="1.7rem" lineHeight="1.8rem">
                    {data?.contragentName ?? 'Компания'}
                  </Heading>
                  {!isEmpty(data) && (
                    <HStack alignItems="center" spacing={4}>
                      {isAuthenticated ? (
                        <Link to={`/orders/${data?.accountId}`} style={{ width: 'fit-content' }}>
                          <Text
                            color="blue.500"
                            transition="0.3s"
                            width="fit-content"
                            _hover={{
                              opacity: 0.8,
                            }}
                          >
                            {getDeclinationString(data?.publishedOrdersAmount, [
                              'заявка',
                              'заявки',
                              'заявок',
                            ])}
                          </Text>
                        </Link>
                      ) : (
                        <UnauthorizedInfo.Popover>
                          <Text
                            color="blue.500"
                            transition="0.3s"
                            cursor="pointer"
                            width="fit-content"
                            _hover={{
                              opacity: 0.8,
                            }}
                          >
                            Заявок
                          </Text>
                        </UnauthorizedInfo.Popover>
                      )}
                      {isAuthenticated ? (
                        <Link
                          to={`/equipments/${data?.accountId}`}
                          style={{ width: 'fit-content' }}
                        >
                          <Text
                            color="blue.500"
                            transition="0.3s"
                            width="fit-content"
                            _hover={{
                              opacity: 0.8,
                            }}
                          >
                            {getDeclinationString(data?.publishedEquipmentsAmount, [
                              'оборудование',
                              'оборудования',
                              'оборудований',
                            ])}
                          </Text>
                        </Link>
                      ) : (
                        <UnauthorizedInfo.Popover>
                          <Text
                            color="blue.500"
                            transition="0.3s"
                            cursor="pointer"
                            width="fit-content"
                            _hover={{
                              opacity: 0.8,
                            }}
                          >
                            Оборудования
                          </Text>
                        </UnauthorizedInfo.Popover>
                      )}

                      {isAuthenticated && data?.accountId !== dataMyAccount?.accountId && (
                        <Box>
                          <FavoriteButton
                            isLoading={isLoadingFavorite}
                            isActive={isFavorite}
                            onClick={handleFavoriteClick}
                          />
                        </Box>
                      )}
                      <Tooltip label="Просмотров">
                        <ItemIconOptions size="lg" icon={MdOutlineRemoveRedEye}>
                          {data?.numberOfViews}
                        </ItemIconOptions>
                      </Tooltip>
                      {isAdmin && (
                        <HStack alignItems="center" spacing={4}>
                          <Tooltip label="Дата регистрации">
                            <ItemIconOptions size="lg" icon={MdOutlineCalendarToday}>
                              {getDateReadFormat(data?.createdAt)}
                            </ItemIconOptions>
                          </Tooltip>
                          <Tooltip label="Последняя активность">
                            <ItemIconOptions size="lg" icon={MdOutlineCalendarToday}>
                              {data?.lastActivityAt
                                ? getDateReadFormat(data?.lastActivityAt)
                                : 'Нет активности'}
                            </ItemIconOptions>
                          </Tooltip>
                        </HStack>
                      )}
                    </HStack>
                  )}
                </Stack>
              </HStack>
              <BlockSection>
                <BlockHeading>Компания выступает в роли</BlockHeading>
                <Box>
                  <GridFieldItem isReady isLoading={isLoading} label="Заказчик">
                    {data?.isCustomer ? 'Да' : 'Нет'}
                  </GridFieldItem>
                  <GridFieldItem isReady isLoading={isLoading} label="Исполнитель">
                    {data?.isSupplier ? 'Да' : 'Нет'}
                  </GridFieldItem>
                </Box>
              </BlockSection>
              <BlockSection>
                <BlockHeading>Общая информация</BlockHeading>
                <Box>
                  <GridFieldItem isReady isLoading={isLoading} label="Наименование">
                    {data?.contragentName}
                  </GridFieldItem>
                  <GridFieldItem isReady isLoading={false} label="ИНН">
                    {data?.inn}
                  </GridFieldItem>
                  <GridFieldItem isReady isLoading={false} label="Основной вид деятельности">
                    {!isEmpty(data?.mainOKVED) &&
                      `(${data?.mainOKVED?.code}) ${data?.mainOKVED?.name}`}
                  </GridFieldItem>
                  <GridFieldItem isReady isLoading={false} label="Адрес">
                    {data?.address?.displayString}
                  </GridFieldItem>
                </Box>
              </BlockSection>
              {data?.address?.latitude && data?.address?.longitude && (
                <ContentMap
                  longitude={data?.address?.longitude}
                  latitude={data?.address?.latitude}
                />
              )}
              {data?.description && (
                <BlockSection>
                  <BlockHeading>Описание</BlockHeading>
                  <Box style={{ whiteSpace: 'pre-line' }}>{data?.description}</Box>
                </BlockSection>
              )}
              <BlockSection>
                <BlockHeading>Контактные лица</BlockHeading>
                {isAuthenticated ? (
                  <>
                    {data?.contragentContacts?.map((contact) => (
                      <Stack spacing="30px">
                        <Box>
                          <GridFieldItem isReady isLoading={false} fontWeight="bold">
                            {contact?.contactFullName}
                          </GridFieldItem>
                          <GridFieldItem isReady isLoading={false} label="Должность">
                            {contact?.jobTitle}
                          </GridFieldItem>
                          <GridFieldItem isReady isLoading={false} label="Телефон">
                            {contact?.contactPhoneNumber}
                          </GridFieldItem>
                          <GridFieldItem
                            isReady
                            isLoading={false}
                            label="Электронная почта для связи"
                          >
                            {contact?.contactEmail}
                          </GridFieldItem>
                        </Box>
                      </Stack>
                    ))}
                  </>
                ) : (
                  <UnauthorizedInfo.Document />
                )}
              </BlockSection>
              {isSupplier && (
                <>
                  {isLoadingVendorData ? (
                    <Flex alignItems="center" justifyContent="center">
                      <Spinner color="blue.500" size="md" />
                    </Flex>
                  ) : (
                    <>
                      <BlockSection>
                        <BlockHeading>Условия производства</BlockHeading>
                        <Box>
                          <GridFieldItem
                            isReady
                            label="Есть конструкторское бюро"
                            isLoading={false}
                          >
                            {vendorData?.hasDesignBureau ? 'Да' : 'Нет'}
                          </GridFieldItem>
                          <GridFieldItem isReady label="Являюсь производителем" isLoading={false}>
                            {vendorData?.isManufacturer ? 'Да' : 'Нет'}
                          </GridFieldItem>

                          {isAuthenticated ? (
                            <>
                              {data?.address?.displayString && (
                                <GridFieldItem isReady label="Адрес производства" isLoading={false}>
                                  {vendorData?.manufacturingAddress?.displayString}
                                </GridFieldItem>
                              )}
                            </>
                          ) : (
                            <GridFieldItem isReady isLoading={false} label="Адрес производства">
                              <UnauthorizedInfo.Link />
                            </GridFieldItem>
                          )}
                        </Box>
                      </BlockSection>
                      <BlockSection>
                        <BlockHeading>Выпускаемая продукция</BlockHeading>
                        <Box>
                          {vendorData?.manufacturingRange?.map((i) => (
                            <GridFieldItem isReady isLoading={false}>
                              {i.name}
                            </GridFieldItem>
                          ))}
                        </Box>
                      </BlockSection>
                      <BlockSection>
                        <BlockHeading>Дополнительная информация</BlockHeading>
                        <Box>
                          <GridFieldItem isReady label="Часы работы" isLoading={false}>
                            {getWorkScheduleString(vendorData?.workingHours)}
                          </GridFieldItem>
                          <GridFieldItem isReady label="Доставка" isLoading={false}>
                            {vendorData?.hasDelivery ? 'Есть' : 'Нет'}
                          </GridFieldItem>
                        </Box>
                      </BlockSection>
                      {!isEmpty(vendorData?.wareHouses) && (
                        <BlockSection>
                          <BlockHeading>Складские помещения</BlockHeading>
                          <Box>
                            {vendorData?.wareHouses?.map((i) => (
                              <GridFieldItem isReady isLoading={false}>
                                {i.address?.displayString}
                              </GridFieldItem>
                            ))}
                          </Box>
                        </BlockSection>
                      )}
                    </>
                  )}
                </>
              )}
              <BlockSection>
                <BlockHeading isLoading={isLoadingFiles}>Документы компании</BlockHeading>
                <BlockGrid>
                  {isEmpty(dataFiles) ? (
                    <>
                      <Text color="gray.400">Нет документов</Text>
                    </>
                  ) : (
                    dataFiles?.map((file, index) => (
                      <FileWidget key={index} data={file} isHideDelete />
                    ))
                  )}
                </BlockGrid>
              </BlockSection>
              {isSupplier && (
                <>
                  {isLoadingVendorData ? (
                    <Flex alignItems="center" justifyContent="center">
                      <Spinner color="blue.500" size="md" />
                    </Flex>
                  ) : (
                    <BlockSection>
                      <BlockHeading isLoading={isLoadingFiles}>Документы исполнителя</BlockHeading>
                      <BlockGrid>
                        {isEmpty(vendorData?.files) ? (
                          <>
                            <Text color="gray.400">Нет документов</Text>
                          </>
                        ) : (
                          vendorData?.files?.map((file, index) => (
                            <FileWidget key={index} data={file} isHideDelete />
                          ))
                        )}
                      </BlockGrid>
                    </BlockSection>
                  )}
                </>
              )}
              {isAdmin && (
                <BlockSection>
                  <HStack pt="30px" spacing="16px">
                    <Button onClick={() => history.push(`${id}/edit`)}>Редактировать</Button>
                    <Delete
                      isOpen={isOpenDelete}
                      onOpen={() => setIsOpenDelete.on()}
                      onClose={() => setIsOpenDelete.off()}
                      onDelete={handleDelete}
                    />
                  </HStack>
                </BlockSection>
              )}
            </BlockCollection>
            <Box>
              <ContactsCompany
                phone={data?.contragentContactFirstOrDefault?.contactPhoneNumber}
                email={data?.contragentContactFirstOrDefault?.contactEmail}
              />
            </Box>
          </Entity>
        </ItemColumns>
      </ScrollBox>
    </Container>
  )
}
