import React from 'react'
import { Box, Flex, Heading, Spinner, Stack, Text } from '@chakra-ui/react'
import { Helmet } from 'react-helmet'

export const PageHeading = (
  { renderBack, renderLeft, count = 0, isLoading, renderAction, children }
) => {

  return (
    <>
      <Helmet>
        <title>{children} – Сделаем в России</title>
      </Helmet>
      <Flex pb="30px" alignItems="flex-end" justifyContent="space-between">
        <Stack spacing={3} width="100%">
          <Box width="fit-content">
            {renderBack && renderBack}
          </Box>
          <Flex flex={1}>
            {renderLeft && renderLeft}
            <Flex alignItems="center" width="100%">
              <Heading fontSize="1.7rem" lineHeight="1.8rem" width="100%">
                {children}
                {count > 0 && (
                  <Text as="sup" ml={2} fontSize="0.8rem" top="-1rem" fontWeight="normal">{count}</Text>
                )}
              </Heading>
              {isLoading && (
                <Spinner ml={3} size="sm" color="blue.500"/>
              )}
            </Flex>
          </Flex>
        </Stack>
        {renderAction && (
          <Box flex="none">{renderAction}</Box>
        )}
      </Flex>
    </>
  )
}
