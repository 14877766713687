import React from 'react'
import { Select } from 'components/Select'
import { analyticPeriodEnum, analyticPeriodLabel } from '../../constants/analyticPeriodEnum'

export const PeriodSelect = ({ value, isShowAll, onChange, ...props }) => {

  const options = [
    {
      label: analyticPeriodLabel[analyticPeriodEnum.day],
      value: analyticPeriodEnum.day
    },
    {
      label: analyticPeriodLabel[analyticPeriodEnum.week],
      value: analyticPeriodEnum.week
    },
    {
      label: analyticPeriodLabel[analyticPeriodEnum.month],
      value: analyticPeriodEnum.month
    },
    {
      label: analyticPeriodLabel[analyticPeriodEnum.year],
      value: analyticPeriodEnum.year
    },
    ...isShowAll ? [{
      label: analyticPeriodLabel[analyticPeriodEnum.all],
      value: analyticPeriodEnum.all
    }] : []
  ]

  const handleChange = (e) => {
    onChange(e)
  }

  return (
    <Select
      options={options}
      placeholder="Период"
      onChange={handleChange}
      value={value}
      {...props}
    />
  )
}
