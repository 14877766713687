import { useCallback, useMemo, useState } from 'react'
import { analyticPeriodEnum } from '../../../constants/analyticPeriodEnum'
import { isEmpty } from 'lodash';

const initialState = {
  role: '',
  okved: {},
  region: '',
  period: analyticPeriodEnum.month
}

export const useFilter = () => {
  const [filter, setFilter] = useState(initialState)

  const filterParameters = useMemo(() => {
    return  {
      ...filter.role !=='' && { role: [ filter.role ] },
      ...!isEmpty(filter.okved) && { okved: [ filter.okved?.code ] },
      ...filter.region !=='' && { region: [ filter.region ] },
      period: filter.period
    }
  }, [filter])

  const update = useCallback(async (field, value) => {
    setFilter(e => ({
      ...e,
      [field]: value
    }))
  }, [setFilter])

  const reset = useCallback(async () => {
    setFilter(initialState)
  }, [setFilter])

  return {
    dataFilter: filter,
    updateFilter: update,
    resetFilter: reset,
    filterParameters,
  };
};
