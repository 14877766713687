import React from 'react'
import { FormLabel as FormLabelChakra, Tooltip } from '@chakra-ui/react'

export const FormLabel = ({ children, name, tooltipLabel, ...props }) => {
  if (tooltipLabel) {
    return (
      <Tooltip
        label={tooltipLabel}
        openDelay={200}
        placement="bottom-start"
        offset={[0, 4]}
        aria-label={tooltipLabel}
        fontSize="0.82rem"
        lineHeight="1.3"
        padding="8px 10px"
      >
        <FormLabelChakra
          cursor="help"
          htmlFor={name}
          fontSize="0.84rem"
          width="fit-content"
          mb="6px"
          {...props}
        >
          {children}
        </FormLabelChakra>
      </Tooltip>
    )
  }
  return (
    <FormLabelChakra
      htmlFor={name}
      fontSize="0.84rem"
      width="100%"
      mb="6px"
      fontWeight="normal"
      {...props}
    >
      {children}
    </FormLabelChakra>
  )
}
