import React from 'react'
import { EntityList } from 'components/EntityList'
import { useParams } from 'react-router-dom'
import { Snippet } from 'components/Snippet'
import { useOrderList } from './services/useOrderList'
import { Box } from '@chakra-ui/react'

export const Equipment = () => {
  const { accountId } = useParams()

  const {
    dataList,
    isLoading,
    getNextPage,
    hasMoreRows
  } = useOrderList(accountId)

  return (
    <Box pb="80px">
      <EntityList
        isLoading={isLoading}
        hasMore={hasMoreRows}
        data={dataList}
        onNext={getNextPage}
        keyField={'id'}
      >
        {(item, index) => (
          <Snippet.Equipment isOpenNewTab data={item}/>
        )}
      </EntityList>
    </Box>
  )
}
