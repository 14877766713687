import React, { useEffect, useMemo } from 'react'
import { FieldsConstructor } from '../FieldsConstructor'
import { FormLabel } from '../FormLabel'
import { Center, Spinner, Stack } from '@chakra-ui/react'
import { filter, isEmpty } from 'lodash'
import { useFetchRequiredFields } from 'services/useFetchRequiredFields'

export const FilterFieldsRequired = ({ value, machineClassifierId, onChange }) => {
  const { fetchRequiredFields, dataRequiredFields, isLoadingFetchRequiredFields } = useFetchRequiredFields()

  const rows = useMemo(() => {
    return dataRequiredFields?.rows?.map(item => ({
      id: item?.id,
      placeholder: item?.name,
      typeId: item?.fieldTypeId,
      options: item?.values,
      machineClassifierId: item?.machineClassifierId
    }))
  }, [dataRequiredFields])

  const rowsFilter = useMemo(() => filter(rows, e => e?.machineClassifierId === machineClassifierId), [rows, machineClassifierId])

  const handleChange = (e) => {
    const values = filter(value, i => i.id !== e?.id)
    onChange([ ...values, {...e} ])
  }

  useEffect(() => {
    fetchRequiredFields()
  }, [])


  if (isLoadingFetchRequiredFields) {
    return (
      <Center height="80px">
        <Spinner color="blue.500" size="sm"/>
      </Center>
    )
  }

  if (!isEmpty(rowsFilter)) {
    return (
      <Stack spacing="12px">
        <FormLabel mb={0}>Основные параметры</FormLabel>
        <FieldsConstructor
          value={value}
          onChange={handleChange}
          data={rowsFilter}
        />
      </Stack>
    )
  }

  return null
}
