import { useFetchClassifiers } from './services/useFetchClassifiers'
import React, { useEffect, useMemo, useState } from 'react'
import { Tree } from './components/Tree'
import { findIndex, isEmpty } from 'lodash'
import { InputSearch } from 'components/InputSearch'
import { Box, HStack, useColorModeValue } from '@chakra-ui/react'
import { Add } from './components/Add'
import { useCreateClassifier } from './services/useCreateClassifier'
import { useUpdateClassifier } from './services/useUpdateClassifier'

export const Classifier = () => {
  const [valueSearch, setValueSearch] = useState('')
  const bgInput = useColorModeValue('white', 'gray.700')

  const { getClassifiers, isLoadingFetch, data } = useFetchClassifiers()
  const { createClassifier } = useCreateClassifier()
  const { updateClassifier } = useUpdateClassifier()

  const searchData = useMemo(() => {
    if (isEmpty(data)) {
      return []
    }
    const search = (data) => {
      if (isEmpty(data)) {
        return []
      }
      return data?.map(item => {
        const name = item?.name?.toLowerCase() ?? ''
        const _valueSearch = valueSearch.toLowerCase() ?? ''
        const isFind = name.includes(_valueSearch)
        const children = search(item?.childrens)
        const isFindChildren = findIndex(children, e => e.isFind || e.isFindChildren)

        return {
          ...item,
          isFind,
          isFindChildren: isFindChildren !== -1,
          childrens: children
        }
      })
    }
    return search(data)
  }, [data, valueSearch])

  const handleCreate = (data) => new Promise(async (resolve, reject) => {
    try {
      await createClassifier(data)
      await getClassifiers()
      resolve()
    } catch (e) {
      reject(e)
    }
  })

  const handleUpdate = (data) => new Promise(async (resolve, reject) => {
    try {
      await updateClassifier(data)
      await getClassifiers()
      resolve()
    } catch (e) {
      reject(e)
    }
  })

  useEffect(() => {
    getClassifiers()
  }, [])

  return (
    <Box pb="80px">
      <HStack
        spacing="12px"
        alignItems="center"
        mb="12px"
      >
        <InputSearch
          delay={400}
          placeholder="Поиск по наименованию"
          name="search"
          value={valueSearch}
          onChange={e => setValueSearch(e)}
          bg={bgInput}
        />
        <Add onCreate={handleCreate}>
          Вид оборудования
        </Add>
      </HStack>
      <Tree
        isSearch={valueSearch !== ''}
        data={searchData}
        isLoading={isLoadingFetch}
        onCreate={handleCreate}
        onUpdate={handleUpdate}
      />
    </Box>
  )
}
