import React from 'react'
import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { TextAreaForm } from 'components/TextAreaForm'
import { Field, Formik } from 'formik'
import { Form } from 'components/Form'
import * as Yup from 'yup'

const formSchema = Yup.object().shape({
  message: Yup.string().required('Required'),
})

export const ReasonForm = ({ isOpen, onClose, isLoading, onChange }) => {
  const handleForm = (values) => {
    onChange && onChange(values)
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Отказ в публикации</ModalHeader>
        <ModalCloseButton mt={1} />
        <ModalBody>
          <Formik
            initialValues={{
              message: '',
            }}
            enableReinitialize={true}
            validationSchema={formSchema}
            onSubmit={handleForm}
          >
            {(props) => (
              <Form onSubmit={props.handleSubmit}>
                <Field
                  autoFocus
                  name="message"
                  label="Причина отказа"
                  component={TextAreaForm}
                />
                <Box mt={6} mb={6}>
                  <Button isLoading={isLoading} type="submit" colorScheme="red" mr={3}>
                    Отказать в публикации
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
