import React from 'react'
import { extendTheme, ChakraProvider } from '@chakra-ui/react'

export const theme = extendTheme({
  styles: {
    global: {
      'html, body': {},
    },
  },
  colors: {
    yellowRus: {
      '50': '#FFF3E5',
      '100': '#FFDFB8',
      '200': '#FFCA8A',
      '300': '#FFB55C',
      '400': '#FFA02E',
      '500': '#FF8B00',
      '600': '#CC6F00',
      '700': '#995300',
      '800': '#663800',
      '900': '#331C00'
    },
    blueRus: {
      '50': '#e3f5ff',
      '100': '#badafa',
      '200': '#8ebcf1',
      '300': '#639ce9',
      '400': '#387ae1',
      '500': '#3571E0',
      '600': '#144f9c',
      '700': '#0c3f71',
      '800': '#032b46',
      '900': '#00121d'
    }
  }
})

export const Theme = ({children}) => {
  return (
    <ChakraProvider theme={theme}>
      {children}
    </ChakraProvider>
  )
}
