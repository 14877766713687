import React from 'react'
import {
  Box,
  Button,
  Collapse,
  Grid,
  Heading,
  Stack,
  Text,
  useColorMode,
  useColorModeValue
} from '@chakra-ui/react'
import { useAuthentication } from 'hooks/useAuthentication'
import { UnauthorizedInfo } from '../../../../../../components/UnauthorizedInfo'

export const ContactsCompany = ({ isLoading, data, onShow, isShow }) => {
  const bg = useColorModeValue('white', 'gray.700')
  const colorSecondaryText = useColorModeValue('gray.400', 'gray.500')
  const { colorMode } = useColorMode()
  const { isAuthenticated } = useAuthentication()

  return (
    <Box
      position="sticky"
      top="20px"
      borderRadius="12px"
      padding="28px"
      backgroundColor={bg}
      {...colorMode === 'light' && {boxShadow: 'rgba(15, 15, 15, 0.1) 0px 3px 6px, rgba(15, 15, 15, 0.1) 0px 9px 24px'}}
    >
      {isAuthenticated ? (
        <Stack spacing="28px">
          <Heading fontSize="1.1rem">Контакты компании</Heading>
          {!isShow && (
            <Button isLoading={isLoading} onClick={onShow} colorScheme="blue" width="100%" size="lg">Показать контакты</Button>
          )}
          <Collapse in={isShow}>
            <Grid templateColumns="0.5fr 1fr" gap="6px">
              <Text color={colorSecondaryText}>ФИО:</Text>
              {data?.contactFullName ? <Text>{data?.contactFullName}</Text> : <Text color={colorSecondaryText}>Нет</Text>}
              <Text color={colorSecondaryText}>Должность:</Text>
              {data?.jobTitle ? <Text>{data?.jobTitle}</Text> : <Text color={colorSecondaryText}>Нет</Text>}
              <Text color={colorSecondaryText}>Телефон:</Text>
              {data?.contactPhoneNumber ? <Text>{data?.contactPhoneNumber}</Text> : <Text color={colorSecondaryText}>Нет</Text>}
              <Text color={colorSecondaryText}>Email:</Text>
              {data?.contactEmail ? <Text>{data?.contactEmail}</Text> : <Text color={colorSecondaryText}>Нет</Text>}
            </Grid>
          </Collapse>
        </Stack>
      ) : (
        <Stack spacing="28px">
          <Heading fontSize="1.1rem">Контакты компании</Heading>
          <UnauthorizedInfo.Block/>
        </Stack>
      )}
    </Box>
  )
}
