import _ from 'lodash'
import { Select } from 'components/Select'
import React from 'react'

export const ManufacturerForm = ({ field, form, options, ...props }) => {
  const value = options?.find(o => o.value === field.value)

  const handleChange = (option) => {
    if (option) {
      form.setFieldValue(field.name, option.value)
    } else {
      form.setFieldValue(field.name, '')
    }
    form.setFieldValue('brand', '')
  }

  return (
    <Select
      isForm
      name={field.name}
      value={value ?? ''}
      onChange={handleChange}
      options={options}
      isInvalid={_.get(form.errors, field.name) && _.get(form.touched, field.name)}
      error={_.get(form.errors, field.name)}
      {...props}
    />
  )
}
