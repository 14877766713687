import React from 'react'
import { ImageUploaderCollection } from '../ImageUploaderCollection';
import { isEmpty } from 'lodash'

export const ImageUploaderCollectionForm = (
  { name, form, push, remove, ...props }
) => {

  const values = form.values[name]

  const handleChange = (id) => {
    push({ id })
  }

  const handleDelete = (index) => {
    remove(index)
  }

  return (
    <ImageUploaderCollection
      label="Изображения"
      values={values}
      onChange={handleChange}
      onDelete={handleDelete}
      {...props}
    />
  )
}
