import { useFetchMachine } from 'services/useFetchMachine'
import React, { useEffect, useMemo } from 'react'
import { Select } from 'components/Select'
import { isEmpty } from 'lodash';

export const IndustrySelect = ({ value, onChange, ...props }) => {
  const { fetchMachine, isLoadingFetchMachine, dataMachine } = useFetchMachine()

  const options = useMemo(() => (
    [
      {
        label: 'Все отрасли',
        value: ''
      },
      ...!isEmpty(dataMachine?.rows) ? dataMachine?.rows?.map(i => ({ label: i.name, value: i.id })) : []
    ]
  ), [dataMachine])

  const handleChange = (e) => {
    onChange(e)
  }

  useEffect(() => {
    fetchMachine({
      level: 1
    })
  }, [])

  return (
    <Select
      options={options}
      isLoading={isLoadingFetchMachine}
      isDisabled={isLoadingFetchMachine}
      placeholder="Вид оборудования"
      onChange={handleChange}
      value={value}
      {...props}
    />
  )
}
