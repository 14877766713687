import React from 'react'
import { Flex, Heading, Spinner } from '@chakra-ui/react'

export const BlockHeading = ({ renderAction, isLoading, children, ...props }) => {
  return (
    <Flex justify="space-between" align="center" pb="4px">
      <Flex alignItems="center">
        <Heading fontSize="1.1rem" {...props}>
          {children}
        </Heading>
        {isLoading && (
          <Spinner color="blue.500" size="sm" ml={3}/>
        )}
      </Flex>
      {renderAction}
    </Flex>
  )
}
