import React from 'react'
import { Box, Center, Flex, Grid, HStack, Text, useColorModeValue } from '@chakra-ui/react'
import { truncate } from 'lodash'
import { DifferenceText } from '../DifferenceText'

export const SnippetItem = ({ data, index }) => {
  const bgContainerBorder = useColorModeValue('gray.100', 'gray.700')

  return (
    <Box
      borderBottomWidth={1}
      borderColor={bgContainerBorder}
      transition="0.3s"
    >
      <Grid templateColumns="30px 1fr 300px" gap={5} padding="12px 20px 12px 12px" alignItems="center">
        <Center>
          <Text
            fontSize="0.8rem"
            lineHeight="18px"
            color="gray.400"
          >
            {index + 1}
          </Text>
        </Center>
        <Flex flexDirection="column" justifyContent="center">
          <Text
            lineHeight={1.2}
          >
            {truncate(data?.name !== '' ? data?.name : 'Нет заголовка', { length: 100 })}
          </Text>
        </Flex>
        <HStack spacing="10px" alignItems="center" justifyContent="flex-end">
          <Text>{data?.value ?? 0}</Text>
          <DifferenceText value={data?.difference ?? 0}/>
        </HStack>
      </Grid>
    </Box>
  )
}
