import React from 'react'
import { getAccess } from 'utils/getAccess'
import { Route } from 'react-router-dom'
import { useAccount } from '../../hooks/useAccount'
import { Login } from './components/Login'
import { LayoutServices } from '../LayoutServices'

export const PrivateRoute = ({ path, hasAnyRoles, children, ...props }) => {
  const { dataAccount } = useAccount()
  const access = getAccess(dataAccount?.roles, hasAnyRoles)
  return (
    <Route path={path} {...props}>
      {access ? children : (
        <LayoutServices>
          <Login/>
        </LayoutServices>
      )}
    </Route>
  )
}
