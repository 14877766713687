import React, { useEffect, useReducer } from 'react'
import { WorkScheduleTimeItem } from './WorkScheduleTimeItem'
import {
  Box,
  Flex,
  Grid,
  Modal, ModalBody,
  ModalCloseButton, ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure, useStyleConfig
} from '@chakra-ui/react'
import { isEmpty } from 'lodash';
import { FormLabel } from '../FormLabel';
import { getWorkScheduleString } from '../../utils/getWorkScheduleString';

export const TYPE_DAYS = [
  'Пн',
  'Вт',
  'Ср',
  'Чт',
  'Пт',
  'Сб',
  'Вс'
]


const initialState = {
  weekdays: [
    [], [], [], [], [], [], []
  ]
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_WEEKDAYS': {
      const newItems = [...state.weekdays]
      newItems[action.index] = action.value
      return {
        ...state,
        weekdays: newItems
      };
    }
    case 'UPDATE_STORE': {
      return {
        ...state,
        ...action.value
      }
    }
    case 'RESET': {
      return {
        ...initialState
      }
    }
    default:
      throw new Error()
  }
}

export const WorkScheduleMemo = (
  {
    value = {},
    label,
    onChange,
    error,
    isInvalid,
    tooltipLabel,
    placeholder,
    ...props }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [state, setState] = useReducer(reducer, isEmpty(value) ? initialState : value)
  const styles = useStyleConfig('Input', { size: 'md'})

  const handleClose = () => {
    onClose()
  }

  const setWeekDays = (value, index) => {
    setState({type: 'UPDATE_WEEKDAYS', value, index});
  }

  useEffect(() => {
    onChange && onChange(state)
  }, [state])

  useEffect(() => {
    setState({type: 'UPDATE_STORE', value});
  }, [value])

  return (
    <>
      <Box>
        {label && (
          <FormLabel tooltipLabel={tooltipLabel}>
            {label}
          </FormLabel>
        )}
        <Flex
          onClick={onOpen}
          {...styles.field}
          alignItems="center"
          minH="40px"
          height="auto"
          cursor="pointer"
          flex={1}
          {...(error && isInvalid && {
            borderColor: '#E53E3E',
            boxShadow: '0 0 0 1px #e53e3e'
          })}
        >
          {isEmpty(value) ? (
            <Text color="gray.400">{placeholder}</Text>
          ) : (
            <Text fontSize="0.93rem" lineHeight="1.2">{getWorkScheduleString(value.weekdays)}</Text>
          )}
        </Flex>
        {props.description && (
          <Box fontSize="0.7rem" color="gray.400" mt="4px">
            {props.description}
          </Box>
        )}
        {error && isInvalid && (
          <Box fontSize="0.7rem" color="red.500" mt="4px">
            {error}
          </Box>
        )}
      </Box>
      <Modal isOpen={isOpen} onClose={handleClose} trapFocus={false}>
        <ModalOverlay />
        <ModalContent minW="640px">
          <ModalHeader>Расписание</ModalHeader>
          <ModalCloseButton mt={1} />
          <ModalBody pb="30px">
            <Grid gridRowGap="8px" mb={2}>
              {state.weekdays.map((item, index) => (
                <WorkScheduleTimeItem
                  key={index}
                  value={item}
                  index={index}
                  onChange={(value, index) => setWeekDays(value, index)}
                  label={TYPE_DAYS[index]}
                />
              ))}
            </Grid>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export const WorkSchedule = React.memo(WorkScheduleMemo);
