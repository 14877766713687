import { Container } from 'components/Container'
import { PageHeading } from 'components/PageHeading'
import { Tab, TabList, TabPanels, Tabs } from '@chakra-ui/react'
import { TabPanel } from '../../components/TabPanel'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { Manufacturer } from './components/Manufacturer'
import { RequiredField } from './components/RequiredField'
import { Field } from './components/Field'
import { Classifier } from './components/Classifier'
import { ScrollBox } from 'components/ScrollBox'

export const Configurator = () => {
  const history = useHistory()
  const { paramTabIndex } = useParams()
  const [tabIndex, setTabIndex] = useState(0)

  const handleTabsChange = (index) => {
    history.replace(`/configurator/${index}`)
  }

  useEffect(() => {
    setTabIndex(Number(paramTabIndex))
  }, [paramTabIndex])

  return (
    <Container>
      <ScrollBox>
        <PageHeading>
          Конфигуратор
        </PageHeading>
        <Tabs onChange={handleTabsChange} index={tabIndex} isLazy>
          <TabList>
            <Tab>Каталогизация</Tab>
            <Tab>Производители и марки</Tab>
            <Tab>Основные параметры</Tab>
            <Tab>Дополнительные признаки</Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              <Classifier/>
            </TabPanel>
            <TabPanel>
              <Manufacturer/>
            </TabPanel>
            <TabPanel>
              <RequiredField/>
            </TabPanel>
            <TabPanel>
              <Field/>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </ScrollBox>
    </Container>
  )
}
