import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useQuery from '../useQuery'
import { api } from 'api'
import { actions as account } from 'store/profile/account'

export const useAccount = () => {
  const dispatch = useDispatch()
  const { data } = useSelector(state => state.profile.account);

  const {
    isSuccess,
    isLoading,
    isIdle,
    request,
    response
  } = useQuery((data) => api.accounts.fetchItem(data))

  useEffect(() => {
    if (isSuccess) {
      dispatch(account.update(response))
    }
  }, [isSuccess])

  return {
    getAccount: request,
    dataAccount: data,
    isIdle,
    isLoading
  }
}
