import useQuery from 'hooks/useQuery'
import { api } from 'api'
import { useEffect } from 'react'
import { actions as favorite } from 'store/favorite/companies'
import { useDispatch, useSelector } from 'react-redux'

export const useFetchFavoriteCompanies = () => {
  const dispatch = useDispatch()
  const favoriteCompaniesIds = useSelector(state => state.favorite.companies.data)

  const dataRequest = {
    searchParameters: {
      IsFavourite: true
    }
  }

  const {
    isSuccess,
    request,
    response,
  } = useQuery(() => api.accounts.fetchList(dataRequest))

  useEffect(() => {
    if (isSuccess) {
      const result = response?.rows?.map(i => i.accountId)
      dispatch(favorite.update(result))
    }
  }, [isSuccess])

  return {
    favoriteCompaniesIds,
    fetchFavoriteCompanies: request
  }
}
