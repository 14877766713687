import { Modal } from 'components/Modal'
import { Form } from 'components/Form'
import { Field, Formik } from 'formik'
import * as Yup from 'yup'
import { InputForm } from 'components/InputForm'
import React, { useState } from 'react'
import { BlockSection } from 'components/BlockSection'
import { Button, Stack } from '@chakra-ui/react'

const formSchema = Yup.object().shape({
  name: Yup.string().required('')
})

export const Add = (
  { onCreate, parentId, header, setHeader, setParentId }
) => {
  const [isLoading, setIsLoading] = useState(false)

  const isOpen = !!parentId

  const onClose = () => {
    setParentId(null)
    setHeader(null)
  }

  const handleForm = async (values) => {
    try {
      setIsLoading(true)
      const dataRequest = {
        ...values,
        ...parentId && {
          parentId
        }
      }
      await onCreate(dataRequest)
      onClose()
    } catch (e) {} finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        header={header ?? 'Вид оборудования'}
      >
        <Formik
          enableReinitialize
          onSubmit={handleForm}
          validationSchema={formSchema}
          initialValues={{
            name: ''
          }}
        >
          {(props) => (
            <Form onSubmit={props.handleSubmit}>
              <Stack spacing="24px">
                <BlockSection>
                  <Field
                    autoFocus
                    name="name"
                    label="Наименование"
                    component={InputForm}
                    isDisabled={isLoading}
                  />
                </BlockSection>
                <BlockSection>
                  <Button
                    width="100%"
                    type="submit"
                    isLoading={isLoading}
                    colorScheme="blue"
                  >
                    Добавить
                  </Button>
                </BlockSection>
              </Stack>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  )
}
