import { Modal } from 'components/Modal'
import { Form } from 'components/Form'
import { Field, Formik } from 'formik'
import * as Yup from 'yup'
import { InputForm } from 'components/InputForm'
import React, { useState } from 'react'
import { BlockSection } from 'components/BlockSection'
import { Button, Stack, useDisclosure } from '@chakra-ui/react'
import { HiPlusCircle } from 'react-icons/hi'
import { SelectForm } from 'components/SelectForm'
import { TextAreaForm } from 'components/TextAreaForm'
import { fieldTypeId, fieldTypeIdLabel } from 'constants/fieldTypeId'

const formSchema = Yup.object().shape({
  name: Yup.string().required('')
})

const getValues = (data) => {
  const _result = data.split('\n')
  return _result.filter(n => n !== '')
}

export const Add = ({ onCreate, isDisabled }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [isLoading, setIsLoading] = useState(false)

  const handleForm = async (values) => {
    try {
      setIsLoading(true)
      const dataRequest = {
        name: values?.name,
        fieldTypeId: values?.fieldTypeId,
        ...values?.fieldTypeId === fieldTypeId.list && {
          values: getValues(values?.values)
        }
      }
      await onCreate(dataRequest)
      onClose()
    } catch (e) {} finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <Button
        isDisabled={isDisabled}
        colorScheme="blue"
        onClick={onOpen}
        width="fit-content"
        flex="none"
        leftIcon={<HiPlusCircle fontSize="1.3rem" />}
      >
        Добавить
      </Button>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        header="Добавление поля"
      >
        <Formik
          enableReinitialize
          onSubmit={handleForm}
          validationSchema={formSchema}
          initialValues={{
            name: '',
            fieldTypeId: fieldTypeId.text,
            values: ''
          }}
        >
          {(props) => (
            <Form onSubmit={props.handleSubmit}>
              <Stack spacing="24px">
                <BlockSection>
                  <Field
                    autoFocus
                    name="name"
                    label="Наименование"
                    component={InputForm}
                    isDisabled={isLoading}
                  />
                  <Field
                    name="fieldTypeId"
                    label="Тип"
                    component={SelectForm}
                    isDisabled={isLoading}
                    options={[
                      {
                        label: fieldTypeIdLabel[fieldTypeId.text],
                        value: fieldTypeId.text
                      },
                      {
                        label: fieldTypeIdLabel[fieldTypeId.number],
                        value: fieldTypeId.number
                      },
                      {
                        label: fieldTypeIdLabel[fieldTypeId.bool],
                        value: fieldTypeId.bool
                      },
                      {
                        label: fieldTypeIdLabel[fieldTypeId.list],
                        value: fieldTypeId.list
                      }
                    ]}
                  />
                  {props.values?.fieldTypeId === fieldTypeId.list && (
                    <Field
                      name="values"
                      label="Значение"
                      minH="240px"
                      component={TextAreaForm}
                      isDisabled={isLoading}
                      placeholder="Каждое значение с новой строки"
                    />
                  )}
                </BlockSection>
                <BlockSection>
                  <Button
                    width="100%"
                    type="submit"
                    isLoading={isLoading}
                    colorScheme="blue"
                  >
                    Добавить
                  </Button>
                </BlockSection>
              </Stack>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  )
}
