import React from 'react'
import { createIcon } from '@chakra-ui/icons'

export const IconFavorite = createIcon({
  displayName: 'Favorite',
  viewBox: '0 0 20 19',
  path: (
    <path
      fill='currentColor'
      d="M9.99912 15.217L4.12161 18.507L5.43411 11.9003L0.488281 7.32699L7.17745 6.53366L9.99912 0.416992L12.8208 6.53366L19.5099 7.32699L14.5641 11.9003L15.8766 18.507L9.99912 15.217ZM9.99912 13.307L13.5383 15.2878L12.7474 11.3103L15.7249 8.55616L11.6974 8.07866L9.99912 4.39616L8.30078 8.07949L4.27328 8.55616L7.25078 11.3103L6.45995 15.2878L9.99912 13.307V13.307Z"
    />
  ),
})
