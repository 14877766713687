export const analyticPeriodEnum = {
  day: 'Day',
  week: 'Week',
  month: 'Month',
  year: 'Year',
  all: 'All'
}

export const analyticPeriodLabel = {
  [analyticPeriodEnum.day]: 'День',
  [analyticPeriodEnum.week]: 'Неделя',
  [analyticPeriodEnum.month]: 'Месяц',
  [analyticPeriodEnum.year]: 'Год',
  [analyticPeriodEnum.all]: 'Все время'
}

