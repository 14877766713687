export const TYPE_DAYS = [
  'Пн',
  'Вт',
  'Ср',
  'Чт',
  'Пт',
  'Сб',
  'Вс'
]

const twoDigits = (num) => {
  return ('0' + num).slice(-2);
}

export const getWorkScheduleString = (data = []) => {
  let days = []
  let result = ''
  data.forEach(item => {
    if (item.length === 0) {
      days.push('Выходной')
      return
    }
    const _item = item[0]
    if (_item.fromHours === 0 && _item.fromMinutes === 0 && _item.toHours === 0 && _item.toMinutes ===  0) {
      days.push('Круглосуточно')
      return
    }
    days.push(`${twoDigits(_item.fromHours)}:${twoDigits(_item.fromMinutes)} - ${twoDigits(_item.toHours)}:${twoDigits(_item.toMinutes)}`)
  })
  days.forEach((item, index) => {
    result = `${result} ${TYPE_DAYS[index]}: ${item}`
  })
  return result
}
