import React, { useEffect, useMemo, useState } from 'react'
import { PageHeading } from 'components/PageHeading'
import { ItemColumns } from 'components/ItemColumns'
import {
  Box,
  Grid,
  HStack, Link,
  Stack,
  Text,
  Tooltip,
  useBoolean,
  useColorModeValue
} from '@chakra-ui/react'
import { GridFieldItem } from 'components/GridField'
import { MdDoneAll, MdOutlineCalendarToday, MdOutlineEditCalendar, MdOutlineRemoveRedEye } from 'react-icons/md'
import { ItemIconOptions } from 'components/ItemIconOptions'
import { BlockCollection } from 'components/BlockCollection'
import { BlockSection } from 'components/BlockSection'
import { BlockHeading } from 'components/BlockHeading'
import { useParams } from 'react-router-dom'
import { useDeleteEquipment, useFetchEquipment } from '../services'
import { State } from './components/State'
import { getDateReadFormat } from 'utils/dateFormatting'
import { isEmpty, filter } from 'lodash'
import { ContactsCompany } from './components/ContactsCompany'
import { useFetchAccount, useReply, useUpdateState } from '../services'
import { useAccess } from 'hooks/useAccess'
import { rolesType } from 'constants/rolesType'
import { useAccount } from 'hooks/useAccount'
import { Entity } from 'components/Entity'
import { Delete } from './components/Delete'
import { useHistory } from 'react-router'
import { Container } from 'components/Container'
import { useAuthentication } from 'hooks/useAuthentication'
import { UnauthorizedInfo } from 'components/UnauthorizedInfo'
import { ContentMap } from 'components/ContentMap'
import { ImageCarouselEntity } from 'components/ImageCarouselEntity'
import { fieldTypeId } from 'constants/fieldTypeId'
import { ClassifierBlock } from './components/ClassifireBlock'
import { equipmentAvailabilityLabel, equipmentAvailabilityType } from 'constants/equipmentAvailabilityType'
import { useUpdateFavoriteState } from '../../services/useUpdateFavoriteState'
import { useFetchFavoriteEquipments } from 'services/useFetchFavoriteEquipments'
import { FavoriteButton } from 'components/FavoriteButton'
import { ScrollBox } from 'components/ScrollBox'

const ParameterItem = ({label, children}) => {
  return (
    <Box borderBottomWidth={0} borderColor="gray.100" py="10px">
      <Text fontSize="0.9rem" color="gray.400" mb="3px">{label}</Text>
      <Text lineHeight="1.2">{children}</Text>
    </Box>
  )
}

const Fields = ({ data }) => {
  return data?.map(item => {
    const value = item?.machineClassifierField?.fieldTypeId === fieldTypeId.bool ? (
      item?.fieldValue === 'true' ? 'Да' : 'Нет'
    ) : item?.fieldValue

    return (
      <GridFieldItem
        isReady
        isLoading={false}
        label={item.machineClassifierField?.name}
      >
        {value}
      </GridFieldItem>
    )
  })
}

export const Show = () => {
  const history = useHistory()
  const { isAuthenticated } = useAuthentication()
  const { id } = useParams()
  const { dataAccount: dataMyAccount } = useAccount()
  const { dataEquipment: data, isLoadingFetchEquipment, isSuccessFetchEquipment, fetchEquipment } = useFetchEquipment(id)
  const { setReply, isLoadingReply } = useReply(id)
  const [isAdmin] = useAccess([rolesType.admin])
  const { getAccount, dataAccount } = useFetchAccount()
  const { updateState, isLoadingUpdateState } = useUpdateState(id)
  const [isOpenDelete, setIsOpenDelete] = useBoolean()
  const { deleteEquipment } = useDeleteEquipment(id)
  const isMy = isAuthenticated && data?.creator?.id === dataMyAccount.accountId

  const { updateFavorite } = useUpdateFavoriteState()
  const { favoriteEquipmentsIds } = useFetchFavoriteEquipments()
  const isFavorite = !isEmpty(favoriteEquipmentsIds) && favoriteEquipmentsIds?.includes(data?.id)
  const [isLoadingFavorite, setIsLoadingFavorite] = useState(false)

  const machineClassifierId = data?.industrySet?.[0]?.id

  const handleFavoriteClick = async (e) => {
    try {
      e.stopPropagation()
      e.preventDefault()
      setIsLoadingFavorite(true)
      await updateFavorite({ id: data?.id, stateId: isFavorite ? 2 : 1})
    } catch (e) {} finally {
      setIsLoadingFavorite(false)
    }
  }

  const borderColor = useColorModeValue('gray.100', 'gray.700')

  const fields = useMemo(() => {
    if (isEmpty(data?.stubDetails)) {
      return []
    }
    return filter(data?.stubDetails, e => (
      !e?.machineClassifierField?.isRequired && e?.machineClassifierField?.machineClassifierId === machineClassifierId && e?.fieldValue !== ''
    ))
  }, [data])

  const fieldsRequired = useMemo(() => {
    if (isEmpty(data?.stubDetails)) {
      return []
    }

    return filter(data?.stubDetails, e => (
      e?.machineClassifierField?.isRequired && e?.machineClassifierField?.machineClassifierId === machineClassifierId && e?.fieldValue !== ''
    ))
  }, [data, machineClassifierId])

  const handleShowContacts = async () => {
    await setReply()
    await fetchEquipment()
  }

  const handleUpdateState = async (stateId, message) => {
    await updateState({ stateId, message })
    await fetchEquipment()
  }

  const handleDelete = async () => {
    await deleteEquipment(id)
    setIsOpenDelete.off()
    history.goBack()
  }

  useEffect(async () => {
    if (isSuccessFetchEquipment) {
      await getAccount(data?.creator?.id)
    }
  }, [isSuccessFetchEquipment])

  return (
    <Container>
      <ScrollBox>
        <PageHeading>
          {data?.name ?? `Оборудование ${id}`}
        </PageHeading>
        {(isMy || isAdmin) && (
          <State
            data={data?.state}
            rejectReason={data?.rejectReason}
            isLoading={isLoadingUpdateState || isLoadingFetchEquipment}
            onChange={handleUpdateState}
          />
        )}
        <ItemColumns>
          <BlockCollection>
            <Entity isLoading={isLoadingFetchEquipment} isEmpty={isEmpty(data)}>
              <Grid templateColumns="1fr 1fr" gap="16px">
                <Box pr="30px">
                  <ImageCarouselEntity data={data?.images}/>
                </Box>
                <Box>
                  <HStack height="20px" alignItems="center" spacing={4}>
                    <Tooltip label="Создано">
                      <ItemIconOptions
                        size="lg"
                        icon={MdOutlineCalendarToday}>
                        {getDateReadFormat(data?.createdAt)}
                      </ItemIconOptions>
                    </Tooltip>
                    <Tooltip label="Изменено">
                      <ItemIconOptions
                        size="lg"
                        icon={MdOutlineEditCalendar}
                      >
                        {getDateReadFormat(data?.updatedAt)}
                      </ItemIconOptions>
                    </Tooltip>
                    <Tooltip label="Всего откликов">
                      <ItemIconOptions
                        size="lg"
                        icon={MdDoneAll}
                      >
                        {data?.numberOfReplies}
                      </ItemIconOptions>
                    </Tooltip>
                    <Tooltip label="Просмотров">
                      <ItemIconOptions
                        size="lg"
                        icon={MdOutlineRemoveRedEye}
                      >
                        {data?.numberOfViews}
                      </ItemIconOptions>
                    </Tooltip>
                    <Box>
                      {isAuthenticated && data?.creator?.id !== dataMyAccount.accountId && (
                        <FavoriteButton
                          isLoading={isLoadingFavorite}
                          isActive={isFavorite}
                          onClick={handleFavoriteClick}
                        />
                      )}
                    </Box>
                  </HStack>
                  <Box borderTopWidth={1} borderColor={borderColor} mt="14px">
                    <ParameterItem label="Производитель">
                      {`${data?.manufacturer?.name ?? ''}`}
                    </ParameterItem>
                    {!isEmpty(data?.brand) && (
                      <ParameterItem label="Марка">
                        {`${data?.brand?.name ?? ''}`}
                      </ParameterItem>
                    )}
                    {!isEmpty(data?.model) && (
                      <ParameterItem label="Модель">
                        {`${data?.model ?? ''}`}
                      </ParameterItem>
                    )}
                    {data?.equipmentAvailabilityType === equipmentAvailabilityType.scheduled && !isEmpty(data?.scheduledStartDate) && !isEmpty(data?.scheduledEndDate) && (
                      <ParameterItem label="Загрузка оборудования">
                        {`${getDateReadFormat(data?.scheduledStartDate) ?? ''} – ${getDateReadFormat(data?.scheduledEndDate) ?? ''}`}
                      </ParameterItem>
                    )}
                    {(data?.equipmentAvailabilityType === equipmentAvailabilityType.alwaysFree || data?.equipmentAvailabilityType === equipmentAvailabilityType.freeByDeal)  && (
                      <ParameterItem label="Загрузка оборудования">
                        {equipmentAvailabilityLabel[data?.equipmentAvailabilityType]}
                      </ParameterItem>
                    )}
                    {isAuthenticated ? (
                      <>
                        {data?.location?.displayString && (
                          <ParameterItem label="Местонахождение">
                            {data?.location?.displayString}
                          </ParameterItem>
                        )}
                      </>
                     ) : (
                      <ParameterItem label="Местонахождение">
                        <UnauthorizedInfo.Link/>
                      </ParameterItem>
                    )}
                  </Box>
                </Box>
              </Grid>
              {!isEmpty(fieldsRequired) && (
                <BlockSection>
                  <BlockHeading>Основные параметры</BlockHeading>
                  <Box>
                    <Fields data={fieldsRequired} machineClassifierId={machineClassifierId}/>
                  </Box>
                </BlockSection>
              )}
              {!isEmpty(fields) && (
                <BlockSection>
                  <BlockHeading>Дополнительные признаки</BlockHeading>
                  <Box>
                    <Fields data={fields} machineClassifierId={machineClassifierId}/>
                  </Box>
                </BlockSection>
              )}
              {!isEmpty(data?.description) && (
                <BlockSection>
                  <BlockHeading>Описание</BlockHeading>
                  <Box style={{ whiteSpace: 'pre-line' }}>
                    {data?.description}
                  </Box>
                </BlockSection>
              )}
              <BlockSection>
                <BlockHeading>Информация о компании</BlockHeading>
                <Box>
                  <GridFieldItem
                    isReady
                    isLoading={false}
                    label="Наименование"
                  >
                    {isAuthenticated ? (
                      <Link
                        isExternal href={`/companies/${dataAccount?.accountId}`}
                        color="blue.400"
                        transition="0.3s"
                        _hover={{
                          color: 'blue.200'
                        }}
                      >
                        {dataAccount?.contragentName}
                      </Link>
                    ) : <UnauthorizedInfo.Link/>}
                  </GridFieldItem>
                  <GridFieldItem
                    isReady
                    isLoading={false}
                    label="ИНН"
                  >
                    {isAuthenticated ? dataAccount?.inn : <UnauthorizedInfo.Link/>}
                  </GridFieldItem>
                  <GridFieldItem
                    isReady
                    isLoading={false}
                    label="Основной вид деятельности"
                  >
                    {isAuthenticated ? `${dataAccount?.mainOKVED?.code} ${dataAccount?.mainOKVED?.name ?? ''}` : <UnauthorizedInfo.Link/>}
                  </GridFieldItem>
                  <GridFieldItem
                    isReady
                    isLoading={false}
                    label="Адрес"
                  >
                    {isAuthenticated ? dataAccount?.address?.displayString : <UnauthorizedInfo.Link/>}
                  </GridFieldItem>
                </Box>
                {data?.location?.latitude && data?.location?.longitude && (
                  <ContentMap
                    longitude={data?.location?.longitude}
                    latitude={data?.location?.latitude}
                  />
                )}
                {(isMy || isAdmin) && (
                  <Delete
                    isOpen={isOpenDelete}
                    onOpen={() => setIsOpenDelete.on()}
                    onClose={() => setIsOpenDelete.off()}
                    onDelete={handleDelete}
                  />
                )}
              </BlockSection>
            </Entity>
          </BlockCollection>
          <Box position="relative">
            <Stack spacing="30px" position="sticky" top="20px">
              <ContactsCompany
                data={data?.activeContragentContact}
                onShow={handleShowContacts}
                isShow={data?.isEquipmentRepliedByCurrentUser || data?.creator?.id === dataMyAccount?.accountId}
                isLoading={isLoadingFetchEquipment || isLoadingReply}
              />
              <ClassifierBlock
                data={data?.industrySet}
              />
            </Stack>
          </Box>
        </ItemColumns>
      </ScrollBox>
    </Container>
  )
}
