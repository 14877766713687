import { Box, Button } from '@chakra-ui/react'
import { EntityList } from 'components/EntityList'
import { SnippetItem } from './components/SnippetItem'
import React from 'react'
import { useArticleList } from './services/useArticleList'
import { Container } from 'components/Container'
import { PageHeading } from 'components/PageHeading'
import { Link } from 'react-router-dom'
import { useAccess } from 'hooks/useAccess'
import { rolesType } from 'constants/rolesType'
import { ScrollBox } from 'components/ScrollBox'

export const List = () => {
  const { dataList, getNextPage, hasMoreRows, totalCount, isLoading } = useArticleList()
  const [isAdmin] = useAccess([rolesType.admin])

  return (
    <Container>
      <ScrollBox>
        <PageHeading
          count={totalCount}
          {...isAdmin && {
            renderAction: (
              <Button
                as={Link}
                to={`/articles/create`}
                colorScheme="blue"
              >
                Новая статья
              </Button>
            )
          }}
        >
          Новости
        </PageHeading>
        <Box pb="80px">
          <EntityList
            hasMore={hasMoreRows}
            isLoading={isLoading}
            data={dataList}
            onNext={getNextPage}
          >
            {(item, index) => (
              <SnippetItem data={item}/>
            )}
          </EntityList>
        </Box>
      </ScrollBox>
    </Container>
  )
}
