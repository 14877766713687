import React from 'react'
import { createIcon } from '@chakra-ui/icons'

export const IconCompanies = createIcon({
  displayName: 'Companies',
  viewBox: '0 0 21 20',
  path: (
    <path
      fill='currentColor'
      d="M18 16.6667H19.6666V18.3333H1.33331V16.6667H2.99998V2.49999C2.99998 2.27898 3.08778 2.06701 3.24406 1.91073C3.40034 1.75445 3.6123 1.66666 3.83331 1.66666H17.1666C17.3877 1.66666 17.5996 1.75445 17.7559 1.91073C17.9122 2.06701 18 2.27898 18 2.49999V16.6667ZM16.3333 16.6667V3.33332H4.66665V16.6667H16.3333ZM7.16665 9.16666H9.66665V10.8333H7.16665V9.16666ZM7.16665 5.83332H9.66665V7.49999H7.16665V5.83332ZM7.16665 12.5H9.66665V14.1667H7.16665V12.5ZM11.3333 12.5H13.8333V14.1667H11.3333V12.5ZM11.3333 9.16666H13.8333V10.8333H11.3333V9.16666ZM11.3333 5.83332H13.8333V7.49999H11.3333V5.83332Z"
    />
  ),
})
