import { filter, isEmpty, truncate } from 'lodash';
import { FixedSizeList as ListWindow } from 'react-window'
import { Box, Center, HStack, Icon, Spinner, Text, useColorModeValue } from '@chakra-ui/react'
import React, { useEffect, useMemo, useState } from 'react'
import { InputSearch } from 'components/InputSearch'
import { useFetchMachine } from './services/useFetchMachine'
import { BsList } from 'react-icons/bs'

export const MachineClassifierList = ({ activeClassifierId, setActiveClassifierId }) => {
  const [valueSearch, setValueSearch] = useState('')
  const bgContainer = useColorModeValue('gray.50', 'gray.700')
  const bg = useColorModeValue('white', 'gray.700')
  const bgHover = useColorModeValue('gray.50', 'whiteAlpha.200')
  const bgActive = useColorModeValue('blue.500', 'blue.400')
  const color = useColorModeValue('black', 'white')
  const colorActive = useColorModeValue('white', 'black')
  const colorHover = useColorModeValue('blue.400', 'blue.300')
  const bgInput = useColorModeValue('white', 'gray.700')
  const borderColor = useColorModeValue('gray.100', 'transparent')

  const { getMachine, isLoadingFetch, data } = useFetchMachine()

  const rows = useMemo(() => {
    if (isEmpty(data?.rows)) {
      return []
    }
    return filter(data?.rows, e => {
      const name = e?.name?.toLowerCase() ?? ''
      const _valueSearch = valueSearch.toLowerCase() ?? ''
      return name.includes(_valueSearch)
    })
  }, [data?.rows, valueSearch])

  useEffect(() => {
    getMachine({ level: 1 })
  }, [])

  const Row = ({ index, key, style }) => {
    const isActive = rows[index].id === activeClassifierId
    return (
      <div key={key} style={{ ...style}} className="post">
        <HStack
          cursor="pointer"
          onClick={() => setActiveClassifierId(rows[index].id)}
          role="group"
          spacing="10px"
          bg={isActive ? bgActive : bg}
          height="100%"
          alignItems="center"
          color={isActive ? colorActive : color}
          px="14px"
          transition="0.3s"
          lineHeight="1.1"
          fontSize="0.9rem"
          flex={1}
          _hover={{
            ...!isActive && {
              backgroundColor: bgHover,
              color: colorHover
            }
          }}
        >
          <Icon
            as={BsList}
            fontSize="1.1rem"
            transition="0.3s"
          />
          <Text>{truncate(rows[index]?.name, { length: 80 })}</Text>
        </HStack>
      </div>
    )
  }

  return (
    <Box
      borderWidth={1}
      borderColor={borderColor}
      backgroundColor={bgContainer}
      borderRadius="8px"
      overflow="hidden"
      padding="var(--chakra-space-4)"
    >
      <HStack spacing="12px" alignItems="center" mb="12px">
        <InputSearch
          autoFocus
          delay={90}
          placeholder="Поиск по отраслям"
          name="search"
          value={valueSearch}
          onChange={e => setValueSearch(e)}
          bg={bgInput}
        />
      </HStack>
      <Box
        borderWidth={1}
        backgroundColor={bg}
        borderRadius="8px"
        overflow="hidden"
      >
        {!isEmpty(rows) ? (
          <ListWindow
            width="100%"
            height={400}
            itemCount={rows?.length}
            itemSize={50}
          >
            {Row}
          </ListWindow>
        ) : (
          <Center height="400px">
            {isLoadingFetch ? <Spinner color="blue.500"/> : 'Нет данных'}
          </Center>
        )}
      </Box>
    </Box>
  )
}
