import Addon from './addon.svg'
import { HStack, Image, Text } from '@chakra-ui/react';

export const SpecialHeading = ({ children, ...props }) => {
  return (
    <HStack alignItems="flex-start">
      <Image width="27px" height="auto" src={Addon} pt="3px"/>
      <Text
        fontWeight={800}
        textTransform="uppercase"
        fontSize="14px"
        {...props}
      >{children}</Text>
    </HStack>
  )
}
