import React, { useEffect, useState } from 'react'
import { Input } from '../Input'
import _ from 'lodash'
import isNumber from 'is-number'

export const InputNumberForm = React.forwardRef(({ field, form, sizeInput, label, ...props }, ref) => {
  const [value, setValue] = useState('')
  const handleBlur = () => {
    if (isNumber(Number(value))) {
      form.setFieldValue(field.name, value)
      return
    }
    setValue('')
    form.setFieldValue(field.name, '')
  }

  useEffect(() => {
    setValue(field.value)
  }, [field.value])

  return (
    <Input
      ref={ref}
      name={field.name}
      label={label}
      isInvalid={_.get(form.errors, field.name) && _.get(form.touched, field.name)}
      error={_.get(form.errors, field.name)}
      {...field}
      value={value}
      onChange={e => setValue(e.target.value)}
      onBlur={handleBlur}
      {...props}
    />
  )
})
