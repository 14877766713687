import React from 'react'
import {
  Input as ChakraInput,
  FormControl,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Box,
} from '@chakra-ui/react'
import { FormLabel } from '../FormLabel'
import MaskedInput from 'react-text-mask'

export const InputMask = React.forwardRef(
  (
    {
      name,
      isInvalid,
      leftAddon,
      rightAddon,
      label,
      mask,
      placeholder,
      value,
      size = 'md',
      textAlign = 'left',
      ...props
    },
    ref
  ) => {
    return (
      <FormControl isInvalid={isInvalid}>
        {label && <FormLabel name={name}>{label}</FormLabel>}
        <InputGroup size={size}>
          {leftAddon && <InputLeftAddon children={leftAddon} />}
          <MaskedInput
            mask={mask}
            placeholder={placeholder}
            value={value || ''}
            {...props}
            render={(ref, props) => (
              <ChakraInput ref={ref} id={name} size={size} textAlign={textAlign} {...props} />
            )}
          />
          {rightAddon && <InputRightAddon children={rightAddon} />}
        </InputGroup>
        {props.description && (
          <Box fontSize="0.7rem" color="gray.400" mt={2}>
            {props.description}
          </Box>
        )}
      </FormControl>
    )
  }
)
