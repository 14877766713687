import React from 'react'
import { Flex, Spinner } from '@chakra-ui/react'

export const Loading = ({ color = 'blue.500' }) => {
  return (
    <Flex height="110px" align="center" justify="center">
      <Spinner color={color} />
    </Flex>
  )
}
