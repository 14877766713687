import { useFetchFields } from 'services/useFetchFields'
import React, { useEffect, useMemo } from 'react'
import { FieldsConstructor } from '../FieldsConstructor'
import { FormLabel } from '../FormLabel'
import { Center, Spinner, Stack } from '@chakra-ui/react'
import { filter, isEmpty } from 'lodash'

export const FilterFields = ({ value, classifierId, machineClassifierId, onChange }) => {
  const { fetchFields, dataFields, isLoadingFetchFields } = useFetchFields()

  const rows = useMemo(() => {
    const result = []
    if (!isEmpty(dataFields?.rows)) {
      for (const item of dataFields.rows) {
        if (item?.machineClassifierId === classifierId) {
          result.push({
            id: item?.id,
            placeholder: item?.name,
            typeId: item?.fieldTypeId,
            options: item?.values,
            machineClassifierId: item?.machineClassifierId
          })
        }
      }
    }
    return result
  }, [dataFields, classifierId])

  const rowsFilter = useMemo(() => filter(rows, e => e?.machineClassifierId === machineClassifierId), [rows, machineClassifierId])

  const handleChange = (e) => {
    const values = filter(value, i => i.id !== e?.id)
    onChange([ ...values, {...e} ])
  }

  useEffect(() => {
    fetchFields()
  }, [])

  useEffect(() => {
    if (classifierId) {
      onChange([])
    }
  }, [classifierId])

  if (isLoadingFetchFields && classifierId) {
    return (
      <Center height="80px">
        <Spinner color="blue.500" size="sm"/>
      </Center>
    )
  }

  if (!isEmpty(rowsFilter)) {
    return (
      <Stack spacing="12px">
        <FormLabel mb={0}>Дополнительные признаки</FormLabel>
        <FieldsConstructor
          value={value}
          onChange={handleChange}
          data={rowsFilter}
        />
      </Stack>
    )
  }

  return null
}
