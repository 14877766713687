import { Icon } from '@chakra-ui/icons'

export const IconArrow = (props) => (
  <Icon viewBox="0 0 21 21" {...props}>
    <path
      fill="currentColor"
      d="M16.7017 5.69L2.35667 20.035L0 17.6783L14.3433 3.33333H1.70167V0H20.035V18.3333H16.7017V5.69V5.69Z"
    />
  </Icon>
)
