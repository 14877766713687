import { useCallback, useState } from 'react'
import download from 'downloadjs'
import { useSelector } from 'react-redux'
import { apiConfig } from 'api.config'

export const useDownloadFile = () => {
  const { token } = useSelector(state => state.profile.authentication)
  const [isLoading, setIsLoading] = useState(false)

  const request = useCallback((id, fileName, extension) => {
    setIsLoading(true)
    fetch(`${apiConfig}/file/download/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    }).then(res => res.blob().then(blob => {
      download(blob, `${fileName}.${extension}`);
    })).finally(() => setIsLoading(false))
  }, [])

  return ({
    isLoadingDownload: isLoading,
    download: request
  })
}
