import { Badge, Box, Center, HStack, Icon, IconButton, Spinner, Stack, Text, useColorModeValue } from '@chakra-ui/react'
import React from 'react'
import { isEmpty } from 'lodash'
import { FixedSizeList as ListWindow } from 'react-window'
import { BsInputCursorText } from 'react-icons/bs'
import { fieldTypeId, fieldTypeIdBadge, fieldTypeIdLabel } from 'constants/fieldTypeId'
import { SettingsIcon } from '@chakra-ui/icons'

export const FieldList = ({ rows, isLoading, onUpdate }) => {
  const bgContainer = useColorModeValue('white', 'gray.700')

  const bg = useColorModeValue('white', 'transparent')
  const bgHover = useColorModeValue('gray.50', 'whiteAlpha.200')
  const colorHover = useColorModeValue('blue.400', 'blue.300')
  const borderColor = useColorModeValue('gray.100', 'gray.600')

  const Row = ({ index, key, style }) => {
    const handleUpdate = (e) => {
      e.stopPropagation()
      e.preventDefault()
      onUpdate(rows[index])
    }

    return (
      <div key={key} style={{ ...style}} className="post">
        <HStack
          role="group"
          height="100%"
          spacing="10px"
          borderColor={borderColor}
          backgroundColor={bg}
          px="var(--chakra-space-4)"
          py="var(--chakra-space-2)"
          overflow="hidden"
          alignItems="center"
          justifyContent="space-between"
          transition="0.3s"
          _hover={{
            backgroundColor: bgHover,
          }}
        >
          <HStack spacing="10px" _groupHover={{ color: colorHover }}>
            <Icon as={BsInputCursorText} fontSize="1.1rem" />
            <Stack spacing="2px" lineHeight="1.1">
              <HStack spacing="10px">
                <Text>{rows[index]?.name}</Text>
              </HStack>
              {rows[index].fieldTypeId === fieldTypeId.list && (
                <Text opacity={0.5} fontSize="0.72rem">{rows[index]?.values?.join(', ')}</Text>
              )}
            </Stack>
          </HStack>
          <HStack spacing="10px">
            <Badge
              borderRadius="4px"
              colorScheme={fieldTypeIdBadge[rows[index]?.fieldTypeId]}
              textTransform="inherit"
            >
              {fieldTypeIdLabel[rows[index]?.fieldTypeId]}
            </Badge>
            <IconButton
              variant="ghost"
              size="sm"
              aria-label="Update"
              icon={<SettingsIcon fontSize="0.8rem"/>}
              borderRadius="full"
              onClick={handleUpdate}
            />
          </HStack>
        </HStack>
      </div>
    )
  }

  return (
    <Box
      borderWidth={1}
      borderRadius="8px"
      overflow="hidden"
      backgroundColor={bgContainer}
    >
      {!isEmpty(rows) ? (
        <ListWindow
          width="100%"
          height={400}
          itemCount={rows?.length}
          itemSize={54}
        >
          {Row}
        </ListWindow>
      ) : (
        <Center height="400px">
          {isLoading ? <Spinner color="blue.500"/> : 'Нет данных'}
        </Center>
      )}
    </Box>
  )
}
