import React from 'react'
import { createIcon } from '@chakra-ui/icons'

export const IconClassifier = createIcon({
  displayName: 'Classifier',
  viewBox: '0 0 15 18',
  path: (
    <g fill="none">
      <path d="M5.00033 16.5H12.0837C13.2343 16.5 14.167 15.5673 14.167 14.4167V5.97639C14.167 5.31335 13.9036 4.67747 13.4348 4.20862L11.4584 2.23223C10.9895 1.76339 10.3536 1.5 9.6906 1.5H3.75033C2.59973 1.5 1.66699 2.43274 1.66699 3.58333V9.83333" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M14.147 5.66676H11.25C10.5596 5.66676 10 5.10711 10 4.41676V1.51978" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M6.07757 10.9454L8.61217 8.41083C8.76845 8.25455 8.98041 8.16675 9.20143 8.16675C9.42244 8.16675 9.63441 8.25455 9.79069 8.41083L10.5894 9.20959C10.9149 9.53502 10.9149 10.0627 10.5894 10.3881L8.05482 12.9227C7.89854 13.079 7.68657 13.1668 7.46555 13.1668H6.25016C6.02004 13.1668 5.8335 12.9802 5.8335 12.7501V11.5347C5.8335 11.3137 5.92129 11.1017 6.07757 10.9454Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M3.41683 13.1667C3.4168 13.1897 3.39815 13.2083 3.37515 13.2083C3.35215 13.2083 3.33351 13.1897 3.3335 13.1667C3.33349 13.1437 3.35211 13.125 3.37511 13.125C3.38618 13.125 3.3968 13.1294 3.40463 13.1372C3.41245 13.145 3.41684 13.1556 3.41683 13.1667" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M0.916829 13.1667C0.916802 13.1897 0.898145 13.2083 0.875146 13.2083C0.852147 13.2083 0.833505 13.1897 0.833496 13.1667C0.833487 13.1437 0.852114 13.125 0.875113 13.125C0.886181 13.125 0.896799 13.1294 0.904625 13.1372C0.912452 13.145 0.916843 13.1556 0.916829 13.1667" stroke="#323232" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
  ),
})
