import React from 'react'
import { createIcon } from '@chakra-ui/icons'

export const IconProfile = createIcon({
  displayName: 'Profile',
  viewBox: '0 0 20 20',
  path: (
    <g fill="none">
      <path d="M16.6667 16.6667V16.0417C16.6667 14.0857 15.081 12.5 13.125 12.5H6.87501C4.919 12.5 3.33334 14.0857 3.33334 16.0417V16.6667" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <circle cx="10" cy="5.83333" r="3.33333" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
  ),
})
