import useQuery from 'hooks/useQuery'
import { api } from 'api'
import { useEffect } from 'react'

export const useFetchAccount = (id) => {
  const {
    isLoading,
    response,
    request
  } = useQuery(() => api.accounts.fetchItem(id))

  useEffect(() => {
    request()
  }, [])

  return {
    isLoading,
    data: response
  }
}
