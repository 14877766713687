import React, { useEffect, useState } from 'react'
import {
  Box, Button,
  Flex, Grid, Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay, Stack, Text,
  useDisclosure, useStyleConfig,
} from '@chakra-ui/react'
import { useFetchData } from './services'
import { InputSearch } from 'components/InputSearch'
import { isEmpty, uniqBy } from 'lodash'
import { Symbol } from 'components/Symbol'
import { FormLabel } from '../FormLabel'
import { SmallCloseIcon } from '@chakra-ui/icons'
import { SpeedTree } from './components/SpeedTree'

const Container = ({ onClick, error, isInvalid, children }) => {
  const styles = useStyleConfig('Input', { size: 'md' })

  return (
    <Flex
      {...styles.field}
      alignItems="center"
      minH="40px"
      height="auto"
      flex={1}
      {...(onClick && {
        onClick: onClick,
        cursor: 'pointer',
      })}
      {...(error &&
        isInvalid && {
          borderColor: '#E53E3E',
          boxShadow: '0 0 0 1px #e53e3e',
        })}
    >
      {children}
    </Flex>
  )
}

const Item = ({ placeholder, value, error, isHideName, isInvalid, onClick, onClearClick }) => {
  return (
    <Container onClick={onClick} error={error} isInvalid={isInvalid}>
      {isEmpty(value) ? (
        <Text color="gray.400">{placeholder}</Text>
      ) : (
        <Flex justifyContent="space-between" alignItems="center" flex={1}>
          <Grid
            py="0.46rem"
            pr="10px"
            templateColumns="40px 1fr"
            cursor="pointer"
          >
            <Flex alignItems="center">
              <Symbol>
                {value?.section}
              </Symbol>
            </Flex>
            <Stack spacing="2px" pt="1px" justifyContent="center">
              <Text fontWeight="semibold" fontSize="0.8rem">{value?.code}</Text>
              {!isHideName && (
                <Text fontSize="0.9rem" lineHeight="1.2">{value?.name}</Text>
              )}
            </Stack>
          </Grid>
          <Icon as={SmallCloseIcon} color="gray.600" onClick={onClearClick}/>
        </Flex>
      )}
    </Container>
  )
}

export const Okpd2 = (
  {
    value,
    placeholder,
    label,
    isInvalid,
    tooltipLabel,
    isHideName,
    error,
    isMulti,
    onChange,
    ...props
  }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { getData, resetData, data, isLoading } = useFetchData()
  const [inputValue, setInputValue] = useState('')

  const handleClose = () => {
    setInputValue('')
    resetData()
    onClose()
  }

  const handleChange = (e) => {
    if (isMulti) {
      const result = [...value, e]
      onChange(uniqBy(result, 'code'))
    } else {
      onChange(e)
    }
    handleClose()
  }

  const handleClear = (e, index) => {
    e.preventDefault()
    e.stopPropagation()
    if (isMulti) {
      onChange(value.filter((_, i) => i !== index))
      return
    }
    onChange('')
  }

  useEffect(() => {
    if (isOpen) {
      getData({ substringSearch: { searchString: inputValue } })
    }
  }, [isOpen, inputValue])

  return (
    <>
      <Box>
        {label && (
          <FormLabel tooltipLabel={tooltipLabel}>
            {label}
          </FormLabel>
        )}
        {isEmpty(value) ? (
          <Container onClick={onOpen} error={error} isInvalid={isInvalid}>
            <Text color="gray.400">{placeholder}</Text>
          </Container>
        ) : (
          <>
            {isMulti ? (
              <Stack spacing="8px">
                {value.map((item, index) => (
                  <Item
                    key={index}
                    value={item}
                    placeholder={placeholder}
                    error={error}
                    isInvalid={isInvalid}
                    isHideName={isHideName}
                    onClearClick={(e) => handleClear(e, index)}
                  />
                ))}
                <Button onClick={onOpen} size="xs">Еще</Button>
              </Stack>
            ) : (
              <Item
                value={value}
                placeholder={placeholder}
                error={error}
                isInvalid={isInvalid}
                isHideName={isHideName}
                onClick={onOpen}
                onClearClick={(e) => handleClear(e)}
              />
            )}
          </>
        )}
        {props.description && (
          <Box fontSize="0.7rem" color="gray.400" mt="4px">
            {props.description}
          </Box>
        )}
        {error && isInvalid && (
          <Box fontSize="0.7rem" color="red.500" mt="4px">
            {error}
          </Box>
        )}
      </Box>

      <Modal isOpen={isOpen} onClose={handleClose} trapFocus={false}>
        <ModalOverlay/>
        <ModalContent maxW="680px">
          <ModalHeader>Справочник ОКПД 2</ModalHeader>
          <ModalCloseButton mt={1}/>
          <ModalBody pb="30px">
            <InputSearch
              autoFocus
              placeholder="Поиск по коду или наименованию"
              size="lg"
              name="search"
              value={inputValue}
              onChange={e => setInputValue(e)}
              isLoading={isLoading}
              mb="28px"
            />
            <Flex height="450px">
              {isEmpty(data?.rows) ? (
                <Flex alignItems="center" justifyContent="center" flex={1}>
                  <Text>Нет результата</Text>
                </Flex>
              ) : (
                <Box width="100%">
                  <SpeedTree
                    data={data?.rows}
                    onChange={handleChange}
                  />
                </Box>
              )}
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
