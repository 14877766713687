import React, { useMemo } from 'react'
import { PageHeading } from 'components/PageHeading'
import { ListContent } from 'components/ListContent'
import { Button, HStack } from '@chakra-ui/react'
import { Filter } from './components/Filter'
import { EntityList } from 'components/EntityList'
import { SortMenu } from 'components/SortMenu/SortMenu'
import { Container } from 'components/Container'
import { ViewSwitch } from 'components/ViewSwitch'
import { useCompanyData } from './services'
import { EntityMap } from 'components/EntityMap'
import { truncate } from 'lodash'
import { useMapViewState } from './services/useMapViewState'
import { Snippet } from 'components/Snippet'
import { useUpdateFavoriteState } from '../services/useUpdateFavoriteState'
import { useAccountsAddRole } from 'services/useAccountsAddRole'
import { useAccountsDeleteRole } from 'services/useAccountsDeleteRole'
import { ScrollBox } from 'components/ScrollBox'
import { useAccess } from '../../../hooks/useAccess'
import { rolesType } from '../../../constants/rolesType'
import { useVerify } from '../services/useVerify'

export const List = () => {
  const { updateViewState, mapViewState } = useMapViewState()
  const { updateFavorite } = useUpdateFavoriteState()
  const { verifyId } = useVerify()
  const { addRole } = useAccountsAddRole()
  const { deleteRole } = useAccountsDeleteRole()
  const [isAdmin] = useAccess([rolesType.admin])

  const {
    dataList,
    totalCount,
    dataMap,
    isLoading,
    getNextPage,
    hasMoreRows,
    dataFilter,
    updateFilter,
    resetFilter,
    exportFile,
    isLoadingExportFile
  } = useCompanyData()

  const _dataMap = useMemo(() => {
    return (
      dataMap?.map(item => ({
        id: item?.id,
        latitude: item?.latitude,
        longitude: item?.longitude,
        list: item?.list?.map(i => ({
          id: i?.id,
          title: truncate(i?.contragentName, { length: 100 }),
          description: truncate(i?.description, { length: 100 }),
          to: `/companies/${i?.id}`,
          imageData: i?.logo
        }))

      }))
    )
  }, [dataMap])

  return (
    <Container>
      <ListContent>
        <ScrollBox>
          <PageHeading
            count={totalCount}
            renderAction={
              <HStack>
                {isAdmin && (
                  <Button isLoading={isLoadingExportFile} size="sm" onClick={exportFile}>
                    Скачать
                  </Button>
                )}
                <SortMenu
                  value={dataFilter.sort}
                  onChange={e => updateFilter('sort', e)}
                />
                <ViewSwitch
                  value={dataFilter.view}
                  onChange={e => updateFilter('view', e)}
                />
              </HStack>
            }
          >
            Компании
          </PageHeading>
          {dataFilter.view === 'list' ? (
            <EntityList
              isLoading={isLoading}
              hasMore={hasMoreRows}
              data={dataList}
              onNext={getNextPage}
              keyField={'accountId'}
            >
              {(item, index) => (
                <Snippet.Company
                  data={item}
                  onFavorite={updateFavorite}
                  onAddRole={addRole}
                  onDeleteRole={deleteRole}
                  onVerify={verifyId}
                  isOpenNewTab
                />
              )}
            </EntityList>
          ) : (
            <EntityMap
              isActivePopover
              isLoading={isLoading}
              data={_dataMap}
              initialViewState={mapViewState}
              onViewStateChange={e => updateViewState(e)}
              isHideAuth
            />
          )}
        </ScrollBox>
        <ScrollBox
          borderLeftWidth={1}
        >
          <Filter
            isLoading={isLoading}
            data={dataFilter}
            update={updateFilter}
            reset={resetFilter}
          />
        </ScrollBox>
      </ListContent>
    </Container>
  )
}
