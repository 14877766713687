import { useCallback } from 'react';

export const useYaMetric = () => {
  const isDevelopment = process.env.NODE_ENV === 'development'

  const yaClick = useCallback((target) => {
    if (!isDevelopment) {
      window?.ym(90924930,'reachGoal',target)
      console.log('reachGoal', target)
    }
  }, [isDevelopment])

  return {
    yaClick
  }
}
