import React from 'react'
import { createIcon } from '@chakra-ui/icons'

export const IconList = createIcon({
  displayName: 'List',
  viewBox: '0 0 12 12',
  path: (
    <path
      fill='currentColor'
      d="M0 0.666504H12V1.99984H0V0.666504ZM0 10.6665H12V11.9998H0V10.6665ZM0 7.33317H12V8.6665H0V7.33317ZM0 3.99984H12V5.33317H0V3.99984Z"
    />
  ),
})
