import { LayoutServices } from 'components/LayoutServices'
import { CardWrapper } from 'components/CardWrapper'
import { Center, Heading, Stack, Text } from '@chakra-ui/react'
import React from 'react'

export const NotFound = () => {
  return (
    <LayoutServices>
      <CardWrapper
        maxW="500px"
        width="100%"
      >
        <Center height="300px">
          <Stack textAlign="center">
            <Heading>404</Heading>
            <Text>Такой страницы не существует</Text>
          </Stack>
        </Center>
      </CardWrapper>
    </LayoutServices>
  )
}
