/**
 * Функция для корректного отображения числительных
 * @param titles ['Яблоко','Яблока','Яблок'] [1,2,5]
 * @param n
 * @returns {string} Значение titles в зависимости от n
 */
export const declOfNum = (titles: string[], n: number) =>
  titles[
    n % 10 === 1 && n % 100 !== 11
      ? 0
      : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20)
      ? 1
      : 2
    ];
