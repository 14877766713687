import { SlideWrapper } from '../components/SlideWrapper'
import { Box, Flex, Grid, Heading, Image, Stack, Text, useMediaQuery } from '@chakra-ui/react'
import { Container } from '../components/Container'
import Img from './images/image.png'
import Img2 from './images/image-2.png'
import Img3 from './images/image-3.png'
import { Scene } from 'react-scrollmagic'
import { useWindowHeight } from '../components/useWindowHeight'
import { transition } from '../constants/transition'
import Bg from '../Lessors/images/bg.svg'
import { Logo } from '../components/Logo'

export const Lessees = () => {
  const windowHeight = useWindowHeight()
  const [isLarge768] = useMediaQuery('(min-width: 768px)')

  return (
    <>
      <Scene duration={windowHeight * 3} triggerElement="#scene25">
        {scene25 => {
          const isScene1 = scene25 > 0.75
          return (
            <div id="scene25">
              <Scene duration={windowHeight} triggerElement="#scene26">
                {scene26 => {
                  const style = () => {
                    if (isLarge768) {
                      if (!isScene1) {
                        return { backgroundColor: '#1A1A1A', backgroundImage: Bg, backgroundSize: 'cover'}
                      } else {
                        return { backgroundColor: 'white'}
                      }
                    }
                    return { backgroundColor: '#1A1A1A'}
                  }
                  return (
                    <div>
                      <SlideWrapper
                        justifyContent="space-between"
                        top={0}
                        position={['static', 'static', 'sticky']}
                        transition={transition.cubic}
                        pt={['20px', '20px', '0px']}
                        pb={['30px', '30px', '0px']}
                        {...style()}
                      >
                        <Container flex={1}>
                          <Grid
                            templateColumns={['1fr', '1fr', '1fr', '.68fr 1fr']}
                            gap="50px"
                            flex={1}
                          >
                            <Flex
                              alignItems="center"
                              maxW="500px"
                              transition={transition.cubic}
                              {...isLarge768 && {
                                opacity: isScene1 > 0.3 ? 0 : 1
                              }}
                            >
                              <Stack spacing={4} color="white">
                                <Logo/>
                                <Heading fontSize={['30px', '40px', '60px', '65px']}>
                                  Для исполнителя
                                </Heading>
                                <Text>
                                    Размещайте информацию о своих свободных производственных мощностях в виде карточек оборудования. Получайте отклики и заявки от заинтересованных предприятий
                                </Text>
                              </Stack>
                            </Flex>
                            <Flex alignItems="center" display={['none', 'none', 'none', 'flex']}>
                              <Image
                                position="absolute"
                                width="1100px"
                                height="auto"
                                src={Img}
                                borderRadius="30px"
                                boxShadow="0px 20px 100px rgba(0, 0, 0, 0.1)"
                                transition={transition.cubic}
                                transform={scene25 > .44 ? 'scale(.95)' : 'scale(0.9)'}
                              />
                              <Image
                                position="absolute"
                                width="1100px"
                                height="auto"
                                zIndex={2}
                                src={Img2}
                                opacity={scene25 > .44 ? 1 : 0}
                                borderRadius="30px"
                                boxShadow="0px 20px 100px rgba(0, 0, 0, 0.1)"
                                transition={transition.cubic}
                                transform={scene25 > .44 ? 'scale(.95)' : 'scale(0.9)'}
                              />
                            </Flex>
                          </Grid>
                        </Container>
                      </SlideWrapper>
                      <SlideWrapper/>
                      <SlideWrapper position={['static', 'static', 'sticky']}>
                        <Container flex={1}>
                          <Grid templateColumns={['1fr', '1fr', '1fr', '.68fr 1fr']} flex={1} gap='30px'>
                            <Flex
                            maxW="500px"
                            mb="40px"
                            pt='30px'
                            {...isLarge768 ? {
                              color: isScene1 ? 'black' : 'white'
                            } : {
                              color: 'white'
                            }}
                            >
                            <Box>
                              <Stack spacing={4}>
                                <Heading fontSize={['1.2rem', '1.5rem', '1.7rem', '2rem']}>
                                  Для исполнителя 
                                </Heading>
                                <Text>
                                  Находите заказы в реестре заявок с помощью фильтров. Предлагайте свою продукцию и услуги заказчикам
                                </Text>
                              </Stack>
                            </Box>
                          </Flex>
                            <Flex alignItems="center" display={['none', 'none', 'none', 'flex']}>
                              <Image
                                position="absolute"
                                width="1100px"
                                height="auto"
                                zIndex={3}
                                src={Img3}
                                opacity={scene25 > .44 ? 1 : 0}
                                borderRadius="30px"
                                boxShadow="0px 20px 100px rgba(0, 0, 0, 0.1)"
                                transition={transition.cubic}
                                transform={scene25 > .44 ? 'scale(.97)' : 'scale(0.9)'}
                              />
                            </Flex>
                          </Grid>
                        </Container>
                      </SlideWrapper>
                    </div>
                  )
                }}
              </Scene>
            </div>
          )
        }}
      </Scene>
    </>
  )
}
