import useQuery from 'hooks/useQuery'
import { api } from 'api'
import { useEffect } from 'react'

export const useFetchReply = () => {
  const {
    isLoading,
    response,
    request
  } = useQuery(() => api.reply.fetchList())

  useEffect(() => {
    request()
  }, [])

  return {
    data: response?.rows,
    isLoading
  }
}
