import useQuery from 'hooks/useQuery'
import { api } from 'api'

export const useFetchVendorsData = (id) => {
  const {
    isLoading,
    response,
    request
  } = useQuery(() => api.vendorsData.fetchData(id))

  return ({
    getVendorsData: request,
    vendorData: response,
    isLoadingVendorData: isLoading
  })
}
