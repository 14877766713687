import { Modal } from 'components/Modal'
import { Form } from 'components/Form'
import { Field, Formik } from 'formik'
import * as Yup from 'yup'
import { InputForm } from 'components/InputForm'
import React, { useState } from 'react'
import { BlockSection } from 'components/BlockSection'
import { Button, Icon, Stack, useDisclosure } from '@chakra-ui/react'
import { HiPlusCircle } from 'react-icons/hi'

const formSchema = Yup.object().shape({
  name: Yup.string().required('')
})

export const AddBrand = ({ onChange, isDisabled }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [isLoading, setIsLoading] = useState(false)

  const handleForm = async (values) => {
    try {
      setIsLoading(true)
      await onChange(values)
      onClose()
    } catch (e) {} finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <Button
        isDisabled={isDisabled}
        size="xs"
        colorScheme="gray"
        color="blue.400"
        fontSize="0.8rem"
        onClick={onOpen}
        width="fit-content"
        flex="none"
        variant="link"
        leftIcon={<Icon as={HiPlusCircle} fontSize="1rem"/>}
        _hover={{
          color: 'blue.500'
        }}
        _active={{
          color: 'blue.600'
        }}
      >
        Добавить
      </Button>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        header="Добавление марки"
      >
        <Formik
          enableReinitialize
          onSubmit={handleForm}
          validationSchema={formSchema}
          initialValues={{
            name: ''
          }}
        >
          {(props) => (
            <Form onSubmit={props.handleSubmit}>
              <Stack spacing="24px">
                <BlockSection>
                  <Field
                    autoFocus
                    name="name"
                    label="Наименование"
                    component={InputForm}
                    isDisabled={isLoading}
                  />
                </BlockSection>
                <BlockSection>
                  <Button
                    width="100%"
                    type="submit"
                    isLoading={isLoading}
                    colorScheme="blue"
                  >
                    Добавить
                  </Button>
                </BlockSection>
              </Stack>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  )
}
