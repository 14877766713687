import React from 'react'
import { ImageUploader } from '../ImageUploader'

export const ImageUploaderForm = ({field, form, onDelete, ...props}) => {
  const handleUploadFile = (e) => {
    form.setFieldValue(field.name, { id: e })
  }

  return (
    <ImageUploader
      {...field}
      value={field.value}
      onChange={handleUploadFile}

      {...props}
    />
  )
}
