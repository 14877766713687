import React from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Loading } from '../Loading'
import { Box, Flex, Spinner, Text } from '@chakra-ui/react'
import { isEmpty } from 'lodash'
import { ErrorBoundary } from '../ErrorBoundary'

export const EntityList = (
  {
    hasMore,
    data,
    onNext,
    keyField = 'id',
    isLoading,
    children
  }) => {
  if (isEmpty(data) && isLoading) {
    return (
      <Flex alignItems="center" justifyContent="center" height="200px">
        <Spinner color="blue.500"/>
      </Flex>
    )
  }
  return (
    <InfiniteScroll
      hasMore={!hasMore}
      dataLength={data.length}
      scrollableTarget="scrollableDiv"
      next={onNext}
      style={{ overflow: 'visible' }}
      //pullDownToRefreshThreshold={300}
      {...isLoading && { loader: <Loading/>}}
    >
      {!isEmpty(data) ? data?.map((item, index) => (
        <Box
          key={item[keyField]}
          tabIndex="none"
          _focus={{
            outline: 'none',
          }}
        >
          <ErrorBoundary>
            {children(item, index)}
          </ErrorBoundary>
        </Box>
      )) : !isLoading && (
        <Flex alignItems="center" justifyContent="center" height="200px">
          <Text color="gray.400">Ничего не найдено</Text>
        </Flex>
      )}
    </InfiniteScroll>
  )
}
