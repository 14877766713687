import { useToast } from '@chakra-ui/react'
import useQuery from 'hooks/useQuery'
import { api } from 'api'
import { useEffect } from 'react'

export const useFetchData = () => {
  const toast = useToast();
  const {
    isError,
    isLoading,
    isSuccess,
    response,
    request,
    reset
  } = useQuery((data) => api.classifier.fetchOkved(data))

  useEffect(() => {
    if (isError) {
      toast({
        title: 'Ошибка',
        description: 'Не удалось получить данные',
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  }, [isError])

  return {
    getData: request,
    resetData: reset,
    data: response,
    isLoading,
    isSuccess
  }
}
