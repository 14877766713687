import React from 'react'
import { Box, Flex, IconButton, Text, useColorModeValue } from '@chakra-ui/react'
import { FileIcon } from 'components/FileIcon'
import { FileSizeBadge } from 'components/FileSizeBadge'
import { useDownloadFile } from './services/useDownloadFile'
import { HiOutlineTrash } from 'react-icons/hi'
import { useDeleteFile } from './services/useDeleteFile'

export const FileWidget = ({ isHideDelete, data, onDelete }) => {
  const { id, displayName, fileName, extension, size, description } = data
  const { download, isLoadingDownload } = useDownloadFile()
  const { deleteFile, isLoadingDeleteFile } = useDeleteFile()

  const bg = useColorModeValue('white', 'gray.700')
  const bgContainerBorder = useColorModeValue('gray.100', 'transparent')
  const bgBorder = useColorModeValue('gray.100', 'gray.600')

  const colorSecondaryText = useColorModeValue('gray.400', 'gray.500')

  const handleClick = async () => {
    await download(id, fileName, extension)
  }

  const handleDelete = async (e) => {
    try {
      e.preventDefault()
      e.stopPropagation()
      await deleteFile(id)
      onDelete && onDelete(data)
    } catch (e) {}
  }

  return (
    <Box
      height="125px"
      position="relative"
      onClick={handleClick}
    >
      <Flex
        role="group"
        cursor="pointer"
        backgroundColor={bg}
        position="relative"
        borderRadius="10px"
        borderWidth={1}
        borderColor={bgContainerBorder}
        width="100%"
        padding="14px"
        justifyContent="flex-start"
        flexDirection="column"
        transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
        _hover={{
          //boxShadow: '0px 0px 24px rgba(0, 0, 0, 0.05)',
          boxShadow: 'rgba(15, 15, 15, 0.1) 0px 3px 6px, rgba(15, 15, 15, 0.1) 0px 9px 24px',
          borderColor: 'transparent',
          position: 'absolute',
          height: 'auto',
          zIndex: 3
        }}
      >
        <Flex alignItems="center" pb="14px" justifyContent="space-between">
          <Flex alignItems="center">
            <FileIcon mr="14px" name={extension} isLoading={isLoadingDownload}/>
            <Flex justifyContent="center" flexDirection="column" lineHeight={1.3}>
              <Text>{displayName}</Text>
              <FileSizeBadge size={size}/>
            </Flex>
          </Flex>
          {!isHideDelete && (
            <IconButton
              size="sm"
              aria-label="Delete"
              isDisabled={isLoadingDownload}
              icon={<HiOutlineTrash />}
              onClick={handleDelete}
              isLoading={isLoadingDeleteFile}
            />
          )}
        </Flex>
        <Box alignItems="center" flex={1} minH="22px" pt="8px" borderTopWidth={1} borderColor={bgBorder}>
          <Text
            fontSize="0.84rem"
            color={colorSecondaryText}
            overflow="hidden"
            textOverflow="ellipsis"
            lineHeight="20px"
            height="20px"
            _groupHover={{
              whiteSpace: 'pre-wrap',
              height: 'auto'
            }}
          >
            {description}
          </Text>
        </Box>
      </Flex>
    </Box>
  )
}
