import React from 'react'
import { FormControl, FormLabel, Switch as ChakraSwitch } from '@chakra-ui/react'

export const Switch = React.forwardRef(({ name, label, value, labelProps, onChange, ...props }) => {
  const handleChange = (e) => onChange && onChange(e)
  return (
    <FormControl
      display="flex"
      alignItems="center"
      borderColor="gray.100"
      borderRadius={6}
      pr="10px"
    >
      <ChakraSwitch
        id={name}
        isChecked={value || false}
        onChange={handleChange}
        mr={3}
        {...props}
      />
      <FormLabel
        htmlFor={name}
        mb="0"
        fontWeight="normal"
        py="2px"
        mr={0}
        cursor="pointer"
        {...labelProps}
      >
        {label}
      </FormLabel>
    </FormControl>
  )
})
