import React, { useState } from 'react'
import {
  Box,
  Button,
  Modal, ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useToast
} from '@chakra-ui/react'
import { DropZone } from '../DropZone'
import { InputForm } from '../InputForm'
import { Field, Formik } from 'formik'
import { TextAreaForm } from '../TextAreaForm'
import { Form } from '../Form'
import * as Yup from 'yup'
import { BlockSection } from '../BlockSection'

const formSchema = Yup.object().shape({
  name: Yup.string().required('Заполните поле'),
  description: Yup.string().required('Заполните поле')
})

export const FileUploader = ({ isOpen, onOpen, onClose, isLoading, onChange }) => {
  const toast = useToast()
  const [fileState, setFileState] = useState(null)

  const handleForm = (values) => {
    onChange && onChange({
      ...values,
      file: fileState
    })
    setFileState(null)
  }

  const handleUpload = (acceptedFiles) => {
    const uploadFile = acceptedFiles[0]
    const reader = new FileReader()
    reader.readAsDataURL(uploadFile)
    reader.onloadend = () => {
      setFileState({
        name: uploadFile.name,
        type: uploadFile.type,
        size: uploadFile.size,
        uri: reader.result,
      })
    }
  }

  const handleClose = () => {
    setFileState(null)
    onClose()
  }

  return (
    <>
      <Button
        onClick={onOpen}
        size="sm"
      >
        Загрузить документ
      </Button>
      <Modal isOpen={isOpen} onClose={handleClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Загрузка документа</ModalHeader>
          <ModalCloseButton mt={1} />
          <ModalBody>
            <Formik
              enableReinitialize
              validationSchema={formSchema}
              initialValues={{
                name: '',
                description: '',
              }}
              onSubmit={handleForm}
            >
              {(props) => (
                <Form onSubmit={props.handleSubmit}>
                  <BlockSection>
                    <DropZone
                      maxFiles={1}
                      maxSize={104857600}
                      height="200px"
                      //width="528px"
                      multiple={false}
                      uploadedFile={fileState}
                      onUpload={handleUpload}
                      onError={() => {
                        toast({
                          description: `Файл слишком большой`,
                          variant: 'solid',
                          status: 'error',
                        })
                      }}
                    />
                    <Field
                      name="name"
                      label="Наименование файла"
                      component={InputForm}
                      isDisabled={isLoading}
                    />
                    <Field
                      name="description"
                      label="Описание"
                      component={TextAreaForm}
                      isDisabled={isLoading}
                    />
                  </BlockSection>
                  <Box pt="30px" pb="24px">
                    <Button
                      type="submit"
                      colorScheme="blue"
                      isLoading={isLoading}
                    >
                      Загрузить
                    </Button>
                  </Box>
                </Form>
                )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
