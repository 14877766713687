import React, { useEffect, useMemo } from 'react'
import { Select } from 'components/Select'
import { useFetchRegionEquipments } from 'services/useFetchRegionEquipments/useFetchRegionEquipments'
import { isEmpty } from 'lodash';

export const RegionEquipmentsSelect = ({ value, onChange, ...props }) => {
  const { fetchRegionEquipments, isLoadingFetchRegionEquipments, dataRegionEquipments } = useFetchRegionEquipments()

  const options = useMemo(() => (
    [
      {
        label: 'Все регионы',
        value: ''
      },
      ...!isEmpty(dataRegionEquipments) ? dataRegionEquipments?.map(i => ({ label: i, value: i })) : []
    ]
  ), [dataRegionEquipments])

  const handleChange = (e) => {
    onChange(e)
  }

  useEffect(() => {
    fetchRegionEquipments()
  }, [])

  return (
    <Select
      options={options}
      isLoading={isLoadingFetchRegionEquipments}
      isDisabled={isLoadingFetchRegionEquipments}
      placeholder="Регион"
      onChange={handleChange}
      value={value}
      {...props}
    />
  )
}
