import React from 'react'
import {
  Input as ChakraInput,
  FormControl,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Box, InputRightElement, IconButton, Icon,
} from '@chakra-ui/react'
import { FormLabel } from '../FormLabel'
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'

export const InputPassword = React.forwardRef(
  (
    {
      name,
      error,
      isHideError,
      isInvalid,
      leftAddon,
      rightAddon,
      rightElement,
      label,
      mask,
      placeholder,
      value,
      tooltipLabel,
      size = 'md',
      addonProps,
      ...props
    },
    ref
  ) => {
    const [isShowPassword, setIsShowPassword] = React.useState(false)

    return (
      <FormControl isInvalid={isInvalid}>
        {label && (
          <FormLabel name={name} tooltipLabel={tooltipLabel}>
            {label}
          </FormLabel>
        )}
        <InputGroup size={size}>
          {leftAddon && <InputLeftAddon children={leftAddon} />}
          <ChakraInput
            ref={ref}
            type={isShowPassword ? 'text' : 'password'}
            id={name}
            placeholder={placeholder}
            size={size}
            value={value || ''}
            {...props}
          />
          {rightAddon && (
            <InputRightAddon
              backgroundColor="gray.100"
              children={rightAddon}
              px="0"
              {...addonProps}
            />
          )}
          <InputRightElement>
            <IconButton
              size="sm"
              aria-label="Delete"
              variant="ghost"
              icon={<Icon as={isShowPassword ? AiOutlineEye : AiOutlineEyeInvisible} boxSize="18px"/>}
              onClick={() => setIsShowPassword(!isShowPassword)}
              _focus={{
                boxShadow: 'none'
              }}
            />
          </InputRightElement>
        </InputGroup>
        {error && isInvalid && !isHideError && (
          <Box fontSize="0.7rem" color="red.500" mt="4px">
            {error}
          </Box>
        )}
      </FormControl>
    )
  }
)
