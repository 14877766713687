import React from 'react'
import { CardWrapper } from 'components/CardWrapper'
import { Button, Heading, Stack, Text } from '@chakra-ui/react'
import { InputForm } from 'components/InputForm'
import { Form } from 'components/Form'
import { Field, Formik } from 'formik'
import * as Yup from 'yup'
import { PasswordCheck } from '../../components/PasswordCheck/PasswordCheck'
import { useResetPassword } from './services'
import { useLocation } from 'react-router-dom'
import { getUrlParameter } from 'utils/getUrlParameter'
import { useHistory } from 'react-router'
import { LayoutServices } from 'components/LayoutServices'
import { InputPasswordForm } from '../../components/InputPasswordForm'

const formSchema = Yup.object().shape({
  newPassword: Yup.string().required('Заполните поле')
})

const initialValues = {
  newPassword: ''
}

export const NewPassword = () => {
  const location = useLocation()
  const history = useHistory()
  const email = location.search ? getUrlParameter('email', location.search) : ''
  const token = location?.search ? getUrlParameter('token', location.search) : ''
  const { request, isLoading } = useResetPassword()

  const handleForm = async (values, {setSubmitting, resetForm}) => {
    try {
      const dataRequest = {
        ...values,
        token,
        email
      }
      await request(dataRequest)
      resetForm(initialValues)
      setSubmitting(false)
      history.push('/account/0')
    } catch (e) {}
  }

  return (
    <LayoutServices>
      <CardWrapper
        pt="40px"
        pb="50px"
        px="60px"
      >
        <Stack spacing={5}>
          <Heading fontSize="1.6rem">Новый пароль</Heading>
          <Text lineHeight={1.3}>
            Придумайте новый пароль для восстановления доступа в систему
          </Text>
          <Formik
            enableReinitialize={true}
            onSubmit={handleForm}
            validationSchema={formSchema}
            initialValues={initialValues}
          >
            {(props) => (
              <Form onSubmit={props.handleSubmit}>
                <Stack spacing={6}>
                  <Field
                    name="newPassword"
                    size="lg"
                    label="Пароль"
                    component={InputPasswordForm}
                    isDisabled={isLoading}
                  />
                  <PasswordCheck
                    value={props.values.newPassword}
                  />
                  <Button
                    type="submit"
                    size="lg"
                    isLoading={isLoading}
                    colorScheme="blue"
                  >
                    Изменить пароль
                  </Button>
                </Stack>
              </Form>
            )}
          </Formik>
        </Stack>
      </CardWrapper>
    </LayoutServices>
  )
}
