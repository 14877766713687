import { useToast } from '@chakra-ui/react'
import useQuery from 'hooks/useQuery'
import { api } from 'api'
import { useEffect } from 'react'

export const useFetchArticle = () => {
  const toast = useToast()
  const {
    isError,
    isLoading,
    error,
    request,
    response
  } = useQuery((id) => api.knowledgeBase.fetchItem(id))

  useEffect(() => {
    if (isError) {
      toast({
        title: 'Ошибка',
        description: error.response?.data?.ErrorMessage,
        status: 'error',
      })
    }
  }, [isError])

  return {
    fetchArticle: request,
    data: response,
    isLoadingFetchArticle: isLoading,
  }
}
