import React, { useEffect, useState } from 'react'
import {
  Badge,
  Box,
  Flex,
  Grid,
  HStack, Icon,
  Stack,
  Text,
  Tooltip,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react'
import { isEmpty, truncate } from 'lodash'
import { Link } from 'react-router-dom'
import { ImageEntity } from 'components/ImageEntity'
import { useAuthentication } from 'hooks/useAuthentication'
import { useFetchFavoriteCompanies } from 'services/useFetchFavoriteCompanies'
import { FavoriteButton } from '../../FavoriteButton'
import { useAccount } from 'hooks/useAccount'
import { useAccess } from 'hooks/useAccess'
import { rolesType } from 'constants/rolesType'
import { AnalyticAccessButton } from '../../AnalyticAccessButton'
import { ItemIconOptions } from '../../ItemIconOptions'
import { MdMailOutline, MdOutlineCalendarToday } from 'react-icons/md'
import { getDateReadFormat } from '../../../utils/dateFormatting'
import { VerifyButton } from '../../VerifyButton'
import { FiLock } from 'react-icons/fi'
import { ShortGuide } from '../../ShortGuide'

export const Company = ({ data, onFavorite, onDeleteRole, onAddRole, onVerify, isOpenNewTab }) => {
  const toast = useToast()
  const { dataAccount: dataMyAccount } = useAccount()
  const [isAdmin] = useAccess([rolesType.admin])
  const { isAuthenticated } = useAuthentication()
  const bgContainerBorder = useColorModeValue('gray.100', 'gray.700')
  const bgContainerHover = useColorModeValue('gray.50', 'whiteAlpha.100')
  const bgContainerActive = useColorModeValue('gray.100', 'whiteAlpha.50')

  const { favoriteCompaniesIds } = useFetchFavoriteCompanies()
  const isFavorite =
    !isEmpty(favoriteCompaniesIds) && favoriteCompaniesIds?.includes(data?.accountId)
  const [isLoadingFavorite, setIsLoadingFavorite] = useState(false)
  const [isLoadingVerify, setIsLoadingVerify] = useState(false)

  const [isAnalytic, setIsAnalytic] = useState(false)
  const [isLoadingAnalyticAccess, setIsLoadingAnalyticAccess] = useState(false)
  const [needVerify, setNeedVerify] = useState(false)

  useEffect(() => {
    setIsAnalytic(data?.roles?.includes(rolesType.analytic))
  }, [data])

  const handleFavoriteClick = async (e) => {
    try {
      e.stopPropagation()
      e.preventDefault()
      setIsLoadingFavorite(true)
      await onFavorite({ id: data?.accountId, stateId: isFavorite ? 2 : 1 })
    } catch (e) {
    } finally {
      setIsLoadingFavorite(false)
    }
  }

  const handleVerifyClick = async (e) => {
    try {
      e.stopPropagation()
      e.preventDefault()
      setIsLoadingVerify(true)
      await onVerify({ id: data?.accountId })
      setNeedVerify(false)
    } catch (e) {
    } finally {
      setIsLoadingVerify(false)
    }
  }

  const handleAnalyticAccessClick = async (e) => {
    try {
      e.stopPropagation()
      e.preventDefault()
      setIsLoadingAnalyticAccess(true)
      if (!isAnalytic) {
        await onAddRole({ id: data?.accountId, data: [rolesType.analytic] })
        toast({
          description: `Доступ к аналитике успешно открыт`,
          variant: 'solid',
          status: 'success',
        })
        setIsAnalytic(true)
        return
      }
      await onDeleteRole({ id: data?.accountId, data: [rolesType.analytic] })
      toast({
        description: `Доступ к аналитике успешно закрыт`,
        variant: 'solid',
        status: 'success',
      })
      setIsAnalytic(false)
    } catch (e) {
    } finally {
      setIsLoadingAnalyticAccess(false)
    }
  }

  useEffect(() => {
    if (isAdmin && !data?.emailConfirmed) {
      setNeedVerify(true)
    }
  }, [data])

  return (
    <Link to={`/companies/${data.accountId}`} {...(isOpenNewTab && { target: '_blank' })}>
      <Box
        cursor="pointer"
        borderBottomWidth={1}
        borderColor={bgContainerBorder}
        transition="0.3s"
        position="relative"
        _hover={{
          zIndex: 3,
          backgroundColor: bgContainerHover,
        }}
        _active={{
          backgroundColor: bgContainerActive,
        }}
      >
        <Grid templateColumns="130px 1fr 320px 32px" gap={5} padding="18px">
          <ImageEntity data={data?.logo} />
          <Flex flexDirection="column" justifyContent="center">
            <Stack spacing="24px">
              <Stack spacing={3}>
                <HStack pb="4px">
                  {data?.isCustomer && (
                    <Badge fontSize="0.64rem" colorScheme="blue" borderRadius={4} px="6px">
                      Заказчик
                    </Badge>
                  )}
                  {data?.isSupplier && (
                    <Badge fontSize="0.64rem" colorScheme="green" borderRadius={4} px="6px">
                      Исполнитель
                    </Badge>
                  )}
                </HStack>
                <Stack spacing="4px" lineHeight="1.2">
                  <Text fontWeight="semibold" fontSize="18px" lineHeight={1.2}>
                    {truncate(data.contragentName, { length: 100 })}
                  </Text>
                  {isAuthenticated && (
                    <Text fontSize="0.8rem">
                      {data?.region?.name}
                    </Text>
                  )}
                </Stack>
                {isAuthenticated ? (
                  <>
                    {(data?.description && data.description !== '') && (
                      <Text fontSize="14px" lineHeight="18px">
                        {truncate(data.description, { length: 150 })}
                      </Text>
                    )}
                  </>
                ) : (
                  <>
                    <HStack spacing="6px">
                      <Text fontSize="14px" lineHeight="18px" color="gray.400">
                        Описание
                      </Text>
                      <Icon as={FiLock} fontSize="0.9rem" color="gray.400"/>
                    </HStack>
                  </>
                )}
              </Stack>
              {isAuthenticated && (
                <HStack height="20px" alignItems="center" spacing={4}>
                  <Tooltip label="Почта">
                    <ItemIconOptions icon={MdMailOutline}>
                      {truncate(data?.contactEmail, { length: 30 })}
                    </ItemIconOptions>
                  </Tooltip>
                  {isAdmin && (
                    <>
                      <Tooltip label="Дата регистрации">
                        <ItemIconOptions icon={MdOutlineCalendarToday}>
                          {getDateReadFormat(data?.createdAt)}
                        </ItemIconOptions>
                      </Tooltip>
                      <Tooltip label="Последняя активность">
                        <ItemIconOptions icon={MdOutlineCalendarToday}>
                          {data?.lastActivityAt
                            ? getDateReadFormat(data?.lastActivityAt)
                            : 'Нет активности'}
                        </ItemIconOptions>
                      </Tooltip>
                    </>
                  )}
                </HStack>
              )}
            </Stack>
          </Flex>
          {data?.mainOKVED && (
            <ShortGuide
              section={data?.mainOKVED?.section}
              code={data?.mainOKVED?.code}
              name={data?.mainOKVED?.name}
            />
          )}
          {isAuthenticated && data?.accountId !== dataMyAccount.accountId && (
            <Box position="absolute" right="18px" bottom="18px">
              <HStack>
                {isAdmin && (
                  <>
                    {needVerify ? (
                      <HStack alignItems="center">
                        <Box
                          color="orange.400"
                          fontSize="0.74rem"
                          backgroundColor="orange.100"
                          borderRadius="full"
                          padding="2px 10px"
                          fontWeight="semibold"
                        >
                          Не подтержден
                        </Box>
                        <VerifyButton isLoading={isLoadingVerify} onClick={handleVerifyClick} />
                      </HStack>
                    ) : (
                      <>
                        <AnalyticAccessButton
                          isLoading={isLoadingAnalyticAccess}
                          isActive={isAnalytic}
                          onClick={handleAnalyticAccessClick}
                        />
                        <FavoriteButton
                          isLoading={isLoadingFavorite}
                          isActive={isFavorite}
                          onClick={handleFavoriteClick}
                        />
                      </>
                    )}
                  </>
                )}
              </HStack>
            </Box>
          )}
        </Grid>
      </Box>
    </Link>
  )
}
