import axios from 'axios'

const ENTITY = 'knowledgeBase'

export const knowledgeBase = Object.freeze({
  async fetchList(data) {
    const response = await axios.post(`/${ENTITY}/search`, data)
    return response.data
  },
  async fetchItem(id) {
    const response = await axios.get(`/${ENTITY}/${id}`)
    return response.data
  },
  async createItem(data) {
    const response = await axios.post(`/${ENTITY}`, data)
    return response.data
  },
  async updateItem(data) {
    const response = await axios.put(`/${ENTITY}`, data)
    return response.data
  },
  async deleteItem(id) {
    const response = await axios.delete(`/${ENTITY}/${id}`)
    return response.data
  },
})
