import React from 'react'
import { FormControl } from '@chakra-ui/react'
import { RadioGroup } from '../RadioGroup'

export const RadioGroupForm = React.forwardRef(({ field, form, label, labelProps, ...props }) => {
  const handleChange = (e) => form.setFieldValue(field.name, e)
  return (
    <FormControl>
      <RadioGroup
        value={field.value}
        onChange={handleChange}
        label={label}
        {...props}
      />
    </FormControl>
  )
})
