import React from 'react'
import { Box, HStack } from '@chakra-ui/react'
import { PeriodSelect } from '../../../../components/PeriodSelect'

export const Filter = ({ data, update, reset }) => {
  return (
    <HStack spacing="14px">
      <Box width="140px">
        <PeriodSelect
          isShowAll
          value={data.period}
          onChange={e => update('period', e.value)}
        />
      </Box>
    </HStack>
  )
}
