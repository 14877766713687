import { Text } from '@chakra-ui/react'
import { useMemo } from 'react'

export const DifferenceText = ({ value = 0, ...props }) => {

  const _value = useMemo(() => (
    value !== 0 ? Math.ceil(value * 100) : 0
  ), [value])

  const color = useMemo(() => {
    if (_value === 0) {
      return 'gray.300'
    }
    return _value > 0 ? 'green.400' : 'red.400'
  }, [_value])

  return (
    <Text
      fontSize="0.9rem"
      color={color}
      {...props}
    >
      {_value}%
    </Text>
  )
}
