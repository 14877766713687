import { Image } from '@chakra-ui/react'
import LogoImg from './logo.svg'

export const Logo = () => {
  return (
    <Image
      width={{
        base: '201.43px',
        md: '201.43px'
      }}
      height="auto"
      alt="logo"
      src={LogoImg}
    />
  )
}
