import { useLocation } from 'react-router-dom'
import { useEffect, useRef } from 'react'

export const YaMetric = ({ children }) => {
  const location = useLocation()
  const init = useRef(false)
  const isDevelopment = process.env.NODE_ENV === 'development'

  useEffect(() => {
    if (!isDevelopment) {
      window.ym(90924930, 'init', {
        defer: true,
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
        webvisor:true
      })
      init.current = true
      console.log('Init YaMetrika')
    }
  }, [])

  useEffect(() => {
    if (!isDevelopment && init.current) {
      window.ym(90924930, 'hit', `${location?.pathname}${location?.search}`)
    }
  }, [location])

  return children
}
