import { BackgroundVideo } from './components/BackgroundVideo'
import { Box } from '@chakra-ui/react'
import { Intro } from './Intro'
import { About } from './About'
import { Controller } from 'react-scrollmagic'
import { Footer } from './Footer'
import { Segment } from './Segment'
import { Lessors } from './Lessors'
import { Lessees } from './Lessees'
import { useEffect } from 'react'
import AOS from 'aos'
import { Registry } from './Registry'
import { Additional } from './Additional'

export const LandingStankofond = () => {

  useEffect(() => {
    setTimeout(() => {
      AOS.init({
        duration : 900,
        disable: 'mobile'
      });
    }, 300)
  }, [])

  return (
    <Box
      position="relative"
      css={{
        '&': {
          fontSize: '20px'
        }
      }}
    >
      <Controller
        globalSceneOptions={{triggerHook: 1}}
      >
        <Box
          position="relative"
          zIndex={2}
        >
          <Intro/>
          <About isShow={true}/>
          <Segment/>
          <Lessors/>
          <Lessees/>
          <Registry/>
          <Additional/>
          <Footer/>
        </Box>
        <BackgroundVideo/>
      </Controller>
    </Box>
  )
}
