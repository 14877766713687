import axios from 'axios'

const ENTITY = 'reply'

export const reply = Object.freeze({
  async fetchList() {
    const response = await axios.get(`/${ENTITY}`)
    return response.data
  },
  async fetchCount() {
    const response = await axios.get(`/${ENTITY}/count`)
    return response.data
  }
})
