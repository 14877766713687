import axios from 'axios'

const ENTITY = 'classifier'

export const classifier = Object.freeze({
  async fetchOkved(data) {
    const response = await axios.post(`/${ENTITY}/okved/search`, data)
    return response.data
  },
  async fetchOkpd2(data) {
    const response = await axios.post(`/${ENTITY}/okpd2/search`, data)
    return response.data
  },
  async fetchRegions() {
    const response = await axios.get(`/${ENTITY}/region`)
    return response.data
  },
  async fetchRegionSearch(data) {
    const response = await axios.post(`/${ENTITY}/region/search`, data)
    return response.data
  },
  async fetchAddress(data) {
    const response = await axios.post(`/${ENTITY}/address/search`, data)
    return response.data
  },
  async fetchOkei(data) {
    const response = await axios.post(`/${ENTITY}/okei/search`, data)
    return response.data
  },
  async fetchManufacture(data) {
    const response = await axios.post(`/${ENTITY}/manufacture/search`, data)
    return response.data
  },
  async fetchEquipmentTools(data) {
    const response = await axios.post(`/${ENTITY}/equipmentTool/search`, data)
    return response.data
  },
  async fetchMachineManufacturer(params) {
    const response = await axios.get(`/${ENTITY}/machine/manufacturer`, { params })
    return response.data
  },
  async fetchMachineBrand() {
    const response = await axios.get(`/${ENTITY}/machine/brand`)
    return response.data
  },
  async fetchMachineRequiredField(params) {
    const response = await axios.get(`/${ENTITY}/machine/requiredfield`, { params })
    return response.data
  },
  async fetchMachine(params) {
    const response = await axios.get(`/${ENTITY}/machine`, { params })
    return response.data
  },
  async fetchMachineField(params) {
    const response = await axios.get(`/${ENTITY}/machine/field`, { params })
    return response.data
  },
  async fetchRegionEquipments() {
    const response = await axios.get(`/${ENTITY}/region/equipments`)
    return response.data
  },
  async fetchRegionOrders() {
    const response = await axios.get(`/${ENTITY}/region/orders`)
    return response.data
  },
  async fetchPeriodicity() {
    const response = await axios.get(`/${ENTITY}/periodicity`)
    return response.data
  },
})
