import React from 'react'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent, AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay, Button
} from '@chakra-ui/react';

export const Delete = ({ isOpen, onOpen, onClose, onDelete, isLoading }) => {
  return (
    <>
      <Button
        onClick={onOpen}
        color="red.400"
        variant="outline"
        isLoading={isLoading}
      >Удалить</Button>
      <AlertDialog isOpen={isOpen} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Удаление
            </AlertDialogHeader>

            <AlertDialogBody>
              Вы действительно хотите удалить материал? Это действие нельзя отменить
            </AlertDialogBody>
            <AlertDialogFooter mb="8px">
              <Button onClick={onClose}>Отмена</Button>
              <Button colorScheme="red" ml={3} onClick={onDelete}>
                Удалить
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}
