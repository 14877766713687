import { actions as authentication } from './profile/authentication'
import { actions as account } from './profile/account'
import { actions as favoriteCompanies } from './favorite/companies'
import { actions as equipmentsCompanies } from './favorite/equipments'
import { actions as ordersCompanies } from './favorite/orders'

export const resetStore = () => async (dispatch, getState) => {
  dispatch(authentication.reset())
  dispatch(account.reset())
  dispatch(favoriteCompanies.reset())
  dispatch(equipmentsCompanies.reset())
  dispatch(ordersCompanies.reset())
}
