import useQuery from 'hooks/useQuery'
import { api } from 'api'
import { useEffect } from 'react'
import { actions as favorite } from 'store/favorite/equipments'
import { useDispatch, useSelector } from 'react-redux'

export const useFetchFavoriteEquipments = () => {
  const dispatch = useDispatch()
  const favoriteEquipmentsIds = useSelector(state => state.favorite.equipments.data)

  const dataRequest = {
    searchParameters: {
      IsFavourite: true
    }
  }

  const {
    isSuccess,
    request,
    response,
  } = useQuery(() => api.equipment.fetchList(dataRequest))

  useEffect(() => {
    if (isSuccess) {
      const result = response?.rows?.map(i => i.id)
      dispatch(favorite.update(result))
    }
  }, [isSuccess])

  return {
    favoriteEquipmentsIds,
    fetchFavoriteEquipments: request
  }
}
