import { combineReducers } from '@reduxjs/toolkit'
import ordersReducer from './orders'
import equipmentsReducer from './equipments'
import companiesReducer from './companies'
import supportRequestReducer from './supportRequest'

export default combineReducers({
  orders: ordersReducer,
  equipments: equipmentsReducer,
  companies: companiesReducer,
  supportRequest: supportRequestReducer
})
