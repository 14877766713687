import React from 'react'
import { ErrorBoundary as ErrorBoundaryLib } from 'react-error-boundary'
import { ErrorFallback } from '../ErrorFallback'

export const ErrorBoundary = ({ children, ...props}) => {
  return (
    <ErrorBoundaryLib FallbackComponent={ErrorFallback} {...props}>
      {children}
    </ErrorBoundaryLib>
  )
}
