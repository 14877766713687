export const deliveryType = {
  bySelf: 'BySelf',
  byCourier: 'ByCourier',
  byShippingCompany: 'ByShippingCompany'
}

export const deliveryLabel = {
  [deliveryType.bySelf]: 'Самостоятельно',
  [deliveryType.byCourier]: 'Курьером',
  [deliveryType.byShippingCompany]: 'Транспортной компанией',
}
