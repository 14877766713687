import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export const ViewPort = ({ children }) => {
  const location = useLocation()

  useEffect(() => {
    const head = document.head
    const meta = document.querySelector('meta[name="viewport"]')
    const content = meta.getAttribute('content')
    const newMeta = document.createElement('meta')

    newMeta.setAttribute('name', 'viewport')
    if (location.pathname === '/') {
      newMeta.setAttribute('content', 'width=device-width, initial-scale=1')

    } else {
      newMeta.setAttribute('content', 'width=1500')
    }

    head.removeChild(meta)
    head.appendChild(newMeta)
  }, [location.pathname])

  return (
    <>{children}</>
  )
}
