import { GeoAddress } from '../GeoAddress'
import _ from 'lodash'

export const GeoAddressForm = ({ field, form, ...props }) => {
  return (
    <GeoAddress
      isInvalid={_.has(form.errors, field.name) && _.has(form.touched, field.name)}
      value={field.value}
      onChange={e => form.setFieldValue(field.name, e)}
      {...props}
    />
  )
}
