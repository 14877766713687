import { Box, Button, Flex, Heading, Stack, Text, useBoolean } from '@chakra-ui/react'
import { SlideWrapper } from '../components/SlideWrapper'
import { Container } from '../components/Container'
import { ArrowForwardIcon } from '@chakra-ui/icons'
import { PolicyModal } from './components/PolicyModal'
import { Link } from 'react-router-dom'

export const Footer = () => {
  const [isOpenPolicy, setIsOpenPolicy] = useBoolean()

  const handlePolicyClick = (e) => {
    e.preventDefault()
    setIsOpenPolicy.on()
  }

  return (
    <>
      <SlideWrapper justifyContent="space-between">
        <Flex flex={1} alignItems="center" color="white" textAlign="center">
          <Container
            alignItems="center"
            pt={['30px', '30px', '0px', '0px']}
            pb={['30px', '30px', '0px', '0px']}
          >
            <Flex data-aos="fade-up">
              <Stack spacing="20px" maxW="1000px">
                <Heading fontSize={['30px', '40px', '50px']}>
                  Оперативно находите исполнителей и привлекайте дополнительные заказы!
                </Heading>
                <Text fontSize="22px">
                  Создайте профиль и воспользуйтесь всеми преимуществами платформы бесплатно
                </Text>
                <Stack
                  spacing={3} pt="20px"
                  direction={['column', 'row']}
                  justifyContent={['flex-start', 'center']}
                >
                  <Button
                    as={Link}
                    colorScheme="blueRus"
                    to="/registration"
                  >Зарегистрироваться</Button>
                  <Button
                    as={Link}
                    colorScheme="whiteAlpha"
                    to="/equipments"
                    rightIcon={<ArrowForwardIcon/>}
                  >Войти</Button>
                </Stack>
              </Stack>
            </Flex>
          </Container>
        </Flex>
        <Flex
          minH="80px"
          alignItems="center"
          marginTop="80px"
          borderTopWidth={1}
          borderColor="whiteAlpha.400"
          py={['20px', '0']}
        >
          <Container
            fontSize="0.8rem"
            color="whiteAlpha.500"
            lineHeight="22px"
          >
            <Flex justifyContent="space-between" flexDirection={['column', 'row']}>
              <Stack direction={['column', 'row']} spacing={[2, 6]} mb={['20px', '0']}>
                <Text>© 2022 Сделаем в России</Text>
                <Link onClick={handlePolicyClick}>Пользовательское соглашение</Link>
              </Stack>
              <Stack spacing={1}>
                <Text>Если у вас остались вопросы, пишите на почту:</Text>
                <Box>
                  <Link
                    href="mailto:info@сделаемвроссии.рф"
                  >
                    info@сделаемвроссии.рф
                  </Link>
                </Box>
              </Stack>
            </Flex>
          </Container>
        </Flex>
      </SlideWrapper>
      <PolicyModal
        isOpen={isOpenPolicy}
        onClose={() => setIsOpenPolicy.off()}
      />
    </>

  )
}
