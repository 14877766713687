import React from 'react'
import { Badge, Button, Flex, Grid, HStack, Spinner, useColorModeValue } from '@chakra-ui/react'
import { isEmpty } from 'lodash'
import {
  supportRequestStatusColorScheme, supportRequestStatusLabel,
  supportRequestStatusType
} from 'constants/supportRequestStatusType'

export const State = ({data, dataAccount, isLoading, onChange}) => {
  const bg = useColorModeValue('gray.50', 'gray.700')
  const borderColor = useColorModeValue('gray.100', 'transparent')

  const handleUpdate = (stateId, status) => {
    onChange(stateId, status)
  }

  const handleOpenEmail = () => {
    if (dataAccount?.contactEmail) {
      window.open(`mailto:${dataAccount?.contactEmail}`)
    }
  }

  if (isEmpty(data)) {
    return null
  }

  return (
    <>
      <Grid
        borderColor={borderColor}
        borderWidth={1}
        templateColumns="1fr 1fr"
        minH="100px"
        backgroundColor={bg}
        borderRadius="12px"
        mb="38px"
        gap="40px"
        padding="24px"
        position="relative"
      >
        {isLoading && (
          <Flex
            position="absolute"
            zIndex={10}
            left={0}
            right={0}
            bottom={0}
            top={0}
            width="100%"
            height="100%"
            backgroundColor="whiteAlpha.200"
            alignItems="center"
            justifyContent="center"
            borderRadius="12px"
            mb="38px"
          >
            <Spinner color="blue.500"/>
          </Flex>
        )}
        <Flex alignItems="center">
          <Badge
            fontSize="0.9rem"
            colorScheme={`${supportRequestStatusColorScheme[data.status]}`}
            px="8px"
            py="1px"
            borderRadius="4px"
          >
            {supportRequestStatusLabel[data.status]}
          </Badge>
        </Flex>
        <HStack justifyContent="flex-end" spacing={3}>
          {(data.status === supportRequestStatusType.rejected || data.status === supportRequestStatusType.completed) && (
            <>
              <Button
                variant="outline"
                colorScheme="gray"
                onClick={() => handleUpdate(1)}
                size="sm"
              >
                В работу
              </Button>
            </>
          )}
          {(data.status === supportRequestStatusType.inWork) && (
            <>
              <Button
                variant="outline"
                colorScheme="gray"
                onClick={() => handleUpdate(3)}
                size="sm"
              >
                Отклонить
              </Button>
              <Button
                variant="outline"
                colorScheme="green"
                onClick={() => handleUpdate(2)}
                size="sm"
              >
                Завершить
              </Button>
            </>
          )}
          <Button
            colorScheme="blue"
            onClick={handleOpenEmail}
            size="sm"
          >
            Связаться с заказчиком
          </Button>
        </HStack>
      </Grid>
    </>
  )
}
