import { useToast } from '@chakra-ui/react'
import useQuery from 'hooks/useQuery'
import { api } from 'api'
import download from 'downloadjs'

export const useExportReplies = (data) => {
  const toast = useToast()
  const {
    isLoading,
    request
  } = useQuery(() => api.equipment.exportReplies(data))

  const exportRepliesFile = async () => {
    try {
      const result = await request(data)
      download(result, `exported-replies-file.xlsx`)
    } catch (e) {
      toast({
        title: 'Ошибка',
        description: 'Не удалось получить данные',
        status: 'error'
      })
    }
  }

  return {
    isLoading,
    exportRepliesFile
  }
}
