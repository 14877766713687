export const initialValue = `
Памятка для редактирования

поддерживаются все html теги, например: перенос на следующую строку <br/>

**Жирное начертание**
__Жирное начертание__
*Не прямое начертание*
_Не прямое начертание_
++Начертание с подчеркиванием++
~~Начертание с зачеркиванием~~
\\
@[youtube](DdLhDZMizvI) 

\\
->Текст по центру<-

![Minion](https://octodex.github.com/images/minion.png)

> Первая вложенность текста
>> Вторая вложенность текста
> > > Третья вложенность текста.

\\
[Вставить ссылку](https://blog.bhanuteja.dev)

\\
Заголовки

# h1 Headinge
## h2 Heading
### h3 Heading
#### h4 Heading
##### h5 Heading
###### h6 Heading

+ Создайте список, начав строку с '+', '-', or '*'
+ Вложенные списки составляются с отступом в 2 пробела:
  - Изменение символа маркера приводит к запуску нового списка:
    * Первая позиция списка
    + Любая другая позиция 
    - Конец списка позиций
+ Продолжение.

Начало нумерованного списка

1. первое
2. второе 
3. третье

\\
Наименование таблицы
| Заголовок | Описание таблицы |
| --- | ----------- |
|первое| первое описание значения в виде таблицы |
|второе| второе описание значения в виде таблицы |
|третье| третье описание значения в виде таблицы |

`
