import { find, isEmpty } from 'lodash'

export const getActiveContragentContactToSubmit = (list, id) => {
  const init = {
    contactFullName: '',
    contactEmail: '',
    jobTitle: '',
    contactPhoneNumber: '',
    id: 0
  }
  if (isEmpty(list) || id === '') {
    return { ...init }
  }
  const item = find(list, e => e.id === id)
  if (!isEmpty(item)) {
    return { ...item }
  }
  return { ...init }
}
