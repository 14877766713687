import React from 'react'
import { Input } from '../Input'
import _ from 'lodash'

export const InputForm = React.forwardRef(({ field, form, sizeInput, label, ...props }, ref) => {
  return (
    <Input
      ref={ref}
      name={field.name}
      label={label}
      isInvalid={_.get(form.errors, field.name) && _.get(form.touched, field.name)}
      error={_.get(form.errors, field.name)}
      {...field}
      {...props}
    />
  )
})
