import { useToast } from '@chakra-ui/react'
import useQuery from 'hooks/useQuery'
import { api } from 'api'
import { useEffect, useMemo } from 'react'

export const useFetchAvgPageViews = () => {
  const toast = useToast()
  const {
    isError,
    isLoading,
    error,
    request,
    response
  } = useQuery((data) => api.yametrikaProxy.fetchAvgPageViews(data))

  const dataFetchAvgPageViews = useMemo(() => {
    return {
      avgTitle: null,
      avgValue: null,
      difference: null,
      footerTitle: 'Всего',
      footerValue: null,
      series: null,
      title: 'Глубина просмотров',
      type: 'Visits',
      value: response?.totals[0] ?? null,
      chartData: response?.data?.map(i => ({
        label: i?.dimensions[0]?.name,
        value: i?.metrics[0]
      })) ?? null
    }
  }, [response])

  useEffect(() => {
    if (isError) {
      toast({
        title: 'Ошибка',
        description: error.response?.data?.ErrorMessage,
        status: 'error',
      })
    }
  }, [isError])

  return {
    fetchFetchAvgPageViews: request,
    dataFetchAvgPageViews,
    isLoadingFetchAvgPageViews: isLoading
  }
}
