import useQuery from 'hooks/useQuery'
import { api } from 'api'
import { useEffect } from 'react'
import { useToast } from '@chakra-ui/react'

export const useUpdate = (id) => {
  const toast = useToast()
  const {
    isError,
    isLoading,
    isSuccess,
    error,
    request
  } = useQuery((data) => api.accounts.updateItem(data, id))

  useEffect(() => {
    if (isError) {
      toast({
        title: 'Ошибка',
        description: error.response?.data?.ErrorMessage,
        status: 'error',
      })
    }
  }, [isError])

  useEffect(() => {
    if (isSuccess) {
      toast({
        title: 'Готово',
        description: 'Аккаунт успешно обновлен',
        status: 'success'
      })
    }
  }, [isSuccess])

  return {
    isLoadingUpdate: isLoading,
    isSuccessUpdate: isSuccess,
    update: request
  }
}
