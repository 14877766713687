import React, { useMemo } from 'react'
import { useFetch } from './services/useFetch'

const OPTION_URL = {
  address: 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address',
  fio: 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/fio',
}

const TOKEN = '729b21efbde6763624c5416de4690e2f4027d20f'

const HEADERS = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
  Authorization: `Token ${TOKEN}`,
}

const OPTIONS = {
  address: {
    count: 10,
    locations: [{ country_iso_code: 'ru' }, { country_iso_code: 'by' }],
    restrict_value: true,
  },
  fio: {},
}

const options = (query, option = null) => ({
  method: 'POST',
  mode: 'cors',
  headers: HEADERS,
  body: JSON.stringify({
    query: query,
    ...(option && OPTIONS[option]),
  }),
})

export const DaData = ({ typeOption = 'address', initialQuery, children }) => {
  const { data, isLoading, updateParams } = useFetch(OPTION_URL[typeOption], options(initialQuery))
  const resultData = useMemo(() => {
    if (data && data.suggestions && data.suggestions.length) {
      return data.suggestions.map((suggestion) => ({
        ...suggestion,
        value: suggestion.unrestricted_value.replace(/^\d{6},\s*/, ''),
      }))
    }
    return null
  }, [data])
  const onUpdateQuery = (value) => {
    updateParams(options(value, typeOption))
  }
  return <>{children(resultData, isLoading, onUpdateQuery)}</>
}
