import React, { useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { Box, Spinner, Text } from '@chakra-ui/react'
import { useDispatch } from 'react-redux'
import { resetStore } from 'store/reset'
import { useAuthentication } from 'hooks/useAuthentication'
import { useInvalidateSession } from './services'

export const Logout = () => {
  const dispatch = useDispatch()
  const { isAuthenticated } = useAuthentication()
  const { request } = useInvalidateSession()

  useEffect(() => {
    const logout = async () => {
      try {
        await request()
      } catch (e) {} finally {
        dispatch(resetStore())
      }
    }
    logout()
  }, []);

  return isAuthenticated ? (
    <>
      <Box
        width="100%"
        height="100%"
        display="flex"
        flexDirection="column"
        alignContent="center"
        alignItems="center"
        padding="60px"
      >
        <Text>
          Загрузка...
        </Text>
        <Spinner color="blue.500" size="md" marginTop="20px"/>
      </Box>
    </>
  ) : <Redirect to="/equipments"/>
}
