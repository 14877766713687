export const orderStateType = {
  new: 'New',
  updated: 'Updated',
  onModeration: 'OnModeration',
  published: 'Published',
  cancelled: 'Cancelled',
  rejected: 'Rejected',
  completed: 'Completed'
}

export const statusLabel = {
  [orderStateType.new]: 'Новая заявка',
  [orderStateType.updated]: 'Обновлено',
  [orderStateType.onModeration]: 'На модерации',
  [orderStateType.published]: 'Опубликовано',
  [orderStateType.cancelled]: 'Отменено',
  [orderStateType.rejected]: 'Отклонено',
  [orderStateType.completed]: 'Завершено',
}

export const statusDescription = {
  [orderStateType.new]: 'Вы создали заявку, осталось отправить ее на модерацию. После проверки она будет опубликована',
  [orderStateType.updated]: 'Вы обновили заявку, отправьте ее на модерацию.',
  [orderStateType.onModeration]: 'Модерация занимает от 30 минут до 2 часов. Если модерация длится дольше, обратитесь в службу технической поддержки.',
  [orderStateType.published]: 'Заявка опубликована, прием предложений открыт.',
  [orderStateType.cancelled]: 'Администратор отменил заявку, она скрыта из поиска.',
  [orderStateType.rejected]: 'Администратор отклонил вашу заявку.',
  [orderStateType.completed]: 'Вы завершили заявку.',
}

export const statusColorScheme = {
  [orderStateType.new]: 'blue',
  [orderStateType.updated]: 'blue',
  [orderStateType.onModeration]: 'purple',
  [orderStateType.published]: 'green',
  [orderStateType.cancelled]: 'orange',
  [orderStateType.rejected]: 'red',
  [orderStateType.completed]: 'purple'
}
