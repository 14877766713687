import { getMarkdown } from 'utils/getMarkdown'
import { markdownCss } from './constants/markdownCss'
import { Box, Stack } from '@chakra-ui/react'

export const MarkdownRender = ({ content }) => {
  return (
    <Stack spacing="20px">
      <Box
        dangerouslySetInnerHTML={{__html:getMarkdown(content ?? '')}}
        css={markdownCss}
      />
    </Stack>
  )
}
