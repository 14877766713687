import { Box, Button, Center, Flex, Grid, Stack, Text, useColorModeValue } from '@chakra-ui/react'
import { getDateTimeReadFormat } from 'utils/dateFormatting'
import React, { useState } from 'react';
import { InterviewModal } from '../../../../../../components/InterviewModal'

export const Item = ({ data }) => {
  const bgContainerBorder = useColorModeValue('gray.100', 'gray.700')
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <Box
        borderBottomWidth={1}
        borderColor={bgContainerBorder}
        transition="0.3s"
      >
        <Grid templateColumns="1fr 1fr 200px 200px" gap={5} padding="18px" alignItems="center">
          <Stack spacing="6px" lineHeight="1">
            <Text color={data?.contragentName ? 'black' : 'gray.400'}>
              {data?.contragentName ?? 'Нет наименования'}
            </Text>
            <Text
              fontSize="0.8rem"
              lineHeight="18px"
              color="gray.400"
            >
              {getDateTimeReadFormat(data?.createdAt)}
            </Text>
          </Stack>
          <Flex>
            {data?.usability}
          </Flex>
          <Flex>
            {data?.dealSumResult > 0 ? (
              <Stack spacing="4px" lineHeight="1">
                <Text>
                  {data?.dealSumResult}
                </Text>
              </Stack>
            ) : (
              <Text
                lineHeight="18px"
                color="gray.400"
              >
                Не указана
              </Text>
            )}
          </Flex>
          <Center>
            <Button onClick={() => setIsOpen(true)}>Ответы</Button>
          </Center>
        </Grid>
      </Box>
      {isOpen && (
        <InterviewModal
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          data={data}
        />
      )}
    </>
  )
}
