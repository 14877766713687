import React from 'react'
import { NavigationBar } from './NavigationBar'
import { Container } from 'components/Container'
import { Box, Flex, HStack, Text, useColorModeValue } from '@chakra-ui/react'
import { NavigationMenu } from './NavigationMenu'
import { InputSearch } from './InputSearch'
import { Logo } from './Logo'
import { useEffect } from 'react'
import { useAccount } from 'hooks/useAccount'
import { useAuthentication } from '../../hooks/useAuthentication'
import { useFetchNotificationCount } from 'services/useFetchNotificationCount'
import { useLocation } from 'react-router-dom'
import { versionConfig } from 'version.config'
import { useFetchFavoriteEquipments } from 'services/useFetchFavoriteEquipments'
import { useFetchFavoriteOrders } from 'services/useFetchFavoriteOrders'
import { useFetchFavoriteCompanies } from 'services/useFetchFavoriteCompanies'

const exceptionsUrl = ['/', '/registration', '/stankofond']

export const Wrapper = ({children}) => {
  const location = useLocation()
  const { getAccount } = useAccount()
  const { isAuthenticated } = useAuthentication()
  const { getCount } = useFetchNotificationCount()
  const { fetchFavoriteEquipments } = useFetchFavoriteEquipments()
  const { fetchFavoriteOrders } = useFetchFavoriteOrders()
  const { fetchFavoriteCompanies } = useFetchFavoriteCompanies()

  const bgFooter = useColorModeValue('white', 'gray.800')

  const pathname = location?.pathname

  useEffect(() => {
    getAccount()
  }, [isAuthenticated])

  useEffect(() => {
    if (isAuthenticated) {
      fetchFavoriteEquipments()
      fetchFavoriteOrders()
      fetchFavoriteCompanies()
    }
  }, [isAuthenticated])

  useEffect( () => {
    getCount()
  }, [location?.pathname])

  if (exceptionsUrl.includes(pathname)) {
    return children
  }

  return (
    <Box minW="1200px">
      <Flex
        height="100vh"
        flexDirection="column"
        justifyContent="space-between"
        overflow="hidden"
      >
        <Flex flexDirection="column" flex={1}>
          <Box
            backgroundColor="white"
            width="100%"
          >
            <NavigationBar/>
            <Flex
              height="88px"
              alignItems="center"
              borderBottomWidth={1}
              px="30px"
            >
              <Container display="flex" justifyContent="space-between">
                <HStack spacing={4} flex={1}>
                  <Logo/>
                  <InputSearch flex={1}/>
                  <NavigationMenu pl="20px"/>
                </HStack>
              </Container>
            </Flex>
          </Box>
          {children}
        </Flex>
        <Flex
          height="80px"
          backgroundColor={bgFooter}
          alignItems="center"
          borderTopWidth={1}
        >
          <Container
            justifyContent="flex-end"
            textAlign="left"
            fontSize="0.9rem"
            color="gray.400"
            lineHeight="20px"
          >
            <Text>© 2022 Сделаем в России</Text>
            <Text fontSize="0.6rem">{`${versionConfig.version} (${versionConfig.update})`}</Text>
          </Container>
        </Flex>
      </Flex>
    </Box>
  )
}
