import React from 'react'
import { CardWrapper } from 'components/CardWrapper'
import { Heading, Stack } from '@chakra-ui/react'
import { LoginForm } from 'components/LoginForm'
import { LayoutServices } from '../../components/LayoutServices'

export const Enter = () => {
  return (
    <LayoutServices>
      <CardWrapper
        maxW="500px"
        width="100%"
      >
        <Stack pt="28px" pb="30px" px="40px" spacing="20px">
          <Heading fontSize="1.8rem" textAlign="center">Вход</Heading>
          <LoginForm />
        </Stack>
      </CardWrapper>
    </LayoutServices>
  )
}
