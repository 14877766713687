import { useState, useEffect } from 'react'
import { isEmpty } from 'lodash'
import usePagination from '../usePagination'
import { sortDirectionType, sortFieldType } from 'constants/sortType'
import { useLocation } from 'react-router-dom'

export const useEntityList = (
  { request, searchParameters, parameters, sort, isDisabled }
) => {
  const {
    paginationNextPage,
    paginationUpdate,
    hasMoreRows,
    totalCount,
    pageSize
  } = usePagination()
  const location = useLocation()
  const [data, setData] = useState([])

  const _sort = !isEmpty(sort) ? sort.split(':') : [`${sortFieldType.default}`,`${sortDirectionType.descending}`]
  const sortDirection = _sort[1]
  const sortField = _sort[0]

  const dataExportRequest = {
    ...parameters,
    pagingParameters: {
      pageNumber: 1,
      pageSize: 10000,
      sortDirection,
      ...sortField !== sortFieldType.default && { sortField },
    },
    searchParameters
  }

  const update = (data) => {
    if (data?.rows) {
      if (data?.pagingParameters?.pageNumber === 1) {
        setData(data?.rows)
      } else {
        setData(e => [...e, ...data?.rows])
      }
    }
    if (!isEmpty(data?.pagingParameters)) {
      paginationUpdate({
        totalCount: data?.totalCount,
        pageNumber: data?.pagingParameters?.pageNumber
      })
    }
  }

  useEffect(async () => {
    if (isDisabled) {
      return
    }
    setData([])
    const dataRequest = {
      ...parameters,
      pagingParameters: {
        pageNumber: 1,
        pageSize,
        sortDirection,
        ...sortField !== sortFieldType.default && { sortField },
      },
      ...!isEmpty(searchParameters) && {searchParameters}
    }

    const result = await request(dataRequest)
    update(result)
  }, [searchParameters, parameters, sortField, sortDirection, location.pathname, isDisabled])

  const getNextPage = async () => {
    const params = paginationNextPage()
    const result = await request(
      {
        ...parameters,
        pagingParameters: {
          ...params,
          sortDirection,
          ...sortField !== sortFieldType.default && { sortField },
        },
        ...!isEmpty(searchParameters) && {searchParameters}
      })
    update(result)
  }

  return {
    getNextPage,
    hasMoreRows,
    totalCount,
    dataExportRequest,
    dataList: data
  }
}
