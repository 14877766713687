import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Flex,
  HStack,
  Icon, IconButton,
  Text, useColorModeValue
} from '@chakra-ui/react'
import { Brands } from './components/Brands'
import { MdOutlineBuild } from 'react-icons/md'
import React from 'react'
import { SettingsIcon } from '@chakra-ui/icons'

export const Item = ({ data, onUpdateClick }) => {
  const bg = useColorModeValue('gray.50', 'gray.700')
  const colorHover = useColorModeValue('blue.400', 'blue.300')
  const borderColor = useColorModeValue('gray.100', 'transparent')

  const handleUpdate = (e) => {
    e.stopPropagation()
    e.preventDefault()
    onUpdateClick(data)
  }

  return (
    <>
      <AccordionItem
        borderWidth={1}
        borderColor={borderColor}
        mb="10px"
        backgroundColor={bg}
        borderRadius="8px"
        overflow="hidden"
      >
        {({ isExpanded }) => (
          <Flex
            flexDirection="column"
            {...isExpanded && {
              backgroundColor: bg
            }}
          >
            <AccordionButton
              role="group"
              height="54px"
              _hover={{
                bg: 'transparent'
              }}
              _focus={{
                outline: 'none',
                boxShadow: 'none'
              }}
            >
              <Flex alignItems="center" justifyContent="space-between" flex={1} pr="12px">
                <HStack
                  transition="0.3s"
                  spacing="10px"
                  flex="1"
                  textAlign="left"
                  alignItems="center"
                  _groupHover={{
                    color: colorHover
                  }}
                >
                  <Icon as={MdOutlineBuild} fontSize="1.1rem" />
                  <Text>{data?.name}</Text>
                </HStack>
                <IconButton
                  variant="ghost"
                  size="sm"
                  aria-label="Update"
                  icon={<SettingsIcon fontSize="0.8rem"/>}
                  borderRadius="full"
                  onClick={handleUpdate}
                />
              </Flex>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4}>
              {isExpanded && (
                <Brands manufacturerId={data?.id}/>
              )}
            </AccordionPanel>
          </Flex>
        )}
      </AccordionItem>
    </>
  )
}
