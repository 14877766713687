import useQuery from 'hooks/useQuery'
import { api } from 'api'
import { useEffect } from 'react';

export const useFiles = (id) => {
  const {
    isLoading,
    response,
    request
  } = useQuery(() => api.accounts.fetchFiles(id))

  useEffect(() => {
    request()
  }, [])

  return ({
    dataFiles: response,
    isLoadingFiles: isLoading
  })
}
