import useQuery from 'hooks/useQuery'
import { api } from 'api'
import { useEffect } from 'react'
import { useToast } from '@chakra-ui/react'

export const useCreateOrder = () => {
  const toast = useToast()
  const {
    isError,
    isLoading,
    isSuccess,
    error,
    request
  } = useQuery((data) => api.order.createItem(data))

  useEffect(() => {
    if (isError) {
      toast({
        title: 'Ошибка',
        description: error.response?.data?.ErrorMessage,
        status: 'error',
      })
    }
  }, [isError])

  useEffect(() => {
    if (isSuccess) {
      toast({
        title: 'Готово',
        description: 'Заявка успешно создана',
        status: 'success',
      })
    }
  }, [isSuccess])

  return ({
    createOrder: request,
    isLoadingCreate: isLoading,
    isSuccessCreate: isSuccess
  })
}
