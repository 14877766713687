import { Box, Center, Flex, useColorModeValue, useRadio, useRadioGroup } from '@chakra-ui/react'
import { useEffect } from 'react'
import { IconMap } from './icons/IconMap'
import { IconList } from './icons/IconList'

const viewOptions = {
  list: 'list',
  map: 'map'
}

const Item = (props) => {
  const color = useColorModeValue('black', 'white')
  const bgActive = useColorModeValue('gray.100', 'gray.600')

  const { getInputProps, getCheckboxProps } = useRadio(props)

  const input = getInputProps()
  const checkbox = getCheckboxProps()

  const Icons = {
    [viewOptions.list]: <IconList fontSize="0.8rem"/>,
    [viewOptions.map]: <IconMap/>
  }

  return (
    <Box as="label">
      <input {...input} />
      <Center
        {...checkbox}
        cursor="pointer"
        color={color}
        transition="0.3s"
        boxSize="30px"
        _checked={{
          //color: colorActive,
          backgroundColor: bgActive
        }}
        _focus={{
          boxShadow: 'none',
        }}
        _hover={{
          opacity: 0.9
        }}
      >
        {Icons[props.children]}
      </Center>
    </Box>
  )
}

export const ViewSwitch = ({ onChange, value }) => {
  const options = [viewOptions.list, viewOptions.map]

  const { getRootProps, getRadioProps, setValue } = useRadioGroup({
    name: 'socials',
    defaultValue: value,
    onChange
  })

  useEffect(() => {
    setValue(value)
  }, [value])

  const group = getRootProps()

  return (
    <Box>
      <Flex
        flexWrap="wrap"
        borderWidth={1}
        borderRadius="var(--chakra-radii-md)"
        overflow="hidden"
        {...group}
      >
        {options.map((value) => {
          const radio = getRadioProps({ value })
          return (
            <Item key={value} {...radio}>
              {value}
            </Item>
          )
        })}
      </Flex>
    </Box>
  )
}
