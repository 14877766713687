import { FixedSizeList as ListWindow } from 'react-window'
import React, { useEffect, useMemo, useState } from 'react'
import { Box, Center, Flex, HStack, Icon, IconButton, Spinner, Text, useColorModeValue } from '@chakra-ui/react'
import { InputSearch } from 'components/InputSearch'
import { filter, isEmpty } from 'lodash'
import { Add } from './components/Add'
import { useCreateBrand } from './services/useCreateBrand'
import { BsList } from 'react-icons/bs'
import { useFetchBrands } from './services/useFetchBrands'
import { useUpdateBrand } from './services/useUpdateBrand'
import { SettingsIcon } from '@chakra-ui/icons'
import { Update } from './components/Update'

export const Brands = ({ manufacturerId }) => {
  const [valueSearch, setValueSearch] = useState('')
  const bg = useColorModeValue('white', 'gray.700')
  const bgHover = useColorModeValue('gray.50', 'whiteAlpha.200')
  const colorHover = useColorModeValue('blue.400', 'blue.300')
  const bgInput = useColorModeValue('white', 'gray.700')

  const [updateValue, setUpdateValue] = useState(null)

  const { createBrand } = useCreateBrand()
  const { updateBrand } = useUpdateBrand()
  const { getBrands, isLoadingFetchBrands, dataBrands } = useFetchBrands()

  const rows = useMemo(() => {
    if (isEmpty(dataBrands?.rows)) {
      return []
    }
    return filter(dataBrands?.rows, e => {
      const name = e?.brand?.toLowerCase() ?? ''
      const _valueSearch = valueSearch.toLowerCase() ?? ''
      return name.includes(_valueSearch)
    })
  }, [dataBrands?.rows, valueSearch])

  const handleCreate = (data) => new Promise(async (resolve, reject) => {
    try {
      await createBrand({
        manufacturerId,
        ...data
      })
      await getBrands({ manufacturerId })
      resolve()
    } catch (e) {
      reject(e)
    }
  })

  const handleUpdate = (data) => new Promise(async (resolve, reject) => {
    try {
      await updateBrand(data)
      await getBrands({ manufacturerId })
      resolve()
    } catch (e) {
      reject(e)
    }
  })

  const handleOpenUpdate = (data) => {
    setUpdateValue(data)
  }

  const handleCloseUpdate = () => {
    setUpdateValue(null)
  }

  useEffect(() => {
    getBrands({ manufacturerId })
  }, [])

  const Row = ({ index, key, style }) => (
    <div key={key} style={{ ...style}} className="post">
      <Flex
        role="group"
        bg={bg}
        height="100%"
        alignItems="center"
        justifyContent="space-between"
        px="14px"
        transition="0.3s"
        flex={1}
        _hover={{
          backgroundColor: bgHover,
        }}
      >
        <HStack spacing="10px">
          <Icon
            as={BsList}
            fontSize="1.1rem"
            transition="0.3s"
          />
          <Text
            _groupHover={{
              color: colorHover
            }}
          >{rows[index]?.brand}</Text>
        </HStack>
        <IconButton
          variant="ghost"
          size="sm"
          opacity={0}
          aria-label="Update"
          icon={<SettingsIcon fontSize="0.8rem"/>}
          borderRadius="full"
          onClick={() => handleOpenUpdate(rows[index])}
          _groupHover={{
            opacity: 1
          }}
        />
      </Flex>
    </div>
  )

  return (
    <>
      <HStack spacing="12px" alignItems="center" mb="12px">
        <InputSearch
          autoFocus
          delay={90}
          placeholder="Поиск по маркам"
          name="search"
          value={valueSearch}
          onChange={e => setValueSearch(e)}
          bg={bgInput}
        />
        <Add onCreate={handleCreate}/>
      </HStack>
      <Box
        borderWidth={1}
        backgroundColor={bg}
        borderRadius="8px"
        overflow="hidden"
      >
        {!isEmpty(rows) ? (
          <ListWindow
            width="100%"
            height={400}
            itemCount={rows?.length}
            itemSize={42}
          >
            {Row}
          </ListWindow>
        ) : (
          <Center height="400px">
            {isLoadingFetchBrands ? <Spinner color="blue.500"/> : 'Нет данных'}
          </Center>
        )}
      </Box>
      <Update
        initialValues={updateValue}
        isOpen={!!updateValue}
        onClose={handleCloseUpdate}
        onUpdate={handleUpdate}
      />
    </>
  )
}
