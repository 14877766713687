import { useHistory, useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react'
import queryString from 'query-string'

const serializeValue = (value) => {
  if (typeof value === 'object' && value !== null) {
    return JSON.stringify(value)
  }
  return value
}

const deserializeValue = (value) => {
  if (typeof value === 'string') {
    try {
      return JSON.parse(value)
    } catch (e) {
      return value // Если это не JSON, оставляем как есть
    }
  }
  return value
}

const ensureArray = (value) => {
  if (!Array.isArray(value)) {
    return [value]
  }
  return value
}

export const useUrlState = (initialState) => {
  const location = useLocation()
  const history = useHistory()

  const parseInitialState = () => {
    const parsed = queryString.parse(location.search)
    const deserialized = { ...initialState }

    Object.keys(parsed).forEach((key) => {
      if (Array.isArray(initialState[key])) {
        deserialized[key] = ensureArray(parsed[key]).map(deserializeValue)
      } else {
        deserialized[key] = deserializeValue(parsed[key])
      }
    })

    return deserialized
  }

  const [state, setState] = useState(parseInitialState)

  useEffect(() => {
    const serializedState = { ...state }

    Object.keys(serializedState).forEach((key) => {
      if (Array.isArray(serializedState[key])) {
        serializedState[key] = serializedState[key].map(serializeValue)
      } else {
        serializedState[key] = serializeValue(serializedState[key])
      }
    })

    const query = queryString.stringify(serializedState)
    history.replace({ search: query })
  }, [state, history])

  const resetFilter = () => {
    setState(initialState)
  }

  return [state, setState, resetFilter]
}
