import React, { useMemo } from 'react'
import { BlockCollection } from 'components/BlockCollection'
import { Box, Button, Grid, Stack } from '@chakra-ui/react'
import { BlockSection } from 'components/BlockSection'
import { Select } from 'components/Select'
import { useAccess } from 'hooks/useAccess'
import { rolesType } from 'constants/rolesType'
import { CompanyLabel } from '../CompanyLabel/CompanyLabel'
import { ClassifierSelects } from 'components/ClassifierSelects'
import { FilterFields } from 'components/FilterFields'
import { find, isEmpty } from 'lodash'
import { FilterFieldsRequired } from 'components/FilterFieldsRequired'
import { RegionSelect } from '../../../../../components/RegionSelect'

const optionsState = [
  {
    label: 'Обновлено',
    value: 2
  },
  {
    label: 'На модерации',
    value: 3
  },
  {
    label: 'Опубликовано',
    value: 4
  },
  {
    label: 'Отменено',
    value: 5
  },
  {
    label: 'Отклонено',
    value: 6
  }
]

const optionsAdmin = [
  {
    label: 'Новая заявка',
    value: 1
  }
]

export const Filter = ({ data, update, reset, isLoading, accountId }) => {
  const [isAdmin] = useAccess([rolesType.admin])

  const classifierId = useMemo(() => {
    if (isEmpty(data.industrySet)) {
      return undefined
    }
    const result = find(data.industrySet, e => e?.levelId === 1)
    if (result) {
      return result?.id
    }
    return undefined
  }, [data.industrySet])

  const machineClassifierId = data?.industrySet?.[0]?.id

  return (
    <Box
      pointerEvents={isLoading ? 'none' : 'auto'}
      opacity={isLoading ? 0.4 : 1}
    >
      <BlockCollection>
        <BlockSection>
          <Grid templateColumns="1fr" gap="14px" pt="20px">
            <Button size="sm" onClick={reset}>Сбросить</Button>
          </Grid>
          {(isAdmin) && (
            <Select
              isMulti
              label="Статус оборудования"
              options={[
                ...!isAdmin ? optionsAdmin : [],
                ...optionsState
              ]}
              placeholder="Выберите..."
              value={data.states}
              onChange={e => update('states', e)}
            />
          )}
          <RegionSelect
            isMulti
            value={data.regions}
            onChange={e => update('regions', e)}
            label="Регион"
          />
          <CompanyLabel accountId={accountId}/>
          <ClassifierSelects
            isClearable
            onChange={e => update('industrySet', e)}
            value={data.industrySet}
          />
          <Stack spacing={5} py="6px">
            <FilterFieldsRequired
              machineClassifierId={machineClassifierId}
              onChange={e => update('fieldsRequired', e)}
              value={data.fieldsRequired}
            />
            <FilterFields
              machineClassifierId={machineClassifierId}
              classifierId={classifierId}
              onChange={e => update('fields', e)}
              value={data.fields}
            />
          </Stack>
        </BlockSection>
      </BlockCollection>
    </Box>
  )
}
