import useQuery from 'hooks/useQuery'
import { api } from 'api'
import { useEffect } from 'react'
import { useToast } from '@chakra-ui/react'

export const useFetchRegionEquipments = () => {
  const toast = useToast()
  const {
    isLoading,
    isSuccess,
    response,
    isError,
    error,
    request
  } = useQuery((data) => api.classifier.fetchRegionEquipments(data))

  useEffect(() => {
    if (isError) {
      toast({
        title: 'Ошибка',
        description: error.response?.data?.ErrorMessage,
        status: 'error',
      })
    }
  }, [isError])

  return ({
    fetchRegionEquipments: request,
    dataRegionEquipments: response,
    isLoadingFetchRegionEquipments: isLoading,
    isSuccessFetchRegionEquipments: isSuccess
  })
}
