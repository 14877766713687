import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { Container } from 'components/Container'
import { PageHeading } from 'components/PageHeading'
import { Tab, TabList, TabPanels, Tabs } from '@chakra-ui/react'
import { TabPanel } from 'components/TabPanel'
import { Equipment } from './tabs/Equipment'
import { Order } from './tabs/Order'
import { Company } from './tabs/Company'
import { InterviewEquipments } from './tabs/InterviewEquipments'
import { YaMetrika } from './tabs/YaMetrika/YaMetrika'
import { useAccess } from 'hooks/useAccess'
import { rolesType } from 'constants/rolesType'
import { ScrollBox } from 'components/ScrollBox'

export const Analytics = () => {
  const history = useHistory()
  const [isAdmin] = useAccess([rolesType.admin])
  const { paramTabIndex } = useParams()
  const [tabIndex, setTabIndex] = useState(0)

  const handleTabsChange = (index) => {
    history.replace(`/analytics/${index}`)
  }

  useEffect(() => {
    setTabIndex(Number(paramTabIndex))
  }, [paramTabIndex])

  if (isAdmin) {
    return (
      <Container>
        <ScrollBox>
          <PageHeading>
            Аналитика
          </PageHeading>
          <Tabs onChange={handleTabsChange} index={tabIndex} isLazy>
            <TabList>
              <Tab>Оборудование</Tab>
              <Tab>Заявки</Tab>
              <Tab>Компании</Tab>
              <Tab>Пользователи</Tab>
              <Tab>Опрос пользователей</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <Equipment/>
              </TabPanel>
              <TabPanel>
                <Order/>
              </TabPanel>
              <TabPanel>
                <Company/>
              </TabPanel>
              <TabPanel>
                <YaMetrika/>
              </TabPanel>
              <TabPanel>
                <InterviewEquipments/>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ScrollBox>
      </Container>
    )
  }

  return (
    <Container>
      <ScrollBox>
        <PageHeading>
          Аналитика
        </PageHeading>
        <Company/>
      </ScrollBox>
    </Container>
  )
}
