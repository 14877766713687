import { useFetchAnalyticOrder } from './services/useFetchAnalyticOrder'
import { useEffect } from 'react'
import { Box, Grid, Heading, Stack, Text } from '@chakra-ui/react'
import { isEmpty } from 'lodash'
import { AnalyticChart } from '../../components/AnalyticChart'
import { Entity } from 'components/Entity'
import { SnippetItem } from '../../components/SnippetItem'
import { Filter } from './components/Filter'
import { useFilter } from './hooks/useFilter'
import { useChartProps } from '../../hooks/useChartProps'

export const Order = () => {
  const { fetchAnalyticOrder, isLoadingFetchAnalyticOrder, data } = useFetchAnalyticOrder()
  const { dataFilter, updateFilter, resetFilter, filterParameters } = useFilter()

  const { chartProps } = useChartProps(dataFilter)

  useEffect(() => {
    fetchAnalyticOrder(filterParameters)
  }, [filterParameters])

  return (
    <Stack spacing="30px">
      <Box>
        <Filter data={dataFilter} update={updateFilter} reset={resetFilter}/>
      </Box>
      <Stack spacing="50px" pb="80px">
        <Stack spacing="20px">
          <Grid
            templateColumns="1fr 1fr"
            templateRows="1fr 1fr"
            gap="34px"
          >
            {!isEmpty(data) && data?.charts?.map((item, index) => (
              <AnalyticChart
                key={index}
                isLoading={isLoadingFetchAnalyticOrder}
                data={item}
                chartProps={chartProps}
              />
            ))}
          </Grid>
        </Stack>
        <Stack spacing="20px">
          <Stack spacing="10px">
            <Heading fontSize="1.4rem">{data?.topRaiting?.title}</Heading>
            <Text>{data?.topRaiting?.description}</Text>
          </Stack>
          <Box>
            <Entity isLoading={isLoadingFetchAnalyticOrder} isEmpty={isEmpty(data?.topRaiting?.values)}>
              {data?.topRaiting?.values?.map((item, index) => (
                <SnippetItem data={item} index={index}/>
              ))}
            </Entity>
          </Box>
        </Stack>
      </Stack>
    </Stack>
  )
}
