import { Box, Grid, HStack, useColorModeValue } from '@chakra-ui/react'
import { InputSearch } from 'components/InputSearch'
import { Add } from './components/Add'
import { filter, isEmpty } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { MachineClassifierList } from './components/MachineClassifierList'
import { FieldList } from './components/FieldList/FieldList'
import { Update } from './components/Update'
import { useFetchRequiredFields } from '../../../../services/useFetchRequiredFields'
import { useCreateRequiredField } from './services/useCreateRequiredField'
import { useUpdateRequiredField } from './services/useUpdateRequiredField'

export const RequiredField = () => {
  const [valueSearch, setValueSearch] = useState('')
  const bgContainer = useColorModeValue('gray.50', 'gray.700')
  const borderColor = useColorModeValue('gray.100', 'transparent')
  const bgInput = useColorModeValue('white', 'gray.700')

  const [updateValue, setUpdateValue] = useState(null)

  const [activeClassifierId, setActiveClassifierId] = useState(null)

  const { fetchRequiredFields, isLoadingFetchRequiredFields, dataRequiredFields } = useFetchRequiredFields()
  const { createRequiredField } = useCreateRequiredField()
  const { updateRequiredField } = useUpdateRequiredField()

  const rows = useMemo(() => {
    if (isEmpty(dataRequiredFields?.rows)) {
      return []
    }
    return filter(dataRequiredFields?.rows, e => {
      const name = e?.name?.toLowerCase() ?? ''
      const _valueSearch = valueSearch.toLowerCase() ?? ''
      return name.includes(_valueSearch)
    })
  }, [dataRequiredFields?.rows, valueSearch])

  const handleCreate = (data) => new Promise(async (resolve, reject) => {
    try {
      await createRequiredField({
        machineClassifierId: activeClassifierId,
        ...data
      })
      await fetchRequiredFields({ machineClassifierId: activeClassifierId })
      resolve()
    } catch (e) {
      reject(e)
    }
  })

  const handleUpdate = (data) => new Promise(async (resolve, reject) => {
    try {
      await updateRequiredField(data)
      await fetchRequiredFields({ machineClassifierId: activeClassifierId })
      resolve()
    } catch (e) {
      reject(e)
    }
  })

  const handleOpenUpdate = (data) => {
    setUpdateValue(data)
  }

  const handleCloseUpdate = () => {
    setUpdateValue(null)
  }

  useEffect(() => {
    if (activeClassifierId) {
      fetchRequiredFields({ machineClassifierId: activeClassifierId })
    }
  }, [activeClassifierId])

  return (
    <>
      <Grid templateColumns="minmax(300px, 400px) 1fr" gap="24px" pb="80px">
        <MachineClassifierList
          activeClassifierId={activeClassifierId}
          setActiveClassifierId={setActiveClassifierId}
        />
        <Box
          borderWidth={1}
          borderColor={borderColor}
          backgroundColor={bgContainer}
          borderRadius="8px"
          overflow="hidden"
          padding="var(--chakra-space-4)"
        >
          <HStack
            spacing="12px"
            alignItems="center"
            mb="12px"
          >
            <InputSearch
              delay={90}
              placeholder="Поиск по полям"
              name="search"
              value={valueSearch}
              onChange={e => setValueSearch(e)}
              bg={bgInput}
            />
            <Add onCreate={handleCreate} isDisabled={!activeClassifierId}/>
          </HStack>
          <FieldList rows={rows} isLoading={isLoadingFetchRequiredFields} onUpdate={handleOpenUpdate}/>
        </Box>
      </Grid>
      <Update
        initialValues={updateValue}
        isOpen={!!updateValue}
        onClose={handleCloseUpdate}
        onUpdate={handleUpdate}
      />
    </>
  )
}
