import React from 'react'
import { Box } from '@chakra-ui/react'
import { EntityList } from 'components/EntityList'
import { Snippet } from 'components/Snippet'
import { useUpdateFavoriteState } from './services/useUpdateFavoriteState'
import { useCompanyData } from './services/useCompanyData'

export const Company = () => {
  const { updateFavorite } = useUpdateFavoriteState()

  const {
    dataList,
    isLoading,
    getNextPage,
    hasMoreRows,
  } = useCompanyData()

  return (
    <Box pb="80px">
      <EntityList
        isLoading={isLoading}
        hasMore={hasMoreRows}
        data={dataList}
        onNext={getNextPage}
        keyField={'accountId'}
      >
        {(item, index) => (
          <Snippet.Company data={item} onFavorite={updateFavorite}/>
        )}
      </EntityList>
    </Box>
  )
}
