import moment from 'moment'
import momentTimezone from 'moment-timezone'
import 'moment/locale/ru'

export const DATE_FORMAT = 'DD.MM.YYYY'
export const TIME_FORMAT = 'HH:mm'
export const DATE_TIME_FORMAT = 'DD.MM.YYYY HH:mm'

export const getTimeZoneMoment = () => {
  const timeZoneName = momentTimezone.tz.guess()
  return momentTimezone.tz(timeZoneName).format('Z')
}

export const isInvalidDate = (date) => {
  return moment(date, DATE_FORMAT, true).isValid()
}

export const isValidTime = (value) => {
  return moment(value, TIME_FORMAT, true).isValid()
  //return hours >= 0 && hours <= 23 && minutes >= 0 && minutes <= 59;
}

export const getDiffDateDays = (start, end) => {
  const startMoment = moment(start ? start : undefined)
  const endMoment = moment(end ? end : undefined)
  return startMoment.diff(endMoment, 'days')
}

export const getDiffDateHours = (start, end) => {
  const startMoment = moment(start ? start : undefined)
  const endMoment = moment(end ? end : undefined)
  return startMoment.diff(endMoment, 'hours')
}

export const getDiffDateMinutes = (start, end) => {
  const startMoment = moment(start ? start : undefined)
  const endMoment = moment(end ? end : undefined)
  return startMoment.diff(endMoment, 'minutes')
}

export const getDateToPicker = (value) => {
  const formattedDate = moment(value, DATE_FORMAT).toDate()
  return formattedDate
}

export const getDateToSubmit = (value) => {
  if (value === null || value === '') {
    return null
  }
  return moment(value, DATE_FORMAT).format()
}

export const getDateTimeToSubmit = (value) => {
  if (value === null || value === '') {
    return null
  }
  return moment(`${value} 00:00`, `${DATE_FORMAT} ${TIME_FORMAT}`).utc(true).format()
}

export const getMoment = (utc = getTimeZoneMoment()) => {
  return moment().utcOffset(utc).format()
}

export const getDateReadFormat = (value) => {
  if (value === null || value === '') {
    return null
  }
  return moment(value).format(DATE_FORMAT)
}

export const getDateReadLocalizationFormat = (value, format = 'DD.MM.YYYY') => {
  moment.locale('ru')
  if (value === null || value === '' || value === undefined) {
    return null
  }
  return moment(value).format(format)
}

export const getTimeReadFormat = (value) => {
  return moment(value).format(TIME_FORMAT)
}

export const getDateTimeReadFormat = (value) => {
  if (value === null || value === '') {
    return null
  }
  return moment(value).format(DATE_TIME_FORMAT)
}

export const twoDigits = (num) => {
  return ('0' + num).slice(-2)
}

export const getDateWeek = () => {
  return moment().subtract(7, 'day')
}

export const getDateMonth = () => {
  return moment().subtract(1, 'months')
}

export const getDateYear = () => {
  return moment().subtract(1, 'year')
}

export const getPrevMonthLastDay = () => {
  return moment().subtract(1, 'months').endOf('month')
}

export const getRangeDateFromType = (type) => {
  switch (type) {
    case 'week': {
      return [
        getDateToSubmit(getDateWeek().set({ h: 0, m: 0, s: 0 })),
        getDateToSubmit(moment().set({ h: 23, m: 59, s: 0 })),
      ]
    }
    case 'month': {
      return [
        getDateToSubmit(getDateMonth().set({ h: 0, m: 0, s: 0 })),
        getDateToSubmit(moment().set({ h: 23, m: 59, s: 0 })),
      ]
    }
    case 'year': {
      return [
        getDateToSubmit(getDateYear().set({ h: 0, m: 0, s: 0 })),
        getDateToSubmit(moment().set({ h: 23, m: 59, s: 0 })),
      ]
    }
    default:
      return ['', '']
  }
}
