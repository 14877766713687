import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Box, Image } from '@chakra-ui/react'
import Logo1 from './images/logo-1.svg'
import Logo2 from './images/logo-2.svg'

export const Logo = () => {
  const [active, setActive] = useState(false)

  useEffect(() => {
    setInterval(() => {
      setActive(e => !e)
    }, 4000)
  }, [])

  return (
    <Link to={'/equipments'}>
      <Box
        position="relative"
        width="111px"
        height="58px"
      >
        <Image
          opacity={active ? 1 : 0}
          position="absolute"
          left={0}
          top={0}
          src={Logo1}
          width="111px"
          height="58px"
          transition="all 0.3s"
          _active={{
            transform: 'scale(0.98)'
          }}
        />
        <Image
          opacity={!active ? 1 : 0}
          position="absolute"
          left={0}
          top={0}
          src={Logo2}
          width="111px"
          height="58px"
          transition="all 0.3s"
          _active={{
            transform: 'scale(0.98)'
          }}
        />
      </Box>
    </Link>
  )
}
